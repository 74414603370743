// NotificationPopover.js
import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import {
   Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  
} from '@mui/material';


const NotificationPopover = ({ id, destination,id_user,name_user }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (destination === 'customer') {
        fetch(`https://api.fortesol.com.br/api/notifications/list-not-view-user/${id_user}/${name_user}`)
          .then((response) => response.json())
          .then((data) => { setNotifications(data); });
      }


      if (destination === 'system') {
        fetch(`https://api.fortesol.com.br/api/notifications/list-not-view-send-to/system`)
          .then((response) => response.json())
          .then((data) => { setNotifications(data); });
      }

      if (destination === 'demand') {
        fetch(`https://api.fortesol.com.br/api/notifications/list-not-view-demand/${id}`)
        .then((response) => response.json())
        .then((data) => { setNotifications(data); });
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [id, destination]);




  const removeNotification = (id_) => {
    fetch(`https://api.fortesol.com.br/api/notifications/update-view/${id_}`, {
      method: 'PUT',
    }).then(() => {
      setNotifications(notifications.filter((notification) => notification.id_notification !== id_));
    });
  };

   
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  
  const [allNotifications, setAllNotifications] = useState([]);
  const [allNotificationsOpen, setAllNotificationsOpen] = useState(false);

  useEffect(() => {
    if (destination === 'customer') {
      fetch(`https://api.fortesol.com.br/api/notifications/list-user/${id_user}/${name_user}`)
        .then((response) => response.json())
        .then((data) => { setAllNotifications(data); });
    }


    if (destination === 'system') {
      fetch(`https://api.fortesol.com.br/api/notifications/list-send-to/system`)
        .then((response) => response.json())
        .then((data) => { setAllNotifications(data); });
    }

    if (destination === 'demand') {
      fetch(`https://api.fortesol.com.br/api/notifications/list-not-view-demand/${id}`)
      .then((response) => response.json())
      .then((data) => { setAllNotifications(data); });
    }
  const interval = setInterval(() => {
    if (destination === 'customer') {
      fetch(`https://api.fortesol.com.br/api/notifications/list-user/${id_user}/${name_user}`)
        .then((response) => response.json())
        .then((data) => { setAllNotifications(data); });
    }


    if (destination === 'system') {
      fetch(`https://api.fortesol.com.br/api/notifications/list-send-to/system`)
        .then((response) => response.json())
        .then((data) => { setAllNotifications(data); });
    }

    if (destination === 'demand') {
      fetch(`https://api.fortesol.com.br/api/notifications/list-not-view-demand/${id}`)
      .then((response) => response.json())
      .then((data) => { setAllNotifications(data); });
    }
  }, 10000);
  return () => clearInterval(interval);
}, [id, destination]);
  const handleOpenAllNotifications = () => {

    setAllNotificationsOpen(true);
  };

  const handleCloseAllNotifications = () => {
    setAllNotificationsOpen(false);
  };
  return (
    <>
       <IconButton color="inherit" onClick={handleOpen}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon sx={{ color: '#000' }} />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ width: '300px', maxHeight: '400px', overflow: 'auto' }}>
          {notifications.length === 0 ? (
            <Typography sx={{ p: 2 }} variant="body2" color="textSecondary">
              Nenhuma notificação
            </Typography>
          ) : (
            notifications.slice(0, 5).map((notification) => (
              <ListItem key={notification.id_notification} divider>
                <ListItemText
                  primary={notification.title}
                  secondary={notification.message}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeNotification(notification.id_notification)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          )}
        </List>
        <Button  onClick={handleOpenAllNotifications} sx={{ width: '100%', fontSize:11 }}>
          Ver todas
        </Button>
      </Popover>

     {/* Dialog para todas as notificações */}
     <Dialog open={allNotificationsOpen} onClose={handleCloseAllNotifications} maxWidth="md" maxHeight="md" fullWidth>
        <DialogTitle textAlign={'center'}>Notificações</DialogTitle>
        <DialogContent dividers>
          <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
            {allNotifications.length === 0 ? (
              <Typography variant="body2" color="textSecondary">
                Nenhuma notificação
              </Typography>
            ) : (
              allNotifications.map((notification) => (
                <ListItem key={notification.id_notification} divider>
                  <ListItemText
                    sx={{ width: '100%', textAlign: 'center' }}
                    primary={notification.title}
                    secondary={`Mensagem: ${notification.message} - Data: ${notification.creation_date} - Visualizado: ${notification.view_date}`}
                  />
                  <ListItemSecondaryAction>
               {/*     <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeNotification(notification.id_notification)}
                    >
                      <DeleteIcon />
                    </IconButton>*/}
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAllNotifications} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationPopover;
