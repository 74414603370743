import React, { useState } from 'react';
import { Box, Modal, IconButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

function ImageViewer({ src, alt, maxHeight }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
      {/* Imagem em miniatura */}
      <img 
        src={src} 
        alt={alt} 
        onClick={handleOpen} 
        style={{ width: '100%', maxHeight: maxHeight, objectFit: 'cover', borderRadius: '8px' }}
      />
      
      {/* Ícone de zoom */}
      <IconButton 
        onClick={handleOpen} 
        sx={{
          position: 'absolute', 
          bottom: 8, 
          right: 8, 
          color: 'white', 
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
        }}
      >
        <ZoomInIcon />
      </IconButton>

      {/* Modal de visualização da imagem */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ outline: 0 }}>
          <img 
            src={src} 
            alt={alt} 
            style={{ width: '90vw', maxHeight: '90vh', objectFit: 'contain', borderRadius: '8px' }}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default ImageViewer;
