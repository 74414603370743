
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdDelete } from 'react-icons/md';
import ApexChart from 'react-apexcharts'
import './style.css'

import makeAnimated from 'react-select/animated';
import { Alert, List } from '@mui/material';
import ListModules from '../../list_Modules';
import ListInverters from '../../list_Inverters';
const animatedComponents = makeAnimated();

function PhotovoltaicInformation(props) {

  const [openListModules, setOpenListModules] = useState(false)
  const handleClickOpenModules = () => {
    setOpenListModules(true)
  }
  const handleCloseModules = () => {
    setOpenListModules(false)
  }

  const [openListInverters, setOpenListInverters] = useState(false)
  const handleClickOpenInverters = () => {
    setOpenListInverters(true)
  }
  const handleCloseInverters = () => {
    setOpenListInverters(false)
  }


  const [recordsInverter, setRecordsInverter] = useState()
  //Pegando dados dos inversores
  useEffect(() => {
    const inverterList = async () => {
      fetch('http://localhost:5000/api/inverters/list', {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
    
          setRecordsInverter(data)

        }
        )
    }
    inverterList();
  }, []);



  const [listSelectedInverter, setListSelectedInverter] = useState(props.P_listSelectedInverter)
  const [selectedInverter, setSelectedInverter] = useState(props.P_selectedInverter)
  const [arrayMppt, setArrayMppt] = useState(props.P_arrayMppt)
  //buscando inversores por id
  const handleAddInverterToList = (id)=>{
    fetch(`http://localhost:5000/api/inverters/list/${id}`, {
      method: 'GET',
    }).then(response => response.json())
      .then(data => {
     
        handleSelectMPPT(data.id_inverters, data.mppt_by_string)
        setSelectedInverter([...selectedInverter, ...data])
      }
      )
  }

  //Calculando e gerando mppts para leitura posterior
  const handleSelectMPPT = (id_inv, mppt_string) => {
   
    //tratando valores indesejados
    if (!mppt_string || mppt_string === undefined || typeof mppt_string === 'number' || mppt_string === '') {
      return
    }
    //separando valores
    if (id_inv, mppt_string) {
      //dividindo valores em array
      var number_mppt = mppt_string.split('/')
      //dividindo valores em array se houver valores de string diferentes por mppt
      var number_string = number_mppt[1].split('+')
      //verificando se há apenas um valor de string
      if (number_mppt.length === 2 && number_string.length === 1) {
        var array = []
        //criando array de mppt
        for (let i = 0; i < parseInt(number_mppt[0]); i++) {
          array.push(i)
        }
        //criando array de string
        var arrayStr = []
        for (let i = 0; i < parseInt(number_mppt[1]); i++) {
          arrayStr.push(i + 1)
        }
        //setando valores
        setArrayMppt([...arrayMppt, { id: id_inv, mppt: array?.map((data) => { return arrayStr?.map((str) => { return { string: str } }) }) }])
       
      }
      //verificando se há mais de um valor de string
      if (number_string.length > 1) {

        var array = []
        //criando array de mppt
        for (let i = 0; i < parseInt(number_mppt[0]); i++) {
          array.push(i)
        }
        //criando array de string
        var arrayStr = []
        var arrayStr2 = []
        //criando array de string
        for (let i = 0; i < number_string.length; i++) {
          for (let j = 0; j < parseInt(number_string[i]); j++) {
            arrayStr2.push(j + 1)
            if (arrayStr2.length === parseInt(number_string[i])) {
              arrayStr.push(arrayStr2)
              arrayStr2 = []
            }

          }

        }
        //setando valores
        setArrayMppt([...arrayMppt, { id: id_inv, mppt: array?.map((data, index) => { return arrayStr[index].map((str) => { return { string: str } }) }) }])


      


        //        setArrayMppt(array.map((data) => { return { string: data } }))
      }
    }
  }


  const [work_around, setWork_around] = useState()

  useEffect(() => {
    //calculando a quantidade de potência dos inversores
    const handleTotalInverter = () => {
      let qtd = selectedInverter?.length

      let saida_t = selectedInverter?.reduce((acc, cur) => acc + parseInt(cur.output_power)/1000, 0)
      let entrada_t = selectedInverter?.reduce((acc, cur) => acc + parseInt(cur.maximum_photovoltaic_power), 0)
    
      document.querySelector('.qtd_total').textContent = qtd
      document.querySelector('.saida_total').textContent = saida_t
      document.querySelector('.entrada_total').textContent = entrada_t
      
    }
    handleTotalInverter()
  }, [recordsInverter, selectedInverter,work_around,listSelectedInverter])
  
 //repassando valores do contador automaticamente
 useEffect(() => {
  const handleCounterInverter = () => {

    listSelectedInverter?.map((record, i) => {
    var cont = 0
    document.querySelector(`.contInverter${record.id_inverters}`).textContent = `${cont}`
    document.querySelector(`.contInversor${record.id_inverters}`).textContent = ` QTD : ${cont}`
    document.querySelector(`.bt-${record.id_inverters}`).disabled = false
    for (let i = 0; i < selectedInverter.length; i++) {
      if (selectedInverter[i].id_inverters === record.id_inverters) {
        cont++
        document.querySelector(`.contInversor${record.id_inverters}`).textContent = ``
        document.querySelector(`.contInversor${record.id_inverters}`).textContent = ` QTD : ${cont}`
        document.querySelector(`.contInverter${record.id_inverters}`).textContent = `${cont}`
      }
    }

  }
  )}
  handleCounterInverter()
}
  , [selectedInverter, listSelectedInverter, work_around])


  //Modules Part
  const [recordsModule, setRecordsModule] = useState()
  const [selectedModules, setSelectedModules] = useState(props.P_selectedModules)
  const [contMod, setContMod] = useState(props.P_countMod)

  //pegando dados dos módulos
  useEffect(() => {
    const moduleList = async () => {
      fetch('http://localhost:5000/api/modules/list-modules', {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
          setRecordsModule(data)
        }
        )
    }
    moduleList();
  }, [work_around]);
  


  //calculando a quantidade de potência dos módulos
  
  useEffect(() => {
    const handleTotalModules = () => {
      let qtd = selectedModules?.reduce((acc, cur) => acc + parseInt(cur.qtd), 0)
      let saida_t = selectedModules?.reduce((acc, cur) => acc + (parseFloat(cur.maximum_power)*qtd)/1000, 0)


      document.querySelector('.qtd_total_mod').textContent = qtd
      document.querySelector('.saida_total_mod').textContent = saida_t?.toFixed(3)
     
    }
    handleTotalModules()
  }, [selectedModules,work_around])



  //Graph Part
  

  const [cargaMax, setCargaMax] = useState(0)
  const [cargaMaxAtual, setCargaMaxAtual] = useState(0)
  const [cargaNominal, setCargaNominal] = useState(0)
  const [cargaNominalAtual, setCargaNominalAtual] = useState(0)
  const [saldoTotal, setSaldoTotal] = useState(0)
  const [saldoAtual, setSaldoAtual] = useState(0)

  const seriesCargaMax = [cargaMax,cargaMaxAtual]

  const optionsCargaMax = {
    chart: {
      type: 'pie',
    },
    labels: [`Carga Máxima (100%)`, `Carga Atual (${cargaMaxAtual}%)`],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }
  const seriesCargaNominal = [cargaNominal,cargaNominalAtual]
  const optionsCargaNominal = {
    chart: {
      type: 'pie',
    },
    labels: [`Carga Nominal (140%)`, `Carga Nominal Atual (${cargaNominalAtual}%)`],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }
  const seriesSaldoTotal = [saldoTotal,saldoAtual]
  const optionsSaldoTotal = {
    chart: {
      type: 'pie',
    },
    labels: [`Potencia Total (${parseFloat(document.querySelector('.entrada_total')?.textContent)} kW)`, `Potencia Usada (${saldoAtual} kW)`],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }
  useEffect(() => {
    //resultado total de potencia
    const handleTotal = () => {

      let saldo = parseFloat(document.querySelector('.entrada_total').textContent) - parseFloat(document.querySelector('.saida_total_mod').textContent)
      let nominal = ((parseFloat(document.querySelector('.saida_total_mod').textContent) / parseFloat(document.querySelector('.saida_total').textContent)) * 100).toFixed(2)
      let max = ((parseFloat(document.querySelector('.saida_total_mod').textContent)) / (parseFloat(document.querySelector('.entrada_total').textContent)) * 100).toFixed(2)
       
       
      let s = document.querySelector('.saldo')
      let n = document.querySelector('.nominal')
      let m = document.querySelector('.max')

      if (isNaN(saldo) || saldo === 'Infinity') saldo = 0
      if (isNaN(nominal) || nominal === 'Infinity') nominal = "0.00"
      if (isNaN(max) || max === 'Infinity') max = '0.00'

      let input = document.querySelectorAll(`#contModule`)
   
      if (max > 99 || max === 100) {
        for (let i = 0; i < input.length; i++) {
          input[i].style.border = '1px solid red'
        }
      } if (max < 100) {
        for (let i = 0; i < input.length; i++) {
          input[i].style.border = '1px solid #ccc'


        }
      }
      if (max > 98 && max < 100) {
        message('Atenção! Você já ultrapassou em 98% da potência máxima do inversor!',
          {
            autoClose: 3000,
            position: 'bottom-right'
          }
        )
      }
      if (max > 100) {
      message('Atenção! Carga máxima de 100% de potência do inversor ultrapassada!',
          {
            autoClose: 3000,
            position: 'bottom-right'
          }
        )
      }
      if (max === 100.00) {
      message('Atenção! Carga máxima de 100% de potência do inversor atingida!',
          {
            autoClose: 3000,
            position: 'bottom-right'
          }
        )
      }

      if (s && n && m) {
        s.textContent = saldo.toFixed(2)
        n.textContent = `${nominal}%`
        m.textContent = `${max}%`
      }

      setCargaMax(100-parseFloat(max))
      setCargaMaxAtual(parseFloat(max))
      setCargaNominal(140-parseFloat(nominal))
      setCargaNominalAtual(parseFloat(nominal))
      setSaldoTotal(parseFloat(saldo.toFixed(2)))
      setSaldoAtual(parseFloat(document.querySelector('.saida_total_mod').textContent))
    }
    handleTotal()
  }, [selectedInverter, selectedModules, work_around])

  const message = (msg) => {
   
    document.getElementById('message1').style.display = 'block'
    document.getElementById('message1').innerHTML = `<strong>Atenção!</strong>${msg}`
    document.getElementById('message2').style.display = 'block'
    document.getElementById('message2').innerHTML = `<strong>Atenção!</strong>${msg}`
    setTimeout(() => {
        if (document.getElementById('message1')) document.getElementById('message1').style.display = 'none'
        if (document.getElementById('message2')) document.getElementById('message2').style.display = 'none'
    }, 10000)
}
  //pegando valores do childrent
  
    props?.P_setListSelectedInverter(listSelectedInverter)
    props?.P_setSelectedInverter(selectedInverter)
    props?.P_setArrayMppt(arrayMppt)
    props?.P_setSelectedModules(selectedModules)
    props?.P_setCountMod(contMod)

 
    props?.handleTestInformation(()=>{
      
      const contModules = document.querySelectorAll('#contModule')
      if(cargaMaxAtual === 0){
        return false
      }
      for(let i = 0; i < contModules.length; i++){
        if(contModules[i].value === '' || contModules[i].value === '0' || contModules[i].value < cargaMaxAtual*0.10){
          message('Preencha todos os campos de quantidade de módulos!')
          contModules[i].style.border = '1px solid red'
          return false
        }else{
          contModules[i].style.border = '1px solid #ccc'
        }
        if(cargaMaxAtual < 10){
          message('Carga Máxima não pode ser menor que 10%!')
          contModules[i].style.border = '1px solid red'
          return false
        }else{
          contModules[i].style.border = '1px solid #ccc'
        }
        if(cargaMaxAtual > 100){
          message('Carga máxima de 100% de potência do inversor ultrapassada!!')
          contModules[i].style.border = '1px solid red'
          return false
        }else{
          contModules[i].style.border = '1px solid #ccc'
        }
      }

      if(cargaMaxAtual > 100){
      message('Carga máxima de 100% de potência do inversor ultrapassada!'
        )
        return false
      }
      if(cargaMaxAtual === 100){
      message('Carga máxima de 100% de potência do inversor atingida!'
        )
        return false
      }
      if(cargaMaxAtual < 10){
        message('Carga Máxima não pode ser menor que 10%!'
        )
        return false
      }
      return true
    })
  
 
  return (
  

     
      <div className='pointMedicoes' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
  <div style={{ minHeight: 35 }}>
                <Alert id='message1' severity="warning" style={{ width: '100%', display: 'none' }} />
            </div>
              <div className="select-system" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <div className="select-inverters">
                <h3 className='title-section' style={{background:'#001849',color:'#ffffff'}}>Selecione um Inversor:</h3 >
                <div className='select-inv-content'>     
                <div style={{minWidth:'285px',
                          maxWidth:'285px',
                          minHeight: '56px',
                    maxHeight: '56px',
                    paddingLeft:'15px',
                    paddingTop:'10px',

                         }}>

                    <Select
                      options={recordsInverter}
                      //onChange={item =>  setControllerInverter(item) }
                      onChange={item => {
                        setListSelectedInverter(item)
                      
              
                      }}
                      getOptionLabel={(option) => option.brand + ' - ' + option.model}
                      getOptionValue={(option) => option.id_inverters}
                      placeholder="Selecione o inversor"
                      isMulti
                      isSearchable={true}
                      //value={controllerInverter}
                      value={listSelectedInverter}
                    />

                    {/*<input type="text" onChange={handleFilterInversor} />*/}
                    {/*<select onChange={e => handleSelectList(parseInt(e.target.value))}>
                <option value="0">Selecione</option>
                {
                  controllerInverter?.map((record, i) => {
                    return (
                      <option key={i} value={record.id_inverters}>{record.brand} - {record.model}</option>
                    )
                  })
                }
              </select>*/}



        

                  </div>
                  <div style={{paddingLeft:'15px',
                    minHeight: '56px',
                    maxHeight: '56px',
                    minWidth:'100px',
                    maxWidth:'100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    
                <button style={{ backgroundColor: '#cab903',cursor:'pointer' }} onClick={() => {
                  handleClickOpenInverters()
                  }}>Inversores</button>
                  <ListInverters open={openListInverters} handleClose={handleCloseInverters} setWork_around={setWork_around} />
                  </div>
                </div> 

                <ul className='limit-range'>
                      {
                        listSelectedInverter?.map((record, i) => {

                          return (
                            <li key={i} className='list-inverters'>


                              <div>
                                <div style={{
                                  display: 'flex',
                                  margin: '5px',
                                  padding: '5px',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}>
                                  <h4 style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  minWidth: '214px',
                                  maxWidth: '214px',
                                }}>{record.brand} - {record.model}

                                  </h4>
                                <span style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  minWidth: '155px',
                                  maxWidth: '155px',
                                }}>
                                  <button style={{ backgroundColor: 'green',fontSize:'10px'  }} className={`bt+${record.id_inverters}`}
                                    onClick={(e) => {
                                      setWork_around(selectedInverter.length)
                                      handleAddInverterToList(record.id_inverters)
                                      handleSelectMPPT(record.id_inverters, record.mppt_by_string)

             
                                    }}
                                  >+</button>
                                  <button style={{ backgroundColor: 'red', fontSize:'10px' }} className={`bt-${record.id_inverters}`}
                                    onClick={(e) => {
                                      setWork_around(e.target.value)
                                      const index = selectedInverter?.map((inv, index) => { if (inv.id_inverters === record.id_inverters) return index })
                                      const filter = index.filter((inv, index) => { if (inv !== undefined) return inv })
                                      if (filter.length - 1 === 0 || !filter.length || filter.length === undefined || filter.length === null || filter.length === '' || filter.length === ' ' || filter.length === '0' || !filter) {
                                        document.querySelector(`.bt-${record.id_inverters}`).disabled = true
                                      }
                                      if (filter[filter.length - 1] === 2 || filter.length === 2) document.querySelector(`.bt-${record.id_inverters}`).disabled = false

                                      const newselectedInverter = selectedInverter.filter((inv, index) => index !== (filter[filter.length - 1] === undefined ? 0 : filter[filter.length - 1]))
                                        
                                      setSelectedInverter(newselectedInverter)
                                      
                                      const arrayMpptFilter = arrayMppt.filter((inv, index) => index !== (filter[filter.length - 1] === undefined ? 0 : filter[filter.length - 1]))
                                      setArrayMppt(arrayMpptFilter)

                                    }}
                                  >-</button>
                                  &nbsp;
                                  &nbsp;
                                  <p style={{fontSize:'10px',minWidth:'41px'}} className={`contInversor${record.id_inverters}`}> QTD : 0 </p>
                                  &nbsp;
                                  <MdDelete
                                    style={{
                                      textAlign: "center",
                                      height: "100%",
                                      marginTop: "-2pt",
                                      marginLeft: '8pt',
                                      cursor: 'pointer'
                                    }}
                                    color='red'
                                    onClick={() => {

                                      const newselectedInverter = selectedInverter.filter((inv, index) => inv.id_inverters !== record.id_inverters)
                                      setSelectedInverter(newselectedInverter)
                                      const arrayMpptFilter = arrayMppt.filter((mppt, index) => mppt.id !== record.id_inverters)
                                      setArrayMppt(arrayMpptFilter)
                                      listSelectedInverter.splice(i, 1)
                                    }}
                                  />
</span>
                                </div>
                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>
                </div>

                <div className="select-modules">
                <h3 className='title-section'style={{background:'#001849',color:'#ffffff'}}>Selecione um Módulo:</h3>

                <div className='select-mod-content'>  
                         <div style={{minWidth:'285px',
                          maxWidth:'285px',
                          minHeight: '56px',
                    maxHeight: '56px',
                    paddingLeft:'15px',
                    paddingTop:'10px',
                         }}>
                    <Select
                      options={recordsModule}
                      onChange={item => {
                        setSelectedModules(item)
                        setContMod(item)
                      }}
                      getOptionLabel={(option) => option.model_modules}
                      getOptionValue={(option) => option.id_modules}
                      placeholder="Selecione o módulo"
                      isMulti
                      isSearchable={true}
                      components={animatedComponents}
                      value={selectedModules}
                    />
             
                  </div>
                  <div style={{paddingLeft:'15px',
                    minHeight: '56px',
                    maxHeight: '56px',
                    minWidth:'100px',
                    maxWidth:'100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <button style={{ backgroundColor: '#cab903',cursor:'pointer' }}
                    onClick={() => {
                      handleClickOpenModules()
                    }}
                  >Módulos</button>
                  <ListModules open={openListModules} handleClose={handleCloseModules} setWork_around={setWork_around} />
                  </div>
                  </div>

                  <ul className='limit-range'>
                      {
                        selectedModules?.map((record, i) => {
                          return (
                            <li key={i} className='list-modules'>
                                <div style={{
                                  display: 'flex',
                                  margin: '5px',
                                  padding: '5px',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}>
                                <h4 style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  minWidth: '214px',
                                  maxWidth: '214px',
                                }}>{record.model_modules}&nbsp;&nbsp;
                                  
                                </h4>
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  minWidth: '155px',
                                  maxWidth: '155px',
                                
                                }}>
                                <input id={`contModule`}  onChange={(e) => {

const newContMod = contMod?.map((mod, index) => {
  if (mod.id_modules === record.id_modules) {
    return { ...mod, qtd: parseInt(e.target.value) }
  }
  return mod
})
setContMod(newContMod)
const newSelectedModules = selectedModules?.map((mod, index) => {
  if (mod.id_modules === record.id_modules) {
    return { ...mod, qtd: parseInt(e.target.value) }
  }
  return mod
})
setSelectedModules(newSelectedModules)

}
}
placeholder='QTD' type="number" style={{ width: '60px' }} min={0} value={record.qtd} className={`qtd${record.id_modules}`} />
<MdDelete
style={{
  textAlign: "center",
  height: "100%",
  marginTop: "-2pt",
  marginLeft: '8pt',
  cursor: 'pointer'
}}
color='red'
onClick={() => {
  const newSelectModules = selectedModules.filter((mod, index) => mod.id_modules !== record.id_modules)
  setSelectedModules(newSelectModules)
}}
/>
                                </div>
                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>

                </div>
              </div>



                <div className="controller">

                  <div style={{
                    minWidth: '500px',
                    maxWidth: '500px',
                    minHeigth: '550px',
                    maxHeigth: '550px',
                    display: 'flex',
                    justifyContent: 'start',
                    flexDirection: 'column',
                    marginRight: '0px'
                  }}>
                    <table style={{ border: '1px solid #000', backgroundColor: 'white',    minWidth: '500px',
                    maxWidth: '500px',
                    minHeight: '275px',
                    maxHeight: '275px',
                    }}>

                      <thead style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', borderBottom: '1px solid #000',minWidth:'495px',maxWidth:'495px',background:'#004fa39c' }}>
                       <tr>
                          <th style={{ textAlign: 'center',minWidth:'495px',background:'rgb(0, 24, 73)',color:'#ffffff' }}>
                            Controle de Potência Inversores
                          </th>
                          <br />
                       
                        </tr>
                        <tr>
                          <th>
                            ID&nbsp;&nbsp;
                          </th>
                          <th style={{ width: '120px' }} >
                            Inversor
                          </th>
                          <th >
                            &nbsp;&nbsp;QTD&nbsp;&nbsp;
                          </th>
                          <th>&nbsp;&nbsp;Nominal de Saída (CA)&nbsp;</th>
                          <th>&nbsp;Máxima de entrada (CC)</th>
                        </tr>
                        <tr>
                          <th style={{ paddingLeft: '180px' }}>Und (kW)&nbsp;</th>
                          <th>&nbsp;Total (kW)&nbsp;&nbsp;</th>
                          <th>&nbsp;Und (kW)&nbsp;</th>
                          <th>&nbsp;Total (kW)</th>
                        </tr>
                      </thead>
                      <tbody style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#efefefe9',
                        minWidth: '495px',
                        maxWidth: '495px',
                        minHeight:'167px',
                        maxHeight:'167px',
                        overflowY:'auto',
                      }}>
                        {
                          listSelectedInverter?.map((record, i) => {
                            return (
                              <tr key={i} style={{ display: 'flex', borderBottom: '1px solid #000',background:"#fff",}} >
                                <tr style={{ display: 'flex' }}>
                                  <td style={{ maxWidth: '30px',fontSize:'12px',paddingTop:'2px' }}>
                                    {i + 1}
                                    &nbsp;&nbsp;</td>
                                  <td 
                                  style={{ maxWidth: '120px', minWidth: '120px',fontSize:'9px', textAlign:'center',paddingTop:'4px' }} >
                                    {record.brand} - {record.model}
                                    </td>
                                </tr>
                                <tr style={{minWidth:'340px',maxWidth:'340px'}}>
                                  <td className={`qtd${i} `} style={{ marginLeft: '60px',fontSize:'12px',maxWidth: '53px', minWidth: '53px'  }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={`contInverter${record.id_inverters} qtd-inversor`}></span>
                                    &nbsp;&nbsp;</td>
                                  <td className={`potencia_nominal${i}`} 
                                  style={{ paddingLeft: '45px',fontSize:'12px',maxWidth: '84px', minWidth: '84px' }}>{
                                  
                                  document.querySelector(`.qtd${i}`) 
                                  && 
                                  (document.querySelector(`.qtd${i}`).textContent) !== '0' 
                                  ? 
                                  (parseInt(record.output_power) / 1000) 
                                  : 
                                  0}
                                  </td>
                                  <td className={`potencia_nominalTotal${i} nominal-total`} 
                                  style={{ paddingLeft: '35px',fontSize:'12px',maxWidth: '67px', minWidth: '67px'  }}>{
                                    
                                    parseInt((document.querySelector(`.qtd${i}`) 
                                    ?
                                    document.querySelector(`.qtd${i}`).textContent 
                                    :
                                    0)) 
                                    * 
                                    (parseInt(document.querySelector(`.potencia_nominal${i}`) 
                                    ?
                                        
                                    document.querySelector(`.potencia_nominal${i}`).textContent 
                                    :
                                    0))
                                  }
                                  </td>
                                  <td className={`potencia_max${i}`} 
                                  style={{ paddingLeft: '35px',fontSize:'12px' ,maxWidth: '74px', minWidth: '74px' }}>{
                                    document.querySelector(`.qtd${i}`) 
                                    && 
                                    document.querySelector(`.qtd${i}`).textContent !== '0' 
                                    ? 
                                    parseInt(record.maximum_photovoltaic_power) 
                                    :
                                    0
                                  }</td>

                                  <td className={`potencia_maxTotal${i} max-total`} 
                                  style={{ paddingLeft: '30px', paddingRight: '0px',fontSize:'12px',maxWidth: '50px', minWidth: '50px'  }}>{
                                    
                                    (parseInt(document.querySelector(`.qtd${i}`) 
                                    ?
                                      document.querySelector(`.qtd${i}`).textContent : 0))
                                       * 
                                      (parseInt(document.querySelector(`.potencia_max${i}`) 
                                      ?
                                        document.querySelector(`.potencia_max${i}`).textContent : 0))
                                  }</td>
                                </tr>
                              </tr>
                            )
                          })
                        }

                      

                      </tbody>
                      <tfoot style={{minHeight:'28px',maxHeight:'28px', display: 'flex', flexDirection: 'column', alignItems: 'start', borderTop: '1px solid #000',background:'#0036709c',color:'#ffffff' }}>
                      <tr style={{ width: '147px', display: 'table',paddingTop:'4px' }}>
                          <td style={{ maxWidth: '100px', minWidth: '100px',fontSize:'12px' }} >&nbsp;&nbsp;Total:&nbsp;&nbsp;</td>
                          <td style={{ paddingLeft: '0px', paddingRight: '20px',fontSize:'12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td style={{ paddingLeft: '20px', paddingRight: '0px',fontSize:'12px' ,maxWidth: '60px', minWidth: '60px' }}>
                            &nbsp;&nbsp;
                            <span className={`qtd_total`} >1</span>
                            </td>
                          <td style={{ paddingLeft: '45px', paddingRight: '25px',fontSize:'12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td style={{ paddingLeft: '20px', paddingRight: '20px',fontSize:'12px' ,maxWidth: '67px', minWidth: '67px'}}>
                            &nbsp;&nbsp;<span className={`saida_total`}>0</span>
                            </td>
                          <td style={{ paddingLeft: '20px', paddingRight: '30px',fontSize:'12px',maxWidth: '54px', minWidth: '54px' }}>
                            &nbsp;
                            </td>
                          <td style={{ paddingLeft: '40px', paddingRight: '0px',fontSize:'12px' }}><span className={`entrada_total`}>0</span></td>
                        </tr>
                      </tfoot>
                    </table>
                   
                    <table style={{ border: '1px solid #000', backgroundColor: 'white',  minWidth: '500px',
                    maxWidth: '500px',
                    minHeight: '275px',
                    maxHeight: '275px',
                
                    }}>

                      <thead style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'start', borderBottom: '1px solid #000',background:'#004fa39c' }}>
                        <tr>
                          <th style={{textAlign: 'center',minWidth:'492px',background:'rgb(0, 24, 73)',color:'#ffffff' }}>
                            Controle de Potência Modulos
                          </th>
                          <br />

                        </tr>
                        <tr  >
                          <th style={{ maxWidth: '30px' }} >
                            ID&nbsp;&nbsp;
                          </th>
                          <th style={{ minWidth: '270px' }}>
                            Modulo
                          </th>
                          <th>
                            &nbsp;&nbsp;QTD&nbsp;&nbsp;
                          </th>
                          <th style={{ paddingLeft: '20px' }}>&nbsp;&nbsp;Nominal de Saída</th>

                        </tr>
                        <tr>
                          <th style={{ paddingLeft: '340px',paddingRight:'20px' }}>Und (kW)&nbsp;</th>
                          <th>&nbsp;Total (kW)</th>

                        </tr>

                      </thead>
                      <tbody style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#efefefe9',
                        minHeight:'167px',
                        maxHeight:'167px',
                        overflowY:'auto',
                      }}>
                        {
                          selectedModules?.map((record, i) => {
                            return (
                              <tr key={i} style={{ display: 'flex', borderBottom: '1px solid #000', padding: '2px',background:'#fff'}}>
                                <tr style={{ display: 'flex' }}>
                                  <td style={{ maxWidth: '30px',fontSize:'12px' }}>{i + 1}&nbsp;&nbsp;</td>
                                  <td style={{ minWidth: '270px', maxWidth: '270px', textAlign: 'center',fontSize:'9px',paddingTop:'3px' }} > {record.brand_modules} - {record.model_modules}</td>
                                </tr>
                                <tr>
                                  <td style={{ marginLeft: '0px', textAlign: 'center', minWidth: '60px', maxWidth: '60px',fontSize:'12px' }}><span className='qtd-modulos'>{record.qtd ? record.qtd : 0}</span>&nbsp;&nbsp;</td>
                                  <td style={{ paddingLeft: '12px', textAlign: 'center', minWidth: '66px', maxWidth: '66px',fontSize:'12px' }}>{parseInt(record.maximum_power) ? parseInt(record.maximum_power) : 0}</td>
                                  <td className='max-saida-mod' style={{ paddingLeft: '15px', paddingRight: '0px', textAlign: 'center' , minWidth: '66px', maxWidth: '66px',fontSize:'12px'}}>{((parseInt(record.maximum_power) * (record.qtd ? record.qtd : 0)) / 1000).toFixed(3)}</td>
                                </tr>
                              </tr>
                            )
                          })
                        }
  
                      </tbody>
                      <tfoot style={{ minHeight:'28px',maxHeight:'28px', display: 'flex', flexDirection: 'column', alignItems: 'start', borderTop: '1px solid #000',background:'#0036709c',color:'#ffffff'  }}>
                      <tr style={{ width: '150px', display: 'table',paddingTop:'4px' }}>
                          <td style={{ maxWidth: '270px', minWidth: '270px',fontSize:'12px'}} >&nbsp;&nbsp;Total:&nbsp;&nbsp;</td>
                          <td style={{ paddingLeft: '0px', paddingRight: '0px',fontSize:'12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td style={{ paddingLeft: 'px', paddingRight: '0px', minWidth: '52px', maxWidth: '52px',fontSize:'12px',textAlign:'center' }}>&nbsp;&nbsp;<span className={`qtd_total_mod`} >1</span>&nbsp;&nbsp;</td>
                          <td style={{ paddingLeft: '40px', paddingRight: '20px',fontSize:'12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td style={{ paddingLeft:'15px', paddingRight: '0px', minWidth: '66px', maxWidth: '66px',fontSize:'12px',textAlign:'center' }}>&nbsp;&nbsp;<span className={`saida_total_mod`} >1</span>&nbsp;&nbsp;</td>

                        </tr>
                      </tfoot>
                    </table>
                  </div>
<div className='content-graf'>
                  <table style={{ minWidth: '399px',maxWidth:'399px', minHeight:'78.5px',maxHeight:'78.5px', display: 'table', border: '1px solid #000', backgroundColor: 'white' }}>
                    <thead style={{ background:'#004fa39c',minWidth: '394px',maxWidth:'394px', display: 'table', borderBottom: '1px solid #000' }}>
                      <tr style={{textAlign: 'center',minWidth:'399px',background:'rgb(0, 24, 73)',color:'#ffffff'}}>
                        <th style={{ minWidth:'39px' }}>INVERSORES</th>
                      </tr>
                      <tr style={{ width: '150px', display: 'table' }}>
                        <th style={{ minWidth:'100px',maxWidth: '100px',paddingRight:'90px' }}>SALDO (kW)</th>
                        <th style={{ paddingLeft: '0px' }}>CARGA</th>
                      <tr style={{textAlign: 'center',minWidth:'394px',maxWidth:'394px',}}>
                        <th style={{ paddingLeft: '180px',fontSize:'10px',minWidth:'180px',maxWidth:'180px' }}>NOMINAL*</th>
                        <th style={{fontSize:'10px',paddingLeft: '150px' }}>MÁXIMA</th>
                      </tr>
                      </tr>
                    </thead>
                    <tbody style={{ width: '150px', display: 'table' }}>
                      <tr style={{ paddingRight: '0px', display: 'table' }}>
                        <td className='saldo' style={{ paddingLeft: '20px', maxWidth: '30px',fontSize:'13px' }}>0</td>
                        <td className='nominal' style={{ paddingLeft: '150px', maxWidth: '30px',fontSize:'13px' }}>0.00%</td>
                        <td className='max' style={{ paddingLeft: '150px', maxWidth: '30px',fontSize:'13px' }}>0.00%</td>
                      </tr>
                    </tbody>
                  </table>
                  <div style={{
                    minWidth: '400px',
                    maxWidth: '400px',
                    minHeight: '472px',
                    maxHeight: '472px',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    padding: '10px 0',
                    flexDirection: 'column',
                  }}>
                 
                  <div style={{minHeight:'157px',maxHeight:'157px',minWidth:'399px',maxWidth:'399px',display:'flex',alignItems:'center',justifyContent:'center'}}><ApexChart options={optionsCargaMax} series={seriesCargaMax} type="pie" height={150} width={350} /></div>
                  <div style={{minHeight:'157px',maxHeight:'157px',minWidth:'399px',maxWidth:'399px',display:'flex',alignItems:'center',justifyContent:'center',paddingLeft:'15px'}}><ApexChart options={optionsCargaNominal} series={seriesCargaNominal} type="pie" height={150} width={370} /></div>
                  <div style={{minHeight:'157px',maxHeight:'157px',minWidth:'399px',maxWidth:'399px',display:'flex',alignItems:'center',justifyContent:'center'}}><ApexChart options={optionsSaldoTotal} series={seriesSaldoTotal} type="pie" height={150} width={350} /></div>
                  </div>    
                  </div>
                </div>
                <div style={{ minHeight: 35,paddingTop:'5px' }}>
                <Alert id='message2' severity="warning" style={{ width: '100%', display: 'none' }} />
            </div>
              </div>
              
  )
}

export default PhotovoltaicInformation
