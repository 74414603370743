import Notes from "../Notes";
import './style.css';
const NoteContainer = () => {
    return (
        <div className="note-container">
            <h2>Notes</h2>
            <div className="note-container-notes">
            <Notes note={{
                text: "Write your note here...",
                time: "10:18 pm 22 August",
                color: "#bcd803",
            }}
            />
            <Notes note={{
                text: "Write your note here...",
                time: "10:18 pm 22 August",
    
                color: "#bcd803",
            }}
            />
          
            
            </div>
        </div>
    );
}
export default NoteContainer;