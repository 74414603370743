import React, { useState } from 'react';


// Páginas do projeto

import ProjectInformation from './projectInformation.jsx';
import AddressInformation from './addressInformation.jsx';

import Loading from '../loading/index.jsx';

// Importando biblioteca para gerar PDF
import html2pdf from 'html2pdf.js';

// Importando componentes do Material UI
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  Tooltip,
} from '@mui/material';
import Sidebar from '../Sidebar/index.jsx';

// Importando componentes de notificação
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderSolProjetos from '../headeSolProjetos/index.jsx';
import Customers_Of_Customers_Create from './customers_of_customers_create.jsx';
import Photovoltaic_Information from './photovoltaic_information.jsx';
import Documentation from './documentation.jsx';
import validator from 'validator';
import { redirect } from 'react-router-dom';
import ConfirmDialog from '../popup_confirm_dialog/index.jsx';
import InfoDialog from '../popup_info_dialog/index.jsx';
import CelebrationIcon from '@mui/icons-material/Celebration';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

// Função principal do componente

function ServiceProjectSolarCustomer() {

  const token = localStorage.getItem('token')
  const tokenParse = JSON.parse(token)
  const id_customer_integrator = tokenParse.id

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  const [customerCheck, setCustomerCheck] = useState(false)

  // Pag 1 : Estados para armazenar os dados do cliente

  const [name, setName] = useState('')
  const [identify, setIdentify] = useState('')
  const [tipoDocIdentify, setTipoDocIdentify] = useState('')
  const [outroTipoDocIdentify, setOutroTipoDocIdentify] = useState('')
  const [origemDocIdentify, setOrigemDocIdentify] = useState('')
  const [cpf, setCpf] = useState('')
  const [civil_state, setCivil_state] = useState('')
  const [nationality, setNationality] = useState('')
  const [profession, setProfession] = useState('')
  const [fantasy_name, setFantasy_name] = useState('')
  const [corporate_reason, setCorporate_reason] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  const [password, setPassword] = useState('')
  const [cep, setCep] = useState('')
  const [street, setStreet] = useState('')
  const [number, setNumber] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [complement, setComplement] = useState('')
  const [name_representative, setName_representative] = useState('')
  const [identify_representative, setIdentify_representative] = useState('')
  const [tipoDocIdentifyRepresentative, setTipoDocIdentifyRepresentative] = useState('')
  const [outroTipoDocIdentifyRepresentative, setOutroTipoDocIdentifyRepresentative] = useState('')
  const [origemDocIdentifyRepresentative, setOrigemDocIdentifyRepresentative] = useState('')
  const [cpf_representative, setCpf_representative] = useState('')
  const [nationality_representative, setNationality_representative] = useState('')
  const [profession_representative, setProfession_representative] = useState('')



  const [tipoPessoa, setTipoPessoa] = useState('pf')
  const [usuario_uc, setUsuario_uc] = useState('cliente_terceiro')

  const [file_identify, setFile_identify] = useState('')
  const [file_social_contract, setFile_social_contract] = useState('')

  const [selectedCustomer, setSelectedCustomer] = useState('')


  const [msgCabo, setMsgCabo] = useState('')
  const [msgDisjuntor, setMsgDisjuntor] = useState('')

  const [openDialog, setOpenDialog] = useState(false)
  const [msgDialog1, setMsgDialog1] = useState('')
  const [msgDialog2, setMsgDialog2] = useState('')
  const [titleDialog, setTitleDialog] = useState('')
  const [confirmDialog, setConfirmDialog] = useState(false)

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleConfirmDialog = () => {
    setConfirmDialog(true)
    setOpenDialog(false)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [titleInfoDialog, setTitleInfoDialog] = useState('PROJETO SOLICITADO COM SUCESSO!')
  const [msgInfoDialog1, setMsgInfoDialog1] = useState('Procuração baixada automaticamente. É necessário que seja enviada assinada posteriormente.')
  const [msgInfoDialog2, setMsgInfoDialog2] = useState('SOL PROJETOS AGRADECE A PREFERÊNCIA!')
  const [confirmInfoDialog, setConfirmInfoDialog] = useState(false)


  const [celebrar, setCelebrar] = useState(false);
  const { width, height } = useWindowSize(); // Para ajustar o confetti ao tamanho da tela

  const celebration = () => {
    setCelebrar(true);
    setTimeout(() => setCelebrar(false), 5000); // A celebração dura 5 segundos
  }

  const handleClickOpenInfoDialog = () => {
    celebration()
    setOpenInfoDialog(true)
  }

  const handleCloseInfoDialog = () => {
    navigate('/solar-service')
    setOpenInfoDialog(false)
  }

  const handleConfirmInfoDialog = () => {
    setConfirmInfoDialog(true)
    handleCloseInfoDialog()
  }




  const clearFields = () => {
    setName('')
    setCpf('')
    setIdentify('')
    setTipoDocIdentify('')
    setOutroTipoDocIdentify('')
    setCivil_state('')
    setNationality('')
    setProfession('')
    setFantasy_name('')
    setCorporate_reason('')
    setCnpj('')
    setEmail('')
    setContact('')
    setCep('')
    setStreet('')
    setNumber('')
    setNeighborhood('')
    setCity('')
    setState('')
    setComplement('')
    setName_representative('')
    setIdentify_representative('')
    setTipoDocIdentifyRepresentative('')
    setOutroTipoDocIdentifyRepresentative('')
    setCpf_representative('')
    setNationality_representative('')
    setProfession_representative('')
    setFile_identify('')
    setFile_social_contract('')

  }
  // Page 2 : Estados para armazenar os dados do projeto
  const [selectedProjectType, setSelectedProjectType] = useState('');
  const [selectedServiceType, setSelectedServiceType] = useState('');
  const [selectedBranchType, setSelectedBranchType] = useState('aereo');
  const [selectedModalityType, setSelectedModalityType] = useState('');
  const [selectedCableType, setSelectedCableType] = useState('');
  const [selectedNominalSection, setSelectedNominalSection] = useState('');
  const [selectedDisjuntor, setSelectedDisjuntor] = useState('');
  const [selectedContractedDemand, setSelectedContractedDemand] = useState('');
  const [selectedPowerRede, setSelectedPowerRede] = useState('');
  const [selectedNumberUC, setSelectedNumberUC] = useState('');
  const [selectedNumberCustomerUC, setSelectedNumberCustomerUC] = useState('');
  const [selectedNumberART, setSelectedNumberART] = useState('');
  const [selectedProjectOBS, setSelectedProjectOBS] = useState('');

  const [selectedFile_electricity_bill, setSelectedFile_electricity_bill] = useState('');
  const [selectedFile_disjuntor, setSelectedFile_disjuntor] = useState('');
  const [selectedFile_energy_box, setSelectedFile_energy_box] = useState('');
  const [selectedFile_ART, setSelectedFile_ART] = useState('');


  // Pag 3 : Estados para armazenar os dados de endereço
  const [selectedAddressCep, setSelectedAddressCep] = useState('');
  const [selectedAddressStreet, setSelectedAddressStreet] = useState('');
  const [selectedAddressNumber, setSelectedAddressNumber] = useState('');
  const [selectedAddressDistrict, setSelectedAddressDistrict] = useState('');
  const [selectedAddressCity, setSelectedAddressCity] = useState('');
  const [selectedAddressState, setSelectedAddressState] = useState('');
  const [selectedAddressComplement, setSelectedAddressComplement] = useState('');

  const [selectedLatitudeClick, setSelectedLatitudeClick] = useState('');
  const [selectedLongitudeClick, setSelectedLongitudeClick] = useState('');

  const [checkedAddress, setCheckedAddress] = useState(false);
  // Pag 4 : Estados para armazenar os dados dos módulos e inversores
  const [info_kit, setInfo_kit] = useState('');
  const [array_qgbt_demand, setArray_qgbt_demand] = useState([{ ptc: '', corrente: '' }])
  const [selectedInvertersArray, setSelectedInvertersArray] = useState([{ fase: '', qtd: '', ptc: '' }]);
  const [selectedModulesArray, setSelectedModulesArray] = useState([{ quantidade: '' }]);
  const [file_data_photovoltaic, setFile_data_photovoltaic] = useState('');
  // Pag 5 : Estados html para salvar dados do sistema

  const [dataRateio, setDataRateio] = useState('');

  const [ucs, setUcs] = useState([]);
  const [kwhValues, setKwhValues] = useState([]);
  const [percentages, setPercentages] = useState([]);
  const [resultPercentages, setResultPercentages] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);

  const [writeRateio, setWriteRateio] = useState([{ uc: '', percentage: '' }]);
  const [resultWriteRateio, setResultWriteRateio] = useState(0);
  const [rateioType, setRateioType] = useState('write');

  //juntando dois arrays em um unico array
  const newArray = ucs.map((item, index) => {
    return { uc: item, kwh: kwhValues[index], percentage: percentages[index] }
  })








  //Função para download do memorial descritivo

  const [activeStep, setActiveStep] = useState(0);


  const steps = ['Informações do Projeto', 'Informações do Cliente', 'Informações de Endereço'];

  const handleNextasd_ = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    // Verificando as condições para cada etapa antes de avançar
    switch (activeStep) {
      case 0:
        /*
                      if (!file_data_photovoltaic) {
        
        
                  selectedInvertersArray.map((inverter, index) => {
                    if (!inverter.marca) {
                      toast.error('Campo marca do inversor vazio')
                      document.getElementById(`brand-inverter-${index}`)
                      return
                    } else {
                      document.getElementById(`brand-inverter-${index}`).style.border = 'transparent'
                    }
                    if (!inverter.modelo) {
                      toast.error('Campo modelo do inversor vazio')
                      document.getElementById(`model-inverter-${index}`)
                      return
                    } else {
                      document.getElementById(`model-inverter-${index}`).style.border = 'transparent'
                    }
                    if (!inverter.quantidade) {
                      toast.error('Campo quantidade do inversor vazio')
                      document.getElementById(`quantity-inverter-${index}`)
                      return
                    } else {
                      document.getElementById(`quantity-inverter-${index}`).style.border = 'transparent'
                    }
                  })
                  selectedModulesArray.map((module, index) => {
                    if (!module.marca) {
                      toast.error('Campo marca do módulo vazio')
                      document.getElementById(`brand-module-${index}`)
                      return
                    } else {
                      document.getElementById(`brand-module-${index}`).style.border = 'transparent'
                    }
                    if (!module.modelo) {
                      toast.error('Campo modelo do módulo vazio')
                      document.getElementById(`model-module-${index}`)
                      return
                    } else {
                      document.getElementById(`model-module-${index}`).style.border = 'transparent'
                    }
                    if (!module.quantidade) {
                      toast.error('Campo quantidade do módulo vazio')
                      document.getElementById(`quantity-module-${index}`)
                      return
                    } else {
                      setActiveStep((prevActiveStep) => prevActiveStep + 1);
                      document.getElementById(`quantity-module-${index}`).style.border = 'transparent'
                    }
                  })
        
                  }
               
                       if (checked) {
               
                         ucs?.map((uc, index) => {
                           if (!uc) {
                             toast.error('Campo UC vazio')
                             document.getElementById(`uc-${index}`)?.focus()
                             return
                           }
                         })
                         kwhValues?.map((kwh, index) => {
                           if (!kwh) {
                             toast.error('Campo Kwh vazio')
                             document.getElementById(`kwh-${index}`)?.focus()
                             return
                           }
                         })
                         percentages?.map((percentage, index) => {
                           if (!percentage) {
                             toast.error('Calcule a porcentagem')
                             return
                           }
                         })
               
               
               
                       }
*/

        // Condições para a página de informações do projeto
// Função genérica para verificar campos obrigatórios
function checkRequiredField(value, fieldId, errorMessage) {
  const field = document.getElementById(fieldId);
  if (!value) {
    field.style.border = '1px solid #fe0000';
    toast.error(errorMessage);
    return true;
  } else {
    field.style.border = 'transparent';
    return false;
  }
}

// Validação dos inversores
const invertersError = selectedInvertersArray.some((inverter, index) => {
  let error = false;
  if (checkRequiredField(inverter.fase, `fase-inverter-${index}`, 'Selecione a conexão do inversor')) {
    error = true;
  }
  if (checkRequiredField(inverter.ptc, `ptc-inverter-${index}`, 'Informe a potência do inversor')) {
    error = true;
  }
  return error;
});

// Verificações do formulário
if (
  !invertersError &&
  !checkRequiredField(selectedNumberUC, 'number-uc', 'Preencha o número da UC.') &&
  !checkRequiredField(selectedNumberCustomerUC, 'number-customer-uc', 'Preencha o número do cliente.') &&
  !checkRequiredField(selectedProjectType, 'project-type', 'Selecione a classificação do projeto.') &&
  !checkRequiredField(selectedServiceType, 'service-type', 'Selecione o tipo de fornecimento.') &&
  !checkRequiredField(selectedCableType, 'type_cable', 'Selecione o tipo de cabo.') &&
  !checkRequiredField(selectedNominalSection, 'nominal_section', 'Selecione a seção do ramal de conexão.') &&
  !checkRequiredField(selectedDisjuntor, 'disjuntor', 'Selecione o disjuntor.') &&
  !checkRequiredField(selectedBranchType, 'branch-type', 'Selecione o tipo de ramal.') &&
  !checkRequiredField(selectedModalityType, 'rateio', 'Informe se haverá rateio ou não.') &&
  !checkRequiredField(selectedFile_electricity_bill, 'electricity-bill', 'Envie o arquivo da conta de luz.') &&
  (file_data_photovoltaic || info_kit) &&
  !(selectedModalityType === 'autoconsumo_remoto'? (rateioType === 'write' ? resultWriteRateio !== 100 : parseFloat(resultPercentages) !== 100):false) 
) {
  // Demanda Contratada específica para certos tipos de projetos
  if (['a4_horaria_verde', 'a4_horaria_azul'].includes(selectedProjectType) && !selectedContractedDemand) {
    return toast.error('Campo Demanda Contratada é obrigatório');
  }
  
  // Exibir dialog de confirmação se necessário
  if ((msgCabo || msgDisjuntor) && !confirmDialog) {
    handleClickOpenDialog();
    setTitleDialog('Confirmação');
    setMsgDialog1('Avisos:');
    setMsgDialog2('Você deseja continuar?');
  } else {
    return setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
} else {
  if (selectedModalityType === 'autoconsumo_remoto' && ((rateioType === 'write' && resultWriteRateio !== 100) || (rateioType === 'calc' && parseFloat(resultPercentages) !== 100))) {
    return toast.error('A soma das porcentagens deve ser 100%.');
  }
}

break;
      case 1:

        // Condições para a página de informações do cliente
        function validateField(value, fieldId, errorMessage) {
          if (!value) {
            toast.error(errorMessage);
            document.getElementById(fieldId).style.border = '1px solid #fe0000';
            return false;
          }
          document.getElementById(fieldId).style.border = 'transparent';
          return true;
        }
        
        function validateCustomerForm() {
          let isValid = true;
        
          if (tipoPessoa === 'pf') {
            isValid = validateField(name, 'nome', 'Campo nome vazio') && isValid;
            isValid = validateField(tipoDocIdentify, 'tipo_identidade', 'Campo tipo de documento vazio') && isValid;
            isValid = tipoDocIdentify === 'Outro' ? validateField(outroTipoDocIdentify, 'desc_identidade', 'Campo outro tipo de documento vazio') && isValid : isValid;
            isValid = validateField(identify, 'identidade', 'Campo RG vazio') && isValid;
            isValid = tipoDocIdentify === 'RG' ? validateField(origemDocIdentify, 'origem_doc', 'Campo origem de documento vazio') && isValid : isValid;
            isValid = validateField(cpf, 'cpf', 'Campo CPF vazio') && isValid;
            isValid = validateField(civil_state, 'estadoCivil', 'Campo estado civil vazio') && isValid;
            isValid = validateField(nationality, 'nacionalidade', 'Campo nacionalidade vazio') && isValid;
            isValid = validateField(profession, 'profissao', 'Campo profissão vazio') && isValid;
            isValid = validateField(cep, 'cep_pf', 'Campo CEP vazio') && isValid;
            isValid = validateField(street, 'logradouro_pf', 'Campo logradouro vazio') && isValid;
            isValid = validateField(number, 'numero_pf', 'Campo número vazio') && isValid;
            isValid = validateField(neighborhood, 'bairro_pf', 'Campo bairro vazio') && isValid;
            isValid = validateField(city, 'cidade_pf', 'Campo cidade vazio') && isValid;
            isValid = validateField(state, 'estado_pf', 'Campo estado vazio') && isValid;
        
            if (!customerCheck && !file_identify) {
              toast.error('Envie o arquivo do documento de identificação');
              isValid = false;
            }
          }
        
          if (tipoPessoa === 'pj') {
            isValid = validateField(cnpj, 'cnpj', 'Campo CNPJ vazio') && isValid;
            isValid = validateField(corporate_reason, 'razao_social', 'Campo razão social vazio') && isValid;
            isValid = validateField(cep, 'cep_pj', 'Campo CEP vazio') && isValid;
            isValid = validateField(street, 'logradouro_pj', 'Campo logradouro vazio') && isValid;
            isValid = validateField(number, 'numero_pj', 'Campo número vazio') && isValid;
            isValid = validateField(neighborhood, 'bairro_pj', 'Campo bairro vazio') && isValid;
            isValid = validateField(city, 'cidade_pj', 'Campo cidade vazio') && isValid;
            isValid = validateField(state, 'estado_pj', 'Campo estado vazio') && isValid;
            isValid = validateField(cpf_representative, 'cpf_representante', 'Campo CPF representante vazio') && isValid;
            isValid = validateField(name_representative, 'nome_representante', 'Campo nome representante vazio') && isValid;
        
            if (tipoDocIdentifyRepresentative === 'Outro') {
              isValid = validateField(outroTipoDocIdentifyRepresentative, 'desc_identidade_representante', 'Campo outro tipo de documento vazio') && isValid;
            }
            if (tipoDocIdentifyRepresentative === 'RG') {
              isValid = validateField(origemDocIdentifyRepresentative, 'origem_doc_representante', 'Campo origem de documento vazio') && isValid;
            }
        
            if (!customerCheck && !file_identify) {
              toast.error('Envie o arquivo do documento de identificação');
              isValid = false;
            }
          }

          
          if (isValid) {
            handleClickCustomerNewEdit();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
        
        validateCustomerForm();

        break;
      case 2:
        // Condições para a página de informações de endereço
        if (
          selectedAddressCep !== '' &&
          selectedAddressStreet !== '' &&
          selectedAddressNumber !== '' &&
          selectedAddressDistrict !== '' &&
          selectedAddressCity !== '' &&
          selectedAddressState !== '' &&
          selectedLatitudeClick !== '' &&
          selectedLongitudeClick !== ''
        ) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          if (selectedAddressCep === '') {
            document.getElementById('cep-service').style.border = '1px solid #fe0000';
            return toast.error('Preencha o campo CEP');
          }else{
            document.getElementById('cep-service').style.border = 'transparent';
          }
          if (selectedAddressStreet === '') {
            document.getElementById('street-service').style.border = '1px solid #fe0000';
            return toast.error('Preencha o campo Rua');
          }else{
            document.getElementById('street-service').style.border = 'transparent';
          }
          if (selectedAddressNumber === '') {
            document.getElementById('number-service').style.border = '1px solid #fe0000';
            return toast.error('Preencha o campo Número');
          }else{
            document.getElementById('number-service').style.border = 'transparent';
          }
          if (selectedAddressDistrict === '') {
            document.getElementById('district-service').style.border = '1px solid #fe0000';
            return toast.error('Preencha o campo Bairro');
          }else{
            document.getElementById('district-service').style.border = 'transparent';
          }
          if (selectedAddressCity === '') {
            document.getElementById('city-service').style.border = '1px solid #fe0000';
            return toast.error('Preencha o campo Cidade');
          }else{
            document.getElementById('city-service').style.border = 'transparent';
          }
          if (selectedAddressState === '') {
            document.getElementById('state-service').style.border = '1px solid #fe0000';
            return toast.error('Preencha o campo Estado');
          }else{
            document.getElementById('state-service').style.border = 'transparent';
          }
          if (!selectedLatitudeClick && !selectedLongitudeClick) {
            return toast.error('Clique no mapa para definir a localização');
          }
        }


        break;
      case 3:

        // Condições para a página de documentação




        break;


      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };


  //funções do step 0

  const searchByCpf = async (cpf) => {

    if (!cpf || cpf.length < 14) {

      return
    }
    try {
      const response = await fetch(`https://api.fortesol.com.br/api/customers_of_customers/search/cpf/${cpf}`)
        .then(response => response.json())
        .then(data => {
          if (data.message === 'Customer not found') {

            return
          }

          setSelectedCustomer(data)

        })
    } catch (error) {

    }
  }

  const searchByCnpj = async (cnpj) => {

    if (!cnpj || cnpj.length < 18) {
      return
    }
    try {
      const response = await fetch(`https://api.fortesol.com.br/api/customers_of_customers/search/cnpj?cnpj=${cnpj}`)
        .then(response => response.json())
        .then(data => {
          if (data.message === 'Customer not found') {
            return
          }

          setSelectedCustomer(data)
        })
    } catch (error) {

    }
  }


  const handleNewCustomer = async () => {

    if (tipoPessoa === 'pf') {




      //const response = await fetch('https://api-aplication-fortesol.vercel.app/api/photovoltaic_modules', {
      const response = await fetch('https://api.fortesol.com.br/api/customers_of_customers/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name_customers_of_customers: name, identify_customers_of_customers: identify, cpf_customers_of_customers: cpf, civil_state_customers_of_customers: civil_state, nationality_customers_of_customers: nationality, profession_customers_of_customers: profession, email_customers_of_customers: email, contact_customers_of_customers: contact, cep_customers_of_customers: cep, street_customers_of_customers: street, number_customers_of_customers: number, neighborhood_customers_of_customers: neighborhood, city_customers_of_customers: city, state_customers_of_customers: state, complement_customers_of_customers: complement, id_customer_integrator: id_customer_integrator, type_doc_identify_customers_of_customers: tipoDocIdentify === 'Outro' ? outroTipoDocIdentify : tipoDocIdentify, origin_doc_identify_customers_of_customers: origemDocIdentify })
      }).then((response) => response.json())
        .then((data) => {
          if (data.message === 'ok') {
            if (file_social_contract) {
              fetch(`https://api.fortesol.com.br/api/customers_of_customers/upload/contract-social/${data.id}`, {
                method: 'POST',
                body: file_social_contract
              }).then(response => response.json())
                .then(data => {
                  if (data.message === 'ok') {
                  }
                }).catch(err => {
                  toast.error('Erro ao enviar contrato social')
                })
            }

            if (file_identify) {
              fetch(`https://api.fortesol.com.br/api/customers_of_customers/upload/identify/${data.id}`, {
                method: 'POST',
                body: file_identify
              }).then(response => response.json())
                .then(data => {
                  if (data.message === 'ok') {
                  }
                }).catch(err => {
                  toast.error('Erro ao enviar RG/CNH')
                })
            }
            searchByCpf(cpf)

          }
          else {

          }
        }).catch((error) => {

        })
      return response
    }

    if (tipoPessoa === 'pj') {

      //const response = await fetch('https://api-aplication-fortesol.vercel.app/api/photovoltaic_modules', {
      const response = await fetch('https://api.fortesol.com.br/api/customers_of_customers/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fantasy_name_customers_of_customers: fantasy_name, corporate_reason_customers_of_customers: corporate_reason, cnpj_customers_of_customers: cnpj, email_customers_of_customers: email, contact_customers_of_customers: contact, cep_customers_of_customers: cep, street_customers_of_customers: street, number_customers_of_customers: number, neighborhood_customers_of_customers: neighborhood, city_customers_of_customers: city, state_customers_of_customers: state, complement_customers_of_customers: complement, name_representative_customers_of_customers: name_representative, identify_representative_customers_of_customers: identify_representative, cpf_representative_customers_of_customers: cpf_representative, nationality_representative_customers_of_customers: nationality_representative, profession_representative_customers_of_customers: profession_representative, id_customer_integrator: id_customer_integrator, type_doc_identify_representative_customers_of_customers: tipoDocIdentifyRepresentative === 'Outro' ? outroTipoDocIdentifyRepresentative : tipoDocIdentifyRepresentative, origin_doc_identify_representative_customers_of_customers: origemDocIdentifyRepresentative })
      }).then((response) => response.json())
        .then((data) => {
          if (data.message === 'ok') {
            if (file_social_contract) {
              fetch(`https://api.fortesol.com.br/api/customers_of_customers/upload/contract-social/${data.id}`, {
                method: 'POST',
                body: file_social_contract
              }).then(response => response.json())
                .then(data => {
                  if (data.message === 'ok') {
                  }
                }).catch(err => {
                  toast.error('Erro ao enviar contrato social')
                })
            }

            if (file_identify) {
              fetch(`https://api.fortesol.com.br/api/customers_of_customers/upload/identify/${data.id}`, {
                method: 'POST',
                body: file_identify
              }).then(response => response.json())
                .then(data => {
                  if (data.message === 'ok') {
                  }
                }).catch(err => {
                  toast.error('Erro ao enviar RG/CNH')
                })
            }
            searchByCnpj(cnpj)
          }
          else {

          }
        }).catch((error) => {

        })
      return response
    }

  }


  const handleEditCustomer = async () => {

    if (tipoPessoa === 'pf') {

      //const response = await fetch(`https://api-aplication-fortesol.vercel.app/api/customers/${props?.data.id}`, {
      const response = await fetch(`https://api.fortesol.com.br/api/customers_of_customers/update/${selectedCustomer.id_customers_of_customers}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name_customers_of_customers: name, identify_customers_of_customers: identify, cpf_customers_of_customers: cpf, civil_state_customers_of_customers: civil_state, nationality_customers_of_customers: nationality, profession_customers_of_customers: profession, fantasy_name_customers_of_customers: selectedCustomer.fantasy_name_customers_of_customers, corporate_reason_customers_of_customers: selectedCustomer.corporate_reason_customers_of_customers, cnpj_customers_of_customers: selectedCustomer.cnpj_customers_of_customers, email_customers_of_customers: email, contact_customers_of_customers: contact, cep_customers_of_customers: cep, street_customers_of_customers: street, number_customers_of_customers: number, neighborhood_customers_of_customers: neighborhood, city_customers_of_customers: city, state_customers_of_customers: state, complement_customers_of_customers: complement, id_customer_integrator, type_doc_identify_customers_of_customers: tipoDocIdentify === 'Outro' ? outroTipoDocIdentify : tipoDocIdentify, type_doc_identify_representative_customers_of_customers: selectedCustomer.type_doc_identify_representative_customers_of_customers, origin_doc_identify_customers_of_customers: origemDocIdentify })
      }).then((response) => response.json())
        .then((data) => {

          if (data.message === 'Customer updated successfully') {

          }
        }).catch((error) => {

        })
      return response
    }
    if (tipoPessoa === 'pj') {


      const response = await fetch(`https://api.fortesol.com.br/api/customers_of_customers/update/${selectedCustomer.id_customers_of_customers}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name_customers_of_customers: selectedCustomer.name_customers_of_customers, identify_customers_of_customers: selectedCustomer.identify_customers_of_customers, cpf_customers_of_customers: selectedCustomer.cpf_customers_of_customers, civil_state_customers_of_customers: selectedCustomer.civil_state_customers_of_customers, nationality_customers_of_customers: selectedCustomer.nationality_customers_of_customers, profession_customers_of_customers: selectedCustomer.profession_customers_of_customers, fantasy_name_customers_of_customers: fantasy_name, corporate_reason_customers_of_customers: corporate_reason, cnpj_customers_of_customers: cnpj, email_customers_of_customers: email, contact_customers_of_customers: contact, cep_customers_of_customers: cep, street_customers_of_customers: street, number_customers_of_customers: number, neighborhood_customers_of_customers: neighborhood, city_customers_of_customers: city, state_customers_of_customers: state, complement_customers_of_customers: complement, name_representative_customers_of_customers: name_representative, identify_representative_customers_of_customers: identify_representative, cpf_representative_customers_of_customers: cpf_representative, nationality_representative_customers_of_customers: nationality_representative, profession_representative_customers_of_customers: profession_representative, id_customer_integrator, type_doc_identify_customers_of_customers: selectedCustomer.type_doc_identify_customers_of_customers, type_doc_identify_representative_customers_of_customers: tipoDocIdentifyRepresentative === 'Outro' ? outroTipoDocIdentifyRepresentative : tipoDocIdentifyRepresentative, origin_doc_identify_representative_customers_of_customers: origemDocIdentifyRepresentative })
      }).then((response) => response.json())
        .then((data) => {

          if (data.message === 'Customer updated successfully') {

          }
        }).catch((error) => {

        })
      return response
    }



  }

  const handleClickCustomerNewEdit = async () => {
    if (tipoPessoa === 'pf') {
      await searchByCpf(cpf)
    }
    if (tipoPessoa === 'pj') {
      await searchByCnpj(cnpj)
    }
    if (selectedCustomer) {
      await handleEditCustomer()
    } else {
      await handleNewCustomer()
    }
  }

  const handleDateValid = () => {
    var data = new Date()
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (dia < 10) {
      dia = `0${dia}`
    }
    if (mes === 1) {
      mes = 'Janeiro'
    }
    if (mes === 2) {
      mes = 'Fevereiro'
    }
    if (mes === 3) {
      mes = 'Março'
    }
    if (mes === 4) {
      mes = 'Abril'
    }
    if (mes === 5) {
      mes = 'Maio'
    }
    if (mes === 6) {
      mes = 'Junho'
    }
    if (mes === 7) {
      mes = 'Julho'
    }
    if (mes === 8) {
      mes = 'Agosto'
    }
    if (mes === 9) {
      mes = 'Setembro'
    }
    if (mes === 10) {
      mes = 'Outubro'
    }
    if (mes === 11) {
      mes = 'Novembro'
    }
    if (mes === 12) {
      mes = 'Dezembro'
    }
    var data = `${dia} de ${mes} de ${ano + 1}`
    return data
  }

  const handleDate = () => {
    var data = new Date()
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (dia < 10) {
      dia = `0${dia}`
    }
    if (mes === 1) {
      mes = 'Janeiro'
    }
    if (mes === 2) {
      mes = 'Fevereiro'
    }
    if (mes === 3) {
      mes = 'Março'
    }
    if (mes === 4) {
      mes = 'Abril'
    }
    if (mes === 5) {
      mes = 'Maio'
    }
    if (mes === 6) {
      mes = 'Junho'
    }
    if (mes === 7) {
      mes = 'Julho'
    }
    if (mes === 8) {
      mes = 'Agosto'
    }
    if (mes === 9) {
      mes = 'Setembro'
    }
    if (mes === 10) {
      mes = 'Outubro'
    }
    if (mes === 11) {
      mes = 'Novembro'
    }
    if (mes === 12) {
      mes = 'Dezembro'
    }

    var data = `${dia} de ${mes} de ${ano}`
    return data
  }


  const informations = {
    cnpj: cnpj,
    name: tipoPessoa === 'pf' ? name : corporate_reason,
    cpf: cpf,
    profession: profession,
    identify: identify,
    type_identify: tipoDocIdentify,
    ssp: tipoDocIdentify === 'RG' ? 'SSP-CE' : '',
    street: street,
    number: number,
    cpmt: complement,
    district: neighborhood,
    city: city,
    state: state,
    cep: cep,
    name_r: name_representative,
    cpf_r: cpf_representative,
    identify_r: identify_representative,
    type_identify_r: tipoDocIdentifyRepresentative,
    ssp_r: tipoDocIdentifyRepresentative === 'RG' ? 'SSP-CE' : '',
    profession_r: profession_representative,
    nationality_r: nationality_representative,
    validity: handleDateValid(),
    date: handleDate()
  };

  const handleProcurationDownloadPDF = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://api.fortesol.com.br/api/demand_project_solar/generate-procuration_pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(informations),
      });

      if (response.ok) {
        // Cria um Blob a partir da resposta
        const blob = await response.blob();

        const url = window.URL.createObjectURL(new Blob([blob]));

        const a = document.createElement('a');
        a.href = url;
        a.download = `Procuração Particular - ${name ? name : corporate_reason}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url); // Limpa o objeto URL
        setIsLoading(false);
      } else {
        console.error('Erro ao gerar o PDF:', await response.json());
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Erro ao enviar a requisição:', error);
      setIsLoading(false);
    }
  };
  const sendDemands = async () => {
    setIsLoading(true)


    if (!selectedCustomer.id_customers_of_customers) return

    const arrayInverter = JSON.stringify(selectedInvertersArray)
    const arrayModule = JSON.stringify(selectedModulesArray)
    const arrayRateio = JSON.stringify(newArray.length > 0 ? newArray : writeRateio)
    const dataNow = new Date()
    await fetch('https://api.fortesol.com.br/api/demand_project_solar/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ project_type_demand: selectedProjectType, service_type_demand: selectedServiceType, modality_type_demand: selectedModalityType, power_rede_demand: selectedPowerRede, disjuntor_demand: selectedDisjuntor, number_uc_demand: selectedNumberUC, project_obs_demand: selectedProjectOBS, cep_service_demand: selectedAddressCep, street_service_demand: selectedAddressStreet, district_service_demand: selectedAddressDistrict, city_service_demand: selectedAddressCity, state_service_demand: selectedAddressState, lat_demand: selectedLatitudeClick, log_demand: selectedLongitudeClick, status: 'Solicitado', date_demand: dataNow, date_conclusion_demand: '---', id_customer_integrator: id_customer_integrator, id_customer_of_customers: selectedCustomer.id_customers_of_customers, type_cable_demand: selectedCableType, electricity_bill: '---', nominal_section_demand: selectedNominalSection, branch_type_demand: selectedBranchType, contracted_demand_demand: selectedContractedDemand ? selectedContractedDemand : '---', photos_breaker: '---', energy_box_photos: '---', info_inverter_demand: arrayInverter, info_module_demand: arrayModule, info_data_photovoltaic_demand: '---', info_rateio_demand: arrayRateio, number_service_demand: selectedAddressNumber, doc_proxy_demand: '---', info_proxy_demand: 'send', uc_registered_user: usuario_uc, complement_service_demand: selectedAddressComplement, msgCabo: msgCabo, msgDisjuntor: msgDisjuntor, number_customer_uc: selectedNumberCustomerUC, array_qgbt_demand: JSON.stringify(array_qgbt_demand), info_kit_fotovoltaic_demand: info_kit ,signed_descriptive_memorial:'',single_line_diagram:'',access_opinion:'',operational_relationship:'', link_enel_project:'' })
    }).then(response => response.json())
      .then(data => {
        const id = data.id

        if (selectedFile_electricity_bill) {
          fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageElectricityBill/${id}`, {
            method: 'POST',
            body: selectedFile_electricity_bill
          }).then(response => response.json())
            .then(data => {
              if (data.message === 'Image uploaded successfully') {
              }
            }).catch(err => {
              toast.error('Erro ao enviar conta de luz')
            })
        }
        if (selectedFile_disjuntor) {
          fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImagePhotosBreaker/${id}`, {
            method: 'POST',
            body: selectedFile_disjuntor
          }).then(response => response.json())
            .then(data => {
              if (data.message === 'Image uploaded successfully') {
              }
            }).catch(err => {
              toast.error('Erro ao enviar fotos do disjuntor')
            })
        }
        if (selectedFile_energy_box) {
          fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageEnergyBoxPhotos/${id}`, {
            method: 'POST',
            body: selectedFile_energy_box
          }).then(response => response.json())
            .then(data => {
              if (data.message === 'Image uploaded successfully') {
              }
            }).catch(err => {
              toast.error('Erro ao enviar fotos da caixa de energia')
            })
        }
        if (file_data_photovoltaic) {
          fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageDataPhotovoltaic/${id}`, {
            method: 'POST',
            body: file_data_photovoltaic
          }).then(response => response.json())
            .then(data => {
              if (data.message === 'Image uploaded successfully') {
              }
            }).catch(err => {
              toast.error('Erro ao enviar arquivo de dados fotovoltaicos')
            })
        }

        setIsLoading(false)
        fetch(`https://api.fortesol.com.br/api/timeline/new`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({

            event: 'Memorial Descritivo',
            details: 'Memorial Descritivo Solicitado',
            demand_id: id,
            name_user: tokenParse.name,
          })
        })

      
      fetch(`https://api.fortesol.com.br/api/notifications/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              
            customers_id: tokenParse.id,
            title: `Projeto Solicitado`,
              message: `Projeto cod: ${id} solicitado.`,                      
            view: false,
            view_date: '',
            creation_date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
            send_by: tokenParse.name,
            send_to: 'system',
            id_demand_notifications: id,
          }),
        }).then((response) => response.json())
          .then((data) => {})
  
        toast.success('PROJETO SOLICITADO COM SUCESSO!')
        handleClickOpenInfoDialog()
        handleProcurationDownloadPDF()
      }).catch(err => {
        setIsLoading(false)
        toast.error('Erro ao enviar demanda')
      })


  }










  //Envio de formulário para o servidor








  return (
    <Sidebar>
      {isLoading && <Loading />}
      <HeaderSolProjetos />
      <br />
      <Container maxWidth="md" >
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          zIndex={9999999999}
        />
        {window.innerWidth > 800 && <Stepper activeStep={activeStep} orientation='horizontal'>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>}
        {window.innerWidth <= 800 && <Stepper activeStep={activeStep} orientation='vertical'>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>}
        <div >
          <Container maxWidth="md">

            {
              celebrar && <Confetti
                width={width}
                height={height}
                numberOfPieces={500}
                gravity={0.2}
                wind={0.01}
              />
            } {/* Confetti na tela */}
            <Stepper activeStep={activeStep}>
          
              <ConfirmDialog open={openDialog} close={handleCloseDialog} confirm={handleConfirmDialog} title={titleDialog} msg1={msgDialog1} msg2={msgDialog2} msgCabo={msgCabo} msgDisjuntor={msgDisjuntor} />
              <InfoDialog header='Sol Projetos' open={openInfoDialog} close={handleCloseInfoDialog} title={titleInfoDialog} msg1={msgInfoDialog1} msg2={msgInfoDialog2} confirm={handleConfirmInfoDialog} icon={<CelebrationIcon color='#dec804' />} confirmText='confirmar' />
              {activeStep === 0 && <ProjectInformation
                //Kit Fotovoltaico
                inverters={selectedInvertersArray}
                modules={selectedModulesArray}
                image={file_data_photovoltaic}

                setInverters={setSelectedInvertersArray}
                setModules={setSelectedModulesArray}
                setImage={setFile_data_photovoltaic}
                //Informações do Padrão de Entrada

                projectType={selectedProjectType}
                serviceType={selectedServiceType}
                branchType={selectedBranchType}
                modalityType={selectedModalityType}
                typeCable={selectedCableType}
                nominalSection={selectedNominalSection}
                disjuntor={selectedDisjuntor}
                contractedDemand={selectedContractedDemand}
                powerRede={selectedPowerRede}
                numberUC={selectedNumberUC}
                numberART={selectedNumberART}
                projectObs={selectedProjectOBS}
                file_electricity_bill={selectedFile_electricity_bill}
                file_disjuntor={selectedFile_disjuntor}
                file_energy_box={selectedFile_energy_box}
                fileArt={selectedFile_ART}

                setProjectType={setSelectedProjectType}
                setServiceType={setSelectedServiceType}
                setBranchType={setSelectedBranchType}
                setModalityType={setSelectedModalityType}
                setTypeCable={setSelectedCableType}
                setNominalSection={setSelectedNominalSection}
                setDisjuntor={setSelectedDisjuntor}
                setContractedDemand={setSelectedContractedDemand}
                setPowerRede={setSelectedPowerRede}
                setNumberUC={setSelectedNumberUC}
                setNumberART={setSelectedNumberART}
                setProjectObs={setSelectedProjectOBS}
                setFileElectricityBill={setSelectedFile_electricity_bill}
                setFileDisjuntor={setSelectedFile_disjuntor}
                setFileEnergyBox={setSelectedFile_energy_box}
                setFileArt={setSelectedFile_ART}

                activeStep={activeStep}

                msgCabo={msgCabo}
                setMsgCabo={setMsgCabo}
                msgDisjuntor={msgDisjuntor}
                setMsgDisjuntor={setMsgDisjuntor}
                numberCustomerUC={selectedNumberCustomerUC}
                setNumberCustomerUC={setSelectedNumberCustomerUC}
                QGBT={array_qgbt_demand}
                setQGBT={setArray_qgbt_demand}
                info_kit={info_kit}
                setInfo_kit={setInfo_kit}

                rateioType={rateioType}
                setRateioType={setRateioType}
                writeRateio={writeRateio}
                setWriteRateio={setWriteRateio}
                resultWriteRateio={resultWriteRateio}
                setResultWriteRateio={setResultWriteRateio}

                ucs={ucs}
                setUcs={setUcs}
                kwhValues={kwhValues}
                setKwhValues={setKwhValues}
                percentages={percentages}
                setPercentages={setPercentages}
                resultPercentages={resultPercentages}
                setResultPercentages={setResultPercentages}

                checked={checked}
                setChecked={setChecked}
              />

              }

              {activeStep === 1 && <Customers_Of_Customers_Create

                name={name}
                cpf={cpf}
                identify={identify}
                tipoDocIdentify={tipoDocIdentify}
                outroTipoDocIdentify={outroTipoDocIdentify}
                origemDocIdentify={origemDocIdentify}
                civil_state={civil_state}
                nationality={nationality}
                profession={profession}
                fantasy_name={fantasy_name}
                corporate_reason={corporate_reason}
                cnpj={cnpj}
                email={email}
                contact={contact}
                password={password}
                cep={cep}
                street={street}
                number={number}
                neighborhood={neighborhood}
                city={city}
                state={state}
                complement={complement}
                name_representative={name_representative}
                tipoDocIdentifyRepresentative={tipoDocIdentifyRepresentative}
                outroTipoDocIdentifyRepresentative={outroTipoDocIdentifyRepresentative}
                origemDocIdentifyRepresentative={origemDocIdentifyRepresentative}
                identify_representative={identify_representative}
                cpf_representative={cpf_representative}
                nationality_representative={nationality_representative}
                profession_representative={profession_representative}
                setName={setName}
                setCpf={setCpf}
                setCivil_state={setCivil_state}
                setNationality={setNationality}
                setProfession={setProfession}
                setFantasy_name={setFantasy_name}
                setCorporate_reason={setCorporate_reason}
                setCnpj={setCnpj}
                setEmail={setEmail}
                setContact={setContact}
                setPassword={setPassword}
                setCep={setCep}
                setStreet={setStreet}
                setNumber={setNumber}
                setNeighborhood={setNeighborhood}
                setCity={setCity}
                setState={setState}
                setComplement={setComplement}
                setIdentify={setIdentify}
                setTipoDocIdentify={setTipoDocIdentify}
                setOutroTipoDocIdentify={setOutroTipoDocIdentify}
                setOrigemDocIdentify={setOrigemDocIdentify}

                setName_representative={setName_representative}
                setIdentify_representative={setIdentify_representative}
                setTipoDocIdentifyRepresentative={setTipoDocIdentifyRepresentative}
                setOutroTipoDocIdentifyRepresentative={setOutroTipoDocIdentifyRepresentative}
                setOrigemDocIdentifyRepresentative={setOrigemDocIdentifyRepresentative}
                setCpf_representative={setCpf_representative}
                setNationality_representative={setNationality_representative}
                setProfession_representative={setProfession_representative}

                tipoPessoa={tipoPessoa}
                setTipoPessoa={setTipoPessoa}

                usuario_uc={usuario_uc}
                setUsuario_uc={setUsuario_uc}

                file_identify={file_identify}
                file_social_contract={file_social_contract}
                setFile_identify={setFile_identify}
                setFile_social_contract={setFile_social_contract}

                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}

                clearFields={clearFields}

                customerCheck={customerCheck}
                setCustomerCheck={setCustomerCheck}

                searchByCpf={searchByCpf}
                searchByCnpj={searchByCnpj}
              />
              }

              {activeStep === 2 &&
                <AddressInformation

                  addressCep={selectedAddressCep}
                  addressStreet={selectedAddressStreet}
                  addressNumber={selectedAddressNumber}
                  addressComplement={selectedAddressComplement}
                  addressDistrict={selectedAddressDistrict}
                  addressCity={selectedAddressCity}
                  addressState={selectedAddressState}
                  latitudeClick={selectedLatitudeClick}
                  longitudeClick={selectedLongitudeClick}

                  setAddressCep={setSelectedAddressCep}
                  setAddressStreet={setSelectedAddressStreet}
                  setAddressNumber={setSelectedAddressNumber}
                  setAddressComplement={setSelectedAddressComplement}
                  setAddressDistrict={setSelectedAddressDistrict}
                  setAddressCity={setSelectedAddressCity}
                  setAddressState={setSelectedAddressState}
                  setLatitudeClick={setSelectedLatitudeClick}
                  setLongitudeClick={setSelectedLongitudeClick}

                  cep={cep}
                  street={street}
                  number={number}
                  complement={complement}
                  neighborhood={neighborhood}
                  city={city}
                  state={state}

                  checkedAddress={checkedAddress}
                  setCheckedAddress={setCheckedAddress}


                />}
              {/*activeStep === 3 &&
                <Photovoltaic_Information
                  inverters={selectedInvertersArray}
                  modules={selectedModulesArray}
                  image={file_data_photovoltaic}

                  setInverters={setSelectedInvertersArray}
                  setModules={setSelectedModulesArray}
                  setImage={setFile_data_photovoltaic}
                />*/}
              {activeStep === 3 &&
                <Documentation
                  name={name}
                  identify={identify}
                  tipoDocIdentify={tipoDocIdentify}
                  cpf={cpf}
                  civil_state={civil_state}
                  nationality={nationality}
                  profession={profession}
                  fantasy_name={fantasy_name}
                  corporate_reason={corporate_reason}
                  cnpj={cnpj}
                  email={email}
                  contact={contact}
                  password={password}
                  cep={cep}
                  street={street}
                  number={number}
                  neighborhood={neighborhood}
                  city={city}
                  state={state}
                  complement={complement}
                  name_representative={name_representative}
                  identify_representative={identify_representative}
                  tipoDocIdentifyRepresentative={tipoDocIdentifyRepresentative}
                  cpf_representative={cpf_representative}
                  nationality_representative={nationality_representative}
                  profession_representative={profession_representative}
                  tipoPessoa={tipoPessoa}



                  expanded={expanded}
                  setExpanded={setExpanded}


                  modalityType={selectedModalityType}


                />}
            </Stepper>

          </Container>
          <Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mt: 3 }}
            >
              Voltar
            </Button>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              {activeStep === steps.length - 1 ? (

                <Tooltip
                  title={(!selectedLongitudeClick && !selectedLatitudeClick) ? 'Preencha o endereço e selecione o teclado do cliente.' : ''}
                  arrow
                >
                  <span>
                    <Button disabled={!selectedLongitudeClick && !selectedLatitudeClick} id='btn-download' type="button" variant="contained" color="primary" onClick={sendDemands}>
                      Solicitar Projeto
                    </Button>
                  </span>
                </Tooltip>

              ) : (
                activeStep > 0 ?

                  <Button
                    id='btn-next'
                    type='button'
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    Próximo
                  </Button>

                  :

                  <Button
                    id='btn-next'
                    type='button'
                    variant="contained"
                    color="primary"
                    onClick={() => {

                      handleNext()
                    }}
                  >
                    Próximo
                  </Button>


              )}
            </Box>
          </Grid>
        </div>
        <br />
      </Container>
    </Sidebar>
  )
}

export default ServiceProjectSolarCustomer
