import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React, { useState } from 'react'
import HeaderSolProjetos from '../headeSolProjetos'

export default function InfoDialog(props) {
    
 
  return (
    <Dialog
    open={props.open}
    onClose={props.close}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
       
    {
        props.header === 'Sol Projetos' ? <HeaderSolProjetos/> : null 
    }
    <DialogTitle id="alert-dialog-title" textAlign={'center'} 
    >{props.title} {props.icon}</DialogTitle>
    <DialogContent sx={{boxShadow: '0px 0px 10px 0px #00000057'}} >
      <DialogContentText id="alert-dialog-description">
        <br />
        <Typography variant="h6" component="div" textAlign={'center'} fontFamily={'serif'}>
        {props.msg1}
        </Typography>
        <br />
        <Typography variant="h6" component="div" textAlign={'center'} fontFamily={'serif'}>
        {props.msg2}
        </Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions >
      <Button onClick={props.confirm} color="success" autoFocus>
        {props.confirmText}
      </Button>
    </DialogActions>
  </Dialog>
  )
}
