import React from 'react'
import GlobalStyle from './styles/global'
import RoutesApp from './routes'
import { AuthProvider } from './Context/auth'

import '../src/styles/glob.css'


const App = () => {
  return (
    
    <AuthProvider>
    <GlobalStyle/>
    <RoutesApp/>
    </AuthProvider>
  )
}

export default App 
