import React from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material'
import { GoogleMap, Marker, StreetViewPanorama } from '@react-google-maps/api';

export default function MapView(props) {
  const [latitude, setLatitude] = React.useState(props.data.lat_demand);
  const [longitude, setLongitude] = React.useState(props.data.log_demand);
  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
}

 // Obtenha a largura e a altura da janela
 const larguraTela = '100%';
 const alturaTela = window.innerHeight;

 // A metade da largura é para o mapa maior
 const larguraMapaMaior = larguraTela
 const alturaMapaMaior = alturaTela - alturaTela*0.30;

 // A outra metade é para dois mapas menores, dividindo a altura em duas partes
 const larguraMapaMenor = larguraTela 
 const alturaMapaMenor = alturaTela - alturaTela*0.665;


const options2 = {
  disableDefaultUI: false,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  rotateControl: false,
  fullscreenControl: true,
  mapTypeId: 'satellite',
  streetViewControl: false,
  draggable: true,
  inclination: 0,
  heading: 0,
  tilt: 0,
  zoom: 19,
}
const mapContainerStyle = {
 height: alturaMapaMenor,
  width: larguraMapaMenor
};
    return (
        <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title" textAlign={'center'}    >{props.title}</DialogTitle>
        <DialogContent>
   
          <Grid container spacing={1}>

{   <Grid item xs={4} sm={6} spacing={0}>
<GoogleMap

mapContainerStyle={mapContainerStyle}
zoom={16}
center={center}

>
<Marker position={center} />
</GoogleMap>
<br />
<GoogleMap
    mapContainerStyle={mapContainerStyle}
    zoom={10}
    center={center}
    options={options2}


  >
    <StreetViewPanorama
      position={center}
      visible={true}
      options={{
        enableCloseButton: false,
        addressControl: true,
        fullscreenControl: true,
        panControl: true,
        zoomControl: false,
        clickToGo: true,
        scrollwheel: false,
        visible: true,
        showRoadLabels: true,
        motionTracking: true,
        motionTrackingControl: false,
        motionTrackingControlOptions: true,

      }}
    />
       <Marker position={center} />
  </GoogleMap>
</Grid>}
<Grid item xs={6} sm={6} spacing={0}>
  <GoogleMap
    mapContainerStyle={{  height: alturaMapaMaior , width: larguraMapaMaior }} // Ajuste a altura conforme necessário
    zoom={18}
    options={{
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      rotateControl: true,
      fullscreenControl: true,
      mapTypeId: 'satellite',
      streetViewControl: true,
      draggable: true,
      inclination: 0,
      heading: 0,
      tilt: 0,
      zoom: 19,
    }}
    center={center}
  >
    <Marker position={center} />
  </GoogleMap>
</Grid>
</Grid>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            fechar
          </Button>
        </DialogActions>
      </Dialog>
      )
    }
