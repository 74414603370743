import React, { useEffect, useState } from 'react'


import useAuth  from '../../hooks/useAuth'
import {useForm} from 'react-hook-form';
import { FaUser } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './stylesignin.css'
import Loading from '../../Components/loading';
import validator from 'validator';
import { Height, Visibility, VisibilityOff } from '@mui/icons-material';
import { 
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography 
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';   

const Signin = () => {
  const navigate = useNavigate()
  const {login}= useAuth()
  const [isLoading, setIsLoading] = useState(false)
  


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleLogin = (dataInput) =>{
    setIsLoading(true)
    const email = dataInput.email;
    const password = dataInput.password;
    
    login(email, password).then((response) => {
      setIsLoading(false)
    }).catch((error) => {
      setIsLoading(false)
    }
    )
  
    
 
  }
 useEffect(() => {
  const input_email = document.getElementById('email')
  input_email.addEventListener('keyup', e=>{
    if(e.key === 'Enter'){
      document.getElementById('password').focus()
    }
  }
  )

  const input_password = document.getElementById('password')
  input_password.addEventListener('keyup', e=>{
    if(e.key === 'Enter'){
    
      handleSubmit(handleLogin)()
    }
  }
  )
}, [])
const [showPassword, setShowPassword] = useState(false);


const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};

  return (
    <>
  {isLoading && <Loading />}
   <ToastContainer 
    position='bottom-right' 
    autoClose={3000}
     />
     
  <div className='signin'>
   <div className='container'>
    <div className='content'>
{/*<div className='img'>
<FaUser size={40} color="#000040"/>
</div>*/}


      <CssBaseline />
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <br />
        <br />
        <Grid container spacing={2} sx={{minHeight:'140px'}} gap={1}>
        <Grid item xs={12} sx={{minHeight:'60px'}}>
        <TextField
          error={!!errors.email}
          id="email"
          label="E-mail"
          type="email"
          fullWidth
          size='small'
        
          {...register('email', { 
            required: 'E-mail é obrigatório',
            validate: (value) => validator.isEmail(value) || 'E-mail inválido'
          })}
          helperText={errors.email?.message}
        />
      </Grid>
  <Grid item xs={12} sx={{minHeight:'60px'}}>
        <TextField
          error={!!errors.password}
          id="password"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          size='small'
          

          {...register('password', { required: 'Senha é obrigatória' })}
          helperText={errors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff style={{fontSize:18}}/> : <Visibility style={{fontSize:18}} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
        variant='contained'
        fullWidth
         className='buttonSignin' id='buttonSignin' text="Entrar" onClick={handleSubmit(handleLogin)} >entrar</Button>
        
      </Grid>
      </Grid>
      </Box>
  

       <div>
        </div>
    </div>
    </div>
    </div>

    </>
  )
}

export default Signin
