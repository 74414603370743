
import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import './style.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Popup_demand from '../popup_details_demand';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArticleIcon from '@mui/icons-material/Article';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  TextField,
  Button,
  IconButton,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HeaderSolProjetos from '../headeSolProjetos';
import NotificationPopover from '../notifications';


function ListSolarService(props) {
  const token = JSON.parse(localStorage.getItem('token')) || null;


  const [linhasVisiveis, setLinhasVisiveis] = useState(0);
  const alturaLinha = 54; // Altura de cada linha da tabela (em pixels)

  useEffect(() => {
    const calcularLinhas = () => {
      const alturaTela = window.innerHeight; // Altura da janela do navegador
      const linhas = Math.floor(alturaTela / alturaLinha); // Calcula quantas linhas cabem
      setLinhasVisiveis(linhas);
    };

    // Calcula ao carregar a página
    calcularLinhas();

    // Adiciona um listener para recalcular em caso de redimensionamento da tela
    window.addEventListener('resize', calcularLinhas);

    // Remove o listener ao desmontar o componente
    return () => window.removeEventListener('resize', calcularLinhas);
  }, []);



  const [demand, setDemand] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = linhasVisiveis;


  useEffect(() => {
    // Definir a função fetchDemand fora do setInterval para que possamos chamá-la logo no início e dentro do intervalo.
    const fetchDemand = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-integrator/${props.id}?page=${currentPage}&limit=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setDemand(data);
        setTotalPages(data[1]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    // Chamada inicial para executar o fetchDemand assim que a página abrir
    fetchDemand();
  
    // Iniciar o intervalo para chamadas periódicas
    const interval = setInterval(fetchDemand, 10000);
  
    // Limpeza do intervalo ao desmontar o componente
    return () => clearInterval(interval);
  }, [props.id, currentPage, itemsPerPage]);
  



  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ... renderizar a lista de produtos e a paginação


  const formatDate = (date) => {
    if (date === '---') {
      return '---'
    }
    const dateFormated = new Date(date)
    return dateFormated.toLocaleDateString() + ' ' + dateFormated.toLocaleTimeString()
  };


  document.querySelectorAll('.MuiPaginationItem-root').forEach((item) => {
    item.style.backgroundColor = '#6b705c';
    item.style.color = '#fff';
    if (item.ariaCurrent === 'true') {
      item.style.backgroundColor = '#ffffff';
      item.style.color = '#5e6251';
      item.style.border = '2px solid #5e6251';
    }

  })
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const openClose = () => {
    setOpen(false);
  };

  const [handleData, setHandleData] = useState('');


  const searchDemand = () => {

    let value = document.querySelector('.search-demand').value;
    value = value.toLowerCase();
    value = value.replace('/', '-');
    if (value !== '') {
      fetch(`https://api.fortesol.com.br/api/demand_project_solar/search/${props.id}?page=${currentPage}&limit=${itemsPerPage}&search=${value}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
          setDemand(data);
          setTotalPages(data[1]);

        })
      return
    }

  }


  const [doc_proxy, setDoc_proxy] = useState(null)
     // Funções para abrir e fechar o Dialog de Notificações
     const [openNotifications, setOpenNotifications] = React.useState(false);

     const handleNotifications = () => {
         setOpenNotifications((prev) => !prev);
     };
  return (

    <Sidebar>
      <Grid id='list-project-solar'>
        <Grid className='project-solar'>
          <ToastContainer
            position="bottom-right"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            height={50}
          />
          <HeaderSolProjetos />
          <br />
          <Grid sx={{ display: 'flex', padding: '0 50px 0 50px', justifyContent: 'space-between' }}>
            <Grid sx={{ display: 'flex', paddingLeft: '50px' }}>
              <Grid sx={{ display: 'flex' }}>
                <input style={{height:29}} type="text" className='search-demand' placeholder='Pesquisar'
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      searchDemand()
                    }
                  }
                  }
                  onChange={(e) => {


                    if (e.target.value === '') {
                      fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-integrator/${props.id}?page=${currentPage}&limit=${itemsPerPage}`, {
                        //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      }).then((response) => response.json())
                        .then((data) => {
                          setDemand(data);
                          setTotalPages(data[1]);
                        })
                    }
                  }} />
                <button id='bt-search' onClick={searchDemand}>Pesquisar</button>
              </Grid>
              <button onClick={() => {
                setCurrentPage(1);
                setLoading(true);
                try {
                  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-integrator/${props.id}?page=${1}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {
                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }


              }} id='bt-all' >Todos</button>
              <button onClick={() => {
                setCurrentPage(1);

                setLoading(true);
                try {
                  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-status/${props.id}?status=${'Concluído'}&page=${1}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {
                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }} id='bt-concluded' >Concluídos</button>

              <button onClick={() => {

                setCurrentPage(1);
                setLoading(true);
                try {
                  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-status/${props.id}?status=${'Em andamento'}&page=${1}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {
                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }}

                id='bt-in-progress' >Em andamento</button>

              <button onClick={() => {

                setLoading(true);
                setCurrentPage(1);
                try {
                  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-status/${props.id}?status=${'Solicitado'}&page=${1}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {

                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }} id='bt-requested'> Solicitados</button>
              <button onClick={() => {

                setLoading(true);
                setCurrentPage(1);
                try {
                  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-status/${props.id}?status=${'Cancelado'}&page=${1}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {

                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }} id='bt-canceled'> Cancelados</button>
            </Grid>

            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
              <NotificationPopover handleClick={handleNotifications} open={openNotifications} id={token.id} destination='customer' name_user={token.name} id_user={token.id} />
              <Link to='/solar-service/demand'><button style={{ background: 'rgb(0, 130, 78)' }} > Novo Projeto</button></Link>
            </Grid>
            </Grid>

            <Grid id='tableDemands' style={{
              padding: '0 0px 0 50px',
            }}>
              {

                (
                  <>

                    <Paper>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">COD.</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Numero UC</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Nome</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">CPF/CNPJ</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Status</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Data da Solicitação</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Data de Conclusão</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Procuração Assinada</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 14 }} align="center">Detalhes</TableCell>
                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {demand[0]?.map((item, index) => (
                            <TableRow key={item.id_demand} sx={{ '&:hover': { backgroundColor: '#eaeaea' } }}>
                              <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{item.id_demand}</TableCell>
                              <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{item.number_uc_demand}</TableCell>
                              <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{item.name_customers_of_customers ? item.name_customers_of_customers : item.corporate_reason_customers_of_customers}</TableCell>
                              <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{item.cpf_customers_of_customers ? item.cpf_customers_of_customers : item.cnpj_customers_of_customers}</TableCell>
                              <TableCell sx={{ p: '5px', fontSize: 12, gap: 1 }} align="center" >
                                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 1 }}>
                                  {item.status}

                                  {(item.status === 'Concluído') ? <CheckCircleOutlineIcon sx={{ fontSize: 15, color: '#0b532acf' }} /> : item.status === 'Cancelado' ? <CheckCircleOutlineIcon sx={{ fontSize: 15, color: '#9a0000' }} />
                                    : <Box
                                      sx={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: item.status === 'Solicitado' ? '#DAA520' : item.status === 'Em andamento' ? '#178948cf' : item.status === 'Concluído' ? '#228b22df' : item.status === 'Cancelamento Solicitado' ? '#c93a3a' : '',
                                        animation: 'pulse 1s infinite',
                                      }}
                                    />}
                                </Grid>
                                {/*<span className='span_check' style={{fontWeight:1000}}>&#10003;</span>*/}
                              </TableCell>
                              <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{formatDate(item.date_demand)}</TableCell>
                              <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{item.date_conclusion_demand === '---' ? '---' : formatDate(item.date_conclusion_demand)}</TableCell>
                              <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">
                                {
                                  item.info_proxy_demand === 'failed'
                                    ?
                                    <Grid key={item.id_demand} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 5 }} className='doc_proxy'>
                                      <label for={item.id_demand} style={{ width: '60px' }} className='label-doc_proxy style-label'>enviar</label>
                                      <input hidden type='file' className={item.id_demand} id={item.id_demand} onChange={e => {
                                        const file = e.target.files[0]
                                        const formdata = new FormData()
                                        formdata.append('doc_proxy_demand', file)
                                        setDoc_proxy(formdata)

                                        fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadDocProxyDemand/${item.id_demand}`, {
                                          method: 'POST',
                                          body: formdata,
                                        }).then(response => response.json())
                                          .then(data => {

                                            if (data.message === 'success') {
                                              fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-info-proxy/${item.id_demand}`, {
                                                method: 'PUT',
                                                headers: {
                                                  'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify({
                                                  info_proxy_demand: 'under_analysis'
                                                })
                                              }).then(response => response.json())
                                                .then(data => {

                                                  if (data.message === 'success') {
                                                    fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-integrator/${props.id}?page=${currentPage}&limit=${itemsPerPage}`, {
                                                      //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                                                      method: 'GET',
                                                      headers: {
                                                        'Content-Type': 'application/json',
                                                      },
                                                    }).then((response) => response.json())
                                                      .then((data) => {
                                                        fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                          method: 'POST',
                                                          headers: {
                                                            'Content-Type': 'application/json',
                                                          },
                                                          body: JSON.stringify({

                                                            event: 'Procuração Assinada',
                                                            details: 'Procuração reenviada para análise',
                                                            demand_id: item.id_demand,
                                                            name_user: token.name
                                                          })
                                                        })
                                                        setDemand(data);
                                                        setTotalPages(data[1]);
                                                      })
                                                  }
                                                })
                                            }
                                          })

                                      }} /><Box
                                        sx={{
                                          width: '10px',
                                          height: '10px',
                                          borderRadius: '50%',
                                          backgroundColor: '#ff0000a7',
                                          animation: 'pulse 1s infinite',
                                        }}
                                      />
                                    </Grid>
                                    : ''
                                }
                                {
                                  item.info_proxy_demand === 'success'
                                    ?
                                    <p key={item.id_demand}> Aprovada <CheckCircleOutlineIcon sx={{ fontSize: 15, color: '#0b532acf' }} /></p>
                                    : ''
                                }
                                {
                                  item.info_proxy_demand === 'under_analysis' && item.doc_proxy_demand !== ''
                                    ?
                                    <p key={item.id_demand} style={{ fontSize: '9pt' }}> Em análise <span style={{ fontSize: '7pt' }}>&#128338;</span></p>
                                    : ''
                                }
                                {
                                  item.info_proxy_demand === 'send' && item.doc_proxy_demand === '---'
                                    ?
                                    <Grid key={item.id_demand} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} className='doc_proxy'>
                                      <label for={item.id_demand} style={{ width: '60px' }} className='label-doc_proxy style-label'>enviar</label>
                                      <input hidden type='file' className={item.id_demand} id={item.id_demand} onChange={e => {
                                        const file = e.target.files[0]
                                        const formdata = new FormData()
                                        formdata.append('doc_proxy_demand', file)
                                        setDoc_proxy(formdata)

                                        fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadDocProxyDemand/${item.id_demand}`, {
                                          method: 'POST',
                                          body: formdata,
                                        }).then(response => response.json())
                                          .then(data => {

                                            if (data.message === 'success') {
                                              fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-info-proxy/${item.id_demand}`, {
                                                method: 'PUT',
                                                headers: {
                                                  'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify({
                                                  info_proxy_demand: 'under_analysis'
                                                })
                                              }).then(response => response.json())
                                                .then(data => {

                                                  if (data.message === 'success') {
                                                    fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-integrator/${props.id}?page=${currentPage}&limit=${itemsPerPage}`, {
                                                      //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                                                      method: 'GET',
                                                      headers: {
                                                        'Content-Type': 'application/json',
                                                      },
                                                    }).then((response) => response.json())
                                                      .then((data) => {
                                                        fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                          method: 'POST',
                                                          headers: {
                                                            'Content-Type': 'application/json',
                                                          },
                                                          body: JSON.stringify({

                                                            event: 'Procuração Assinada',
                                                            details: 'Procuração enviada para análise',
                                                            demand_id: item.id_demand,
                                                            name_user: token.name
                                                          })
                                                        })
                                                        setDemand(data);
                                                        setTotalPages(data[1]);
                                                      })
                                                  }
                                                })
                                            }
                                          })

                                      }} />
                                    </Grid>
                                    : ''
                                }
                              </TableCell>
                              <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">
                                {/*<button className='bt-details' onClick={() => {
                                //  document.querySelector(`.popup`).style.display = 'block';
                                handleClickOpen();
                                setHandleData(item);
                              }}>Detalhes</button>*/}
                                <IconButton sx={{ margin: '0px!important', backgroundColor: '#e9573d', '&:hover': { backgroundColor: ' #b83922' } }} id={`${item.id_demand}-D`} onClick={() => {
                                  //  document.querySelector(`.popup`).style.display = 'block';
                                  handleClickOpen();
                                  setHandleData(item);
                                }
                                }><ArticleIcon fontSize="small" /></IconButton>

                              </TableCell>

                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <Popup_demand
                        data={handleData}
                        open={open}
                        handleClose={openClose}
                      />

                    </Paper>

                    <Pagination style={{ marginTop: '10px' }}
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, page) => handlePageChange(page)}
                      showFirstButton
                      showLastButton
                      boundaryCount={1} // Número de páginas antes e depois das ellipses
                      siblingCount={1} // Número de páginas antes e depois da página atual
                    />
                  </>
                )}


            </Grid>


          </Grid>
        </Grid>
    </Sidebar>
  )
}

export default ListSolarService
