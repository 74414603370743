import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Tooltip, Viewer, Worker } from '@react-pdf-viewer/core';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HideImageIcon from '@mui/icons-material/HideImage';
import Edit_System_Project_Dialog from '../system_project_edit_dialog';
import System_Project_Dialog from '../system_project_dialog';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import Updade_Art from '../update_art';
import html2pdf from 'html2pdf.js';
import file_pro from '../../assets/doc.jpeg'
import file_pro_dr from '../../assets/doc_dr_rodrigo.jpeg'
import html2canvas from 'html2canvas';
import DocViewer from 'react-doc-viewer';
import axios from 'axios';

export default function Preview(props) {
 

    const [src, setSrc] = React.useState(null)
    const [typeFile, setTypeFile] = React.useState(null)
    const [typeFileName, setTypeFileName] = React.useState(null)
    
    if(props.title === 'PROCURAÇÃO ASSINADA' && props.data?.file_doc_identify !== null){
    const response = fetch(`https://api.fortesol.com.br/api/customers_of_customers/download/identify/${props.data?.id_customer_of_customers}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${props.user.token}`
        }
    }).then((response) => response.blob())
        .then((blob) => {
            setTypeFile(blob.type.split('/')[1])
            setTypeFileName(blob.type.split('/')[0])
            const url = window.URL.createObjectURL(new Blob([blob]));
            setSrc(url)
        }).catch((error) => {
            console.log(error)
        })
    }

    const download_word_descriptive_memorial = () => {
        if(props.title === 'MEMORIAL DESCRITIVO'){
            fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/word_descriptive_memorial/${props.data.id_demand}`, {
               method: 'GET',
               headers: {
                   'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
               },
           })
           .then(response => {
               // Extrai o header 'Content-Disposition' da resposta
               const contentDisposition = response.headers.get('Content-Disposition');
               let fileName = 'Memorial_Descritivo.docx'; // Nome padrão, caso o nome não seja encontrado
               if (contentDisposition) {
                   // Extrai o nome do arquivo do header 'Content-Disposition'
                   const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                   console.log('fileNameMatch', fileNameMatch)
                   if (fileNameMatch.length > 1) {
                       fileName = fileNameMatch[1];  // Define o nome do arquivo
                   }
               }
           
               if (response.ok) {
                   return response.blob().then(blob => ({ blob, fileName }));
               }
               throw new Error('Erro ao baixar o arquivo.');
           })
           .then(({ blob, fileName }) => {
               // Cria um link temporário para baixar o arquivo
               const url = window.URL.createObjectURL(new Blob([blob]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', fileName); // Define o nome do arquivo extraído
               document.body.appendChild(link);
               link.click();
               link.parentNode.removeChild(link);  // Remove o link depois de clicar
           })
           .catch(err => console.error('Erro ao baixar o arquivo:', err));
       }
    }
  
    const download = () => {
        if (props.title === 'PROCURAÇÃO ASSINADA') {

            const documment = document.getElementById('procuracao')
            const opt = {
                margin: 0,
                filename: `${props.data?.cnpj_customers_of_customers
                    ?
                    props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
                    :
                    props.data?.name_customers_of_customers.replace(/\s/g, '-')
                }.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            }
            html2pdf().from(documment).set(opt).save()

        } else {
          
            const link = document.createElement('a');
            link.href = props.src;
            link.download = `${props.data?.cnpj_customers_of_customers
                ?
                props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
                :
                props.data?.name_customers_of_customers.replace(/\s/g, '-')
                }.${props.typeFile}`
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        
            
              
        }
    }

    const statusSuccess = () => {
        fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-info-proxy/${props.data?.id_demand}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                info_proxy_demand: 'success',
            })
        }).then(response => response.json())
            .then(data => {
                if (data.message === 'success') {
                    fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                    
                            event: 'PROCURAÇÃO',
                            details: 'PROCURAÇÃO ACEITA', 
                            demand_id: props.data?.id_demand,
                            name_user: props.user?.name
                        })
                    })
                    document.getElementById('bt-search').click()
                    setTimeout(() => {
                        document.getElementById(`${props.data?.id_demand}`).click()
                    }, 100)
                }
            })
            .catch((error) => {

            })
    }
    const statusFailed = () => {
        fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-info-proxy/${props.data?.id_demand}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                info_proxy_demand: 'failed',
            })
        }).then(response => response.json())
            .then(data => {
                if (data.message === 'success') {
                    fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                    
                            event: 'PROCURAÇÃO',
                            details: 'PROCURAÇÃO REJEITADA',
                            demand_id: props.data?.id_demand,
                            name_user: props.user?.name
                        })
                    })
                    document.getElementById('bt-search').click()
                    setTimeout(() => {
                        document.getElementById(`${props.data?.id_demand}`).click()
                    }, 100)
                }
            })
            .catch((error) => {

            })
    }

    //Função para abrir e fechar o Dialog de editar memorial descritivo

    const [openEditDescriptive, setOpenEditDescriptive] = React.useState(false);

    const handleClickEditDescriptive = () => {
        setOpenEditDescriptive(true);
    }

    const closeEditDescriptive = () => {
        document.getElementById('bt-search').click();
        document.getElementById(`${props.data.id_demand}`).click()
        document.getElementById('btn-m-d').click()
        setOpenEditDescriptive(false);
    }

    //Função para abrir e fechar o Dialog de projeto
    const [openProject, setOpenProject] = React.useState(false);

    const handleClickProject = () => {
        document.getElementById('btn-m-d').click()
        setOpenProject((prev) => !prev);
    }




    const [update, setUpdate] = React.useState(false)
    const [openArt, setOpenArt] = React.useState(false)
    const closeArt = () => {
        setOpenArt(false)
    }
    const refresh = () => {
        setUpdate(true)
    }
    const [passTrue, setPassTrue] = React.useState(false)
    setTimeout(() => {
        if(document.querySelector('.rpv-core__textbox')) setPassTrue(true)
       
    }, 500)


    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth={true}
        >
            <Edit_System_Project_Dialog open={openEditDescriptive} close={closeEditDescriptive} data={props.data} />
            <System_Project_Dialog open={openProject} handleClose={handleClickProject} data={props.data} />
            <Updade_Art open={openArt} close={closeArt} id={props.data?.id_demand} refresh={refresh} click={true} />

            <DialogContent>
                <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}> {props.title} </DialogTitle>


                {

                    props.src ?

                        (
                            (
                            props.title !== 'PROCURAÇÃO ASSINADA' ? (
                                props.typeFile === 'pdf' ? (
                                    <div style={{ width: '100%', height: 1120, paddingLeft: '10%' }}>
                                    <Worker workerUrl="pdf.worker.min.js">
                                        <div style={{ height: '750px' }}>
                                            <Viewer fileUrl={props.src} />
                                        </div>
                                    </Worker>
                                        </div>
                                )
                                    : props.typeFileName === 'image' ? (
                                        <img src={props.src} alt="Imagem" style={{ width: '100%' }} />
                                    )
                                        :
                                        (
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <HideImageIcon />
                                            </Box>

                                        )
                            )

                                :

                                (
                                    props.typeFile === 'pdf' ? (
                                        <div id='procuracao'>

                                            <div style={{ width: '100%', height: 1120 ,paddingLeft:'7%'}}>

                                                <Worker workerUrl="pdf.worker.min.js">
                                                    <div style={{ height: '750px' }}>
                                                        <Viewer fileUrl={props.src} />
                                                    </div>
                                                </Worker>
                                            </div>

                                            {
                                                typeFile === 'pdf' ? (
                                                    <>
                                                        <div style={{ width: '100%', height: 1120 }}>

                                                            <Worker workerUrl="pdf.worker.min.js">
                                                                <div style={{ height: '750px' }}>
                                                                    <Viewer fileUrl={src} />
                                                                </div>
                                                            </Worker>
                                                        </div>
                                                        <div style={{ width: '100%', height: 1120 }}>
                                                            <img src={file_pro_dr} alt="Imagem" style={{ width: '100%' }} />
                                                        </div>
                                                        <div style={{ width: '100%', height: 1120, paddingTop: 30 }}>
                                                            <img src={file_pro} alt="Imagem" style={{ width: '100%' }} />
                                                        </div>
                                                    </>
                                                ) : typeFileName === 'image' ? (
                                                    <>
                                                        <div style={{ width: '100%', height: 1120 }}>
                                                            <img src={src} alt="Imagem" style={{ width: '100%' }} />
                                                        </div>
                                                        <div style={{ width: '100%', height: 1120 }}>
                                                            <img src={file_pro_dr} alt="Imagem" style={{ width: '100%' }} />
                                                        </div>
                                                        <div style={{ width: '100%', height: 1120, paddingTop: 30 }}>
                                                            <img src={file_pro} alt="Imagem" style={{ width: '100%' }} />
                                                        </div>

                                                    </>
                                                ) : (
                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                        <HideImageIcon />
                                                    </Box>
                                                )
                                            }


                                        </div>

                                    )
                                        : props.typeFileName === 'image' ? (
                                            <div id='procuracao'>
                                                <img src={props.src} alt="Imagem" style={{ width: '100%', height: 1120 }} />
                                                {
                                                    typeFile === 'pdf' ? (
                                                        <>
                                                            <Worker workerUrl="pdf.worker.min.js">
                                                                <div style={{ height: '750px' }}>
                                                                    <Viewer fileUrl={src} />
                                                                </div>
                                                            </Worker>
                                                            <div style={{ width: '100%', height: 1120 }}>
                                                                <img src={file_pro_dr} alt="Imagem" style={{ width: '100%' }} />
                                                            </div>
                                                            <div style={{ width: '100%', height: 1120, paddingTop: 30 }}>
                                                                <img src={file_pro} alt="Imagem" style={{ width: '100%' }} />
                                                            </div>
                                                        </>
                                                    )
                                                        : typeFileName === 'image' ?
                                                            (
                                                                <>
                                                                    <div style={{ width: '100%', height: 1120 }}>
                                                                        <img src={src} alt="Imagem" style={{ width: '100%' }} />
                                                                    </div>
                                                                    <div style={{ width: '100%', height: 1120 }}>
                                                                        <img src={file_pro_dr} alt="Imagem" style={{ width: '100%' }} />
                                                                    </div>
                                                                    <div style={{ width: '100%', height: 1120, paddingTop: 30 }}>
                                                                        <img src={file_pro} alt="Imagem" style={{ width: '100%' }} />
                                                                    </div>

                                                                </>
                                                            ) : (
                                                                <Box display="flex" justifyContent="center" alignItems="center">
                                                                    <HideImageIcon />
                                                                </Box>
                                                            )
                                                }
                                            </div>
                                        )
                                            :
                                            (

                                                <Box display="flex" justifyContent="center" alignItems="center">
                                                    <HideImageIcon />
                                                </Box>

                                            )
                                        )
                                )





                        )
                        : (
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        )



                }


            </DialogContent>

            <DialogActions >
                {
                    props.title === 'CONTA DE LUZ' ? (
                      
                     passTrue && <DialogContentText id="alert-dialog-description"> Senha: {props.data.cpf_customers_of_customers.replace('.', '').slice(0, 5)} </DialogContentText>
                    
                    ) : null
                }
                <DialogContent >

                    {
                        props.title === 'PROCURAÇÃO ASSINADA' ? (
                            <List>
                                <ListItemText
                                    primary="STATUS:"
                                    secondary={
                                        props.data.info_proxy_demand === 'send' ? 'Aguardando Envio.' :
                                            props.data.info_proxy_demand === 'under_analysis' ? 'Aguardando Confirmação.' :
                                                props.data.info_proxy_demand === 'success' ? 'Procuração Aceita.' :
                                                    props.data.info_proxy_demand === 'failed' ? 'Procuração rejeitada, aguardando reenvio.' : ''

                                    } />
                                {props.data.info_proxy_demand === 'under_analysis' ? <ListItem sx={{ gap: 1 }}>
                                    <Button variant='contained' color='success' size='small' onClick={statusSuccess}>confirmar</Button>
                                    <Button variant='contained' color='error' size='small' onClick={statusFailed}>rejeitar</Button>
                                </ListItem> : ''}
                            </List>
                        ) : null
                    }
                    {
                        props.title === 'MEMORIAL DESCRITIVO' ? (
                            <List>
                                <ListItem sx={{ gap: 1 }}>
                                    <Button variant='contained' color='success' size='small' disabled={!props.src}  onClick={handleClickEditDescriptive}>editar</Button>
                                    <Button variant='contained' color='primary' size='small' disabled={!props.src} onClick={handleClickProject}>refazer</Button>
                                </ListItem>
                            </List>
                        ) : null
                    }
                </DialogContent>
                {
                    props.title === 'PROCURAÇÃO ASSINADA' ? (
                        props.data.info_proxy_demand === 'success' &&
                        <Button component="label"
                            variant="contained"
                        >
                            atualizar
                            <input type="file"
                                accept='image/* , application/pdf'
                                hidden onChange={
                                    (e) => {
                                        const file = e.target.files[0]
                                        const formdata = new FormData()
                                        formdata.append('doc_proxy_demand', file)
                                        fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadDocProxyDemand/${props.data?.id_demand}`, {
                                            method: 'POST',
                                            body: formdata,
                                        }).then(response => response.json())
                                            .then(data => {

                                                if (data.message === 'success') {
                                                    fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                        },
                                                        body: JSON.stringify({
                                                    
                                                            event: 'PROCURAÇÃO',
                                                            details: 'ATUALIZAÇÃO DE ARQUIVO',
                                                            demand_id: props.data?.id_demand,
                                                            name_user: props.user?.name
                                                        })
                                                    })
                                                    toast.success(`Arquivo enviado com sucesso!`)
                                                    document.getElementById('bt-search').click()
                                                    setTimeout(() => {
                                                        document.getElementById(`${props.data?.id_demand}`).click()
                                                        document.getElementById('btn-doc-proxy-demand').click()
                                                        setUpdate(props.title)
                                                    }, 100)
                                                }
                                            }).catch(err => {
                                                toast.error('Erro ao enviar arquivo!')
                                            })
                                    }
                                } />
                            &nbsp;
                            <CloudUploadIcon />
                        </Button>
                    ) : null
                }
                {
                    props.title !== 'PROCURAÇÃO ASSINADA' ? (
                        props.title !== 'ART' ?
                            props.title !== 'MEMORIAL DESCRITIVO' &&
                            <Button component="label"
                                variant="contained"
                            >
                                atualizar
                                <input type="file"
                                    accept='image/* , application/pdf'
                                    hidden onChange={
                                        (e) => {
                                            const file = e.target.files[0]
                                            const formdata = new FormData()

                                            if (props.title === 'Documento de Identificação') {
                                                formdata.append('identify', file)
                                                fetch(`https://api.fortesol.com.br/api/customers_of_customers/upload/identify/${props.data?.id_customer_of_customers}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {

                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'Documento de Identificação',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',  
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })

                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-identify').click()

                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }
                                            if (props.title === 'CONTRATO SOCIAL') {
                                                formdata.append('contract_social', file)
                                                fetch(`https://api.fortesol.com.br/api/customers_of_customers/upload/contract-social/${props.data?.id_customer_of_customers}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {
                                                            
                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'CONTRATO SOCIAL',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })
                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-contract-social').click()
                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }
                                            if (props.title === 'CONTA DE LUZ') {
                                                formdata.append('electricity_bill', file)
                                                fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageElectricityBill/${props.data?.id_demand}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {
                                                            
                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'CONTA DE LUZ',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',  
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })
                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-electricity_bill').click()
                                                               
                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }
                                            if (props.title === 'DISJUNTOR') {
                                                formdata.append('photos_breaker', file)
                                                fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImagePhotosBreaker/${props.data?.id_demand}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {
                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'DISJUNTOR',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })
                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-photos-breaker').click()
                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }
                                            if (props.title === 'UNIDADE CONSUMIDORA') {
                                                formdata.append('energy_box_photos', file)
                                                fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageEnergyBoxPhotos/${props.data?.id_demand}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {
                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'UNIDADE CONSUMIDORA',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',  
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })
                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-energy-box').click()
                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }
                                            if (props.title === 'KIT FOTOVOLTAICO') {
                                                formdata.append('data_photovoltaic', file)
                                                fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageDataPhotovoltaic/${props.data?.id_demand}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {
                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'KIT FOTOVOLTAICO',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })
                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-data-photovoltaic').click()
                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }
                                            if (props.title === 'MEMORIAL DESCRITIVO ASSINADO') {
    
                                                formdata.append('signed_descriptive_memorial', file)
                                                fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageSignedDescriptiveMemorial/${props.data?.id_demand}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {
                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'MEMORIAL DESCRITIVO ASSINADO',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })
                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-signed-descriptive-memorial').click()
                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }
                                            if (props.title === 'DIAGRAMA UNIFILAR') {
                                                formdata.append('single_line_diagram', file)
                                                fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageSingleLineDiagram/${props.data?.id_demand}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {
                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'DIAGRAMA UNIFILAR',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })
                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-single-line-diagram').click()
                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }
                                            if (props.title === 'PARECER DE ACESSO') {
                                                formdata.append('access_opinion', file)
                                                fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageAccessOpinion/${props.data?.id_demand}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {
                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'PARECER DE ACESSO',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })
                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-access-opinion').click()
                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }
                                            if (props.title === 'RELACIONAMENTO OPERACIONAL') {
                                                formdata.append('operational_relationship', file)
                                                fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadImageOperationalRelationship/${props.data?.id_demand}`, {
                                                    method: 'POST',
                                                    body: formdata
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        if (data.message === 'Image uploaded successfully') {
                                                            fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                            
                                                                    event: 'RELACIONAMENTO OPERACIONAL',
                                                                    details: 'ATUALIZAÇÃO DE ARQUIVO',
                                                                    demand_id: props.data?.id_demand,
                                                                    name_user: props.user?.name
                                                                })
                                                            })
                                                            toast.success(`Arquivo enviado com sucesso!`)
                                                            document.getElementById('bt-search').click()
                                                            setTimeout(() => {
                                                                document.getElementById(`${props.data?.id_demand}`).click()
                                                                document.getElementById('btn-operational-relationship').click()
                                                                setUpdate(props.title)
                                                            }, 100)
                                                        }
                                                    }).catch(err => {
                                                        toast.error('Erro ao enviar arquivo!')
                                                    })
                                            }

                                            e.target.value = ''

                                        }
                                    } />
                                &nbsp;
                                <CloudUploadIcon />
                            </Button>
                            :
                            <Button variant='contained' color='primary' onClick={() => setOpenArt(true)}>atualizar</Button>
                    ) : null
                }
                {
                    props.title === 'MEMORIAL DESCRITIVO' ? (
                        <>
                        <Button size='small' variant='contained' color='error' disabled={!props.src} onClick={download}>pdf&nbsp;<CloudDownloadIcon /></Button>
                        <Button size='small' variant='contained' color='primary' disabled={!props.src} onClick={download_word_descriptive_memorial}>word&nbsp;<CloudDownloadIcon /></Button>
                        </>
                    ) :
                
                <Button disabled={props.typeFile ? (props.typeFile === 'json' ? true : false) : true} onClick={download} color="primary" autoFocus variant='contained'>
                    download&nbsp;<CloudDownloadIcon />
                </Button>
}
                <Button onClick={props.close} color="primary">
                    Fechar
                </Button>
            </DialogActions>

        </Dialog>
    )
}
