import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Collapse, Grid, ListItemText, Switch, TextField, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';   
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Viewer, Worker } from '@react-pdf-viewer/core';

 
function Documentation(props){
  //Download procuração
const handlePdfDownloadPro = () => {
  document.querySelector('#expand').click()

setTimeout(() => {
    const conteudo = document.querySelector('.body-procuracao')
    const pdf = conteudo
    const opt = {
      margin: 0,
      filename: `Procuração Particular - ${props.name ? props.name : props.corporate_reason}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }
    html2pdf().from(pdf).set(opt).save().then(() => {
      toast.success('Procuração Particular gerada com sucesso', { autoClose: 2000 })
      props.handleClick()
    }).catch((error) => {
      toast.error('Erro ao gerar Procuração Particular', { autoClose: 2000 })
    })
}, 10)

  }



 


    const handleDateValid = () => {
        var data = new Date()
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()
    
        if (dia < 10) {
          dia = `0${dia}`
        }
        if (mes === 1) {
          mes = 'Janeiro'
        }
        if (mes === 2) {
          mes = 'Fevereiro'
        }
        if (mes === 3) {
          mes = 'Março'
        }
        if (mes === 4) {
          mes = 'Abril'
        }
        if (mes === 5) {
          mes = 'Maio'
        }
        if (mes === 6) {
          mes = 'Junho'
        }
        if (mes === 7) {
          mes = 'Julho'
        }
        if (mes === 8) {
          mes = 'Agosto'
        }
        if (mes === 9) {
          mes = 'Setembro'
        }
        if (mes === 10) {
          mes = 'Outubro'
        }
        if (mes === 11) {
          mes = 'Novembro'
        }
        if (mes === 12) {
          mes = 'Dezembro'
        }
        var data = `${dia} de ${mes} de ${ano + 1}`
        return data
      }
    
      const handleDate = () => {
        var data = new Date()
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()
    
        if (dia < 10) {
          dia = `0${dia}`
        }
        if (mes === 1) {
          mes = 'Janeiro'
        }
        if (mes === 2) {
          mes = 'Fevereiro'
        }
        if (mes === 3) {
          mes = 'Março'
        }
        if (mes === 4) {
          mes = 'Abril'
        }
        if (mes === 5) {
          mes = 'Maio'
        }
        if (mes === 6) {
          mes = 'Junho'
        }
        if (mes === 7) {
          mes = 'Julho'
        }
        if (mes === 8) {
          mes = 'Agosto'
        }
        if (mes === 9) {
          mes = 'Setembro'
        }
        if (mes === 10) {
          mes = 'Outubro'
        }
        if (mes === 11) {
          mes = 'Novembro'
        }
        if (mes === 12) {
          mes = 'Dezembro'
        }
    
        var data = `${dia} de ${mes} de ${ano}`
        return data
      }
    
      
   
      const informations = {
        cnpj:props.cnpj,
        name: props.tipoPessoa === 'pf' ? props.name : props.corporate_reason,
        cpf:props.cpf,
        profession:props.profession,
        identify:props.identify,
        type_identify:props.type_identify,
        ssp:props.type_identify === 'RG' ? 'SSP-CE' : '',
        street:props.street,
        number:props.number,
        cpmt:props.complement,
        district:props.neighborhood,
        city:props.city,
        state:props.state,
        cep:props.cep,
        name_r:props.name_representative,
        cpf_r:props.cpf_representative,
        identify_r:props.identify_representative,
        type_identify_r:props.type_identify_representative,
        ssp_r:props.type_identify_representative === 'RG' ? 'SSP-CE' : '',
        profession_r:props.profession_representative,
        nationality_r:props.nationality_representative,
        validity:handleDateValid(),
        date:handleDate()
      };

  
      const handleDownloadWord = async () => {
        try {
          // Faz a requisição para o backend
          const response = await fetch(`https://api.fortesol.com.br/api/demand_project_solar/generate-procuration_word`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(informations), // Envia os dados para o backend
          });
    
          // Verifica se a resposta está ok
          if (!response.ok) {
            throw new Error('Erro ao gerar o documento');
          }
    
          // Converte a resposta para Blob (arquivo binário)
          const blob = await response.blob();
    
          // Cria um link temporário para download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
    
          // Define o nome do arquivo para download
          const contentDisposition = response.headers.get('Content-Disposition');
          const fileName = contentDisposition
            ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
            :  `Procuração Particular - ${props.name ? props.name : props.corporate_reason}.docx`;
    
          link.setAttribute('download', fileName);
    
          // Simula o clique no link para iniciar o download
          document.body.appendChild(link);
          link.click();
    
          // Remove o link após o download
          link.parentNode.removeChild(link);
          props.handleClick()
        } catch (error) {
          console.error('Erro ao baixar o documento:', error);
        }
      };

      const [pdfUrl, setPdfUrl] = useState(null);
    
      const handleDownloadPDF = async () => {
              try {
                const response = await fetch(`https://api.fortesol.com.br/api/demand_project_solar/generate-procuration_pdf`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(informations),
                });
          
                if (response.ok) {
                  // Cria um Blob a partir da resposta
                  const blob = await response.blob();
                  
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  
                  setPdfUrl(url);
                  
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = `Procuração Particular - ${props.name ? props.name : props.corporate_reason}.pdf`;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                  window.URL.revokeObjectURL(url); // Limpa o objeto URL
                   props.handleClick()
                } else {
                  console.error('Erro ao gerar o PDF:', await response.json());
                 
                }
              } catch (error) {
                console.error('Erro ao enviar a requisição:', error);
             
              }
      };

     



  return (
    <Grid container maxWidth={'md'} >
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2, width: '100%'}}>
    <Alert severity="info">É necessário que a procuração seja baixada para ser assinada e enviada posteriormente.</Alert>
    </Box>
<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2, width: '100%'}}>
<Button onClick={handleDownloadWord} variant='contained' color='primary'>word <CloudDownloadIcon sx={{ ml: 1 }} /></Button>
<Button onClick={handleDownloadPDF} sx={{ ml: 1 }} variant='contained' color='error'>pdf <CloudDownloadIcon sx={{ ml: 1 }} /></Button>
{/*

<Button
id='expand'
onClick={handleExpandClick}
endIcon={props.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
>
    Procuração Particular
</Button>
<Button  variant='contained' color='primary'onClick={handlePdfDownloadPro}>Baixar Procuração <CloudDownloadIcon sx={{ ml: 1 }} /></Button>
*/}
</Box>
{/*
<Collapse in={props.expanded} timeout="auto" unmountOnExit>
<Grid item xs={12} >
<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2, width: '100%',boxShadow: 1, p: 2, bgcolor: 'background.paper' }}>

<div className="body-procuracao">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '0px',
          width: '80%',
          maxHeight: '1123px',

          minHeight: '1123px',
          textAlign: 'center',
        }} >
          <h2>Procuração Particular</h2>
  
        {
        props.tipoPessoa === 'pj' &&  
        <div className='procuracao-text pj'>
        <p> <strong>OUTORGANTE: {props.corporate_reason}</strong>, CNPJ <span>nº 
                        <strong> {props.cnpj}</strong></span>, situada na&nbsp; {props.street}, {props.number}{props.complement ? `, ${props.complement} `: ''}, {props.neighborhood} – {props.city} - 
           {props.state} – CEP: {props.cep}, neste ato representada por {props.name_representative},
{props.nationality_representative}, {props.profession_representative}, RG nº {props.rg_representative} – SSPCE e inscrito no CPF sob o nº
{props.cpf_representative}.
           </p>
          </div>
          }
         {props.tipoPessoa === 'pf' && <div className='procuracao-text pf'>
         <p><strong> OUTORGANTE: {props.name}</strong>, {props.profession}, CPF nº 
                        <strong> {props.cpf}</strong> e RG nº {props.rg} SSP-CE, residente e domiciliado na &nbsp;
                        {props.street}, {props.number}{props.complement ? `, ${props.complement}, `: ''} {props.neighborhood} – {props.city} – {props.state}
                        - CEP: {props.cep}.</p>
          </div>}
          <div className="procuracao-text">
            <p><strong>OUTORGADO</strong>: Rodrigo Fernandes Feijó, brasileiro, Engenheiro Eletricista, CPF sob nº 669.011.203-97, RG nº 99010500307 SSP-CE e RNP nº  0618721460 CREA-CE, residente na Rua Luisa Teixeira de Araújo,   nº 69, São Francisco, Pentecoste-CE, CEP: 62.640-000.</p>
          </div>
          <div className="procuracao-text">
            <p><strong>PODERES ESPECÍFICOS</strong>: para representar o outorgante perante Companhia Energética do Ceará, Agência Nacional de Energia Elétrica - ANEEL e Agência Reguladora do Estado  do Ceará - ARCE , relativo aos processos e procedimentos de suas atuais ou futuras unidades consumidoras de energia elétrica, podendo para tanto, requerer, alertar e assinar todos os atos necessários ao bom e fiel cumprimento desse mandato, podendo subestabelecer total ou parcialmente, com ou sem reserva de poderes.</p>
          </div>
          <div className="procuracao-text">
            <p>Este Instrumento possuí validade até {handleDateValid()}.</p>
          </div>
          <div className="procuracao-text">
            <p style={{
              textAlign: 'right',
            }}>Fortaleza, {handleDate()}.</p>
          </div>
        <div style={{ textAlign: 'center', marginTop: '70px', paddingBottom: '200px' }} className="procuracao-text">
            <p style={{ textAlign: 'center' }}>_____________________________________</p>
            <br />
            {props.tipoPessoa === 'pj' &&  <div style={{ textAlign: 'center', width: '100%' }} className="procuracao-ass-pj">
           <p style={{textAlign:'center'}}>{props.name_representative}</p>
                        <p style={{textAlign:'center'}}>CPF nº {props.cpf_representative}</p> 
                        <p style={{textAlign:'center'}}>{props.corporate_reason}</p> 
                        <p style={{textAlign:'center'}}>CNPJ nº {props.cnpj}</p>
            </div>}
            {props.tipoPessoa === 'pf' &&<div style={{ textAlign: 'center', width: '100%' }} className="procuracao-ass-pf">
            <p style={{textAlign:'center'}}>{props.name}</p>
            <p style={{textAlign:'center'}}>CPF nº {props.cpf}</p> 
            </div>}

          </div>
        </div>


      </div>
</Box>
</Grid>
</Collapse>
*/}





</Grid>
  )
}

export default Documentation

