import Sidebar from "../../Components/Sidebar"
import { Box, Button, Card, Switch, TextField, Typography, List, ListItem, ListItemText, Grid, Paper } from "@mui/material";
import { CloudUpload, Notifications, People } from "@mui/icons-material";

const Settings = () => {

   const handleUploadModelMD = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('model_memorial_descriptive', file);
    fetch('https://api.fortesol.com.br/api/demand_project_solar/upload-model-memorial-descriptive', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(res => console.log(res))
      .catch(err => console.log(err));
      e.target.value = null;
  }

  const handleUploadModelProcuration = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('model_procuration', file);
    fetch('https://api.fortesol.com.br/api/demand_project_solar/upload-model-procuration', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(res => console.log(res))
      .catch(err => console.log(err));
      e.target.value = null;
  }

  const handleUploadModelRateio = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('model_rateio', file);
    fetch('https://api.fortesol.com.br/api/demand_project_solar/upload-model-rateio', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(res => console.log(res))
      .catch(err => console.log(err));
      e.target.value = null;
  }

  const handleUploadModelBigger = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('model_bigger', file);
    fetch('https://api.fortesol.com.br/api/demand_project_solar/upload-model-bigger', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(res => console.log(res))
      .catch(err => console.log(err));
      e.target.value = null;
  }

  const handleUploadModelMinor = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('model_minor', file);
    fetch('https://api.fortesol.com.br/api/demand_project_solar/upload-model-minor', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(res => console.log(res))
      .catch(err => console.log(err));
      e.target.value = null;
  }

    return (
      <Sidebar>
      <Grid container sx={{ height: '100vh',pl:'60px' }}>
      {/* Parte Central */}
      <Grid item xs={8} style={{ padding: 20 }}>
        <Paper elevation={3} style={{ height: '100%' }}>
          <Typography variant="h5" align="center" style={{ padding: 20 }}>
           
          </Typography>
          {/* coisas futuras */}
        </Paper>
      </Grid>
      {/* Parte Direita */}
      <Grid item xs={4} style={{ padding: 20 }}>
        <Paper elevation={3} style={{ height: '100%', overflow: 'auto' }}>
          <Typography variant="h4" align="center" style={{ padding: 20 }}>
           
          </Typography>

          {/* Últimas Atividades */}
          <Typography align="center"  variant="h6" style={{ padding: 10 }}>
            Últimas Atividades
          </Typography>
          <List>
           
            {/* Adicione mais atividades aqui */}
          </List>

          {/* Usuários Online */}
          <Typography align="center"  variant="h6" style={{ padding: 10 }}>
            Usuários Online
          </Typography>
          <List>
         
            {/* Adicione mais usuários online aqui */}
          </List>

          {/* Usuários Clientes */}
          <Typography align="center"  variant="h6" style={{ padding: 10 }}>
            Usuários Clientes
          </Typography>
          <List>
      
            {/* Adicione mais clientes aqui */}
          </List>

          {/* Usuários Funcionários */}
          <Typography align="center"  variant="h6" style={{ padding: 10 }}>
            Usuários Funcionários
          </Typography>
          <List>
  
            {/* Adicione mais funcionários aqui */}
          </List>

          {/* Envio de Modelos de Formulários */}
          <Typography variant="h6" align="center" style={{ padding: 10 }}>
            Envio de Modelos de Formulários
          </Typography>
          <List>
            <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">Memorial Descritivo</Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUpload />}
                component='label'
              >
                upload
                <input
                  type="file"
                  hidden
                  onChange={handleUploadModelMD}
                  accept=".docx"
                />
              </Button>

            </ListItem>
            <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">Procuração</Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUpload />}
                component='label'
              >
                upload
                <input
                  type="file"
                  hidden
                  onChange={handleUploadModelProcuration}
                  accept=".docx"
                />
              </Button>
            </ListItem>
            <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">Formulário de ingresso +10</Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUpload />}
                component='label'
              >
                upload
                <input
                  type="file"
                  hidden
                  onChange={handleUploadModelBigger}
                  accept=".docx"
                />
              </Button>
            </ListItem>
            <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">Formulário de ingresso -10</Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUpload />}
                component='label'
              >
                upload
                <input
                  type="file"
                  hidden
                  onChange={handleUploadModelMinor}
                  accept=".docx"
                />
              </Button>
            </ListItem>

            <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">Rateio</Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUpload />}
                component='label'
              >
                upload
                <input
                  type="file"
                  hidden
                  onChange={handleUploadModelRateio}
                  accept=".docx"
                />
              </Button>
            </ListItem>
           
          </List>
        </Paper>
      </Grid>
    </Grid>
    </Sidebar>
  );
};
 
  export default Settings