import { Link } from "react-router-dom";
import Sidebar from "../../Components/Sidebar"
import { Navigation } from "../../Components/navbar";
import './styleGlobal.css'

import { useState } from "react"
import { useForm } from 'react-hook-form';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import   
 Typography from '@mui/material/Typography';   
import { Box, Grid } from "@mui/material";


const Services = () => {
const services = [
  {
    "title": 'Memorial Descritivo',
    "link": "/services/project-solar-list",
    "image": "capa.png"
  },

 
]


  return (

<>

<Sidebar>
  <Grid container spacing={2}  justifyContent="start" sx={{paddingLeft:'80px',paddingTop:'30px',height:'100%',width:'100%'}}>
 {

services.map((service) => (
  <Grid item xs={12} sm={6} md={2} >
  <Box sx={{ width: 150, height: 150,margin:0 }}>
  <Link to={service.link}>
<Card 

sx={{
  maxWidth: 150,
  height: 150,
  backgroundImage: `url(${service.image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: '#023047',
  transition: 'all 0.2s ease-in-out',
  boxShadow: ' 0 0 8px 4px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    boxShadow: 3,
    transform: 'scale(1.05)',
    transition: 'all 0.2s ease-in-out'
  }
  
}} >
      <CardActionArea sx={{height:'100%',width:'100%'}}>
        <CardContent>
          <Typography gutterBottom variant="h7" component="div" sx={{fontSize:10.5, color: 'white',marginTop:9.5,marginLeft:1.5}}>
            {service.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  </Link>
  </Box>
  </Grid>
  )) }
  </Grid>
</Sidebar>
</>
  )
}
export default Services