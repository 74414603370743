
import Tesseract from 'tesseract.js'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Sidebar from '../Sidebar'
import './styleAterramento.css'
import './styleLaudo.css'
import { FaCheckCircle, FaFile } from "react-icons/fa";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";



import img01 from './index_files/Image_001.jpg';



import img08 from './index_files/Image_008.jpg';

import img10 from './index_files/Image_010.jpg';


import cert01 from './index_files/cert01.jpg';
import cert02 from './index_files/cert02.jpg';
import cert03 from './index_files/cert03.jpg';


import imgFormCliente from '../../assets/formClient.svg'
import imgMap from '../../assets/map.svg'
import artReport from '../../assets/blogReport.svg'
import html2pdf from 'html2pdf.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigation } from '../navbar'






const Aterramento = () => {

  //ART DATA


  const [artEngenheiro, setArtEngenheiro] = useState({ nome: '', titulo: '', empresa: '', registro: '' })
  let nomeEng = artEngenheiro.nome
  let tituloEng = artEngenheiro.titulo
  let registroEng = artEngenheiro.registro
  const [artObra, setArtObra] = useState({ rua: '', numero: '', complemento: '', bairro: '', cidade: '', estado: '', cep: '' })
  const [dataArt, setDataArt] = useState({ numero: '', site: '' })
  

  const getBase64Image = (files, img) => {

    fetch(img)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'qrCode.png', { type: 'image/png' })
        lerQrCode(files, file)
        return file
      })

  }

  /*
  
  const downloadArquivo = (file) => {
    const img = file
    const a = document.createElement('a');
    a.href = img.src;
    a.download = 'qrCode.png';
    a.style.display = 'none';
    
    
    
    const clickHandler = () => {
      setTimeout(() => {
      URL.revokeObjectURL(a.href);
      a.removeEventListener('click', clickHandler);
    }
  , 150);
}
a.addEventListener('click', clickHandler, false);
a.click();
return a;
 
}
*/

  const corteQrCode = (file) => {

    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 150;
        canvas.height = 120;
        ctx.drawImage(img, 630, 1000, 200, 100, 0, 0, 200, 100);
        const dataUrl = canvas.toDataURL('image/png');
        const img2 = new Image();
        img2.src = dataUrl;


        getBase64Image(file, dataUrl)
        //downloadArquivo(img2)


      }
    }
    reader.readAsDataURL(file);
  }



  const lerQrCode = (files, img) => {

    try {
      const nowName = files.name
      const newName = nowName.length > 7 ? nowName.slice(0, 7) + '...' : nowName


      let formData = new FormData()
      formData.append('file', img)
      fetchRequest(formData)
      function fetchRequest(formData) {
        fetch('https://api.qrserver.com/v1/read-qr-code/', {
          method: 'POST',
          body: formData
        }).then(response => response.json())
          .then(data => {

            files && setFileNameART(newName)
            if (data && files) {
              const reader = new FileReader()
              reader.onload = e => setFileART(e.target.result)
              reader.readAsDataURL(files)
              const section = document.querySelector('.uploadedRow')
              section.style.display = 'block'
            }

            const result = data[0].symbol[0].data
            setDataArt({ ...dataArt, site: result })

            const input = document.querySelector('.artSite')
            input.value = data[0].symbol[0].data
          }).catch(err => {
            toast.error('Erro ao ler QRCode')
          }
          )

      }




    } catch (err) {
      console.log(err)
    }
  }

  const numeroArt = (file) => {

    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 150;
        canvas.height = 40;
        //ctx.drawImage(img,630,1000,200,100,0,0,200,100);

        ctx.drawImage(img, 610, 80, 200, 100, 0, 0, 150, 100);
        const dataUrl = canvas.toDataURL('image/png');
        const img2 = new Image();
        img2.src = dataUrl;

        Tesseract.recognize(
          img2,
          'eng',
        ).then(({ data: { text } }) => {
          const input = document.querySelector('.nArt')
          input.value = text


          setDataArt({ ...dataArt, numero: text })
        })
      }
    }


    reader.readAsDataURL(file);
  }


  const corteArt = (file) => {

    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 623;
        canvas.height = 117;
        //ctx.drawImage(img,630,1000,200,100,0,0,200,100);

        ctx.drawImage(img, 235, 1000, 490, 100, 0, 0, 630, 120);
        const dataUrl = canvas.toDataURL('image/png');
        const img2 = new Image();
        img2.src = dataUrl;
        const imgArt = document.querySelector('.imgArt')
        imgArt.src = String(dataUrl)
        const imgArt2 = document.querySelector('.imgArt2')
        imgArt2.src = String(dataUrl)
        const imgArt3 = document.querySelector('.imgArt3')
        imgArt3.src = String(dataUrl)


      }
    }


    reader.readAsDataURL(file);
  }





  const infoEngenheiro = (file) => {

    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 200;
        //ctx.drawImage(img,630,1000,200,100,0,0,200,100);
        ctx.drawImage(img, 60, 160, 500, 100, 0, 0, 750, 110);
        const dataUrl = canvas.toDataURL('image/png');
        const img2 = new Image();
        img2.src = dataUrl;

        Tesseract.recognize(
          img2,
          'eng',
        ).then(({ data: { text } }) => {

          const conteudo = text.split('\n')
          const nome = conteudo[1]
          const titulo = conteudo[2].split('Titulo profissional. ')[1]
          const empresa = conteudo[3].split('Empresa contratada: ')[1].replace('SOLUGOES', 'SOLUÇÕES')

          const inputNome = document.querySelector('.eng')
          const inputTitulo = document.querySelector('.tituloP')

          inputNome.value = nome
          inputTitulo.value = titulo


          setArtEngenheiro({ ...artEngenheiro, nome: String(nome), titulo: String(titulo), empresa: String(empresa) })

        })
      }
    }


    reader.readAsDataURL(file);
  }


  const registroEngenheiro = (file) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 150;
        canvas.height = 40;
        //ctx.drawImage(img,630,1000,200,100,0,0,200,100);

        ctx.drawImage(img, 530, 170, 200, 100, 0, 0, 200, 110);
        const dataUrl = canvas.toDataURL('image/png');
        const img2 = new Image();
        img2.src = dataUrl;

        Tesseract.recognize(
          img2,
          'eng',
        ).then(({ data: { text } }) => {

          const conteudo = text.split('\n')
          const registroCrea = conteudo[0].split('RNP:')[1]
          const input = document.querySelector('.RNP')
          input.value = parseInt(registroCrea)

          setArtEngenheiro({ ...artEngenheiro, registro: registroCrea })

        })
      }
    }


    reader.readAsDataURL(file);
  }
  const infoCliente = (file) => {
    enderecoCliente(file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 80;
        //ctx.drawImage(img,630,1000,200,100,0,0,200,100);

        ctx.drawImage(img, 80, 280, 400, 100, 0, 0, 630, 110);
        const dataUrl = canvas.toDataURL('image/png');
        const img2 = new Image();
        img2.src = dataUrl;


        Tesseract.recognize(
          img2,
          'eng',
        ).then(({ data: { text } }) => {
          const conteudo = text.split('\n')

          const nome = conteudo[0]
          const complementoC = conteudo[3]

          const cidadeSuja = conteudo[5].replace('Cidade: ', '')
          const cidadeLimpa = cidadeSuja.replace('UF: cf', '')

          const nomeCliente = nome.split(':')[1] ? nome.split(':')[1] : '---'
          const ruaCliente = conteudo[1] ? conteudo[1] : '---'
          const complementoCliente = complementoC.split(':')[1] ? complementoC.split(':')[1] : '---'
          const cidadeCliente = cidadeLimpa ? cidadeLimpa : '---'






          const razao_social = document.getElementById('InputRazao_social')
          const logradouro = document.getElementById('InputLogradouro')
          const complemento = document.getElementById('InputComplemento')
          const cidade = document.getElementById('InputCidade')
          razao_social.value = nomeCliente
          logradouro.value = ruaCliente
          complemento.value = complementoCliente
          cidade.value = cidadeCliente

          setCliente({ ...cliente, razao_social: nomeCliente, logradouro: ruaCliente, complemento: complementoCliente, cidade: cidadeCliente })
        })
      }
    }


    reader.readAsDataURL(file);
  }


  const enderecoCliente = (file) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 100;
        //ctx.drawImage(img,630,1000,200,100,0,0,200,100);

        ctx.drawImage(img, 420, 280, 500, 100, 0, 0, 600, 100);
        const dataUrl = canvas.toDataURL('image/png');
        const img2 = new Image();
        img2.src = dataUrl;

        Tesseract.recognize(
          img2,
          'eng',
        ).then(({ data: { text } }) => {

          const conteudo = text.split('\n')





          const bairroCerto = conteudo[3] ? conteudo[3] : '---'
          const cnpjCpf = conteudo[0].split(':')[1] ? conteudo[0].split(':')[1] : '---'
          const cepC = conteudo[5].split('CEP:')[1] ? conteudo[5].split('CEP:')[1].replace() : '---'
          const bairroC = bairroCerto.split(':')[1] ? bairroCerto.split(':')[1] : '---'
          const numeroC = conteudo[1].split('N')[1] ? conteudo[1].split('N')[1] : '---'
          const uf = conteudo[5].split('CEP:')[0].replace('UF-', '') ? conteudo[5].split('CEP:')[0].replace('UF-', '') : '---'


          const cnpj = document.getElementById('InputCNPJ')
          const numero = document.getElementById('InputNumero')
          const bairro = document.getElementById('InputBairro')
          const estado = document.getElementById('InputEstado')
          const cep = document.getElementById('InputCep')
          cnpj.value = cnpjCpf
          numero.value = parseInt(numeroC)
          bairro.value = bairroC
          estado.value = uf.toUpperCase()
          cep.value = cepC.replace(/(\d{5})(\d{3})/, "$1-$2")

          setCliente({ ...cliente, cnpj: cnpjCpf, numero: numeroC, bairro: bairroC, estado: uf.toUpperCase(), cep: cepC.replace(/(\d{5})(\d{3})/, "$1-$2") })








        })
      }
    }


    reader.readAsDataURL(file);
  }

  const infoObra = (file) => {
    enderçoObra(file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 70;
        //ctx.drawImage(img,630,1000,200,100,0,0,200,100);

        ctx.drawImage(img, 80, 425, 400, 100, 0, 0, 630, 110);
        const dataUrl = canvas.toDataURL('image/png');
        const img2 = new Image();
        img2.src = dataUrl;


        Tesseract.recognize(
          img2,
          'eng',
        ).then(({ data: { text } }) => {
          const conteudo = text.split('\n')


          const complemento = conteudo[2]

          const ruaObra = conteudo[0]
          const complementoObra = complemento.split(':')[1]
          const cidadeObra = conteudo[4].replace('Cidade: ', '')

          setArtObra({ ...artObra, rua: ruaObra, complemento: complementoObra, cidade: cidadeObra })
        })
      }
    }


    reader.readAsDataURL(file);
  }


  const enderçoObra = (file) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 100;
        //ctx.drawImage(img,630,1000,200,100,0,0,200,100);

        ctx.drawImage(img, 420, 400, 500, 100, 0, 0, 600, 100);
        const dataUrl = canvas.toDataURL('image/png');
        const img2 = new Image();
        img2.src = dataUrl;

        Tesseract.recognize(
          img2,
          'eng',
        ).then(({ data: { text } }) => {

          const conteudo = text.split('\n')

          const bairroCerto = conteudo[1]



          const cep = conteudo[2].split('CEP:')[1]
          const bairro = bairroCerto.split(':')[1]
          const numero = conteudo[0].split('N')[1]
          const uf = conteudo[2].split('CEP:')[0].replace('UF-', '').toLocaleUpperCase()
          setArtObra({ ...artObra, numero: numero, bairro: bairro, estado: uf, cep: cep })
        })
      }
    }


    reader.readAsDataURL(file);
  }
















  const {
    register,

    formState: { errors },

  } = useForm()
  const [cliente, setCliente] = useState({ razao_social: '', cnpj: '', email: '', nome_fantasia: '', logradouro: '', numero: '', complemento: '', bairro: '', cidade: '', estado: '', cep: '' })




  const handleCNPJ = async (cnpj) => {

    if (!cnpj.value) {
      toast.error('Campo CNPJ vazio')
      return
    }
    const cnpjCorrect = cnpj.value.replace(/[^\d]+/g, '');
    if (cnpjCorrect.length !== 14) {
      toast.error('CNPJ inválido')
      return
    }
    await fetch(`https://publica.cnpj.ws/cnpj/${cnpjCorrect}`)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'ERROR') {
          toast.error('CNPJ não encontrado')
          return
        }

        const cnpjformat = data.estabelecimento.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        const formattedCep = data.estabelecimento.cep.replace(/(\d{5})(\d{3})/, "$1-$2")


        const razao_social = document.getElementById('InputRazao_social')
        const nome_fantasia = document.getElementById('InputNome_fantasia')
        const logradouro = document.getElementById('InputLogradouro')
        const numero = document.getElementById('InputNumero')
        const complemento = document.getElementById('InputComplemento')
        const bairro = document.getElementById('InputBairro')
        const cidade = document.getElementById('InputCidade')
        const estado = document.getElementById('InputEstado')
        const cep = document.getElementById('InputCep')
        razao_social.value = data.razao_social
        nome_fantasia.value = data.estabelecimento.nome_fantasia
        cnpj.value = cnpjformat
        logradouro.value = data.estabelecimento.logradouro
        numero.value = data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N'
        complemento.value = data.estabelecimento.complemento ? data.estabelecimento.complemento : '---'
        bairro.value = data.estabelecimento.bairro
        cidade.value = data.estabelecimento.cidade.nome
        estado.value = data.estabelecimento.estado.sigla
        cep.value = data.estabelecimento.cep



        const _cnpj = [
          data.razao_social,
          cnpjformat,
          data.estabelecimento.email,
          data.estabelecimento.nome_fantasia,
          data.estabelecimento.logradouro,
          data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N',
          data.estabelecimento.complemento ? data.estabelecimento.complemento : '---',
          data.estabelecimento.bairro,
          data.estabelecimento.cidade.nome,
          data.estabelecimento.estado.sigla,
          formattedCep

        ]
        setCliente({ razao_social: _cnpj[0], cnpj: _cnpj[1], email: _cnpj[2], nome_fantasia: _cnpj[3], logradouro: _cnpj[4], numero: _cnpj[5], complemento: _cnpj[6], bairro: _cnpj[7], cidade: _cnpj[8], estado: _cnpj[9], cep: _cnpj[10] })
        document.querySelector('.group').style.display = 'block'

      }).catch(err => {
        console.log(err)

      })
  }

  const formatEndereco = (logradouro, numero, complemento, bairro, cidade, estado, cep) => {

    const endereco = `Rua :${logradouro} Nº ${numero}, Complemento: ${complemento}, Bairro: ${bairro}, Cidade: ${cidade} - ${estado}, CEP: ${cep}`
    return endereco
  }





  /* Form Img */

  const [fileNameART, setFileNameART] = useState()
  const [fileART, setFileART] = useState(null)
  const [fileFotos, setFileFotos] = useState(null)
  const [listImage, setListImage] = useState([])







  const [isOpen, setIsOpen] = useState(1);
  const [points, setPoints] = useState([])
  const [malha, setMalha] = useState([{ ponto: 'malha de aterramento', ohms1: '', ohms2: '', ohms3: '' }])

  const addInput = (e) => {
    e.preventDefault()
    setPoints([...points, { ponto: '', ohms: '', ohms1: '', ohms2: '', ohms3: '' }])

  }


  const handleSelect = (e) => {

    const button = document.getElementById('newPoint')
    if (e.target.value.toUpperCase() === '1') {
      button.disabled = false
      setIsOpen(1)
    }
    if (e.target.value.toUpperCase() === '3') {
      button.disabled = false
      setIsOpen(3)
    }
  }

  const averageOhm = (ohms1, ohms2, ohms3) => {
    const m = (parseFloat(ohms1) + parseFloat(ohms2) + parseFloat(ohms3)) / 3
    const Req = m.toFixed(2)
    return Req
  }

  const result = (ohms1, ohms2, ohms3) => {
    const x = parseFloat(ohms2) * 0.1
    const y = parseFloat(ohms2) + x
    const z = parseFloat(ohms2) - x
    const a = x.toFixed(2)
    const b = y.toFixed(2)
    const c = z.toFixed(2)

    if (((parseFloat(ohms2) < (parseFloat(ohms1 * 1.1))) && (parseFloat(ohms1) < 10))

      && ((parseFloat(ohms3) < (parseFloat(ohms2 * 1.1))) && (parseFloat(ohms2) < 10))) {

      return 'Aprovado'
    }
    else {
      return <p style={{ color: 'red' }}>Reprovado</p>
    }
  }
  const resultPoint = (ohms1, ohms2, ohms3, Mohms1, Mohms2, Mohms3) => {
    const x = parseFloat(ohms2) * 0.1
    const y = parseFloat(ohms2) + x
    const z = parseFloat(ohms2) - x
    const a = x.toFixed(2)
    const b = y.toFixed(2)
    const c = z.toFixed(2)

    if ((((parseFloat(ohms2) < (parseFloat(ohms1 * 1.1))) && (parseFloat(ohms1) < 10)) && (((parseFloat(ohms3) < (parseFloat(ohms2 * 1.1))) && (parseFloat(ohms2) < 10)))
      && (parseFloat(ohms1) > parseFloat(Mohms1) || parseFloat(ohms1) > parseFloat(Mohms2 || parseFloat(ohms1) > parseFloat(Mohms3))) && (parseFloat(ohms2) > parseFloat(Mohms1) || parseFloat(ohms2) > parseFloat(Mohms2 || parseFloat(ohms2) > parseFloat(Mohms3))))
      && ((parseFloat(ohms3) < b && parseFloat(ohms3) > c && parseFloat(ohms3)) && (parseFloat(ohms3) > parseFloat(Mohms1) || parseFloat(ohms3) > parseFloat(Mohms2 || parseFloat(ohms3) > parseFloat(Mohms3))))
    ) {
      return 'Aprovado'
    }
    else {
      return <p style={{ color: 'red' }}>Reprovado</p>
    }
  }
  const resultOne = (ohms, ohms1, ohms2, ohms3) => {
    const x = parseFloat(ohms)
    const y = parseFloat(ohms1)
    const z = parseFloat(ohms2)
    const w = parseFloat(ohms3)
    const a = x.toFixed(2)
    const b = y.toFixed(2)
    const c = z.toFixed(2)
    const d = w.toFixed(2)
    if ((a > b || a > c || a > d) && (a < 10)) {

      return 'Aprovado'
    }
    else {
      return <p style={{ color: 'red' }}>Reprovado</p>
    }
  }

  const resultAll = () => {
    const resultMalha = malha.map((m, i) => {

      return result(m.ohms1, m.ohms2, m.ohms3)
    })
    const resultPonto = points.map((ponto, index) => {
      return malha.map((m, i) => {

        return resultOne(ponto.ohms, m.ohms1, m.ohms2, m.ohms3)
      }
      )
    })
    const resultPontos = points.map((ponto, index) => {
      return malha.map((m, i) => {

        return resultPoint(ponto.ohms, ponto.ohms1, ponto.ohms2, ponto.ohms3, m.ohms1, m.ohms2, m.ohms3)
      })
    })


    let reprovadomalha = false
    if (String(resultMalha) !== 'Aprovado') {
      return reprovadomalha = true
    }
    let reprovadoponto = false
    if (isOpen === 1) {
      resultPonto.map((ponto, index) => {
        if (String(ponto) !== 'Aprovado') {
          return reprovadoponto = true
        }
      })
    }
    let reprovadopontos = false
    if (isOpen === 3) {
      resultPontos.map((ponto, index) => {
        if (String(ponto) !== 'Aprovado') {
          return reprovadopontos = true
        }
      })
    }


    if (reprovadomalha === true || reprovadoponto === true || reprovadopontos === true) {

      return <p className='s2' style={{ color: 'red' }}>Reprovado</p>
    } else {
      return <p className='s2' style={{ color: 'green' }}>Aprovado</p>
    }

  }

  const conclusao = () => {
    const resultMalha = malha.map((m, i) => {

      return result(m.ohms1, m.ohms2, m.ohms3)
    })
    const resultPonto = points.map((ponto, index) => {
      return malha.map((m, i) => {

        return resultOne(ponto.ohms, m.ohms1, m.ohms2, m.ohms3)
      }
      )
    })
    const resultPontos = points.map((ponto, index) => {
      return malha.map((m, i) => {

        return resultPoint(ponto.ohms, ponto.ohms1, ponto.ohms2, ponto.ohms3, m.ohms1, m.ohms2, m.ohms3)
      })
    })


    let reprovadomalha = false
    if (String(resultMalha) !== 'Aprovado') {
      return reprovadomalha = true
    }
    let reprovadoponto = false
    if (isOpen === 1) {
      resultPonto.map((ponto, index) => {
        if (String(ponto) !== 'Aprovado') {
          return reprovadoponto = true
        }
      })
    }
    let reprovadopontos = false
    if (isOpen === 3) {
      resultPontos.map((ponto, index) => {
        if (String(ponto) !== 'Aprovado') {
          return reprovadopontos = true
        }
      })
    }

    if (reprovadomalha === true || reprovadoponto === true || reprovadopontos === true) {
      return (
        <p
          style={{
            paddingTop: "10pt",
            paddingLeft: "42pt",
            textIndent: "0pt",
            lineHeight: "150%",
            textAlign: "justify"
          }}>
          Com base nos valores de resistência de aterramento medidos, <br />
          onde o valor de alguns pontos de aterramento estão em desconformidade <br />
          com a medição de resistência de aterramento, <br />
          recomenda-se a realização de manutenção corretiva nos pontos de aterramento <br />
          que apresentaram resistência de aterramento superior a 10Ω, <br />
          ou estão abaixo do valor de referência da malha de aterramento, <br />
          conforme a norma ABNT NBR 5419:2005.
        </p>
      )
    } else {
      return (
        <p
          style={{
            paddingTop: "10pt",
            paddingLeft: "42pt",
            textIndent: "0pt",
            lineHeight: "150%",
            textAlign: "justify"
          }}
        >
          <span>
            Com base nos valores de resistência de aterramento medidos, onde todos
            os
            <br />
            valores obtidos são inferiores a 10Ω
          </span>
          <span className="s20">12</span>
          <span>
            , todos os pontos de aterramento medidos
            <br />
            estão{" "}
          </span>
          <u>
            <b>APROVADOS</b>
          </u>
          <span>
            . Conclui-se que o sistema de aterramento (malha de
            <br />
            aterramento) atende os requisitos das normas, não possuindo tensões de
            toque e<br />
            passo perigosas, garantindo a proteção do pessoal que mantenha ou não
            contato
            <br />
            com as instalações, circuitos de comunicação, controle e outros, bem
            como o bom
            <br />
            funcionamento dos equipamentos elétricos.
          </span>
        </p>

      )
    }

  }



  /* Dados de Medição */

  const [form, setForm] = useState({ dataVistoria: '', dataMedicoes: '', inicioMedicoes: '', fimMedicoes: '', estadoSolo: '', temperaturaAmbiente: '', humidadeAr: '', maiorMedicao: '' })

  const horarioDecorrido = (inicio, fim) => {
    const inputMedicoes = document.getElementById('FimMedicoes')
    const inicioArray = inicio.split('h')
    const fimArray = fim.split('h')
    const horarioInicio = inicioArray[0].split(':')
    const horarioFim = fimArray[0].split(':')
    let horaInicio = Number(horarioInicio[0])
    let minutoInicio = Number(horarioInicio[1])
    let horaFim = Number(horarioFim[0])
    let minutoFim = Number(horarioFim[1])
    if (!horaFim || !minutoFim) {
      return
    }
    if (!horaInicio || !minutoInicio) {
      return
    }
    if (horaFim < horaInicio) {
      inputMedicoes.value = ''
      return toast.error('Hora de termino não pode ser menor que a hora de início')
    }
    if (horaFim === horaInicio && minutoFim < minutoInicio) {
      inputMedicoes.value = ''
      return toast.error('Minuto de termino não pode ser menor que o minuto de início')
    }
    if (horaFim === horaInicio && minutoFim === minutoInicio) {
      inputMedicoes.value = ''
      return toast.error('Hora de termino não pode ser igual a hora de início')
    }

    if (minutoFim < minutoInicio) {
      horaFim = Number(horaFim) - 1
      minutoFim = Number(minutoFim) + 60
    }
    const hora = horaFim - horaInicio
    const minuto = minutoFim - minutoInicio



    if (hora < 0) {
      inputMedicoes.value = ''
      return toast.error('Hora inválida')
    }
    if (minuto < 0) {
      inputMedicoes.value = ''
      return toast.error('Minuto inválido')
    }



    return `${Number(hora)}h${Number(minuto)}`


  }

  const format = (date) => {
    const dateArray = String(date).split('-')
    const year = String(dateArray[0])
    const month = String(dateArray[1])
    const day = String(dateArray[2])
    return `${day}/${month}/${year}`
  }
  const dateReport = (date) => {
    const dateArray = String(date).split('-')
    const year = (dateArray[0])
    const month = (dateArray[1])

    return `${String(month)}/${String(year)}`
  }
  const confereData = (dataInicio, dataFim) => {

    const inputMedicoes = document.getElementById('DataMedicoes')
    const dataInicioArray = String(dataInicio).split('-')
    const dataFimArray = String(dataFim).split('-')
    const dataInicioDate = new Date(dataInicioArray[0], dataInicioArray[1], dataInicioArray[2])
    const dataFimDate = new Date(dataFimArray[0], dataFimArray[1], dataFimArray[2])
    if (dataInicioDate > dataFimDate) {
      inputMedicoes.value = ''
      return toast.error('Data de vistoria não pode ser maior que a data de medições')
    }
    return format(dataFim)


  }

  const confereTemepatura = (temperatura) => {
    if (temperatura.length > 2) {
      const inputTemperatura = document.getElementById('TemperaturaAmbiente')
      inputTemperatura.value = ''
      return toast.error('Temperatura digitada acima do permitido')
    }
    const inputTemperatura = document.getElementById('TemperaturaAmbiente')
    if (Number(temperatura) > 60 || Number(temperatura) < 0) {

      inputTemperatura.value = ''
      return toast.error('Temperatura deve estar entre 0 e 60 graus')
    }
  }

  const confereUmidade = (umidade) => {
    if (umidade.length > 3) {
      const inputUmidade = document.getElementById('HumidadeAr')
      inputUmidade.value = ''
      return toast.error('Umidade digitada acima do permitido')
    }
    const inputUmidade = document.getElementById('HumidadeAr')
    if (Number(umidade) > 100 || Number(umidade) < 0) {
      inputUmidade.value = ''
      return toast.error('Umidade deve estar entre 0 e 100%')
    }
  }



  /* abrir laudo */

  const fecharModal = () => {
    const modal = document.getElementById('modal')
    modal.style.zIndex = '-1000000'

  }
  const gerarModal = () => {
    const modal = document.getElementById('modal')
    modal.style.zIndex = '10000000000'
  }



  /* transformando html em pdf*/


  const gerarPDF = () => {
    const conteudo = document.querySelector('.divReport')
    const pdf = conteudo
    const opt = {
      margin: 0,
      filename: 'Laudo-de-Aterramento.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }
    html2pdf().from(pdf).set(opt).save()

  }



  /* tranformar inteiro em decimal */

  const transformaDecimal = (numero) => {


    if (numero.length > 2) {
      const numeroString = numero.toString()
      const numeroArray = numeroString.split('')
      const numeroTransformado = `${numeroArray[0]}.${numeroArray[1]}${numeroArray[2]}`


      return numeroTransformado

    }
  }

  const calcDistancia = (D, id1, id2) => {
    let Dm = D * 3
    let Ds1 = (D * 3) * 0.568
    let Ds = (D * 3) * 0.618
    let Ds2 = (D * 3) * 0.668
    Dm = Dm.toFixed(2)
    Ds1 = Ds1.toFixed(2)
    Ds = Ds.toFixed(2)
    Ds2 = Ds2.toFixed(2)
    if (id1 === 1) {
      const p = document.getElementById('text1')
      p.innerHTML = ` <p> Dm = ${Dm}m (D * 3) <br /><br />
      Ds1 = ${Ds1}m  (56,8%D) <br /></p>
      <p> Ds = ${Ds}m (61,8%D) <br /><br />
      Ds2 = ${Ds2}m  (66,8%D) <br /> </p>`
    }
    if (id2 === 2) {
      const t1 = document.getElementById('text01')
      t1.innerHTML = `     D = ${Dm}m Distância da Haste de terra à Eletrodo de Corrente
      <br /> `
      const t2 = document.getElementById('text02')
      t2.innerHTML = `= ${Ds2} Distância do Eletrodo de Potência (66,8% D) `
      const t3 = document.getElementById('text03')
      t3.innerHTML = `= ${Ds} Distância do Eletrodo de Potência (61,8% D) `
      const t4 = document.getElementById('text04')
      t4.innerHTML = `= ${Ds1} Distância do Eletrodo de Potência (56,8% D) `

    }

  }




  return (
    <>
      <Navigation>
      <Sidebar >
      <div className='OrganizeMain'>

        <ToastContainer
          position='top-center' />




        <div className='filegroupOne'>

          <div className='headTitle'>
            <h1>Novo Laudo</h1>
          </div>
          <div className='contentArt'>
            <div>
              <img height={400} width={300} src={artReport} alt="" />
            </div>

            <div className='formFile'>
              <br />
              <div className='formTitle'>
                <h2 style={{ color: 'white' }}>ANOTAÇÃO DE RESPONSABILIDADE TÉCNICA:</h2>
              </div>
              <br />
              <br />
              <div className='inputform' style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '80px' }}>
                <div className='inputselectart' style={{ width: '100%', height: '100px' }}>
                  <form className='formART'
                    onClick={() => document.querySelector('.inputART').click()}>
                    <input type='file' accept='image/*' id='inputART' className='inputART' hidden
                      onChange={({ target: { files } }) => {
                        let file = files[0]

                        numeroArt(file);
                        infoEngenheiro(file);
                        registroEngenheiro(file);
                        infoCliente(file);
                        infoObra(file);
                        corteArt(file);
                        corteQrCode(file);
                      }}
                    />


                    {
                      fileART
                        ?
                        <FaCheckCircle color='#00da1d' size={20} />
                        :
                        <>
                          <MdCloudUpload color='#1475cf' size={20} />
                          <p>To Upload</p>
                        </>
                    }

                  </form>
                  <section className='uploadedRow'>
                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <FaFile color='#1475cf' size={12} />
                      <p>{fileNameART}</p>
                      <MdDelete

                        className='delete'
                        cursor={'pointer'}
                        margin={5}
                        color='red'
                        onClick={() => {
                          setFileART(null)
                          setFileNameART('No Selected Files')
                          setDataArt({ ...dataArt, numero: '', site: '' })
                          setArtEngenheiro({ eng: '', tituloP: '', RNP: '' })
                          setCliente({ razao_social: '', cnpj: '', email: '', nome_fantasia: '', logradouro: '', numero: '', complemento: '', bairro: '', cidade: '', estado: '', cep: '' })
                          const input = document.querySelector('.artSite')
                          input.value = ''
                          const inputART = document.querySelector('.inputART')
                          inputART.value = ''
                          const nArt = document.querySelector('.nArt')
                          nArt.value = ''
                          const eng = document.querySelector('.eng')
                          eng.value = ''
                          const tituloP = document.querySelector('.tituloP')
                          tituloP.value = ''
                          const RNP = document.querySelector('.RNP')
                          RNP.value = ''
                          const cnpj = document.querySelector('#InputCNPJ')
                          cnpj.value = ''
                          const razao_social = document.querySelector('#InputRazao_social')
                          razao_social.value = ''
                          const nome_fantasia = document.querySelector('#InputNome_fantasia')
                          nome_fantasia.value = ''
                          const logradouro = document.querySelector('#InputLogradouro')
                          logradouro.value = ''
                          const numero = document.querySelector('#InputNumero')
                          numero.value = ''
                          const complemento = document.querySelector('#InputComplemento')
                          complemento.value = ''
                          const bairro = document.querySelector('#InputBairro')
                          bairro.value = ''
                          const cidade = document.querySelector('#InputCidade')
                          cidade.value = ''
                          const estado = document.querySelector('#InputEstado')
                          estado.value = ''
                          const cep = document.querySelector('#InputCep')
                          cep.value = ''

                          const section = document.querySelector('.uploadedRow')
                          section.style.display = 'none'
                        }
                        }
                      />

                    </span>
                  </section>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}>
                  <div>

                    <span>N° ART: <br />
                      <input className='nArt' type="text"

                        onChange={e => setDataArt({ ...dataArt, numero: e.target.value.toUpperCase() })}
                      /></span>
                  </div>
                  <div>
                    <span>LINK: <br />
                      <input className='artSite' type="text"
                        onChange={e => setDataArt({ ...dataArt, site: e.target.value.toUpperCase() })}
                      /></span>

                  </div>
                  <div>

                    <span>Engenheiro Responsável: <br />
                      <input className='eng' type="text"

                        onChange={e => setArtEngenheiro({ ...artEngenheiro, nome: e.target.value.toUpperCase() })}
                      /></span>
                  </div>
                  <div>

                    <span>Titulo Profissional: <br />
                      <input className='tituloP' type="text"

                        onChange={e => setArtEngenheiro({ ...artEngenheiro, titulo: e.target.value.toUpperCase() })}
                      /></span>
                  </div>
                  <div>

                    <span>RNP: <br />
                      <input className='RNP' type="number"

                        onChange={e => setArtEngenheiro({ ...artEngenheiro, registro: e.target.value })}
                      /></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className='footerForm'>
            <button
              onClick={() => {

                const nArt = document.querySelector('.nArt')
                const eng = document.querySelector('.eng')
                const tituloP = document.querySelector('.tituloP')
                const RNP = document.querySelector('.RNP')
                const artSite = document.querySelector('.artSite')

                if (!nArt.value || !eng.value || !tituloP.value || !RNP.value || !artSite.value) {
                  toast.error('Preencha todos os campos')
                  nArt.focus()
                  if (!nArt.value) {
                    nArt.style.borderBottom = '1px solid red'
                  }
                  if (!eng.value) {
                    eng.style.borderBottom = '1px solid red'
                  }
                  if (!tituloP.value) {
                    tituloP.style.borderBottom = '1px solid red'
                  }
                  if (!RNP.value) {
                    RNP.style.borderBottom = '1px solid red'
                  }
                  if (!artSite.value) {
                    artSite.style.borderBottom = '1px solid red'
                  }


                  return
                }

                if (nArt.value) {
                  nArt.style.borderBottom = '1px solid #34eb3a'
                }
                if (eng.value) {
                  eng.style.borderBottom = '1px solid #34eb3a'
                }
                if (tituloP.value) {
                  tituloP.style.borderBottom = '1px solid #34eb3a'
                }
                if (RNP.value) {
                  RNP.style.borderBottom = '1px solid #34eb3a'
                }
                if (artSite.value) {
                  artSite.style.borderBottom = '1px solid #34eb3a'
                }


                setDataArt({ ...dataArt, numero: nArt.value, site: artSite.value })
                setArtEngenheiro({ ...artEngenheiro, nome: eng.value, titulo: tituloP.value, registro: RNP.value })

                const pagCliente = document.querySelector('.formInform')
                const pagART = document.querySelector('.filegroupOne')
                pagART.style.zIndex = '-5'
                pagCliente.style.zIndex = '1'
              }}

              className='next'>proxima</button>

          </div>

        </div>















        <div className='formInform'>
          <div className='headTitle'>
            <h1>Novo Laudo</h1>
          </div>

          <br />
          <div className='formHeader'>

            <h2>INFORMAÇÕES DO CLIENTE</h2>
            <span>

              <input
                id='InputOption'
                type="checkbox"
                onChange={(e) => {
                  const buscarCNPJ = document.querySelector('.buscarCNPJ')
                  const razao_social = document.querySelector('#InputRazao_social')
                  const nome_fantasia = document.querySelector('#InputNome_fantasia')
                  const logradouro = document.querySelector('#InputLogradouro')
                  const numero = document.querySelector('#InputNumero')
                  const complemento = document.querySelector('#InputComplemento')
                  const bairro = document.querySelector('#InputBairro')
                  const cidade = document.querySelector('#InputCidade')
                  const estado = document.querySelector('#InputEstado')
                  const cep = document.querySelector('#InputCep')

                  if (e.target.checked) {
                    buscarCNPJ.style.display = 'block'
                    razao_social.disabled = true
                    nome_fantasia.disabled = true
                    logradouro.disabled = true
                    numero.disabled = true
                    complemento.disabled = true
                    bairro.disabled = true
                    cidade.disabled = true
                    estado.disabled = true
                    cep.disabled = true
                  } else {
                    buscarCNPJ.style.display = 'none'
                    razao_social.disabled = false
                    nome_fantasia.disabled = false
                    logradouro.disabled = false
                    numero.disabled = false
                    complemento.disabled = false
                    bairro.disabled = false
                    cidade.disabled = false
                    estado.disabled = false
                    cep.disabled = false
                  }
                }
                }

              /> Obter dados da Receita Federal
              <button onClick={() => handleCNPJ(
                document.getElementById('InputCNPJ')
              )} className='buscarCNPJ'>Buscar</button>
            </span>
          </div>
          <br />
          <div className="form" id='infoCliente' style={{ overflowY: 'auto' }}>
            <div>

              <div className='formTitle'>
                <h4>Dados do cliente:</h4>
              </div>
              <div className="reportsOne">

                <div>


                  <div className="formReports">
                    CNPJ:
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        id='InputCNPJ'
                        className={errors?.cnpj && 'input' && "input-error "}
                        type="text"
                        placeholder="CNPJ"

                        onChange={(e) => {
                          setCliente({ ...cliente, cnpj: e.target.value.toUpperCase() })
                        }}

                      />


                    </span>
                  </div>
                  <div className="formReports">
                    Razão Social:
                    <input
                      id='InputRazao_social'
                      className={errors?.razao_social && 'input' && "input-error"}
                      type="text"
                      placeholder="Razão Social"

                      onChange={(e) => {
                        setCliente({ ...cliente, razao_social: e.target.value.toUpperCase() })
                      }
                      }

                    />

                  </div>
                  <div className="formReports">
                    Nome Fantasia:
                    <input
                      id='InputNome_fantasia'
                      className={errors?.nome_fantasia && 'input' && "input-error"}
                      type="text"
                      placeholder="Nome Fantasia"

                      onChange={(e) => {
                        setCliente({ ...cliente, nome_fantasia: e.target.value.toUpperCase() })
                      }}

                    />

                  </div>
                </div>
                <span
                  style={{ padding: '10px' }}
                >
                  <img width={250} height={250} src={imgFormCliente} alt="" />
                </span>

              </div>
            </div>
            <div>

              <div className='formTitle'>
                <h4> Dados de endereço:</h4>
              </div>
              <div className="reportsToo">
                <div>
                  <div className="formReports">
                    Cep:
                    <input
                      id='InputCep'
                      className={errors?.cep && 'input' && "input-error"}
                      type="text"
                      placeholder="Cep"
                      onChange={(e) => {
                        setCliente({ ...cliente, cep: e.target.value.toUpperCase() })
                      }
                      }
                    />
                  </div>

                  <div className="formReports">
                    Logradouro:

                    <input
                      id='InputLogradouro'
                      className={errors?.logradouro && 'input' && "input-error"}
                      type="text"
                      placeholder="Logradouro"

                      onChange={(e) => {
                        setCliente({ ...cliente, logradouro: e.target.value.toUpperCase() })
                      }}
                    />

                  </div>


                  <div className="formReports">
                    Numero:
                    <input
                      id='InputNumero'
                      className={errors?.numero && 'input' && "input-error"}
                      type="number"
                      placeholder="Numero"

                      onChange={(e) => {
                        setCliente({ ...cliente, numero: e.target.value.toUpperCase() })
                      }}

                    />
                  </div>

                  <div className="formReports">
                    Complemento:
                    <input
                      id='InputComplemento'
                      className={errors?.complemento && 'input' && "input-error"}
                      type="text"
                      placeholder="Complemento"

                      onChange={(e) => {
                        setCliente({ ...cliente, complemento: e.target.value.toUpperCase() })
                      }}

                    />
                  </div>
                  <div className="formReports">
                    Bairro:
                    <input
                      id='InputBairro'
                      className={errors?.bairro && 'input' && "input-error"}
                      type="text"
                      placeholder="Bairro"

                      onChange={(e) => {
                        setCliente({ ...cliente, bairro: e.target.value.toUpperCase() })
                      }}

                    />


                  </div>
                  <div className="formReports">
                    Cidade:
                    <input
                      id='InputCidade'
                      className={errors?.cidade && 'input' && "input-error"}
                      type="text"
                      placeholder="Cidade"

                      onChange={(e) => {
                        setCliente({ ...cliente, cidade: e.target.value.toUpperCase() })
                      }}
                    />


                  </div>
                  <div className="formReports">
                    Estado:
                    <input
                      id='InputEstado'
                      className={errors?.estado && 'input' && "input-error"}
                      type="text"
                      placeholder="Estado"

                      onChange={(e) => {
                        setCliente({ ...cliente, estado: e.target.value.toUpperCase() })
                      }}
                    />

                  </div>

                </div>
                <span
                  style={{ padding: '10px' }}
                >
                  <img width={250} height={250} src={imgMap} alt />
                </span>
              </div>
            </div>
          </div>
          <div className='footerForm'>
            <button className='backButton'

              onClick={() => {
                const pagART = document.querySelector('.filegroupOne')
                const pagCliente = document.querySelector('.formInform')
                pagCliente.style.zIndex = '-5'
                pagART.style.zIndex = '1'

              }}
            >voltar</button>
            <button className='next'

              onClick={() => {

                const cnpj = document.getElementById('InputCNPJ')
                const razao_social = document.getElementById('InputRazao_social')
                const nome_fantasia = document.getElementById('InputNome_fantasia')
                const logradouro = document.getElementById('InputLogradouro')
                const numero = document.getElementById('InputNumero')
                const complemento = document.getElementById('InputComplemento')
                const bairro = document.getElementById('InputBairro')
                const cidade = document.getElementById('InputCidade')
                const estado = document.getElementById('InputEstado')
                const cep = document.getElementById('InputCep')
                if (cnpj.value === '' || razao_social.value === '' || logradouro.value === '' || bairro.value === '' || cidade.value === '' || estado.value === '' || cep.value === '') {
                  toast.error('Preencha todos os campos')
                  cnpj.focus()
                  if (!cnpj.value) {
                    cnpj.style.borderBottom = '1px solid red'
                  }
                  if (!razao_social.value) {
                    razao_social.style.borderBottom = '1px solid red'
                  }
                  if (!logradouro.value) {
                    logradouro.style.borderBottom = '1px solid red'
                  }

                  if (!bairro.value) {
                    bairro.style.borderBottom = '1px solid red'
                  }
                  if (!cidade.value) {
                    cidade.style.borderBottom = '1px solid red'
                  }
                  if (!estado.value) {
                    estado.style.borderBottom = '1px solid red'
                  }
                  if (!cep.value) {
                    cep.style.borderBottom = '1px solid red'
                  }


                  return
                }
                if (cnpj.value) {
                  cnpj.style.borderBottom = '1px solid #34eb3a'
                }
                if (razao_social.value) {
                  razao_social.style.borderBottom = '1px solid #34eb3a'
                }
                if (logradouro.value) {
                  logradouro.style.borderBottom = '1px solid #34eb3a'
                }
                if (bairro.value) {
                  bairro.style.borderBottom = '1px solid #34eb3a'
                }
                if (cidade.value) {
                  cidade.style.borderBottom = '1px solid #34eb3a'
                }
                if (estado.value) {
                  estado.style.borderBottom = '1px solid #34eb3a'
                }
                if (cep.value) {
                  cep.style.borderBottom = '1px solid #34eb3a'
                }



                if (cnpj.value === '') {
                  cnpj.value = '---'
                }
                if (razao_social.value === '') {
                  razao_social.value = '---'
                }

                if (logradouro.value === '') {
                  logradouro.value = '---'
                }
                if (numero.value === '') {
                  numero.value = '---'
                }
                if (complemento.value === '') {
                  complemento.value = '---'
                }
                if (bairro.value === '') {
                  bairro.value = '---'
                }
                if (cidade.value === '') {
                  cidade.value = '---'
                }
                if (estado.value === '') {
                  estado.value = '---'
                }
                if (cep.value === '') {
                  cep.value = '---'
                }
                setCliente({
                  cnpj: cnpj.value.toUpperCase(),
                  razao_social: razao_social.value.toUpperCase(),
                  nome_fantasia: nome_fantasia.value.toUpperCase(),
                  logradouro: logradouro.value.toUpperCase(),
                  numero: numero.value.toUpperCase(),
                  complemento: complemento.value.toUpperCase(),
                  bairro: bairro.value.toUpperCase(),
                  cidade: cidade.value.toUpperCase(),
                  estado: estado.value.toUpperCase(),
                  cep: cep.value ? cep.value.replace(/(\d{5})(\d{3})/, "$1-$2") : '---'
                })
                const pagLaudoDados = document.querySelector('.formDados')
                const pagCliente = document.querySelector('.formInform')
                pagCliente.style.zIndex = '-6'
                pagLaudoDados.style.zIndex = '1'


              }}
            >proxima</button>
          </div>

        </div>























        <div className='formDados'>

          <div className='headTitle'>
            <h1>Novo Laudo</h1>
          </div>
          <br />

          <div className='formTitle'>
            <h4>Dados de Medição:</h4>
          </div>
          <div className='contentDados'>

            <div className='formMedicoes'>

              <div className='layoutInformArt'>


                <div className='alingForm'>
                  <div >
                    <label htmlFor="name">Data da Vistoria:</label>
                    <br />
                    <input type="date" id="DataVistoria" name="name"
                      onChange={(e) => {
                        setForm({ ...form, dataVistoria: e.target.value.toUpperCase() })

                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="name">Data das Medições:</label>
                    <br />
                    <input type="date" id="DataMedicoes" name="name"

                      onChange={(e) => {
                        setForm({ ...form, dataMedicoes: e.target.value.toUpperCase() })
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="name">Horário de Início das Medições:</label>
                    <br />
                    <input type="time" id="InicioMedicoes" name="name"
                      onChange={(e) => {
                        setForm({ ...form, inicioMedicoes: `${e.target.value.toUpperCase()}h` })
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="name">Horário de Término das Medições:</label>
                    <br />
                    <input type="time" id="FimMedicoes" name="name"
                      onChange={(e) => {
                        setForm({ ...form, fimMedicoes: `${e.target.value.toUpperCase()}h` })
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="name">Estado do Solo:</label>
                    <br />
                    <select name="" id="EStadoSolo"
                      onChange={(e) => {
                        setForm({ ...form, estadoSolo: `${e.target.value.toUpperCase()}` })

                      }}
                    >
                      <option value="0">Selecione</option>
                      <option value="Seco">Seco</option>
                      <option value="Úmido">Úmido</option>
                      <option value="Molhado">Molhado</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="name">Temperatura Ambiente:</label>
                    <br />
                    <input type="number" id="TemperaturaAmbiente" name="name"
                      onChange={(e) => {

                        confereTemepatura(e.target.value)
                        setForm({ ...form, temperaturaAmbiente: `${e.target.value.toUpperCase()} °C` })

                      }}
                    />
                  </div>
                  <div >
                    <label htmlFor="name">Umidade Relativa do Ar:</label>
                    <br />
                    <input type="number" id="HumidadeAr" name="name"
                      onChange={(e) => {
                        confereUmidade(e.target.value)
                        setForm({ ...form, humidadeAr: `${e.target.value.toUpperCase()}%` })
                      }}
                    />
                  </div>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '10px', width: '100%', borderTop: '2px solid #818991', borderBottom: '2px solid #818991', borderRadius: '0px', margin: '5px 0 5px 0' }}
                  >

                    Foto: <p style={{ fontSize: '9pt' }}> Medidor de Temperatura e Umidade:</p>
                    <form className='formFotos'
                      onClick={() => document.querySelector('#inputEquipamento').click()}>
                      <input type='file' accept='.png, .jpg ' id='inputEquipamento' className='.inputFotos' hidden
                        onChange={({ target: { files } }) => {
                          try {
                            const nowName = files[0].name
                            const newName = nowName.length > 7 ? nowName.slice(0, 7) + '...' : nowName
                            files[0] && setListImage([...listImage, { arq: 'equipamento', name: newName, file: files[0] }])
                            if (files) {
                              const reader = new FileReader()
                              reader.onload = e => setFileFotos(e.target.result)
                              reader.readAsDataURL(files[0])
                              const section = document.querySelector('.listImageTwo')
                              section.style.display = 'block'
                            }
                          } catch (err) {
                            console.log(err)
                          }

                        }}
                      />


                      <MdCloudUpload color='#1475cf' size={20} />
                      <p>Upload</p>

                    </form>
                  </div>
                </div>
                <div className='alingFormCal'>
                  <div>
                    <label htmlFor="name">Informe a maior distância entre dois pontos da malha de aterramento:</label><br />
                    <input type="number" id="MaiorMedicao" name="name"
                      onChange={
                        (e) => {
                          calcDistancia(e.target.value, 1, 2)
                        }
                      } />
                  </div>
                  <div style={{
                    paddingTop: '10px',

                  }}>
                    <p id='text1' style={{
                      fontSize: '8pt',
                      padding: '5px',
                      border: '2px dashed #818991',

                    }}>
                      <p> Dm = 0.00m (D * 3) <br /><br />
                        Ds1 = 0.00m  (56,8%D) <br /></p>
                      <p> Ds = 0.00m  (61,8%D) <br /><br />
                        Ds2 = 0.00m (66,8%D) <br /> </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>







          </div>







          <div className='footerForm'>
            <button className='backButton'
              onClick={() => {
                const pagLaudoDados = document.querySelector('.formDados')
                const pagCliente = document.querySelector('.formInform')
                pagCliente.style.zIndex = '1'
                pagLaudoDados.style.zIndex = '-6'
              }}
            >voltar</button>
            <button className='next'

              onClick={() => {


                const dataVistoria = document.getElementById('DataVistoria')
                const dataMedicoes = document.getElementById('DataMedicoes')
                const inicioMedicoes = document.getElementById('InicioMedicoes')
                const fimMedicoes = document.getElementById('FimMedicoes')
                const estadoSolo = document.getElementById('EStadoSolo')
                const temperaturaAmbiente = document.getElementById('TemperaturaAmbiente')
                const humidadeAr = document.getElementById('HumidadeAr')
                const maiorMedicao = document.getElementById('MaiorMedicao')
                if (!dataVistoria.value || !dataMedicoes.value || !inicioMedicoes.value || !fimMedicoes.value || estadoSolo.value === 0 || !temperaturaAmbiente.value || !humidadeAr.value || !maiorMedicao.value) {
                  toast.error('Preencha todos os campos')
                  dataVistoria.focus()

                  if (!dataVistoria.value) {
                    dataVistoria.style.borderBottom = '1px solid red'
                  }
                  if (!dataMedicoes.value) {
                    dataMedicoes.style.borderBottom = '1px solid red'
                  }
                  if (!inicioMedicoes.value) {
                    inicioMedicoes.style.borderBottom = '1px solid red'
                  }
                  if (!fimMedicoes.value) {
                    fimMedicoes.style.borderBottom = '1px solid red'
                  }

                  if (estadoSolo.value === 0) {
                    estadoSolo.style.borderBottom = '1px solid red'
                  }
                  if (!temperaturaAmbiente.value) {
                    temperaturaAmbiente.style.borderBottom = '1px solid red'
                  }
                  if (!humidadeAr.value) {
                    humidadeAr.style.borderBottom = '1px solid red'
                  }
                  if (!maiorMedicao.value) {
                    maiorMedicao.style.borderBottom = '1px solid red'
                  }

                  return
                }

                if (dataVistoria.value) {
                  dataVistoria.style.borderBottom = '1px solid #34eb3a'
                }
                if (dataMedicoes.value) {
                  dataMedicoes.style.borderBottom = '1px solid #34eb3a'
                }
                if (inicioMedicoes.value) {
                  inicioMedicoes.style.borderBottom = '1px solid #34eb3a'
                }
                if (fimMedicoes.value) {
                  fimMedicoes.style.borderBottom = '1px solid #34eb3a'
                }
                if (!estadoSolo.value === 0) {
                  estadoSolo.style.borderBottom = '1px solid #34eb3a'
                }
                if (temperaturaAmbiente.value) {
                  temperaturaAmbiente.style.borderBottom = '1px solid #34eb3a'
                }
                if (humidadeAr.value) {
                  humidadeAr.style.borderBottom = '1px solid #34eb3a'
                }
                if (maiorMedicao.value) {
                  maiorMedicao.style.borderBottom = '1px solid #34eb3a'
                }


                setForm({
                  dataVistoria: dataVistoria.value,
                  dataMedicoes: dataMedicoes.value,
                  inicioMedicoes: inicioMedicoes.value,
                  fimMedicoes: fimMedicoes.value,
                  estadoSolo: estadoSolo.value,
                  temperaturaAmbiente: temperaturaAmbiente.value,
                  humidadeAr: humidadeAr.value,
                  maiorMedicao: maiorMedicao.value
                })

                const pagLaudoDados = document.querySelector('.formDados')
                const pagFileTwo = document.querySelector('.formFileTwo')
                pagLaudoDados.style.zIndex = '-7'
                pagFileTwo.style.zIndex = '1'

              }}
            >proxima</button>
          </div>
        </div>

























        <div className='formFileTwo'>
          <div className='headTitle'>
            <h1>Novo Laudo</h1>
          </div>
          <div className='contentPicture'>


            <div className='pointMedicoes'>
              <div className='formTitle'>
                <h4>Quantidade de Pontos de Medição:</h4>
              </div>
              <div>
                <br />
                <select id='select' onChange={handleSelect} name="pontos" >
                  <option value="1">1 ponto de medição</option>
                  <option value="3">3 ponto de medição</option>
                </select>

              </div>
              <div>
                <button id='newPoint' onClick={addInput}> Novo Ponto de Medição </button>
              </div>

              <div className='contentTable'>

                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>PONTO DE MEDIÇÃO</th>
                      <th>&#8486;</th>
                      <th><AiFillFileImage /></th>
                      <th><MdDelete /></th>

                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', }}>
                        1
                      </td>
                      <td>
                        <p>{malha[0].ponto.toUpperCase()}</p>
                      </td>
                      <td style={{ display: 'flex', flexDirection: 'column', height: '100%', margin: '0', padding: '0px 0 0px 0' }} >


                        <input className='mPonto01' type="number"
                          style={{ textAlign: 'center' }}
                          min={0}
                          max={10.00}
                          step={0.01}

                          value={malha[0].ohms1}
                          placeholder='0.00'
                          onChange={(e) => {
                            setMalha([{ ...malha[0], ohms1: transformaDecimal(e.target.value) }])

                          }
                          }
                        />

                        <input className='mPonto02' type="number"
                          style={{ textAlign: 'center' }}
                          min={0}
                          max={10.00}
                          step={0.01}
                          value={malha[0].ohms2}
                          placeholder='0.00'
                          onChange={(e) => {
                            setMalha([{ ...malha[0], ohms2: transformaDecimal(e.target.value) }])

                          }
                          }
                        />

                        <input className='mPonto03' type="number"
                          style={{ textAlign: 'center' }}
                          min={0}
                          max={10.00}
                          step={0.01}
                          value={malha[0].ohms3}
                          placeholder='0.00'
                          onChange={(e) => {

                            setMalha([{ ...malha[0], ohms3: transformaDecimal(e.target.value) }])

                          }
                          }
                        />

                      </td>
                      <td>
                        <p
                          style={{
                            textAlign: 'center',
                            paddingLeft: '10pt',
                            paddingRight: '0px',
                          }}>

                          <form className='formFotos'
                            onClick={() => document.querySelector('#inputFotos').click()}>
                            <input enctype="multipart/form-data"  type='file' accept='.png, .jpg ' id='inputFotos' className='.inputFotos' hidden
                              onChange={({ target: { files } }) => {
                                try {

                                  const nowName = files[0].name
                                  const newName = nowName.length > 7 ? nowName.slice(0, 7) + '...' : nowName




                                  files[0] && setListImage([...listImage, { arq: malha[0].ponto, name: newName, file: files[0] }])
                                  if (files) {
                                    const reader = new FileReader()
                                    reader.onload = e => setFileFotos(e.target.result)
                                    reader.readAsDataURL(files[0])
                                    const section = document.querySelector('.listImageTwo')
                                    section.style.display = 'block'
                                  }



                                } catch (err) {
                                  console.log(err)
                                }

                              }}
                            />
                            <MdCloudUpload color='#1475cf' size={20} />
                            <p>To Upload</p>
                          </form>
                        </p>
                      </td>

                    </tr  >

                    {points.map((ponto, index) => (


                      isOpen === 1 ?
                        <>
                          <tr key={index}>
                            <td>{index + 2}</td>
                            <td style={{
                              paddingTop: '22pt',
                            }}>
                              <input
                                style={{ textAlign: 'center' }}
                                value={points[index].ponto}
                                id={`ponto${index}`}
                                {...register(`ponto${index}`)}
                                onChange={(e) => {
                                  const newPoints = points.map((p, i) => {
                                    if (i === index) {
                                      return { ...p, ponto: e.target.value.toUpperCase() }
                                    }
                                    return p
                                  })
                                  setPoints(newPoints)
                                }}

                              />
                              {errors[`ponto${index}`] && <span style={{ color: 'red' }}>___</span>}
                            </td>
                            <td style={{
                              paddingTop: '22pt',
                            }}>
                              <input
                                style={{ textAlign: 'center' }}
                                type='number'
                                min={0}
                                max={10.00}
                                step={0.01}
                                value={points[index].ohms}
                                placeholder='0.00'
                                id={`ohms${index}`}
                                {...register(`ohms${index}`)}
                                onChange={(e) => {
                                  const newPoints = points.map((p, i) => {
                                    if (i === index) {
                                      return { ...p, ohms: transformaDecimal(e.target.value) }
                                    }
                                    return p
                                  })
                                  setPoints(newPoints)
                                }}
                              />
                              {errors[`ohms${index}`] && <span style={{ color: 'red' }}>___</span>}
                            </td>
                            <td>
                              <p
                                style={{
                                  textAlign: 'center',
                                  paddingLeft: '10pt',
                                  paddingRight: '0px',
                                }}>


                                <form
                                  className='formFotos'
                                  onClick={() => document.querySelector(`#id${index}`).click()}>
                                  <input type='file' accept='.png, .jpg ' id={`id${index}`} className='.inputFotos' hidden
                                    onChange={({ target: { files } }) => {
                                      try {

                                        const nowName = files[0].name
                                        const newName = nowName.length > 7 ? nowName.slice(0, 7) + '...' : nowName


                                        files[0] && setListImage([...listImage, { arq: ponto.ponto, name: newName, file: files[0] }])

                                        if (files) {
                                          const reader = new FileReader()
                                          reader.onload = e => setFileFotos(e.target.result)
                                          reader.readAsDataURL(files[0])
                                          const section = document.querySelector('.listImageTwo')
                                          section.style.display = 'block'
                                        }
                                      } catch (err) {
                                        console.log(err)
                                      }

                                    }}
                                  />

                                  {

                                    <>
                                      <MdCloudUpload color='#1475cf' size={20} />
                                      <p>To Upload</p>
                                    </>
                                  }

                                </form>
                              </p>
                            </td>

                            <td>
                              <MdDelete
                                style={{
                                  textAlign: "center",
                                  height: "100%",
                                  paddingTop: "16pt",
                                }}
                                className='delete'
                                cursor={'pointer'}
                                margin={5}
                                color='red'
                                onClick={() => {

                                  if (window.confirm('Deseja realmente excluir este ponto de medição?')) {
                                    const newPoints = points.filter((p, i) => i !== index)

                                    setPoints(newPoints)

                                  }

                                }
                                }
                              />
                            </td>
                          </tr>
                        </>


                        :

                        <>
                          <tr key={index}>
                            <td>{index + 2}</td>
                            <td>
                              <input
                                style={{ textAlign: 'center' }}
                                min={0}
                                max={10.00}
                                step={0.01}
                                value={points[index].ponto}
                                id={`ponto${index}`}
                                onChange={(e) => {
                                  const newPoints = points.map((p, i) => {
                                    if (i === index) {
                                      return { ...p, ponto: e.target.value.toUpperCase() }
                                    }
                                    return p
                                  })
                                  setPoints(newPoints)
                                }}
                              />
                            </td>
                            <td style={{ display: 'flex', flexDirection: 'column' }}>
                              <input
                                style={{ textAlign: 'center' }}
                                min={0}
                                max={10.00}
                                step={0.01}
                                type='number'
                                value={points[index].ohms1}
                                placeholder='0.00'
                                id={`ohms1${index}`}
                                onChange={(e) => {
                                  const newPoints = points.map((p, i) => {
                                    if (i === index) {
                                      return { ...p, ohms1: transformaDecimal(e.target.value) }
                                    }
                                    return p
                                  })
                                  setPoints(newPoints)
                                }}
                              />
                              <input
                                style={{ textAlign: 'center' }}
                                min={0}
                                max={10.00}
                                step={0.01}
                                type='number'
                                value={points[index].ohms2}
                                placeholder='0.00'
                                id={`ohms2${index}`}
                                onChange={(e) => {
                                  const newPoints = points.map((p, i) => {
                                    if (i === index) {
                                      return { ...p, ohms2: transformaDecimal(e.target.value) }
                                    }
                                    return p
                                  })
                                  setPoints(newPoints)
                                }}
                              />
                              <input
                                style={{ textAlign: 'center' }}
                                min={0}
                                max={10.00}
                                step={0.01}
                                type='number'
                                value={points[index].ohms3}
                                placeholder='0.00'
                                id={`ohms3${index}`}
                                onChange={(e) => {
                                  const newPoints = points.map((p, i) => {
                                    if (i === index) {
                                      return { ...p, ohms3: transformaDecimal(e.target.value) }
                                    }
                                    return p
                                  })
                                  setPoints(newPoints)
                                }}
                              />
                            </td>
                            <td

                            >

                              <p
                                style={{
                                  textAlign: 'center',
                                  paddingLeft: '10pt',
                                  paddingRight: '0px',
                                }}>


                                <form
                                  className='formFotos'
                                  onClick={() => document.querySelector(`#id${index}`).click()}>
                                  <input type='file' accept='.png, .jpg ' id={`id${index}`} className='.inputFotos' hidden
                                    onChange={({ target: { files } }) => {
                                      try {

                                        const nowName = files[0].name
                                        const newName = nowName.length > 7 ? nowName.slice(0, 7) + '...' : nowName


                                        files[0] && setListImage([...listImage, { arq: ponto.ponto, name: newName, file: files[0] }])

                                        if (files) {
                                          const reader = new FileReader()
                                          reader.onload = e => setFileFotos(e.target.result)
                                          reader.readAsDataURL(files[0])
                                          const section = document.querySelector('.listImageTwo')
                                          section.style.display = 'block'
                                        }
                                      } catch (err) {
                                        console.log(err)
                                      }

                                    }}
                                  />

                                  {

                                    <>
                                      <MdCloudUpload color='#1475cf' size={20} />
                                      <p>To Upload</p>
                                    </>
                                  }

                                </form>
                              </p>
                            </td>

                            <td>
                              <MdDelete
                                style={{
                                  textAlign: "center",
                                  height: "100%",
                                  paddingTop: "16pt",
                                }}
                                className='delete'
                                cursor={'pointer'}
                                margin={5}
                                color='red'
                                onClick={() => {

                                  if (window.confirm('Deseja realmente excluir este ponto de medição?')) {
                                    const newPoints = points.filter((p, i) => i !== index)

                                    setPoints(newPoints)

                                  }


                                }
                                }
                              />

                            </td>
                          </tr>

                        </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>




            <div className='pointPicture'>

              <div className='formTitle'>
                <h4>Relatório fotográfico:</h4>
              </div>

              <div className='formImgArt' style={{
                height: '100%',
                width: '100%',
              }}>

                <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '5px', width: '100%' }}
                >
                  <div className='listImageTwo'>
                    <h2>MEDIDOR DE TEMPERATURA E UMIDADE:</h2>
                    <ul className='ulImageTwo'>
                      {
                        listImage.map((item, index) => (



                          item.arq === 'equipamento' ?
                            <li key={index}>
                              <span className='spanList'>
                                <span className='imgSpan'>
                                  <img src={URL.createObjectURL(item.file)} alt={item.name} />
                                </span>
                                <span className='optionsImg'>

                                  <AiFillFileImage color='#1475cf' size={12} />
                                  <p>{item.name}</p>
                                  <MdDelete
                                    className='delete'
                                    cursor={'pointer'}
                                    margin={5}
                                    color='red'
                                    onClick={() => {
                                      const newList = listImage.filter((_, i) => i !== index)
                                      setListImage(newList)
                                      if (newList.length === 0) {
                                        setFileFotos(null)
                                        const inputFotos = document.querySelector('#inputFotos')
                                        inputFotos.value = ''
                                      }
                                    }
                                    }
                                  />
                                </span>
                              </span>
                            </li>
                            :
                            <></>
                        ))
                      }
                    </ul>
                    <hr style={{ width: '100%', border: '1px solid #1475cf' }} />
                  </div>
                </div>
                <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '5px', width: '100%' }}

                >
                  <div className='listImageTwo'>


                    <h2 >{malha[0].ponto.toUpperCase()}:</h2>
                    <ul className='ulImageTwo'>
                      {
                        listImage.map((item, index) => (



                          item.arq === malha[0].ponto ?
                            <li key={index}>
                              <span className='spanList'>
                                <span className='imgSpan'>
                                  <img src={URL.createObjectURL(item.file)} alt={item.name} />
                                </span>
                                <span className='optionsImg'>

                                  <AiFillFileImage color='#1475cf' size={12} />
                                  <p>{item.name}</p>
                                  <MdDelete
                                    className='delete'
                                    cursor={'pointer'}
                                    margin={5}
                                    color='red'
                                    onClick={() => {
                                      const newList = listImage.filter((_, i) => i !== index)
                                      setListImage(newList)
                                      if (newList.length === 0) {
                                        setFileFotos(null)
                                        const inputFotos = document.querySelector('#inputFotos')
                                        inputFotos.value = ''
                                      }
                                    }
                                    }
                                  />
                                </span>
                              </span>
                            </li>
                            :
                            <></>
                        ))
                      }
                    </ul>
                    <hr style={{ width: '100%', border: '1px solid #1475cf' }} />
                  </div>
                </div>

                <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '5px', minWidth: '100%' }}

                >

                  {
                    points.map((ponto, index) => (
                      <div key={index} className='listImageTwo'>

                        <h2>{ponto.ponto.toUpperCase()}:</h2>
                        <ul className='ulImageTwo'>
                          {
                            listImage.map((item, index) => (



                              item.arq === ponto.ponto ?
                                <li key={index}>
                                  <span className='spanList'>
                                    <span className='imgSpan'>
                                      <img src={URL.createObjectURL(item.file)} alt={item.name} />
                                    </span>
                                    <span className='optionsImg'>

                                      <AiFillFileImage color='#1475cf' size={12} />
                                      <p>{item.name}</p>
                                      <MdDelete
                                        className='delete'
                                        cursor={'pointer'}
                                        margin={5}
                                        color='red'
                                        onClick={() => {
                                          const newList = listImage.filter((_, i) => i !== index)
                                          setListImage(newList)
                                          if (newList.length === 0) {
                                            setFileFotos(null)
                                            const inputFotos = document.querySelector('#inputFotos')
                                            inputFotos.value = ''
                                          }
                                        }
                                        }
                                      />
                                    </span>
                                  </span>
                                </li>
                                :
                                <></>
                            ))
                          }
                        </ul>
                        <hr style={{ minWidth: '100%', border: '1px solid #1475cf' }} />
                      </div>


                    ))
                  }

                </div>
              </div>

            </div>
          </div>
          <div className='footerForm'>
            <button className='backButton'

              onClick={() => {
                const pagLaudoDados = document.querySelector('.formDados')
                const pagFileTwo = document.querySelector('.formFileTwo')
                pagLaudoDados.style.zIndex = '1'
                pagFileTwo.style.zIndex = '-7'

              }}
            >voltar</button>

            <button id='new audo' className='next' onClick={() => {
              if (isOpen === 1) {
                const a = points.map((ponto, index) => {
                  if (ponto.ponto === '') {
                    toast.error('Preencha todos os campos')
                    const pontos = document.getElementById(`ponto${index}`)
                    pontos.focus()
                    pontos.style.borderBottom = '1px solid red'
                    return true
                  }
                  if (ponto.ohms === '') {
                    toast.error('Preencha todos os campos')
                    const ohms = document.getElementById(`ohms${index}`)
                    ohms.focus()
                    ohms.style.borderBottom = '1px solid red'
                    return true
                  }
                  if (ponto.ponto !== '') {
                    const pontos = document.getElementById(`ponto${index}`)
                    pontos.style.borderBottom = '1px solid #34eb3a'
                  }
                  if (ponto.ohms !== '') {
                    const ohms = document.getElementById(`ohms${index}`)
                    ohms.style.borderBottom = '1px solid #34eb3a'
                  }
                  if (ponto.ponto !== '' && ponto.ohms !== '') {
                    return false
                  }

                }
                )
                if (a.includes(true)) {
                  return
                }
              }

              if (isOpen === 3) {
                const a = points.map((ponto, index) => {
                  if (ponto.ponto === '') {
                    toast.error('Preencha todos os campos')
                    const pontos = document.getElementById(`ponto${index}`)
                    pontos.focus()
                    pontos.style.borderBottom = '1px solid red'
                    return true
                  }
                  if (ponto.ohms1 === '') {
                    toast.error('Preencha todos os campos')
                    const ohms1 = document.getElementById(`ohms1${index}`)
                    ohms1.focus()
                    ohms1.style.borderBottom = '1px solid red'
                    return true
                  }
                  if (ponto.ohms2 === '') {
                    toast.error('Preencha todos os campos')
                    const ohms2 = document.getElementById(`ohms2${index}`)
                    ohms2.focus()
                    ohms2.style.borderBottom = '1px solid red'
                    return true
                  }
                  if (ponto.ohms3 === '') {
                    toast.error('Preencha todos os campos')
                    const ohms3 = document.getElementById(`ohms3${index}`)
                    ohms3.focus()
                    ohms3.style.borderBottom = '1px solid red'
                    return true
                  }
                  if (ponto.ponto !== '') {
                    const pontos = document.getElementById(`ponto${index}`)
                    pontos.style.borderBottom = '1px solid #34eb3a'
                  }
                  if (ponto.ohms1 !== '') {
                    const ohms1 = document.getElementById(`ohms1${index}`)
                    ohms1.style.borderBottom = '1px solid #34eb3a'
                  }
                  if (ponto.ohms2 !== '') {
                    const ohms2 = document.getElementById(`ohms2${index}`)
                    ohms2.style.borderBottom = '1px solid #34eb3a'
                  }
                  if (ponto.ohms3 !== '') {
                    const ohms3 = document.getElementById(`ohms3${index}`)
                    ohms3.style.borderBottom = '1px solid #34eb3a'
                  }
                  if (ponto.ponto !== '' && ponto.ohms1 !== '' && ponto.ohms2 !== '' && ponto.ohms3 !== '') {
                    return false
                  }

                }
                )
                if (a.includes(true)) {
                  return
                }
              }


              const mPonto01 = document.querySelector('.mPonto01')
              const mPonto02 = document.querySelector('.mPonto02')
              const mPonto03 = document.querySelector('.mPonto03')
              if (!mPonto01.value || !mPonto02.value || !mPonto03.value) {
                toast.error('Preencha todos os campos')
                mPonto01.focus()
                if (!mPonto01.value) {
                  mPonto01.style.borderBottom = '1px solid red'
                }
                if (!mPonto02.value) {
                  mPonto02.style.borderBottom = '1px solid red'
                }
                if (!mPonto03.value) {
                  mPonto03.style.borderBottom = '1px solid red'
                }

                return
              }

              if (mPonto01.value) {
                mPonto01.style.borderBottom = '1px solid #34eb3a'
              }
              if (mPonto02.value) {
                mPonto02.style.borderBottom = '1px solid #34eb3a'
              }
              if (mPonto03.value) {
                mPonto03.style.borderBottom = '1px solid #34eb3a'
              }


              gerarModal()
            }


            }>Gerar Laudo</button>
          </div>
        </div>










        <div id='modal' className="groupThree">





          <div className='buttonModal' style={{
            display: 'flex',
            justifyContent: 'center',
            width: '55%',
            alignItems: 'center',
            position: 'fixed',
            top: '50px',
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '30%',
              paddingLeft: '15%',
            }}>

              <button className='printReport' onClick={gerarPDF}>salvar</button>
              <button className='close' onClick={fecharModal}>fechar</button>
            </div>

          </div>

          <br />

          <div className='divReport'>


            <div className='sizePage' style={{
              minHeight: '1100px', maxHeight: '1100px'
            }}>


              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <br />
              <br />
              <br />
              <br />
              <br />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >

                <br />
                <br />


                <h1
                  style={{
                    paddingTop: "4pt",

                    textIndent: "0pt",
                    lineHeight: "186%",
                    textAlign: "center"
                  }}
                >
                  <span>
                    LAUDO DE MEDIÇÃO
                    <br />


                    <br />
                    DE
                    <br />
                    <br />
                  </span>
                </h1>
                <br />
                <h1
                  style={{

                    textIndent: "0pt",
                    lineHeight: "186%",
                    textAlign: "center"
                  }}
                >
                  <span>
                    RESISTÊNCIA DE ATERRAMENTO
                    <br />
                    {(cliente.nome_fantasia ? cliente.nome_fantasia : <></>)}
                  </span>
                </h1>

                <br />
                <br />
                <br />
                <br />

                <p
                  className="s2"
                  style={{ textIndent: "0pt", textAlign: "justify" }}
                >
                  {resultAll()}
                </p>
                <p style={{ paddingLeft: "48pt", textIndent: "0pt", textAlign: "justify" }}></p>
                <br />
                <br />
                <h2
                  style={{
                    paddingTop: "4pt",

                    textIndent: "0pt",
                    textAlign: "center"
                  }}
                >
                  {dateReport(form.dataVistoria)}
                </h2>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />


              <h3
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  textAlign: "justify"
                }}
              >
                CONTRATANTE
              </h3>

              <ul id="l1">
                <li data-list-text="">
                  <p
                    className="s4"
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "42pt",
                      textIndent: "-18pt",
                      textAlign: "justify"
                    }}
                  >
                    RAZÃO SOCIAL:
                    <span className="s5">
                      {cliente.razao_social.toUpperCase()}
                    </span>
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s4"
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "42pt",
                      textIndent: "-18pt",
                      textAlign: "justify"
                    }}
                  >
                    CNPJ: <span className="s5">{cliente.cnpj}</span>
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s4"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "42pt",
                      textIndent: "-18pt",
                      textAlign: "justify"
                    }}
                  >
                    ENDEREÇO:
                    <span className="s5">
                      {formatEndereco(cliente.logradouro, cliente.numero, cliente.complemento, cliente.bairro, cliente.cidade, cliente.estado, cliente.cep)}
                    </span>
                  </p>
                </li>
              </ul>

              <br />
              <br />
              <br />

              <br />
              <div className='footerPage'>


                <p className="s6" style={{ bottom: '0', textAlign: "right" }}>
                  Página 1 de {18 + (points.length)}
                </p>
                <hr style={{ borderStyle: 'solid' }} />
                <p
                  className="s1"
                  style={{ paddingLeft: "80pt", textAlign: "center" }}
                >
                  <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
                </p>
                <br />
                <p
                  className="s7"
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "51pt",

                    textAlign: "center"
                  }}
                >
                  Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
                </p>
              </div>
            </div>
            <br />
            <br />
            <br />

            <div className='sizePage' style={{
              minHeight: '1000px', maxHeight: '1000px'
            }}>
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />

              <br />
              <br />
              <br />
              <br />
              <p
                className="s12"
                style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "justify" }}
              >
                Sumário
              </p>

              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
             
              }}>
                <div>
                  <ol id="l2">
                    <li data-list-text={1}>
                      <p
                        style={{
                          paddingTop: "4pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark0" className="h4">
                          LOCAL DA MEDIÇÃO{" "}
                        </a>

                      </p>
                      <ol id="l3">
                        <li data-list-text="1.1.">
                          <p
                            style={{
                              paddingTop: "2pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark1" className="s14"  >
                              RAZÃO SOCIAL


                            </a>
                          </p>
                        </li>
                        <li data-list-text="1.2.">
                          <p
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark2" className="s14">
                              CNPJ

                            </a>
                          </p>
                        </li>
                        <li data-list-text="1.3.">
                          <p
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark3" className="s14">
                              ENDEREÇO

                            </a>
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text={2}>
                      <p
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark4" className="h4">
                          ANOTAÇÃO DE RESPONSABILIDADE TÉCNICA – ART{" "}
                        </a>
                      </p>


                      <p />
                      <ol id="l4">
                        <li data-list-text="2.1.">
                          <p
                            style={{
                              paddingTop: "2pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark5" className="s14">
                              ART Nº {dataArt.numero}

                            </a>
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text={3}>
                      <p
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark6" className="h4">
                          OBJETIVO{" "}
                        </a>
                      </p>


                      <p />
                    </li>
                    <li data-list-text={4}>
                      <p
                        style={{
                          paddingTop: "2pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark7" className="h4">
                          METODOLOGIA DA MEDIÇÃO{" "}
                        </a>
                      </p>

                      <p />
                      <ol id="l5">
                        <li data-list-text="4.1.">
                          <p
                            style={{
                              paddingTop: "2pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark8" className="s14">
                              NORMAS APLICADAS

                            </a>
                          </p>
                        </li>
                        <li data-list-text="4.2.">
                          <p
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark9" className="s14">
                              MÉTODO UTILIZADO

                            </a>
                          </p>
                        </li>
                        <li data-list-text="4.3.">
                          <p
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark10" className="s14">
                              DATA DA REALIZAÇÃO DO TESTE

                            </a>
                          </p>
                        </li>
                        <li data-list-text="4.4.">
                          <p
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark11" className="s14">
                              TEMPO DE DURAÇÃO DO TESTE

                            </a>
                          </p>
                        </li>
                        <li data-list-text="4.5.">
                          <p
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark12" className="s14">
                              INSTRUMENTO UTILIZADO

                            </a>
                          </p>
                        </li>
                        <li data-list-text="4.6.">
                          <p
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark13" className="s14">
                              CERTIFICADO DE CALIBRAÇÃO RBC

                            </a>
                          </p>
                        </li>
                        <li data-list-text="4.7.">
                          <p
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "50pt",
                              textIndent: "-33pt",
                              textAlign: "justify"
                            }}
                          >
                            <a href="#bookmark14" className="s14">
                              DESCRIÇÃO DO PROCEDIMENTO DE TESTE

                            </a>
                          </p>
                          <ol id="l6">
                            <li data-list-text="4.7.1.">
                              <p
                                style={{
                                  paddingTop: "11pt",
                                  paddingLeft: "72pt",
                                  textIndent: "-44pt",
                                  textAlign: "justify"
                                }}
                              >
                                <a href="#bookmark15" className="s14">
                                  Princípio (ABNT NBR 15749:2009)

                                </a>
                              </p>
                            </li>
                            <li data-list-text="4.7.2.">
                              <p
                                style={{
                                  paddingTop: "16pt",
                                  paddingLeft: "72pt",
                                  textIndent: "-44pt",
                                  textAlign: "justify"
                                }}
                              >
                                <a href="#bookmark16" className="s14">
                                  Procedimento (ABNT NBR 15749:2009)

                                </a>
                              </p>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text={5}>
                      <p
                        style={{
                          paddingTop: "16pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark17" className="h4">
                          RESULTADOS{" "}
                        </a>

                      </p>
                    </li>
                    <li data-list-text={6}>
                      <p
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark18" className="h4">
                          RECOMENDAÇÕES{" "}
                        </a>

                      </p>
                    </li>
                    <li data-list-text={7}>
                      <p
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark19" className="h4">
                          CONCLUSÕES{" "}
                        </a>

                      </p>
                    </li>
                    <li data-list-text={8}>
                      <p
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark20" className="h4">
                          RESPONSÁVEL TÉCNICO{" "}
                        </a>

                      </p>
                    </li>
                    <li data-list-text={9}>
                      <p
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark21" className="h4">
                          ANEXO I – ANOTAÇÃO DE RESPONSABILIDADE TÉCNICA – ART{" "}
                        </a>

                      </p>
                    </li>
                    <li data-list-text={10}>
                      <p
                        style={{
                          paddingTop: "6pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark22" className="h4">
                          ANEXO II – CERTIFICADO DE CALIBRAÇÃO RBC{" "}
                        </a>

                      </p>
                    </li>
                    <li data-list-text={11}>
                      <p
                        style={{
                          paddingTop: "7pt",
                          paddingLeft: "42pt",
                          textIndent: "-35pt",
                          textAlign: "justify"
                        }}
                      >
                        <a href="#bookmark23" className="h4">
                          ANEXO III – RELATÓRIO FOTOGRÁFICO{" "}
                        </a>

                      </p>
                    </li>
                  </ol>

                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
             gap: '9px',
                 
                }}>
                  <a href="#bookmark0" className="s13"
                    style={{}}>
                    3
                  </a>
                  <a href="#bookmark1" className="s14" >
                    3
                  </a>
                  <a href="#bookmark2" className="s14" >
                    3
                  </a>
                  <a href="#bookmark3" className="s14" >
                    3
                  </a>
                  <h4 href="#bookmark4" >
                    3
                  </h4>
                  <a href="#bookmark5" className="s14" >

                    3
                  </a>
                  <h4 href="#bookmark6" >3</h4>
                  <h4 href="#bookmark7" >4</h4>
                  <a href="#bookmark8" className="s14" >

                    4
                  </a>
                  <a href="#bookmark9" className="s14" >

                    4
                  </a>
                  <a href="#bookmark10" className="s14" >

                    4
                  </a>
                  <a href="#bookmark11" className="s14" >

                    4
                  </a>
                  <a href="#bookmark12" className="s14" >

                    4
                  </a>
                  <a href="#bookmark13" className="s14" >

                    4
                  </a>
                  <a href="#bookmark14" className="s14" >

                    5
                  </a>
                  <a href="#bookmark15" className="s14" >

                    5
                  </a>
                  <a href="#bookmark16" className="s14">

                    6
                  </a>
                  <a href="#bookmark17" className="s14" >

                    9
                  </a>
                  <a href="#bookmark18" className="h4" >

                    10
                  </a>
                  <a href="#bookmark19" className="h4" >

                    10
                  </a>
                  <a href="#bookmark20" className="h4" >

                    11
                  </a>
                  <a href="#bookmark21" className="h4" >

                    12
                  </a>
                  <a href="#bookmark22" className="h4" >13</a>
                  <a href="#bookmark23" className="h4" >16</a>
                </div>
              </div>


              <br />
              <br />
              <br />
              <br />
              <br />

              <br />

              <p className="s6" style={{ textAlign: "right" }}>
                Página 2 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>

            </div>
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className='sizePage' style={{
              minHeight: '1000px', maxHeight: '1000px'
            }}>
              <br />
              <br />

              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <hr style={{ borderStyle: 'solid' }} />
              <br />
              <div
                style={{
                  minHeight: '900px', maxHeight: '900px'
                }}
              >


                <ol id="l7">
                  <li data-list-text={1}>
                    <h3
                      style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "justify" }}
                    >
                      <a name="bookmark0" ><strong>LOCAL DA MEDIÇÃO</strong></a>
                    </h3>
                    <br />
                    <ol id="l8">
                      <li data-list-text="1.1.">
                        <p
                          className="s15"
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "42pt",
                            textIndent: "-35pt",
                            textAlign: "justify"
                          }}
                        >
                          <a name="bookmark1"><strong>RAZÃO SOCIAL</strong></a>
                        </p>
                        <p
                          style={{
                            paddingTop: "6pt",
                            paddingLeft: "42pt",
                            textIndent: "0pt",
                            textAlign: "justify"
                          }}
                        >
                          {cliente.razao_social}
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text="1.2.">
                        <p
                          className="s15"
                          style={{
                            paddingLeft: "42pt",
                            textIndent: "-35pt",
                            textAlign: "justify"
                          }}
                        >
                          <a name="bookmark2"><strong>CNPJ</strong></a>
                        </p>
                        <p
                          style={{
                            paddingTop: "6pt",
                            paddingLeft: "42pt",
                            textIndent: "0pt",
                            textAlign: "justify"
                          }}
                        >
                          {cliente.cnpj}
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                          <br />
                        </p>
                      </li>
                      <li data-list-text="1.3.">
                        <p
                          className="s15"
                          style={{
                            paddingLeft: "42pt",
                            textIndent: "-35pt",
                            textAlign: "justify"
                          }}
                        >
                          <a name="bookmark3"><strong>ENDEREÇO</strong></a>
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                          <br />
                        </p>
                        <p
                          style={{
                            paddingLeft: "42pt",
                            textIndent: "0pt",
                            textAlign: "justify"
                          }}
                        >

                          <span>
                            {formatEndereco(cliente.logradouro, cliente.numero, cliente.complemento, cliente.bairro, cliente.cidade, cliente.estado, cliente.cep)}
                          </span>
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                          <br />
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={2}>
                    <h3
                      style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "justify" }}
                    >
                      <a name="bookmark4"><strong>ANOTAÇÃO DE RESPONSABILIDADE TÉCNICA – ART</strong></a>
                    </h3>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>
                    <ol id="l9">
                      <li data-list-text="2.1.">
                        <p
                          className="s15"
                          style={{
                            paddingTop: "4pt",
                            paddingLeft: "42pt",
                            textIndent: "-35pt",
                            textAlign: "justify"
                          }}
                        >
                          <a name="bookmark5"><strong>ART Nº {dataArt.numero}</strong></a>
                          <span className="s17">1</span>
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                          <br />
                        </p>
                        <p
                          style={{ paddingLeft: "8pt", textIndent: "0pt", textAlign: "justify" }}
                        >
                          <span>
                            <a href={dataArt.site}>
                              <img
                                className='imgArt'
                                width={623}
                                height={117}
                                alt="image"

                              />
                            </a>
                          </span>
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                          <br />
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li data-list-text={3}>
                    <h3
                      style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "justify" }}
                    >
                      <a name="bookmark6"><strong>OBJETIVO</strong></a>
                    </h3>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>
                    <p
                      style={{
                        paddingTop: "10pt",
                        paddingLeft: "6pt",
                        textIndent: "35pt",
                        lineHeight: "150%",
                       
                        textAlign: "justify"
                      }}
                    >
                      <span>
                        Avaliar se a Resistência de Aterramento das instalações elétricas do
                        PARQUE
                        <br />
                        INDUSTRIAL acima identificado atende as condições de segurança
                        exigidas, visando:
                      </span>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>
                    <ol id="l10">
                      <li data-list-text={1}>
                        <p
                          style={{
                            paddingLeft: "78pt",
                            textIndent: "-18pt",
                            textAlign: "justify"
                          }}
                        >
                          Verificar a eficácia do eletrodo ou do sistema de aterramento;
                        </p>
                      </li>
                      <li data-list-text={2}>
                        <p
                          style={{
                            paddingTop: "6pt",
                            paddingLeft: "78pt",
                            textIndent: "-18pt",
                            textAlign: "justify"
                          }}
                        >
                          Definir alterações para um sistema de aterramento existente;
                        </p>
                      </li>
                      <li data-list-text={3}>
                        <p
                          style={{
                            paddingTop: "7pt",
                            paddingLeft: "78pt",
                            textIndent: "-18pt",
                            textAlign: "justify"
                          }}
                        >
                          Detectar possíveis tensões de toque e passo perigosas;
                        </p>
                      </li>
                      <li data-list-text={4}>
                        <p
                          style={{
                            paddingTop: "6pt",
                            paddingLeft: "78pt",
                            textIndent: "-18pt",
                            lineHeight: "150%",
                            textAlign: "justify"
                          }}
                        >
                          <span >
                            Determinar a elevação de potencial do sistema de aterramento em
                            relação <br /> ao terra de referência, objetivando garantir a proteção do pessoal
                            que mantenha <br /> ou não contato com as instalações, circuitos de
                            comunicação, controle e outros;
                          </span>
                        </p>
                      </li>
                      <li data-list-text={5}>
                        <p
                          style={{
                            paddingLeft: "78pt",
                            textIndent: "-18pt",
                            textAlign: "justify"
                          }}
                        >
                          Garantir o bom funcionamento dos equipamentos elétricos.
                        </p>
                      </li>
                    </ol>

                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>
                    <p
                      className="s18"
                      style={{
                        paddingTop: "4pt",
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "justify"
                      }}
                    >
                      1{" "}
                      <span className="s19">
                        Clique na imagem acima para verificar a autenticidade da ART.
                      </span>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>

                    <br />
                    <br />
                    <br />
                  </li></ol>
              </div>
              <p className="s6" style={{ textAlign: "right" }}>
                Página 3 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>

            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='sizePage' style={{
              minHeight: '1000px', maxHeight: '1000px'
            }}>
              <br />
              <br />
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />

              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>

              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>

              {/* <li data-list-text={4}>*/}
              <h3
                style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "justify" }}
              >
                <a name="bookmark7"><strong>METODOLOGIA DA MEDIÇÃO</strong></a>
              </h3>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <ol id="l11">
                <li data-list-text="4.1.">
                  <p
                    className="s15"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "42pt",
                      textIndent: "-35pt",
                      lineHeight: "150%",
                      textAlign: "justify"
                    }}
                  >
                    <a name="bookmark8">
                      <span><strong>
                        NORMAS APLICADAS</strong>
                        <br />
                      </span>
                    </a>
                    <span className="p">
                      IEEE, volume III (1964)
                      <br />
                      ABNT NBR 5419:2005
                    </span>
                  </p>
                  <p
                    style={{
                      paddingLeft: "42pt",
                      textIndent: "0pt",
                      textAlign: "justify"
                    }}
                  >
                    ABNT NBR 15749:2009
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                </li>
                <li data-list-text="4.2.">
                  <p
                    className="s15"
                    style={{
                      paddingLeft: "42pt",
                      textIndent: "-35pt",
                      textAlign: "justify"
                    }}
                  >
                    <a name="bookmark9"><strong>MÉTODO UTILIZADO</strong></a>
                  </p>
                  <p
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "42pt",
                      textIndent: "0pt",
                      lineHeight: "150%",
                      textAlign: "justify"
                    }}
                  >
                    Método da Queda de Potencial<span className="s20">2</span>,
                    utilizando método de medição de 61,8% de
                    <i>
                      <b>d</b>
                    </i>
                    <span>
                      , com
                      <br />
                      variação de ±5% de{" "}
                    </span>
                    <i>
                      <b>d</b>
                    </i>
                    . (3 medidas)
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                </li>
                <li data-list-text="4.3.">
                  <p
                    className="s15"
                    style={{
                      paddingLeft: "42pt",
                      textIndent: "-35pt",
                      textAlign: "justify"
                    }}
                  >
                    <a name="bookmark10"><strong>DATA DA REALIZAÇÃO DO TESTE</strong></a>
                  </p>
                  <p
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "42pt",
                      textIndent: "0pt",
                      textAlign: "justify"
                    }}
                  >
                    {confereData(form.dataVistoria, form.dataMedicoes)}
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                </li>
                <li data-list-text="4.4.">
                  <p
                    className="s15"
                    style={{
                      paddingLeft: "42pt",
                      textIndent: "-35pt",
                      textAlign: "justify"
                    }}
                  >
                    <a name="bookmark11"><strong>TEMPO DE DURAÇÃO DO TESTE</strong></a>
                  </p>
                  <p
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "42pt",
                      textIndent: "0pt",
                      textAlign: "justify"
                    }}
                  >
                    {horarioDecorrido(form.inicioMedicoes, form.fimMedicoes)}
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                </li>
                <li data-list-text="4.5.">
                  <p
                    className="s15"
                    style={{
                      paddingLeft: "42pt",
                      textIndent: "-35pt",
                      textAlign: "justify"
                    }}
                  >
                    <a name="bookmark12"><strong>INSTRUMENTO UTILIZADO</strong></a>
                  </p>
                  <p
                    className="s15"
                    style={{
                      paddingTop: "7pt",
                      paddingLeft: "42pt",
                      textIndent: "0pt",
                      lineHeight: "150%",
                      textAlign: "justify"
                    }}
                  >
                    Intrumento:{" "}
                    <span className="p">
                      Terrômetro Digital
                      <br />
                    </span>
                    Fabricante:{" "}
                    <span className="p">
                      Megabras
                      <br />
                    </span>
                    Modelo: <u>MTD20KWR</u>
                  </p>
                  <p
                    className="s15"
                    style={{
                      paddingLeft: "42pt",
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "justify"
                    }}
                  >
                    Nº de Série: <span className="p">UR 4001 H</span>
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>

                </li>
                <li data-list-text="4.6.">
                  <p
                    className="s15"
                    style={{
                      paddingLeft: "42pt",
                      textIndent: "-35pt",
                      textAlign: "justify"
                    }}
                  >
                    <a name="bookmark13"><strong>CERTIFICADO DE CALIBRAÇÃO RBC</strong></a>
                    <span className="s17">3</span>
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                  <p
                    className="s15"
                    style={{
                      paddingTop: "11pt",
                      paddingLeft: "42pt",
                      textIndent: "0pt",
                      textAlign: "justify"
                    }}
                  >
                    Nº do Certificado de Calibração RBC:{" "}
                    <span className="p">67487/2023</span>
                  </p>
                  <p
                    className="s15"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "42pt",
                      textIndent: "0pt",
                      textAlign: "justify"
                    }}
                  >
                    Data da Calibração: <span className="p">31/08/2023</span>
                  </p>
                  <p style={{ paddingLeft: "320pt", textIndent: "0pt", textAlign: "justify" }}>
                    <span>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </span>
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>

                  <p
                    className="s18"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      textAlign: "justify"
                    }}
                  >
                    2{" "}
                    <span className="s19">
                      Método adotado por sua comodidade e alto grau de confiabilidade na
                      prática.
                    </span>
                  </p>
                  <p
                    className="s23"
                    style={{ paddingLeft: "6pt", textIndent: "0pt", textAlign: "justify" }}
                  >
                    3<span className="s24">C</span>
                    <span className="s25">
                      lique na imagem abaixo para visualizar o Certificado de Calibração
                      RBC autenticado.
                    </span>
                  </p>
                </li>
                <br />
                <br />
                <br />

                <p className="s6" style={{ textAlign: "right" }}>
                  Página 4 de {18 + (points.length)}
                </p>
                <hr style={{ borderStyle: 'solid' }} />
                <p
                  className="s1"
                  style={{ paddingLeft: "80pt", textAlign: "center" }}
                >
                  <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
                </p>
                <br />
                <p
                  className="s7"
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "51pt",

                    textAlign: "center"
                  }}
                >
                  Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
                </p>

              </ol>
            </div>
            <br />
            <br />
            <br />
            <br />

            <div className='sizePage' style={{
              minHeight: '1000px', maxHeight: '1000px'
            }}>
              <br />
              <br />
              <br />
              <br />
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <li data-list-text="4.7.">
                <p
                  className="s15"
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "-35pt",
                    textAlign: "justify"
                  }}
                >
                  <a name="bookmark14"><strong>DESCRIÇÃO DO PROCEDIMENTO DE TESTE</strong></a>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <ol id="l12">
                  <li data-list-text="4.7.1.">
                    <p
                      className="s15"
                      style={{
                        paddingLeft: "42pt",
                        textIndent: "-35pt",
                        textAlign: "justify"
                      }}
                    >
                      <a name="bookmark15"><strong>Princípio (ABNT NBR 15749:2009)</strong></a>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>
                    <p
                      style={{
                        paddingTop: "4pt",
                        paddingLeft: "77pt",
                        textIndent: "0pt",
                        lineHeight: "150%",
                        textAlign: "justify"
                      }}
                    >
                      <span>
                        O método da queda de potencial é recomendado para medição de
                        <br />
                        resistência de aterramento através de equipamento específico
                        (terrômetro).
                      </span>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>
                    <p
                      style={{
                        paddingLeft: "77pt",
                        textIndent: "0pt",
                        lineHeight: "150%",
                        textAlign: "justify"
                      }}
                    >
                      <span>
                        O método da queda de potencial consiste basicamente em fazer
                        circular uma
                        <br />
                        corrente através da malha de aterramento sob ensaio por
                        intermédio de um
                        <br />
                        eletrodo auxiliar de corrente e medir a tensão entre a malha
                        de aterramento
                        <br />e o terra de referência (terra remoto) por meio de uma
                        sonda ou eletrodo
                        <br />
                        auxiliar de potencial, conforme indicado na Figura 1.
                      </span>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>
                    <p
                      style={{
                        paddingLeft: "78pt",
                        textIndent: "0pt",
                        textAlign: "justify"
                      }}
                    >
                      <span>
                        <img
                          width={540}
                          height={283}
                          alt="image"
                          src={img08}
                        />
                      </span>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>
                    <p
                      className="s26"
                      style={{
                        paddingLeft: "46pt",
                        textIndent: "0pt",
                        textAlign: "center"
                      }}
                    >
                      Figura 1 - Método da queda de potencial (ABNT NBR 15749:2009)
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>
                    <p
                      className="s27"
                      style={{
                        paddingTop: "7pt",
                        paddingLeft: "162pt",
                        textIndent: "0pt",
                        textAlign: "justify"
                      }}
                    >
                      Legenda
                    </p>
                    <p
                      className="s28"
                      style={{
                        paddingTop: "5pt",
                        paddingLeft: "162pt",
                        textIndent: "0pt",
                        textAlign: "justify"
                      }}
                    >
                      I <span className="s29">Corrente de ensaio</span>
                    </p>
                    <p
                      className="s28"
                      style={{
                        paddingTop: "5pt",
                        paddingLeft: "162pt",
                        textIndent: "0pt",
                        textAlign: "justify"
                      }}
                    >
                      S{" "}
                      <span className="s29">
                        Borne para a sonda ou eletrodo auxiliar de potencial
                      </span>
                    </p>
                    <p
                      className="s28"
                      style={{
                        paddingTop: "5pt",
                        paddingLeft: "162pt",
                        textIndent: "0pt",
                        textAlign: "justify"
                      }}
                    >
                      H{" "}
                      <span className="s29">
                        Borne para o eletrodo auxiliar de corrente
                      </span>
                    </p>
                    <p
                      className="s28"
                      style={{
                        paddingTop: "5pt",
                        paddingLeft: "162pt",
                        textIndent: "0pt",
                        textAlign: "justify"
                      }}
                    >
                      E{" "}
                      <span className="s29">
                        Borne para a malha de aterramento sob medição
                      </span>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                      <br />
                    </p>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <p className="s6" style={{ textAlign: "right" }}>
                      Página 5 de {18 + (points.length)}
                    </p>
                    <hr style={{ borderStyle: 'solid' }} />
                    <p
                      className="s1"
                      style={{ paddingLeft: "80pt", textAlign: "center" }}
                    >
                      <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
                    </p>
                    <br />
                    <p
                      className="s7"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "51pt",

                        textAlign: "center"
                      }}
                    >
                      Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
                    </p>
                  </li>
                </ol>
              </li>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />


            <div className='sizePage' style={{
              minHeight: '1040px', maxHeight: '1040px'
            }}>
              <br />
              <br />
              <br />
              <br />

              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />

              <li data-list-text="4.7.2.">
                <p
                  className="s15"
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "42pt",
                    textIndent: "-35pt",
                    textAlign: "justify"
                  }}
                >
                  <a name="bookmark16"><strong>Procedimento (ABNT NBR 15749:2009)</strong></a>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "77pt",
                    textIndent: "0pt",
                    lineHeight: "150%",
                    textAlign: "justify"
                  }}
                >
                  <span>
                    No processo de medição, o eletrodo auxiliar de potencial deve
                    ser deslocado
                    <br />
                    ao longo de uma direção predefinida, a partir da periferia do
                    sistema de
                    <br />
                    aterramento sob ensaio, em intervalos regulares de medição
                    iguais a 5% da
                    <br />
                    distância{" "}
                  </span>
                  <i>
                    <b>d</b>
                  </i>
                  .
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: "90pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}
                >
                  <span>
                    <img
                      width={503}
                      height={207}
                      alt="image"
                      src={img10}
                    />
                  </span>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  className="s26"
                  style={{
                    paddingLeft: "229pt",
                    textIndent: "-140pt",
                    lineHeight: "141%",
                    textAlign: "justify"
                  }}
                >
                  <span>
                    Figura 2 - Curva característica teórica da resistência de
                    aterramento de um eletrodo pontual
                    <br />
                    (ABNT NBR 15749:2009)
                  </span>
                </p>
                <p
                  className="s27"
                  style={{
                    paddingTop: "7pt",
                    paddingLeft: "162pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}
                >
                  Legenda
                </p>
                <p
                  className="s28"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "184pt",
                    textIndent: "-21pt",
                    lineHeight: "150%",
                    textAlign: "justify"
                  }}
                >
                  R{" "}
                  <span className="s29">
                    Resistência obtida variando a distância da sonda desde a
                    distância
                    <br />d = D até d = 0 (o eletrodo a medir)
                  </span>
                </p>
                <p
                  className="s28"
                  style={{
                    paddingLeft: "162pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}
                >
                  R<span className="s30">V </span>
                  <span className="s29">Valor verdadeiro do aterramento</span>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingTop: "7pt",
                    paddingLeft: "77pt",
                    textIndent: "0pt",
                    lineHeight: "150%",
                    textAlign: "justify"
                  }}
                >
                  <span>
                    Fazendo-se a leitura do valor da resistência em cada posição,
                    obtém-se a<br />
                    curva de resistência em função da distância conforme indicado
                    na Figura 2.
                  </span>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: "77pt",
                    textIndent: "0pt",
                    lineHeight: "149%",
                    textAlign: "justify"
                  }}
                >
                  <span>
                    Efetivamente, podemos observamos na Figura 2, que, próximo ao
                    ponto de
                    <br />
                    medição{" "}
                  </span>
                  <i>
                    <b>E </b>
                  </i>
                  e ao eletrodo auxiliar de corrente{' '}
                  <i>
                    <b>H</b>
                  </i>
                  <span>
                    , existe alto gradiente de
                    <br />
                    potencial. Estas zonas chamam-se{' '}
                  </span>
                  <b>ZONAS DE INFLUÊNCIA</b>
                  <span className="s31">4</span>
                  <b>. </b>sendo a.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: "77pt",
                    textIndent: "0pt",
                    lineHeight: "150%",
                    textAlign: "justify"
                  }}
                >
                  <span>
                    Logo, o valor verdadeiro do aterramento, está no patamar da
                    curva,
                    <br />
                    localizado entre as zonas de influência.
                  </span>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: "6pt",
                    textIndent: "0pt",
                    lineHeight: "1pt",
                    textAlign: "justify"
                  }}
                ></p>
                <p
                  className="s18"
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "6pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}
                >
                  4{" "}
                  <span className="s19">
                    Zona de influência do aterramento sob medição e zona de
                    influência do eletrodo auxiliar de corrente.
                  </span>
                </p>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />


                <p className="s6" style={{ textAlign: "right" }}>
                  Página 6 de {18 + (points.length)}
                </p>
                <hr style={{ borderStyle: 'solid' }} />
                <p
                  className="s1"
                  style={{ paddingLeft: "80pt", textAlign: "center" }}
                >
                  <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
                </p>
                <br />
                <p
                  className="s7"
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "51pt",

                    textAlign: "center"
                  }}
                >
                  Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
                </p>
              </li>
              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
            <br />



            <div className='sizePage' style={{
              minHeight: '950px', maxHeight: '950px'
            }}>
              <br />
              <br />
              <br />
              <br />
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />

              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "justify"
                }}
              >
                Na norma ABNT NBR 15749:2009, temos que, o eletrodo de corrente{" "}
                <i>
                  <b>H{' '}</b>
                </i>
                <br />
                (Figura 1), deve estar a uma distância{" "}
                <i>
                  <b>d </b>
                </i>
                <span>
                  da periferia do sistema de
                  <br />
                  aterramento sob ensaio{" "}
                </span>
                <i>
                  <b>E</b>
                </i>
                <span>
                  , de pelo menos três vezes a maior dimensão deste
                  <br />
                  sistema. No entanto, devem ser feitas medições, mudando a
                  posição do
                  <br />
                  eletrodo de potencial{" "}
                </span>
                <i>
                  <b>S</b>
                </i>
                (
                <i>
                  <b>d</b>
                </i>
                <span className="s32">S</span>), em 5% de{" "}
                <i>
                  <b>d </b>
                </i>
                para a direita{" "}
                <i>
                  <b>d</b>
                </i>
                <span className="s32">S1</span>
                <span>
                  {'  '} e para esquerda
                  <br />
                </span>
                <i>
                  <b>d</b>
                </i>
                <span className="s32">S2 </span>{'  '}da posição inicial
                <i>
                  <b>{'  '}d</b>
                </i>
                <span className="s32">S</span>
                <span>
                  , de modo a garantir que as medições executadas,
                  <br />
                  estão sem sobreposição das zonas de influência, seja do
                  sistema de
                  <br />
                  aterramento sob ensaio, seja do eletrodo auxiliar de corrente.
                  Não há
                  <br />
                  sobreposição entre as áreas de influência se a porcentagem
                  entre a<br />
                  diferença dos valores medidos com o eletrodo de potencial em
                </span>
                <i>
                  <b>{'  '}d</b>
                </i>
                <span className="s32">S1 </span>e{" "}
                <i>
                  <b>d</b>
                </i>
                <span className="s32">S2</span>
                <span>
                  {'  '}e o<br />
                  valor medido em{" "}
                </span>
                <i>
                  <b>d</b>
                </i>
                <span className="s32">S</span>{'  '}não ultrapassar 10%.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "justify"
                }}
              >
                Estima-se que o eletrodo de corrente{" "}
                <i>
                  <b>H </b>
                </i>
                <span>
                  deve ser cravado entre três e cinco
                  <br />
                  vezes o valor da maior dimensão do aterramento sob medição.
                  Assim, para
                  <br />
                  uma haste de aterramento
                </span>
                <span className="s20">5 </span>de 3m, o eletrodo auxiliar de
                potencial{" "}
                <i>
                  <b>S </b>
                </i>
                <span>
                  deve
                  <br />
                  ser cravado à uma distância{" "}
                </span>
                <i>
                  <b>d </b>
                </i>
                relativa ao ponto de medição{" "}
                <i>
                  <b>E</b>
                </i>
                , tal que:
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                className="s33"
                style={{
                  paddingLeft: "220pt",
                  textIndent: "0pt",
                  textAlign: "justify"
                }}
              >
                𝟗 &lt; 𝒅<span className="s34">𝑺</span> &lt; 𝟏𝟓 𝒎𝒆𝒕𝒓𝒐𝒔
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "justify"
                }}
              >
                Ou ainda, para um conjunto<span className="s20">6 </span>
                <span>
                  de três hastes de aterramento de 3m, cravadas
                  <br />
                  em linha, separadas 5m entre elas, o eletrodo de corrente{'  '}
                </span>
                <i>
                  <b>H </b>
                </i>
                <span>
                  deve ser
                  <br />
                  cravado à uma distância{" "}
                </span>
                <i>
                  <b>d</b>
                </i>
                {'  '}relativa ao ponto de medição{" "}
                <i>
                  <b>E</b>
                </i>
                , tal que:
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                className="s33"
                style={{
                  paddingLeft: "219pt",
                  textIndent: "0pt",
                  textAlign: "justify"
                }}
              >
                𝟑𝟎 &lt; 𝒅 &lt; 𝟓𝟎 𝒎𝒆𝒕𝒓𝒐𝒔
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  lineHeight: "149%",
                  textAlign: "justify"
                }}
              >
                Iremos determinar a distância{" "}
                <i>
                  <b>d</b>
                </i>
                <span className="s32">S </span>utilizando o método dos 61,8% de{" "}
                <i>
                  <b>d</b>
                </i>
                <span>
                  . Este
                  <br />
                  método foi desenvolvido inicialmente por{'  '}
                </span>
                <i>
                  <b>G. F. Tagg</b>
                </i>
                <span className="s35">7</span>
                <span>
                  , sendo incluído na
                  <br />
                  norma da ABNT NBR 15749:2009{" "}
                </span>
                <span className="s20">8 </span>
                <span>
                  . Nas considerações do autor, é<br />
                  importante destacar que este presume um{'  '}
                </span>
                <b>SOLO HOMOGÊNEO</b>
                <span>
                  , além de,
                  <br />
                  considerar conhecida a localização do centro elétrico.
                </span>
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "1pt",
                  textAlign: "justify"
                }}
              ></p>
              <p
                className="s18"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "10pt",
                  textAlign: "justify"
                }}
              >
                5{" "}
                <span className="s19">
                  Só existe 1 dimensão que seria o comprimento da haste, 3
                  metros. (3 vezes = 9 metros; 5 vezes = 15 metros).
                </span>
              </p>
              <p
                className="s18"
                style={{
                  paddingLeft: "14pt",
                  textIndent: "-7pt",
                  textAlign: "justify"
                }}
              >
                6{" "}
                <span className="s19">
                  Existem 2 dimensões, a distância entre a 1ª e a 3ª haste, 10
                  metros, e o comprimento das hastes, 3 metros. Neste
                  <br />
                  caso, a maior dimensão seria de 10 metros. (3 vezes = 30
                  metros; 5 vezes = 50 metros).
                </span>
              </p>
              <p
                className="s18"
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "10pt",
                  textAlign: "justify"
                }}
              >
                7 <span className="s19">Publicado em </span>
                <span className="s36">Proceeding of the IEEE</span>
                <span className="s25">
                  , volume III no dia 12, de dezembro de 1964.
                </span>
              </p>
              <p
                className="s18"
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "10pt",
                  textAlign: "justify"
                }}
              >
                8{" "}
                <span className="s19">
                  Subitem 6.1.7.3, para sistemas de aterramento cuja maior
                  dimensão seja inferior a 10 metros.
                </span>
              </p>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />


              <p className="s6" style={{ textAlign: "right" }}>
                Página 7 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>

            </div>



            <div className='sizePage' style={{
              minHeight: '1050px', maxHeight: '1050px',
              marginTop: '100px'
            }}>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  lineHeight: "149%",
                  textAlign: "justify"
                }}
              >
                <span>
                  Destaca-se, ainda, que as zonas de influência de aterramentos
                  múltiplos
                  <br />
                  podem ser tão grandes que, para evitar que não exista
                  superposição das
                  <br />
                  áreas, devemos tomar distancias muito grandes entre o ponto de
                  medição{" "} <br />
                </span>
                <i>
                  <b>E</b>
                </i>
                {'  '}e o eletrodo auxiliar de corrente{" "}
                <i>
                  <b>H</b>
                </i>
                . É fundamental para o método<span className="s20">9 </span>
                <span>
                  que as
                  <br />
                  áreas não se sobreponham.
                </span>
                <span className="s20">10</span>
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "justify"
                }}
              >
                <span>
                  Assim, conhecidas as zonas de influência, podemos cravar o
                  eletrodo
                  <br />
                  auxiliar de corrente a uma distância{" "}
                </span>
                <i>
                  <b>d </b>
                </i>
                <span>
                  previamente determinada e fora da
                  <br />
                  zona de influência. O eletrodo auxiliar de potencial será
                  cravado a
                </span>
                <b>{'  '}61,8% <br /></b>{' '}dessa distância{" "}
                <i>
                  <b>d</b>
                </i>
                .
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "justify"
                }}
              >
                <span>
                  Normalmente devemos realizar no mínimo três medições, sempre
                  <br />
                  considerando que o eletrodo de potencial deve cravar-se fora
                  das zonas de
                  <br />
                  influência.
                </span>
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  lineHeight: "149%",
                  textAlign: "justify"
                }}
              >
                Após realizadas as 3 medições de resistências, em{" "}
                <i>
                  <b>d</b>
                </i>
                <span className="s32">S</span>,
                <i>
                  <b>d</b>
                </i>
                <span className="s32">S1 </span>e{" "}
                <i>
                  <b>d</b>
                </i>
                <span className="s32">S2</span>
                <span>
                  , o valor de
                  <br />
                  resistência considerada{'  '}
                </span>
                <i>

                  <b>R</b>
                </i>
                <span className="s32">c </span>
                <span className="s31">11 </span>
                <span className="s37">
                  será a média dos 3 valores medidos de
                  <br />
                  resistências, conforme fórmula abaixo:
                </span>
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                className="s33"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "190pt",
                  textIndent: "0pt",
                  lineHeight: "75%",
                  textAlign: "justify"
                }}
              >

                𝑹<span className="s34">𝒄</span>
                <span >

                  <span className="s38">= </span>(𝑹<span className="s34">𝒅</span>
                  <span className="s39">𝑺 </span>+ 𝑹<span className="s34">𝒅</span>
                  <span className="s39">𝑺𝟏 </span>+ 𝑹
                  <span className="s34">𝒅</span>
                  <span className="s39">𝑺𝟐 </span>)
                  <span className="s38"> (01) </span>

                  <hr style={{
                    marginLeft: '30px',
                    width: '125px',
                    border: '1px solid #000'
                  }} />
                </span>
              </p>

              <p
                className="s33"
                style={{
                  paddingRight: "50pt",
                  textIndent: "0pt",
                  lineHeight: "11pt",
                  textAlign: "center"
                }}
              >
                𝟑
              </p>
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>

              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "1pt",
                  textAlign: "justify"
                }}
              ></p>
              <p
                className="s18"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "14pt",
                  textIndent: "-7pt",
                  textAlign: "justify"
                }}
              >
                9{" "}
                <span className="s19">
                  Este método não poder ser utilizado para grandes sistemas
                  como: Parques eólicos, linhas de transmissão,
                  <br />
                  subestações de grande porte etc.
                </span>
              </p>
              <p
                className="s18"
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "10pt",
                  textAlign: "justify"
                }}
              >
                10{" "}
                <span className="s19">
                  Este método não pode ser utilizado se a zona de influência for
                  desconhecida.
                </span>
              </p>
              <p
                className="s18"
                style={{
                  paddingLeft: "14pt",
                  textIndent: "-7pt",
                  textAlign: "justify"
                }}
              >
                11{" "}
                <span className="s19">
                  O valor considerado Rc deverá possuir um erro máximo de 5% em
                  relação aos demais valores medidos, sendo
                  <br />
                  assim adotado como valor verdadeiro. Caso o erro seja maior
                  que 5%, as medições serão refeitas, considerando
                  <br />
                  aumentar a distância d entre ponto de medição E e o eletrodo
                  auxiliar de corrente H, até que a condição de erro
                  <br />
                  máximo de 5% seja atendida.
                </span>
              </p>
            </div>
            <br />
            <br />
            <br />

            <p className="s6" style={{ textAlign: "right" }}>
              Página 8 de {18 + (points.length)}
            </p>
            <hr style={{ borderStyle: 'solid' }} />
            <p
              className="s1"
              style={{ paddingLeft: "80pt", textAlign: "center" }}
            >
              <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
            </p>
            <br />
            <p
              className="s7"
              style={{
                paddingTop: "1pt",
                paddingLeft: "51pt",

                textAlign: "center"
              }}
            >
              Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
            </p>
            <br />
            <br />
            <br />




            <div className='sizePage' style={{
              minHeight: '1135px', maxHeight: '1135px'
            }}>
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />


              <div
                style={{
                  minHeight: '900px', maxHeight: '900px'
                }}
              >



                {/*</li>*/}
                <li data-list-text={5}>
                  <h3
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "24pt",
                      textIndent: "-18pt",
                      textAlign: "justify"
                    }}
                  >
                    <a name="bookmark17"><strong>RESULTADOS</strong></a>
                  </h3>

                  <p
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      textAlign: "justify"
                    }}
                  >
                    Os resultados das medições estão mostrados na tabela a seguir:
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                  <table
                    style={{ borderCollapse: "collapse", marginLeft: "15.104pt" }}
                    cellSpacing={0}
                  >
                    <thead>
                      <tr style={{ height: "19pt" }}>
                        <td
                          style={{
                            width: "451pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "2pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                          colSpan={5}
                          bgcolor="#FFD966"
                        >
                          <p
                            className="s40"
                            style={{
                              paddingTop: "1pt",
                              paddingLeft: "149pt",
                              paddingRight: "149pt",
                              textIndent: "0pt",
                              textAlign: "center"
                            }}
                          >
                            CIRCUITOS ELÉTRICOS
                          </p>
                        </td>
                      </tr>

                      <tr style={{ height: "27pt" }}>
                        <th
                          style={{
                            width: "54pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                          bgcolor="#FFF1CC"
                        >
                          <p
                            className="s41"
                            style={{
                              paddingTop: "1pt",
                              paddingLeft: "2pt",
                              paddingRight: "2pt",
                              textIndent: "0pt",
                              textAlign: "center"
                            }}
                          >
                            MEDIÇÃO
                          </p>
                          <p
                            className="s41"
                            style={{
                              paddingLeft: "2pt",
                              paddingRight: "2pt",
                              textIndent: "0pt",
                              textAlign: "center"
                            }}
                          >
                            Nº
                          </p>
                        </th>
                        <th
                          style={{
                            width: "186pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                          bgcolor="#FFF1CC"
                        >
                          <p
                            className="s41"
                            style={{
                              paddingTop: "7pt",
                              paddingLeft: "40pt",
                              paddingRight: "41pt",
                              textIndent: "0pt",
                              textAlign: "center"
                            }}
                          >
                            PONTO DE MEDIÇÃO
                          </p>
                        </th>
                        <th
                          style={{
                            width: "55pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                          bgcolor="#FFF1CC"
                        >
                          <p
                            className="s41"
                            style={{
                              paddingTop: "7pt",
                              textIndent: "0pt",
                              textAlign: "center"
                            }}
                          >
                            Ω
                          </p>
                        </th>
                        <th
                          style={{
                            width: "62pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                          bgcolor="#FFF1CC"
                        >
                          <p
                            className="s42"
                            style={{
                              paddingTop: "7pt",
                              paddingLeft: "23pt",
                              paddingRight: "23pt",
                              textIndent: "0pt",
                              textAlign: "center"
                            }}
                          >
                            R<span className="s43">eq</span>
                          </p>
                        </th>
                        <th
                          style={{
                            width: "94pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                          bgcolor="#FFF1CC"
                        >
                          <p
                            className="s41"
                            style={{
                              paddingTop: "7pt",
                              paddingLeft: "12pt",
                              textIndent: "0pt",
                              textAlign: "justify"
                            }}
                          >
                            RESULTADO <span style={{ color: "#F00" }}>*</span>
                          </p>
                        </th>
                      </tr>
                    </thead>


                    <tbody>
                      {malha.map((ponto, index) => (
                        <tr key={index} style={{ height: "15pt" }}>
                          <td
                            style={{
                              width: "40pt",
                              borderTopStyle: "solid",
                              borderTopWidth: "1pt",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "1pt"
                            }}

                            bgcolor="#FFF1CC"
                          >
                            <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                              <br />
                            </p>
                            <p
                              className="s45"
                              style={{ textIndent: "0pt", textAlign: "center" }}
                            >
                              {index + 1}
                            </p>
                          </td>
                          <td
                            style={{
                              width: "186pt",
                              borderTopStyle: "solid",
                              borderTopWidth: "1pt",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "1pt",
                              borderRightStyle: "solid",
                              borderRightWidth: "1pt"
                            }}

                          >
                            <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                              <br />
                            </p>
                            <p
                              className="s46"
                              style={{
                                paddingLeft: "20pt",
                                textIndent: "0pt",
                                textAlign: "justify",
                                paddingTop: '5pt'
                              }}
                            >
                              {ponto.ponto}
                            </p>
                          </td>
                          <td

                            style={{
                              padding: '5px',
                              textIndent: "0pt",
                              textAlign: "center",
                              display: 'flex',
                              flexDirection: 'column',
                              borderBottomStyle: "solid",
                              borderBottomWidth: "1pt"
                            }}
                          >
                            <td style={{

                              borderBottomStyle: "solid",
                              borderBottomWidth: "1pt"
                            }}>

                              {ponto.ohms1}
                            </td>

                            <td style={{

                              borderBottomStyle: "solid",
                              borderBottomWidth: "1pt"
                            }}>
                              {ponto.ohms2}
                            </td>
                            <td style={{


                            }}>
                              {ponto.ohms3}
                            </td>
                          </td>
                          <td
                            style={{
                              width: "62pt",
                              borderTopStyle: "solid",
                              borderTopWidth: "1pt",
                              borderLeftStyle: "solid",
                              borderLeftWidth: "1pt",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "1pt"
                            }}

                          >
                            <p
                              className="s47"
                              style={{
                                paddingTop: "14pt",
                                paddingLeft: "15pt",
                                textIndent: "0pt",
                                textAlign: "justify"
                              }}
                            >
                              {averageOhm(ponto.ohms1, ponto.ohms2, ponto.ohms3)}
                            </p>
                          </td>
                          <td
                            style={{
                              width: "94pt",
                              borderTopStyle: "solid",
                              borderTopWidth: "1pt",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "1pt"
                            }}

                            bgcolor="#A9D08E"
                          >
                            <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                              <br />
                            </p>
                            <p
                              className="s48"
                              style={{

                                paddingLeft: "20pt",
                                textIndent: "0pt",
                                textAlign: "justify",
                                paddingTop: '5pt'
                              }}
                            >
                              {
                                result(ponto.ohms1, ponto.ohms2, ponto.ohms3)
                              }
                            </p>
                          </td>
                        </tr>
                      ))}



                      {points.map((ponto, index) => (

                        isOpen === 1 ?
                          <tr key={index}>
                            <td
                              style={{
                                width: "54pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}

                              bgcolor="#FFF1CC"
                            >
                              <p style={{ textIndent: "0pt", textAlign: "center" }}>
                                <br />
                              </p>
                              <p
                                className="s45"
                                style={{ textIndent: "0pt", textAlign: "center" }}
                              >{index + 2}
                              </p></td>
                            <td
                              style={{
                                width: "186pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                              }}

                            >
                              <p style={{ textIndent: "0pt", textAlign: "center" }}>
                                <br />
                              </p>
                              <p

                                style={{
                                  paddingLeft: "20pt",
                                  textIndent: "0pt",
                                  textAlign: "justify",
                                  paddingBottom: '10pt'
                                }}
                              >
                                {ponto.ponto}
                              </p>
                            </td>
                            <td

                              style={{
                                textAlign: "center",
                                paddingTop: "9pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}>
                              {ponto.ohms}
                            </td>
                            <td
                              style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}

                            >
                              <p
                                className="s47"
                                style={{
                                  paddingTop: "10pt",

                                  textIndent: "0pt",
                                  textAlign: "center"
                                }}
                              >
                                {ponto.ohms}
                              </p>
                            </td>
                            <td
                              style={{
                                width: "94pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}

                              bgcolor="#A9D08E"
                            >
                              <p style={{ textIndent: "0pt", textAlign: "center" }}>
                                <br />
                              </p>
                              <p
                                className="s48"
                                style={{
                                  paddingLeft: "20pt",
                                  textIndent: "0pt",
                                  textAlign: "justify",
                                  paddingTop: '0pt'
                                }}
                              >
                                {
                                  malha.map((pontoMalha) => (
                                    resultOne(ponto.ohms, pontoMalha.ohms1, pontoMalha.ohms2, pontoMalha.ohms3)
                                  ))
                                }
                              </p>
                            </td>
                          </tr>
                          :
                          <tr key={index}>
                            <td
                              style={{
                                width: "54pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}

                              bgcolor="#FFF1CC"
                            >
                              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                                <br />
                              </p>
                              <p
                                className="s45"
                                style={{ textIndent: "0pt", textAlign: "center" }}
                              >{index + 2} </p></td>
                            <td
                              style={{
                                width: "186pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                              }}

                            >
                              <p style={{ textIndent: "0pt", textAlign: "center" }}>
                                <br />
                              </p>
                              <p
                                className="s46"
                                style={{
                                  paddingLeft: "20pt",
                                  textIndent: "0pt",
                                  textAlign: "justify",
                                  paddingTop: '5pt'
                                }}
                              >
                                {ponto.ponto}
                              </p>
                            </td>
                            <td

                              style={{
                                padding: '5px',
                                textIndent: "0pt",
                                textAlign: "center",
                                display: 'flex',
                                flexDirection: 'column',
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}
                            >
                              <td style={{

                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}>

                                {ponto.ohms1}
                              </td>
                              <td style={{

                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}>
                                {ponto.ohms2}
                              </td>
                              <td>
                                {ponto.ohms3}
                              </td>
                            </td>
                            <td
                              style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}

                            >
                              <p
                                className="s47"
                                style={{
                                  paddingTop: "14pt",

                                  textIndent: "0pt",
                                  textAlign: "center"
                                }}
                              >
                                {averageOhm(ponto.ohms1, ponto.ohms2, ponto.ohms3)}
                              </p>
                            </td>
                            <td
                              style={{
                                width: "94pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt"
                              }}

                              bgcolor="#A9D08E"
                            >
                              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                                <br />
                              </p>
                              <p
                                className="s48"
                                style={{

                                  paddingLeft: "20pt",
                                  textIndent: "0pt",
                                  textAlign: "justify",
                                  paddingTop: '5pt'
                                }}
                              >
                                {
                                  malha.map((pontoMalha) => (
                                    resultPoint(ponto.ohms1, ponto.ohms2, ponto.ohms3, pontoMalha.ohms1, pontoMalha.ohms2, pontoMalha.ohms3)
                                  ))
                                }
                              </p>
                            </td>
                          </tr>
                      ))}

                    </tbody>
                  </table>

                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>

                  <div style={{
                    paddingLeft: "100pt",
                  }}>

                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      padding: '5px 0 5px 0px',
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                      minWidth: '400px',
                      maxWidth: '400px',
                    }}>


                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                        width: '300px',
                        gap: '5px',
                      }}>

                        <p
                          className="s49"
                          style={{ fontSize: '8pt' }}
                        >
                          <strong>
                            <span id='text01'>

                            </span>
                          </strong>
                        </p>

                        <p
                          className="s50"
                          style={{ fontSize: '8pt' }}
                        >
                          <strong>

                            Ω Resistência Medida (ohms)
                          </strong>

                        </p>
                        <p
                          className="s50"
                          style={{ fontSize: '8pt' }}
                        >
                          <strong>

                            Req Resistência Equivalente (ohms)
                          </strong>

                        </p>
                      </div>

                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                        width: '300px',
                        gap: '5px',
                      }}>

                        <p
                          className="s50"
                          style={{ fontSize: '8pt' }}
                        >
                          <strong>

                            <span>
                              <span>d<span className="s51">S2</span> </span> <span id='text02'>  </span>
                              <br />
                            </span>
                          </strong>
                        </p>


                        <p
                          className="s50"
                          style={{ fontSize: '8pt' }}
                        >
                          <strong>

                            <span>
                              <span>d<span className="s51">S </span></span> <span id='text03'> </span>
                              <br />
                            </span>
                          </strong>
                        </p>
                        <p
                          className="s50"
                          style={{ fontSize: '8pt' }}
                        >
                          <strong>

                            <span>d<span className="s51">S1</span></span>
                            <span>
                              <span id='text04'> </span>
                              <br />
                            </span>
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div style={{
                      borderBottomStyle: "solid",
                      minWidth: '400px',
                      maxWidth: '400px',
                      textAlign: 'center',

                    }}>


                      <p
                        className="s52"
                        style={{


                          textIndent: "0pt",
                          textAlign: "center",
                          gap: '5px',
                        }}
                      >
                        <strong>
                          * considera-se o limite de 10Ω adotados pela norma ABNT NBR 5419:2005, haja
                          visto que,
                        </strong>
                        <strong>

                          as demais normas, não apresentam nenhum limite para ser utilizado como
                          parâmetro.
                        </strong>
                      </p>


                    </div>
                    <br />
                  </div>


                  <table
                    style={{ borderCollapse: "collapse", marginLeft: "86.4pt" }}
                    cellSpacing={0}
                  >
                    <tbody>
                      <tr style={{ height: "18pt" }}>
                        <td
                          style={{
                            width: "315pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt",
                            borderRightStyle: "solid",
                            borderRightWidth: "1pt"
                          }}
                          colSpan={2}
                          bgcolor="#FFD966"
                        >
                          <p
                            className="s40"
                            style={{
                              paddingTop: "1pt",
                              paddingLeft: "82pt",
                              textIndent: "0pt",
                              textAlign: "justify"
                            }}
                          >
                            DADOS DAS MEDIÇÕES
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "19pt" }}>
                        <td
                          style={{
                            width: "202pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "2pt",
                              paddingLeft: "4pt",
                              textIndent: "0pt",
                              textAlign: "justify"
                            }}
                          >
                            Data da Vistoria
                          </p>
                        </td>
                        <td
                          style={{
                            width: "113pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "2pt",
                              paddingLeft: "46pt",
                              paddingRight: "11pt",
                              textIndent: "0pt",
                              textAlign: "center"
                            }}
                          >
                            {format(form.dataVistoria)}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "19pt" }}>
                        <td
                          style={{
                            width: "202pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "4pt",
                              textIndent: "0pt",
                              textAlign: "justify"
                            }}
                          >
                            Data das Medições
                          </p>
                        </td>
                        <td
                          style={{
                            width: "113pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "46pt",
                              paddingRight: "11pt",
                              textIndent: "0pt",
                              textAlign: "center"
                            }}
                          >
                            {confereData(form.dataVistoria, form.dataMedicoes)}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "19pt" }}>
                        <td
                          style={{
                            width: "202pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "4pt",
                              textIndent: "0pt",
                              textAlign: "justify"
                            }}
                          >
                            Horário de Início das Medições
                          </p>
                        </td>
                        <td
                          style={{
                            width: "113pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "46pt",
                              paddingRight: "11pt",
                              textIndent: "0pt",
                              textAlign: "center"
                            }}
                          >
                            {form.inicioMedicoes}
                          </p>
                        </td>
                      </tr>

                      <tr style={{ height: "16pt" }}>
                        <td
                          style={{
                            width: "214pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingLeft: "3pt",
                              textIndent: "0pt",
                              lineHeight: "12pt",
                              textAlign: "justify"
                            }}
                          >
                            Horário de Término das Medições
                          </p>
                        </td>
                        <td
                          style={{
                            width: "101pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingRight: "22pt",
                              textIndent: "0pt",
                              lineHeight: "12pt",
                              textAlign: "right"
                            }}
                          >
                            {form.fimMedicoes}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "19pt" }}>
                        <td
                          style={{
                            width: "214pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "3pt",
                              textIndent: "0pt",
                              textAlign: "justify"
                            }}
                          >
                            Estado do Solo
                          </p>
                        </td>
                        <td
                          style={{
                            width: "101pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingRight: "24pt",
                              textIndent: "0pt",
                              textAlign: "right"
                            }}
                          >
                            {form.estadoSolo}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "19pt" }}>
                        <td
                          style={{
                            width: "214pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "3pt",
                              textIndent: "0pt",
                              textAlign: "justify"
                            }}
                          >
                            Temperatura Ambiente
                          </p>
                        </td>
                        <td
                          style={{
                            width: "101pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingRight: "27pt",
                              textIndent: "0pt",
                              textAlign: "right"
                            }}
                          >
                            {form.temperaturaAmbiente}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "19pt" }}>
                        <td
                          style={{
                            width: "214pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "3pt",
                              textIndent: "0pt",
                              textAlign: "justify"
                            }}
                          >
                            Umidade do Relativa do Ar
                          </p>
                        </td>
                        <td
                          style={{
                            width: "101pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2pt"
                          }}
                        >
                          <p
                            className="s46"
                            style={{
                              paddingTop: "3pt",
                              paddingRight: "28pt",
                              textIndent: "0pt",
                              textAlign: "right"
                            }}
                          >
                            {form.humidadeAr}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>

                  <br />
                  <br />
                  <br />
                </li>
              </div>
              <p className="s6" style={{ textAlign: "right" }}>
                Página 9 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>
              <br />
              <br />
              <br />


            </div>
            <div className='sizePage' style={{
              minHeight: '1110px', maxHeight: '1110px'
            }}>


              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <div
                style={{
                  minHeight: '900px', maxHeight: '900px'
                }}
              >

                <li data-list-text={6}>
                  <h3
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "24pt",
                      textIndent: "-18pt",
                      textAlign: "justify"
                    }}
                  >
                    <a name="bookmark18"><strong>RECOMENDAÇÕES</strong></a>
                  </h3>


                  <ol id="l13">
                    <li data-list-text={1}>

                      <p
                        style={{
                          paddingTop: "4pt",
                          paddingLeft: "60pt",
                          textIndent: "-18pt",
                          lineHeight: "149%",
                          textAlign: "justify"
                        }}
                      >
                        <span>
                          Recomenda-se a realização de Laudos de Medição de Resistência de
                          <br />
                          Aterramento periodicamente, não superior a 1 (um) ano;
                        </span>
                      </p>
                    </li>

                  </ol>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                </li>
                <li data-list-text={7}>
                  <h3
                    style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "justify" }}
                  >
                    <a name="bookmark19"><strong>CONCLUSÕES</strong></a>
                  </h3>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>

                  {conclusao()}



                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                  <p
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "1pt",
                      textAlign: "justify"
                    }}
                  ></p>
                  <p
                    className="s18"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      textAlign: "justify"
                    }}
                  >
                    12{" "}
                    <span className="s19">
                      Valor de referência de 10Ω adotados pela norma ABNT NBR 5419:2005.
                    </span>
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />



                </li>
              </div>
              <p className="s6" style={{ textAlign: "right" }}>
                Página 10 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>

            </div>


            <div className='sizePage' style={{
              minHeight: '1120px', maxHeight: '1120px'
            }}>

              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <br />

              <li data-list-text={8}>
                <h3
                  style={{ paddingLeft: "24pt", textIndent: "-18pt", textAlign: "justify" }}
                >
                  <a name="bookmark20"><strong>RESPONSÁVEL TÉCNICO</strong></a>
                </h3>
                <br />
                <p
                  className="s15"
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "42pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}
                >
                  Engenheiro responsável:{" "}
                  <span className="p">{nomeEng}</span>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  className="s15"
                  style={{
                    paddingLeft: "42pt",
                    textIndent: "0pt",
                    lineHeight: "200%",
                    textAlign: "justify"
                  }}
                >
                  Título:{" "}
                  <span className="p">
                    {tituloEng}
                    <br />
                  </span>
                  Registro no CREA-CE:{" "}
                  <span className="p">
                    {registroEng}
                    <br />
                  </span>
                  ART No.: {dataArt.numero}<span className="s17">13</span>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p style={{ paddingLeft: "8pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <a href={dataArt.site}>
                      <img
                        className='imgArt2'
                        width={623}
                        height={117}
                        alt="image"

                      />
                    </a>
                  </span>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: "149pt",
                    textIndent: "0pt",
                    lineHeight: "1pt",
                    textAlign: "justify"
                  }}
                ></p>
                <p
                  className="s4"
                  style={{
                    paddingLeft: "47pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "center"
                  }}
                >
                  <span>{nomeEng}</span>
                </p>
                <p
                  className="s25"
                  style={{ paddingLeft: "190pt", textIndent: "0pt", textAlign: "center" }}
                >
                  <span>
                    {tituloEng}
                    <br />
                    CREA-CE nº {registroEng}
                  </span>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: "6pt",
                    textIndent: "0pt",
                    lineHeight: "1pt",
                    textAlign: "justify"
                  }}
                ></p>
                <p
                  className="s54"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "6pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}
                >
                  13<span className="s55"> </span>
                  <span className="s25">
                    Clique na imagem acima para verificar a autenticidade da ART.
                  </span>
                </p>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />


                <br />
                <br />

                <p className="s6" style={{ textAlign: "right" }}>
                  Página 11 de {18 + (points.length)}
                </p>
                <hr style={{ borderStyle: 'solid' }} />
                <p
                  className="s1"
                  style={{ paddingLeft: "80pt", textAlign: "center" }}
                >
                  <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
                </p>
                <br />
                <p
                  className="s7"
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "51pt",

                    textAlign: "center"
                  }}
                >
                  Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
                </p>
              </li>
            </div>

            <div className='sizePage' style={{
              minHeight: '1130px', maxHeight: '1130px'
            }}>
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <br />
              <div
                style={{
                  minHeight: '900px', maxHeight: '900px'
                }}
              >


                <li data-list-text={9}>
                  <h3
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "24pt",
                      textIndent: "-18pt",
                      textAlign: "justify"
                    }}
                  >
                    <a name="bookmark21"><strong>
                      ANEXO I – ANOTAÇÃO DE RESPONSABILIDADE TÉCNICA – ART
                    </strong>
                    </a>
                  </h3>
                  <br />
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                  <p
                    className="s12"
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "46pt",
                      textIndent: "0pt",
                      textAlign: "center"
                    }}
                  >
                    Acesse o documento<span className="s56">14</span>
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                  <p style={{ paddingLeft: "8pt", textIndent: "0pt", textAlign: "justify" }}>
                    <span>
                      <a href={dataArt.site}>
                        <img
                          className='imgArt3'
                          width={623}
                          height={117}
                          alt="image"

                        />
                      </a>
                    </span>
                  </p>
                  <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                    <br />
                  </p>
                  <p
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "1pt",
                      textAlign: "justify"
                    }}
                  ></p>
                  <p
                    className="s54"
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      textAlign: "justify"
                    }}
                  >
                    14<span className="s55"> </span>
                    <span className="s25">
                      Clique na imagem acima para verificar a autenticidade da ART.
                    </span>
                  </p>

                  <br />

                </li>
              </div>
              <p className="s6" style={{ textAlign: "right" }}>
                Página 12 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>

            </div>

            <div className='sizePage' style={{
              minHeight: '1070px', maxHeight: '1070px'
            }}>
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <br />



              <li data-list-text={10}>
                <h3
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "42pt",
                    textIndent: "-35pt",
                    textAlign: "justify"
                  }}
                >
                  <a name="bookmark22"><strong>ANEXO II – CERTIFICADO DE CALIBRAÇÃO RBC</strong></a>
                </h3>
                <br />
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>
                <p style={{ paddingLeft: "19pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={500}
                      height={800}
                      alt="Uma imagem contendo Diagrama

Descrição gerada automaticamente"
                      title="Uma imagem contendo Diagrama

Descrição gerada automaticamente"
                      src={cert01}
                    />
                  </span>
                </p>
                <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                  <br />
                </p>



                <p className="s6" style={{ textAlign: "right" }}>
                  Página 13 de {18 + (points.length)}
                </p>
                <hr style={{ borderStyle: 'solid' }} />
                <p
                  className="s1"
                  style={{ paddingLeft: "80pt", textAlign: "center" }}
                >
                  <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
                </p>
                <br />
                <p
                  className="s7"
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "51pt",

                    textAlign: "center"
                  }}
                >
                  Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
                </p>
              </li>
            </div>
            <br />
            <br />
            <br />


            <div className='sizePage' style={{
              minHeight: '1060px', maxHeight: '1060px'
            }}>

              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <span>
                  <img
                    width={600}
                    height={800}
                    alt="Tabela

Descrição gerada automaticamente"
                    title="Tabela

Descrição gerada automaticamente"
                    src={cert02}
                  />
                </span>
              </p>

              <br />
              <br />
              <br />
              <br />
              <br />

              <p className="s6" style={{ textAlign: "right" }}>
                Página 14 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>
            </div>
            <br />
            <br />
            <br />

            <div className='sizePage' style={{
              minHeight: '1100px', maxHeight: '1100px'
            }}>
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <p style={{ textIndent: "0pt", textAlign: "justify" }}>
                <span>
                  <img
                    width={634}
                    height={896}
                    alt="Texto, Carta

Descrição gerada automaticamente"
                    title="Texto, Carta

Descrição gerada automaticamente"
                    src={cert03}
                  />
                </span>
              </p>



              <br />

              <p className="s6" style={{ textAlign: "right" }}>
                Página 15 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>
            </div>
            <br />
            <br />
            <br />

            <div className='sizePage' style={{
              minHeight: '1100px', maxHeight: '1100px'
            }}>
              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />


              <li data-list-text={11}>
                <h3
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "42pt",
                    textIndent: "-35pt",
                    textAlign: "justify"
                  }}
                >
                  <a name="bookmark23"><strong>ANEXO III – RELATÓRIO FOTOGRÁFICO</strong></a>
                </h3>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </li>


              <br />
              <br />
              <br />

              <p className="s6" style={{ textAlign: "right" }}>
                Página 16 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>




            </div>
            <div >

              <div className='sizePage' style={{
                minHeight: '1150px', maxHeight: '1150px',
              }}>

                <div className='laudoHeader'>

                  <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                    <span>
                      <img
                        width={240}
                        height={60}
                        alt="image"
                        src={img01}
                      />
                    </span>
                  </p>

                  <br />

                  <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                    <p> +55 85 4141.4900</p>
                    <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                      contato@fortesol.com.br
                      <br />
                    </a>
                    <a href="http://www.fortesol.com.br/" target="_blank">
                      www.fortesol.com.br
                    </a>
                  </p>
                </div>
                <br />
                <hr style={{ borderStyle: 'solid' }} />

                <h3
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "46pt",
                    textIndent: "0pt",
                    lineHeight: "16pt",
                    textAlign: "center"
                  }}
                >
                  RELATÓRIO FOTOGRÁFICO
                </h3>
                <p
                  className="s57"
                  style={{ paddingLeft: "46pt", textIndent: "0pt", textAlign: "center" }}
                >
                  (página 1)
                </p>

                <p
                  style={{
                    paddingTop: "13pt",
                    paddingLeft: "46pt",
                    textIndent: "0pt",
                    textAlign: "center"
                  }}
                >
                  Registros fotográficos e evidências das medições:
                </p>
                <div
                  style={{
                    minHeight: '740px', maxHeight: '740px',
                  }}>

                  {
                    listImage.map((item, index) => (
                      item.arq === 'equipamento'
                        ?
                        <p key={index} style={{ paddingLeft: "10pt", textIndent: "0pt", textAlign: "justify" }}>
                          <span >
                            <span
                              style={{ minWidth: '200px', minHeight: '300px' }} className='spanListReport'>
                              <span
                                style={{ minWidth: '200px', minHeight: '300px' }} className='imgSpanReport'>
                                <img
                                  style={{ minWidth: '200px', minHeight: '300px' }}
                                  src={URL.createObjectURL(item.file)} alt={item.name} />
                              </span>
                            </span>
                          </span>
                        </p>
                        :
                        <></>
                    ))

                  }
                </div>
                <p
                  className="s58"
                  style={{ paddingLeft: "10pt", textIndent: "0pt", textAlign: "center" }}
                >
                  MEDIDOR DE TEMPERATURA E UMIDADE
                </p>



                <br />
                <br />
                <br />

                <p className="s6" style={{ textAlign: "right" }}>
                  Página 17 de {18 + (points.length)}
                </p>
                <hr style={{ borderStyle: 'solid' }} />
                <p
                  className="s1"
                  style={{ paddingLeft: "80pt", textAlign: "center" }}
                >
                  <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
                </p>
                <br />
                <p
                  className="s7"
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "51pt",

                    textAlign: "center"
                  }}
                >
                  Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
                </p>
                <br />

              </div>
            </div>
            <div className='sizePage' style={{
              minHeight: '1100px', maxHeight: '1100px',
            }}>

              <div className='laudoHeader'>

                <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                  <span>
                    <img
                      width={240}
                      height={60}
                      alt="image"
                      src={img01}
                    />
                  </span>
                </p>

                <br />

                <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                  <p> +55 85 4141.4900</p>
                  <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                    contato@fortesol.com.br
                    <br />
                  </a>
                  <a href="http://www.fortesol.com.br/" target="_blank">
                    www.fortesol.com.br
                  </a>
                </p>
              </div>
              <br />
              <hr style={{ borderStyle: 'solid' }} />
              <h3
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "46pt",
                  textIndent: "0pt",
                  lineHeight: "16pt",
                  textAlign: "center"
                }}
              >
                RELATÓRIO FOTOGRÁFICO
              </h3>
              <p
                className="s57"
                style={{ paddingLeft: "47pt", textIndent: "0pt", textAlign: "center" }}
              >
                (página 2)
              </p>

              <div
                style={{
                  minHeight: '740px', maxHeight: '740px',
                }}>



                {
                  listImage.map((item, index) => (
                    item.arq === malha[0].ponto ?
                      <div>

                        <p style={{ paddingLeft: "10pt", textIndent: "0pt", textAlign: "justify" }}>
                          <span className='spanListReport'>
                            <span className='imgSpanReport'>
                              <img
                                width={30}
                                height={20}
                                src={URL.createObjectURL(item.file)} alt={item.name} />
                            </span>
                          </span>

                        </p>

                      </div>
                      :
                      <></>
                  ))}
              </div>
              <p
                className="s58"
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  textAlign: "center"
                }}
              >
                MALHA DE ATERRAMENTO
              </p>



              <br />
              <br />
              <br />

              <p className="s6" style={{ textAlign: "right" }}>
                Página 18 de {18 + (points.length)}
              </p>
              <hr style={{ borderStyle: 'solid' }} />
              <p
                className="s1"
                style={{ paddingLeft: "80pt", textAlign: "center" }}
              >
                <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
              </p>
              <br />
              <p
                className="s7"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "51pt",

                  textAlign: "center"
                }}
              >
                Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
              </p>
              <br />
              <br />
              <br />
            </div>

            {
              points.map((ponto, index) => (

                <div key={index}>
                  <div className='sizePage' style={{
                    minHeight: '1120px', maxHeight: '1120px',
                  }}>

                    <div className='laudoHeader'>

                      <p style={{ paddingLeft: "9pt", textIndent: "0pt", textAlign: "justify" }}>
                        <span>
                          <img
                            width={240}
                            height={60}
                            alt="image"
                            src={img01}
                          />
                        </span>
                      </p>

                      <br />

                      <p style={{ paddingLeft: "34pt", textIndent: "-24pt", textAlign: "right" }}>
                        <p> +55 85 4141.4900</p>
                        <a href="http://www.fortesol.com.br/" className="a" target="_blank">
                          contato@fortesol.com.br
                          <br />
                        </a>
                        <a href="http://www.fortesol.com.br/" target="_blank">
                          www.fortesol.com.br
                        </a>
                      </p>
                    </div>
                    <br />
                    <hr style={{ borderStyle: 'solid' }} />

                    <h3
                      style={{
                        paddingTop: "4pt",
                        paddingLeft: "46pt",
                        textIndent: "0pt",
                        textAlign: "center"
                      }}
                    >
                      RELATÓRIO FOTOGRÁFICO
                    </h3>
                    <p
                      className="s57"
                      style={{ paddingLeft: "47pt", textIndent: "0pt", textAlign: "center" }}
                    >
                      (página {index + 3})
                    </p>

                    <p
                      style={{
                        paddingTop: "12pt",
                        paddingLeft: "30pt",
                        textIndent: "0pt",
                        textAlign: "center"
                      }}
                    >
                      Registros fotográficos e evidências das medições:
                    </p>
                    <div
                      style={{
                        minHeight: '740px', maxHeight: '740px',
                      }}>

                      {
                        listImage.map((item, i) => (
                          item.arq === ponto.ponto ?
                            <p key={i} style={{ paddingLeft: "10pt", textIndent: "0pt", textAlign: "justify" }}>
                              <span className='spanListReport'>
                                <span className='imgSpanReport'>
                                  <img src={URL.createObjectURL(item.file)} alt={item.name} />
                                </span>
                              </span>
                            </p>
                            :
                            <></>
                        ))}

                    </div>
                    <p
                      className="s58"
                      style={{ paddingLeft: "10pt", textIndent: "0pt", textAlign: "center", fontSize: '15px', marginTop: '10px' }}
                    >
                      {ponto.ponto}
                    </p>


                    <br />
                    <br />
                    <br />
                    <br />
                    <br />





                    <p className="s6" style={{ textAlign: "right" }}>
                      Página {index + 19} de {18 + (points.length)}
                    </p>
                    <hr style={{ borderStyle: 'solid' }} />
                    <p
                      className="s1"
                      style={{ paddingLeft: "80pt", textAlign: "center" }}
                    >
                      <span style={{ marginRight: '40px' }}>Forte Feijó Soluções LTDA</span>   <span style={{ marginLeft: '40px' }}> CNPJ: 28.029.741/0001-14</span>
                    </p>
                    <br />
                    <p
                      className="s7"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "51pt",

                        textAlign: "center"
                      }}
                    >
                      Rua Luiza Teixeira de Araújo, 69 – São Francisco – CEP: 62.640-000 – Pentecoste-CE
                    </p>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              ))
            }


          </div>
          {/* <div style={{
display: 'flex',
justifyContent: 'space-between',
width: '30%',
alignItems: 'center',
marginLeft: '38%',
}}>

<button className='printReport' onClick={gerarPDF}>imprimir</button>
<button className='close' onClick={fecharModal}>fechar</button>
</div>
*/}
        </div>
      </div>
</Sidebar>
</Navigation>
    </>
  )
}

export default Aterramento

