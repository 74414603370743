import { DialerSipSharp, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, Menu, MenuItem, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';

export default function New_Edit_User_employee(props) {

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    function gerarSenha(email) {
        // Extrai os primeiros 4 dígitos do ano atual
        let primeirosDigitos = new Date().getFullYear().toString().substring(0, 4);
       
      
        // Extrai as primeiras 4 letras do email
        const primeirasLetras = email.substring(0, 4).toUpperCase();
      
        // Concatena os valores, intercalando @ e #
        const senha = `${primeirosDigitos}@${primeirasLetras}#`;
      
        return senha;
    }



    const [typeUser, setTypeUser] = React.useState('');
    const userTypeList = [
        { label: 'Administrador', value: 'Administrador' },
        { label: 'Colaborador', value: 'Colaborador' },
        { label: 'Leitor', value: 'Leitor' },
    ]
   



      const [name, setName] = React.useState('');
      const [email, setEmail] = React.useState('');
        const [password, setPassword] = React.useState('');
      useEffect(() => {
        if (props.title === 'Novo Usuário') {
        
            setName(props.data.name_employee);
            setEmail(props.data.email_employee);
            setPassword(gerarSenha(props.data.email_employee));
            
        }
        if (props.title === 'Editar Usuário') {
           
            setName(props.data.name_user);
            setEmail(props.data.email);
            setPassword(props.data.password);
            setTypeUser(props.data.typeUser)

        }
      }, [props]);

    const closeUser = () => {
        setName('');
        setEmail('');
        setPassword('');
        props.closeUser();
    }

    const new_user = () => {
        try{
             fetch(`https://api.fortesol.com.br/api/users/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name_user: name,
                email: email,
                password: password,
                typeUser: typeUser,
                id_customer: null,
                id_employee: props.data.id_employee

            }),
        }).then(response => response.json())
        .then(data => {
            
            if(data.message === 'User registered successfully'){
                closeUser();
            }
            if(data.message === 'User already exists'){
                toast.info('E-mail já cadastrado')
            }
        })
        }catch(error){
            console.log(error)
        }
    }

    const edit_user = () => {
        try{
            fetch(`https://api.fortesol.com.br/api/users/update/${props.data.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name_user: name,
                    email: email,
                    password: password,
                    typeUser: typeUser,
                    id_customer: null,
                    id_employee: props.data.id_employee
                }),
            }).then(response => response.json())
            .then(data => {
                
                if(data.message === 'User updated successfully'){
                    closeUser();
                }
                if(data.message === 'User already exists'){
                    toast.info('E-mail já cadastrado')
                }
            })
        }catch(error){
            console.log(error)
        }
    }


    return (
        <Dialog
            open={props.openUser}
            onClose={props.closeUser}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth={true}
        >
            <ToastContainer 
                position="bottom-right"
                autoClose={4000}
                />
            <DialogContent>
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <List>
                    <ListItem>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Nome"
                            type="text"
                            fullWidth
                            value={name.split(' ')[0]}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField

                            id="password-input"
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}   
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                ),
                            }}
                        />
                    </ListItem>
                    <ListItem>
                    <TextField  
                    select
                    label="Tipo de Usuário"
                    value={typeUser}
                    onChange={(e) => setTypeUser(e.target.value)}
                    fullWidth
                    >
                        {userTypeList.map((option) => (
                       <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </TextField>
                    </ListItem>
                    
                </List>


            </DialogContent>
            <DialogActions>
                <Button onClick={closeUser} color="primary">
                    Cancelar
                </Button>
               {props.title === 'Novo Usuário' ?
    
                <Button onClick={new_user} color="primary" autoFocus>
                    Salvar
                </Button>
                :
                <Button onClick={edit_user} color="primary" autoFocus>
                    Salvar
                </Button>}
            </DialogActions>
        </Dialog>
    )
}
