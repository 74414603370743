import { AppBar, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material'
import React from 'react'
import ReplyIcon from '@mui/icons-material/Reply';

export default function HeaderSolProjetos() {
    const response = localStorage.getItem('token') 
    const user = JSON.parse(response)

    return (
        <AppBar position="static" style={{ background: user.typeUser === 'Cliente' ? '#000':'#023047', height: '80px' }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <List sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ListItem sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ListItemIcon>
                            <img src={user.typeUser === 'Cliente' ? '/logosolprojetos.png' : "/capa.png"} style={{ width: '60px', height: '60px' }} alt="" />
                        </ListItemIcon>
                        <ListItemText primary="SOL PROJETOS" secondary="Soluções em projetos fotovoltaicos." sx={{ color: '#fff', marginLeft: '10px', '& p': { color: '#fff' } }} />
                    </ListItem>
                </List>
            </Toolbar>
        </AppBar>
    )
}
 