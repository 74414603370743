import React from 'react'

import { Viewer, Worker } from '@react-pdf-viewer/core';


function DescriptiveMemorialProject(props) {
  

  return (

    <div style={{ width: '100%', height: 1120,paddingLeft:'7%' }}>
        <Worker workerUrl="pdf.worker.min.js">
          <div style={{ height: '750px' }}>
            <Viewer fileUrl={`https://api.fortesol.com.br/api/demand_project_solar/download/pdf_descriptive_memorial/${props.data.id_demand}`} />
          </div>
        </Worker>
      </div>
    


  )
}

export default DescriptiveMemorialProject
