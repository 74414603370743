import React, { useEffect, useState } from 'react'
import Sidebar from '../../Components/Sidebar'
import './style.css'
import validator from 'validator'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DrawingManagerF, GoogleMap, Marker, useJsApiLoader, RectangleF, CircleF, Rectangle, PolylineF, PolygonF, StreetViewPanorama } from '@react-google-maps/api';
import Loading from '../../Components/loading';
import html2pdf from 'html2pdf.js'
import enelLogo from '../../Components/solar/index_files/enel_rateio.png';
import { MdDelete } from 'react-icons/md';


const token = localStorage.getItem('token')
const tokenParse = JSON.parse(token)

const ServiceSolar = () => {
  const id_customer_integrator = tokenParse.id




  const [gab, setGab] = useState(false)
  const [replaceState, setReplaceState] = useState(false)
  /*const rgFormat = (rg) => {
      rg = rg.replace(/\D/g, "");
      rg = rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
  
      if (rg.length > 14) {
        rg = rg.substring(0, 13)
      }
  
      return rg
    }*/
  const celularFormat = (celular) => {
    celular = celular.replace(/\D/g, "")
    celular = celular.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")

    if (celular.length > 15) {
      celular = celular.substring(0, 14)
    }

    return celular
  }

  const cpfFormat = (cpf) => {

    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

    if (cpf.length > 14) {
      cpf = cpf.substring(0, 13)
    }

    return cpf
  }

  const cepFormat = (cep) => {
    cep = cep.replace(/\D/g, "")
    cep = cep.replace(/(\d{5})(\d{3})/, "$1-$2")

    if (cep.length > 9) {
      cep = cep.substring(0, 8)
    }

    return cep
  }

  const cnpjFormat = (cnpj) => {
    cnpj = cnpj.replace(/\D/g, "")
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2")
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2")
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2")
    cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")

    if (cnpj.length > 18) {
      cnpj = cnpj.substring(0, 17)
    }

    return cnpj
  }
  const handleCNPJ = async (cnpj) => {
    if (!cnpj) {
      toast.error('Campo CNPJ vazio')
      return
    }
    const cnpjCorrect = cnpj.replace(/[^\d]+/g, '');
    if (cnpjCorrect.length !== 14) {
      toast.error('CNPJ inválido')
      return
    }
    await fetch(`https://publica.cnpj.ws/cnpj/${cnpjCorrect}`)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'ERROR') {
          toast.error('CNPJ não encontrado')
          return
        }

        const cnpjformat = data.estabelecimento.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        const formattedCep = data.estabelecimento.cep.replace(/(\d{5})(\d{3})/, "$1-$2")


        document.getElementById('PJ-InputFantasyName').value = data.estabelecimento.nome_fantasia
        document.getElementById('PJ-InputSocialReason').value = data.razao_social
        document.getElementById('PJ-InputCNPJ').value = cnpjformat
        document.getElementById('PJ-InputEmail').value = data.estabelecimento.email
        document.getElementById('PJ-InputContact').value = data.estabelecimento.telefone
        document.getElementById('PJ-InputCep').value = formattedCep
        document.getElementById('PJ-InputStreet').value = `Rua ${data.estabelecimento.logradouro}`
        document.getElementById('PJ-InputNumber').value = data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N'
        document.getElementById('PJ-InputNeighborhood').value = data.estabelecimento.bairro
        document.getElementById('PJ-InputCity').value = data.estabelecimento.cidade.nome
        document.getElementById('PJ-InputState').value = data.estabelecimento.estado.sigla
        document.getElementById('PJ-InputComplement').value = data.estabelecimento.complemento ? data.estabelecimento.complemento : '---'




        const _cnpj = [
          data.razao_social,
          cnpjformat,
          data.estabelecimento.email,
          data.estabelecimento.nome_fantasia,
          data.estabelecimento.logradouro,
          data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N',
          data.estabelecimento.complemento ? data.estabelecimento.complemento : '---',
          data.estabelecimento.bairro,
          data.estabelecimento.cidade.nome,
          data.estabelecimento.estado.sigla,
          formattedCep

        ]


      }).catch(err => {
        console.log(err)

      })
  }

  const [latitude, setLatitude] = useState(-22.95245554364288)
  const [longitude, setLongitude] = useState(-43.21047122854996)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAMjFIIlVgRO1yUsl7uLN0RXBMyDHMJUS8",
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const path = [
        /*cima esquedo*/ { lat: latitude + 0.00001, lng: longitude - 0.000019 },
        /*baixo esquerdo*/  { lat: latitude - 0.000019, lng: longitude - 0.000019 },

        /*baixo direito*/{ lat: latitude - 0.000019, lng: longitude + 0.000019 },
        /*cima direito*/{ lat: latitude + 0.00001, lng: longitude + 0.000019 },

  ]




  const containerStyle = {
    width: '300px',
    height: '165px',
    borderRadius: '10px',
  };
  const selectStyle = {
    width: '300px',
    height: '485px',
    borderRadius: '10px',
  };
  const center = {
    lat: latitude,
    lng: longitude,
  };
  const [latitudeClick, setLatitudeClick] = useState()
  const [longitudeClick, setLongitudeClick] = useState()
  const positionClick = {
    lat: latitudeClick,
    lng: longitudeClick,
  }
  const options = {
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: true,
    scaleControl: true,
    rotateControl: false,
    fullscreenControl: true,
    mapTypeId: 'satellite',
    tilt: 0,
    zoom: 18,
  }
  const options2 = {
    disableDefaultUI: false,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    fullscreenControl: true,
    mapTypeId: 'satellite',
    streetViewControl: false,
    draggable: true,
    inclination: 0,
    heading: 0,
    tilt: 0,
    zoom: 19,

  }
  /*
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=344+FlorêncioPinheiro+Pentecoste+Ce+BR&key=AIzaSyAMjFIIlVgRO1yUsl7uLN0RXBMyDHMJUS8`,{
        method: 'GET',
      }).then(response => response.json())
      .then(data => {
        console.log(data)
      })*/
  const [addressCep, setAddressCep] = useState('')
  const [addressNumber, setAddressNumber] = useState('')
  const [addressStreet, setAddressStreet] = useState('')
  const [addressCity, setAddressCity] = useState('')
  const [addressState, setAddressState] = useState('')
  const [addressNeighborhood, setAddressNeighborhood] = useState('')

  useEffect(() => {
    setAddressNumber(parseFloat(document.querySelector('.number-service').value))
    setAddressStreet(parseFloat(document.querySelector('.street-service').value))
    setAddressCity(parseFloat(document.querySelector('.city-service').value))
    setAddressState(parseFloat(document.querySelector('.state-service').value))

  }, [document.querySelector('.number-service')?.value, document.querySelector('.street-service')?.value, document.querySelector('.city-service')?.value, document.querySelector('.state-service')?.value])



 /* fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressNumber}+${addressStreet}+${addressCity}+${addressState}+BR&key=AIzaSyAMjFIIlVgRO1yUsl7uLN0RXBMyDHMJUS8`, {
    method: 'GET',
  }).then(response => response.json())
    .then(data => {
      if (data.status === 'ZERO_RESULTS') {
        return
      }
      setLatitude(data.results[0]?.geometry.location.lat === -14.235004 ? -22.95245554364288 : data.results[0]?.geometry.location.lat)
      setLongitude(data.results[0]?.geometry.location.lng === -51.92528 ? -43.21047122854996 : data.results[0]?.geometry.location.lng)
    })
*/



  /*const handleCriarImagem = () => {
    fetch("https://maps.googleapis.com/maps/api/staticmap?size=400x400&scale=1&format=png&maptype=roadmap&markers=size:small%7Ccolor:blue%7Clabel:P%7C40.737102,-73.990318&path=weight:5%7C40.737102,-73.990318%7C40.749825,-73.987963%7C40.752946,-73.987384%7C40.755823,-73.986397&key=AIzaSyAMjFIIlVgRO1yUsl7uLN0RXBMyDHMJUS8", {
      method: 'GET',
    }).then(response => response.blob())
      .then(data => {
        const image = new Image()
        image.src = URL.createObjectURL(data)
        document.querySelector('.maps4').innerHTML = ''
        document.querySelector('.maps4').appendChild(image)
      })
  }
*/
  const handleAddress = () => {

    if (document.querySelector('.address-customer-checkbox').checked) {
      for (let i = 0; i < 2; i++) {

        if (document.querySelectorAll('.cep-customer')[i] && document.querySelectorAll('.cep-customer')[i].value !== '') document.querySelector('.cep-service').value = document.querySelectorAll('.cep-customer')[i].value
        if (document.querySelectorAll('.number-customer')[i] && document.querySelectorAll('.number-customer')[i].value !== '') document.querySelector('.number-service').value = document.querySelectorAll('.number-customer')[i].value
        if (document.querySelectorAll('.street-customer')[i] && document.querySelectorAll('.street-customer')[i].value !== '') document.querySelector('.street-service').value = document.querySelectorAll('.street-customer')[i].value
        if (document.querySelectorAll('.neighborhood-customer')[i] && document.querySelectorAll('.neighborhood-customer')[i].value !== '') document.querySelector('.district-service').value = document.querySelectorAll('.neighborhood-customer')[i].value
        if (document.querySelectorAll('.state-customer')[i] && document.querySelectorAll('.state-customer')[i].value !== '') document.querySelector('.state-service').value = document.querySelectorAll('.state-customer')[i].value
        if (document.querySelectorAll('.city-customer')[i] && document.querySelectorAll('.city-customer')[i].value !== '') document.querySelector('.city-service').value = document.querySelectorAll('.city-customer')[i].value
        setReplaceState(!replaceState)


        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressNumber}+${addressStreet}+${addressCity}+${addressState}+BR&key=AIzaSyAMjFIIlVgRO1yUsl7uLN0RXBMyDHMJUS8`, {
          method: 'GET',
        }).then(response => response.json())
          .then(data => {
            if (data.status === 'ZERO_RESULTS') {
              return
            }
            setLatitude(data.results[0]?.geometry.location.lat === -14.235004 ? -22.95245554364288 : data.results[0]?.geometry.location.lat)
            setLongitude(data.results[0]?.geometry.location.lng === -51.92528 ? -43.21047122854996 : data.results[0]?.geometry.location.lng)
          })
          
      
      }
    } else {

      document.querySelector('.cep-service').value = ''
      document.querySelector('.number-service').value = ''
      document.querySelector('.street-service').value = ''
      document.querySelector('.district-service').value = ''
      document.querySelector('.state-service').value = ''
      document.querySelector('.city-service').value = ''
      setReplaceState(!replaceState)
    }

    
  }
  //lembrar fazer useEffect para pegar os clientes dos clientes para não cadastrar o mesmo cliente duas vezes


  const [customer_of_customers, setCustomer_of_customers] = useState([])
  useEffect(() => {
    fetch('http://localhost:5000/api/customers_of_customers/list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => response.json())
      .then(data => {
        console.log('---->', data)
        setCustomer_of_customers(data)
      }).catch(err => {
        console.log(err)
      })
  }, [gab])

  const [img_rg_cnh, setImg_rg_cnh] = useState('')
  const [img_electricity_bill, setImg_electricity_bill] = useState('')
  const [img_contract_social, setImg_contract_social] = useState('')
  const [img_photos_breaker, setImg_photos_breaker] = useState('')
  const [img_energy_box_photos, setImg_energy_box_photos] = useState('')
  const [img_data_photovoltaic, setImg_data_photovoltaic] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const handleNewProject = async (data) => {
    setIsLoading(true)
    setGab(!gab)

    if (document.querySelector('.select-customer-type').value === 'pf') {


      const { name, rg, cpf, email, civil_state, nationality, profession, contact, cep_customer_of_customer, street_customer_of_customer, number_customer_of_customer, neighborhood_customer_of_customer, city_customer_of_customer, state_customer_of_customer, complement_customer_of_customer } = data

      const conferir = customer_of_customers?.filter(item => {
        console.log('customers_of_customers', item)
        console.log('cpf db', item.cpf_customers_of_customers)
        console.log('cpf', cpf)
        if (item.cpf_customers_of_customers === cpf && item.cpf !== '') {
          return item
        }
      })
      console.log('conferir', conferir)

      if (conferir.length === 0) {

        await fetch('http://localhost:5000/api/customers_of_customers/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, rg, cpf, email, civil_state, nationality, profession, contact, cep_customer_of_customer, street_customer_of_customer, number_customer_of_customer, neighborhood_customer_of_customer, city_customer_of_customer, state_customer_of_customer, complement_customer_of_customer, id_customer_integrator }),
        }).then(response => response.json())
          .then(data => {
            setGab(!gab)
            
    fetch('http://localhost:5000/api/customers_of_customers/list', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }).then(response => response.json())
              .then(data => {
                console.log('---->', data)
                setCustomer_of_customers(data)
              }).catch(err => {
                console.log(err)
              })

            console.log(data)
            setGab(!gab)
            if (data.message === 'Cliente cadastrado com sucesso') {

            }
          }).catch(err => {
            //  toast.error('Erro ao cadastrar cliente')
          })
      } else {
        console.log('conferir[0].id_customers_of_customers', conferir[0].id_customers_of_customers)
        await fetch(`http://localhost:5000/api/customers_of_customers/update/${conferir[0].id_customers_of_customers}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, rg, cpf, email, civil_state, nationality, profession, contact, cep_customer_of_customer, street_customer_of_customer, number_customer_of_customer, neighborhood_customer_of_customer, city_customer_of_customer, state_customer_of_customer, complement_customer_of_customer, id_customer_integrator }),
        }).then(response => response.json())
          .then(data => {
            setGab(!gab)
            
    fetch('http://localhost:5000/api/customers_of_customers/list', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }).then(response => response.json())
              .then(data => {
                console.log('---->', data)
                setCustomer_of_customers(data)
              }).catch(err => {
                console.log(err)
              })

            console.log(data)
            setGab(!gab)
            if (data.message === 'Cliente atualizado com sucesso') {

            }
          }).catch(err => {
            // toast.error('Erro ao atualizar cliente')
          })
      }

    }
    if (document.querySelector('.select-customer-type').value === 'pj') {
      const { cnpj, fantasy_name, corporate_name, email, contact, cep_customer_of_customer, street_customer_of_customer, number_customer_of_customer, neighborhood_customer_of_customer, city_customer_of_customer, state_customer_of_customer, complement_customer_of_customer, name_representative, rg_representative, cpf_representative, nationality_representative, profession_representative } = data
      console.log(cnpj, fantasy_name, corporate_name, email, contact, cep_customer_of_customer, street_customer_of_customer, number_customer_of_customer, neighborhood_customer_of_customer, city_customer_of_customer, state_customer_of_customer, complement_customer_of_customer, name_representative, rg_representative, cpf_representative, nationality_representative, profession_representative)
      const conferir = customer_of_customers?.filter(item => {
        if (item.cnpj_customers_of_customers === cnpj && item.cnpj !== '') {
          return item
        }
      })
      console.log('conferir', conferir)

      if (conferir.length === 0) {

        await fetch('http://localhost:5000/api/customers_of_customers/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cnpj, fantasy_name, corporate_name, email, contact, cep_customer_of_customer, street_customer_of_customer, number_customer_of_customer, neighborhood_customer_of_customer, city_customer_of_customer, state_customer_of_customer, complement_customer_of_customer, name_representative, rg_representative, cpf_representative, nationality_representative, profession_representative, id_customer_integrator }),
        }).then(response => response.json())
          .then(data => {
            console.log(data)
            setGab(!gab)
           
    fetch('http://localhost:5000/api/customers_of_customers/list', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }).then(response => response.json())
              .then(data => {
              
                console.log('---->', data)
                setCustomer_of_customers(data)
              }).catch(err => {
                console.log(err)
              })


            if (data.message === 'Cliente cadastrado com sucesso') {

            }
          }).catch(err => {
            //toast.error('Erro ao cadastrar cliente')
          })
      } else {
        await fetch(`http://localhost:5000/api/customers_of_customers/update/${conferir[0].id_customers_of_customers}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cnpj, fantasy_name, corporate_name, email, contact, cep_customer_of_customer, street_customer_of_customer, number_customer_of_customer, neighborhood_customer_of_customer, city_customer_of_customer, state_customer_of_customer, complement_customer_of_customer, name_representative, rg_representative, cpf_representative, nationality_representative, profession_representative, id_customer_integrator }),
        }).then(response => response.json())
        .then(data => {
            setGab(!gab)
            
    fetch('http://localhost:5000/api/customers_of_customers/list', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }).then(response => response.json())
              .then(data => {
                console.log('---->', data)
                setCustomer_of_customers(data)
              }).catch(err => {
                console.log(err)
              })

            console.log(data)
            if (data.message === 'Cliente atualizado com sucesso') {

            }
          }).catch(err => {
            //toast.error('Erro ao atualizar cliente')
          })
      }
    }


    setGab(!gab)
    const { project_type, service_type, modality_type, power_rede, disjuntor, number_uc, project_obs, branch_type, contracted_demand, data_input_inverter, data_input_module, data_rateio, } = data

    const { cep, street,number, district, city, state, lat, log } = data
    const status = 'Em andamento'
    const date_demand = new Date()
    const date_conclusion = '---'
    const id_customer_of_customers = customer_of_customers?.filter(item => {if (item.cpf_customers_of_customers === data.cpf || item.cnpj_customers_of_customers === data.cnpj) return item})[0].id_customers_of_customers
   console.log('id_customer_of_customers', id_customer_of_customers)

    let test_id = customer_of_customers[customer_of_customers.length-1].id_customers_of_customers
    console.log('test_id', test_id)
    await fetch('http://localhost:5000/api/demand_project_solar/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ project_type, service_type, modality_type, power_rede, disjuntor, number_uc, project_obs, cep, street,number, district, city, state, lat, log, rg_cnh: 'rg / cnh', electricity_bill: 'conta de luz', contract_social: 'contrato social', status, date_demand, date_conclusion, id_customer_integrator, id_customer_of_customers, branch_type, contracted_demand, photos_breake: '---', energy_box_photos: '---', data_input_inverter, data_input_module, data_photovoltaic: '---', data_rateio,doc_proxy_demand:'---',info_proxy_demand:'send',msg_proxy_demand:'---' }),

    }).then(response => response.json())
      .then(data => {
        const id = data.id
        console.log(data)
        if (img_rg_cnh) {
          fetch(`http://localhost:5000/api/demand_project_solar/uploadImageRgCnh/${id}`, {
            method: 'POST',
            body: img_rg_cnh
          }).then(response => response.json())
            .then(data => {
              console.log(data)
              setGab(!gab)
              if (data.message === 'Image uploaded successfully') {

              }
            }).catch(err => {
              toast.error('Erro ao enviar RG/CNH')
            })
        }
        if (img_electricity_bill) {
          fetch(`http://localhost:5000/api/demand_project_solar/uploadImageElectricityBill/${id}`, {
            method: 'POST',
            body: img_electricity_bill
          }).then(response => response.json())
            .then(data => {
              console.log(data)
              setGab(!gab)
              if (data.message === 'Image uploaded successfully') {

              }
            }).catch(err => {
              toast.error('Erro ao enviar conta de luz')
            })
        }
        if (img_contract_social) {
          fetch(`http://localhost:5000/api/demand_project_solar/uploadImageContractSocial/${id}`, {
            method: 'POST',
            body: img_contract_social
          }).then(response => response.json())
            .then(data => {
              console.log(data)
              setGab(!gab)
              if (data.message === 'Image uploaded successfully') {

              }
            }).catch(err => {
              toast.error('Erro ao enviar contrato social')
            })
        }
        if (img_photos_breaker) {
          fetch(`http://localhost:5000/api/demand_project_solar/uploadImagePhotosBreaker/${id}`, {
            method: 'POST',
            body: img_photos_breaker
          }).then(response => response.json())
            .then(data => {
              console.log(data)
              setGab(!gab)
              if (data.message === 'Image uploaded successfully') {

              }
            }).catch(err => {
              toast.error('Erro ao enviar fotos do disjuntor')
            })
        }
        if (img_energy_box_photos) {
          fetch(`http://localhost:5000/api/demand_project_solar/uploadImageEnergyBoxPhotos/${id}`, {
            method: 'POST',
            body: img_energy_box_photos
          }).then(response => response.json())
            .then(data => {
              console.log(data)
              setGab(!gab)
              if (data.message === 'Image uploaded successfully') {

              }
            }).catch(err => {
              toast.error('Erro ao enviar fotos da caixa de energia')
            })
        }
        if (img_data_photovoltaic) {
          fetch(`http://localhost:5000/api/demand_project_solar/uploadImageDataPhotovoltaic/${id}`, {
            method: 'POST',
            body: img_data_photovoltaic
          }).then(response => response.json())
            .then(data => {
              console.log(data)
              setGab(!gab)
              if (data.message === 'Image uploaded successfully') {

              }
            }).catch(err => {
              toast.error('Erro ao enviar arquivo de dados fotovoltaicos')
            })
        }


        if (data.message === 'Demand project solar created successfully') {
          setIsLoading(false)
          toast.success('Pedido enviado com sucesso!')

          setGab(!gab)
          clearFields()
        }
      }).catch(err => {
        setIsLoading(false)
        toast.error('Erro ao cadastrar projeto')
      })
  }




  const clearFields = () => {

    document.querySelector('#PF-InputName').value = ''
    document.querySelector('#PF-InputRG').value = ''
    document.querySelector('#PF-InputCPF').value = ''
    document.querySelector('#PF-InputEmail').value = ''
    document.querySelector('#PF-InputCivil_state').value = 'solteiro'
    document.querySelector('#PF-InputNationality').value = ''
    document.querySelector('#PF-InputProfession').value = ''
    document.querySelector('#PF-InputContact').value = ''
    document.querySelector('#PF-InputCep').value = ''
    document.querySelector('#PF-InputStreet').value = ''
    document.querySelector('#PF-InputNumber').value = ''
    document.querySelector('#PF-InputNeighborhood').value = ''
    document.querySelector('#PF-InputCity').value = ''
    document.querySelector('#PF-InputState').value = ''
    document.querySelector('#PF-InputComplement').value = ''

    document.querySelector('#PJ-InputCNPJ').value = ''
    document.querySelector('#PJ-InputFantasyName').value = ''
    document.querySelector('#PJ-InputSocialReason').value = ''
    document.querySelector('#PJ-InputEmail').value = ''
    document.querySelector('#PJ-InputContact').value = ''
    document.querySelector('#PJ-InputCep').value = ''
    document.querySelector('#PJ-InputStreet').value = ''
    document.querySelector('#PJ-InputNumber').value = ''
    document.querySelector('#PJ-InputNeighborhood').value = ''
    document.querySelector('#PJ-InputCity').value = ''
    document.querySelector('#PJ-InputState').value = ''
    document.querySelector('#PJ-InputComplement').value = ''
    document.querySelector('#PJ-InputNameRepresentative').value = ''
    document.querySelector('#PJ-InputRGRepresentative').value = ''
    document.querySelector('#PJ-InputCPFRepresentative').value = ''
    document.querySelector('#PJ-InputNationalityRepresentative').value = ''
    document.querySelector('#PJ-InputProfessionRepresentative').value = ''

    document.querySelector('#project-type').value = 'b1_residencial'
    document.querySelector('#service-type').value = 'monofasico'
    document.querySelector('#branch-type').value = 'aereo'
    document.querySelector('#modality-type').value = 'geracao_local'
    document.querySelector('#power-rede').value = '220V'
    document.querySelector('#disjuntor').value = '0'
    if (document.querySelector('#project-type').value === 'a4_horaria_verde') document.querySelector('#contracted-demand').value = ''
    if (document.querySelector('#project-type').value === 'a4_horaria_azul') document.querySelector('#contracted-demand').value = ''
    document.querySelector('#number-uc').value = ''
    document.querySelector('#project-obs').value = ''


    document.querySelector('.cep-service').value = ''
    document.querySelector('.number-service').value = ''
    document.querySelector('.street-service').value = ''
    document.querySelector('.district-service').value = ''
    document.querySelector('.state-service').value = ''
    document.querySelector('.city-service').value = ''





    setInputModulos([{ brand: '', model: '', qtd: '' }])
    setInputInversores([{ brand: '', model: '', qtd: '' }])

    document.querySelector('#brand-module0').value = ''
    document.querySelector('#model-module0').value = ''
    document.querySelector('#qtd-module0').value = ''

    document.querySelector('#brand-inverter0').value = ''
    document.querySelector('#model-inverter0').value = ''
    document.querySelector('#qtd-inverter0').value = ''


    setLatitude(-3.73184765302693)
    setLongitude(-38.50980721912067)
    setImg_rg_cnh('')
    setImg_electricity_bill('')
    setImg_contract_social('')
    setImg_photos_breaker('')
    setImg_energy_box_photos('')
    setImg_data_photovoltaic('')
    setLatitudeClick('')
    setLongitudeClick('')
    inputModulos.map((item, index) => {
      document.querySelector(`#brand-module${index}`).value = ''
      document.querySelector(`#model-module${index}`).value = ''
      document.querySelector(`#qtd-module${index}`).value = ''
    })
    inputInversores.map((item, index) => {
      document.querySelector(`#brand-inverter${index}`).value = ''
      document.querySelector(`#model-inverter${index}`).value = ''
      document.querySelector(`#qtd-inverter${index}`).value = ''
    })
    setRateio1({ uc: '', percent: '' })
    setRateio2({ uc: '', percent: '' })
    setRateio3({ uc: '', percent: '' })
    setRateio4({ uc: '', percent: '' })
    setRateio5({ uc: '', percent: '' })
    setRateio6({ uc: '', percent: '' })
    setRateio7({ uc: '', percent: '' })
    setRateio8({ uc: '', percent: '' })
    setRateio9({ uc: '', percent: '' })
    setRateio10({ uc: '', percent: '' })
    setRateio11({ uc: '', percent: '' })
    setRateio12({ uc: '', percent: '' })
    setRateio13({ uc: '', percent: '' })
    setRateio14({ uc: '', percent: '' })
    for (let i = 1; i < 15; i++) {
      document.querySelector(`.rateio${i}-uc`).value = ''
      document.querySelector(`.rateio${i}-percent`).value = ''
      document.querySelector(`.percentR${i}`).value = ''
    }

    document.querySelector('.address-customer-checkbox').checked = false

  }

  const [rateio1, setRateio1] = useState(false)
  const [rateio2, setRateio2] = useState(false)
  const [rateio3, setRateio3] = useState(false)
  const [rateio4, setRateio4] = useState(false)
  const [rateio5, setRateio5] = useState(false)
  const [rateio6, setRateio6] = useState(false)
  const [rateio7, setRateio7] = useState(false)
  const [rateio8, setRateio8] = useState(false)
  const [rateio9, setRateio9] = useState(false)
  const [rateio10, setRateio10] = useState(false)
  const [rateio11, setRateio11] = useState(false)
  const [rateio12, setRateio12] = useState(false)
  const [rateio13, setRateio13] = useState(false)
  const [rateio14, setRateio14] = useState(false)

  const handlePdfDownloadRateio = () => {
    setIsLoading(true)
    const conteudo = document.querySelector('.body-rateio')
    const pdf = conteudo
    const opt = {
      margin: 0,
      filename: `Rateio - Cliente ${document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputName')?.value : document.querySelector('#PJ-InputSocialReason')?.value}.pdf`,
      image: { type: 'png', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }
    html2pdf().from(pdf).set(opt).save().then(() => {
      setIsLoading(false)
      toast.success('Rateio gerado com sucesso', { autoClose: 2000 })
    }).catch((error) => {
      setIsLoading(false)
      toast.error('Erro ao gerar Rateio', { autoClose: 2000 })
    })
  }





  const [percentTotal, setPercentTotal] = useState(0)
  const handlePocentagemRateio = () => {

    // let somaTotal = (Math.round(parseInt(rateio1?.percent)) ? Math.round(parseInt(rateio1?.percent)) : 0) + (Math.round(parseInt(rateio2?.percent)) ? Math.round(parseInt(rateio2?.percent)) : 0) + (Math.round(parseInt(rateio3?.percent)) ? Math.round(parseInt(rateio3?.percent)) : 0) + (Math.round(parseInt(rateio4?.percent)) ? Math.round(parseInt(rateio4?.percent)) : 0) + (Math.round(parseInt(rateio5?.percent)) ? Math.round(parseInt(rateio5?.percent)) : 0) + (Math.round(parseInt(rateio6?.percent)) ? Math.round(parseInt(rateio6?.percent)) : 0) + (Math.round(parseInt(rateio7?.percent)) ? Math.round(parseInt(rateio7?.percent)) : 0) + (Math.round(parseInt(rateio8?.percent)) ? Math.round(parseInt(rateio8?.percent)) : 0) + (Math.round(parseInt(rateio9?.percent)) ? Math.round(parseInt(rateio9?.percent)) : 0) + (Math.round(parseInt(rateio10?.percent)) ? Math.round(parseInt(rateio10?.percent)) : 0) + (Math.round(parseInt(rateio11?.percent)) ? Math.round(parseInt(rateio11?.percent)) : 0) + (Math.round(parseInt(rateio12?.percent)) ? Math.round(parseInt(rateio12?.percent)) : 0) + (Math.round(parseInt(rateio13?.percent)) ? Math.round(parseInt(rateio13?.percent)) : 0) + (Math.round(parseInt(rateio14?.percent)) ? Math.round(parseInt(rateio14?.percent)) : 0) 


    let somaTotal = 0
    for (let i = 1; i <= 14; i++) {
      if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') {
        somaTotal += parseInt(document.querySelector(`.rateio${i}-percent`)?.value)
      }
    }

    console.log('somaTotal ------->', somaTotal)
    let percentTotal = 0
    for (let i = 1; i <= 14; i++) {
      if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') {
        percentTotal += Math.round(parseInt(document.querySelector(`.rateio${i}-percent`)?.value) / somaTotal * 100)
        console.log(`percent ${i} ------->`, Math.round(parseInt(document.querySelector(`.rateio${i}-percent`)?.value) / somaTotal * 100))
      }
    }
    console.log('percentTotal ------->', percentTotal)




    if (percentTotal > 100) {
      toast.info('Ajustes de valores realizado!')

      /*      percent1 = (parseInt(rateio1?.percent) - (percentTotal - 100))
             somaTotal = percent1 + (Math.round(parseInt(rateio2?.percent)) ? Math.round(parseInt(rateio2?.percent)) : 0) + (Math.round(parseInt(rateio3?.percent)) ? Math.round(parseInt(rateio3?.percent)) : 0) + (Math.round(parseInt(rateio4?.percent)) ? Math.round(parseInt(rateio4?.percent)) : 0) + (Math.round(parseInt(rateio5?.percent)) ? Math.round(parseInt(rateio5?.percent)) : 0) + (Math.round(parseInt(rateio6?.percent)) ? Math.round(parseInt(rateio6?.percent)) : 0) + (Math.round(parseInt(rateio7?.percent)) ? Math.round(parseInt(rateio7?.percent)) : 0) + (Math.round(parseInt(rateio8?.percent)) ? Math.round(parseInt(rateio8?.percent)) : 0) + (Math.round(parseInt(rateio9?.percent)) ? Math.round(parseInt(rateio9?.percent)) : 0) + (Math.round(parseInt(rateio10?.percent)) ? Math.round(parseInt(rateio10?.percent)) : 0) + (Math.round(parseInt(rateio11?.percent)) ? Math.round(parseInt(rateio11?.percent)) : 0) + (Math.round(parseInt(rateio12?.percent)) ? Math.round(parseInt(rateio12?.percent)) : 0) + (Math.round(parseInt(rateio13?.percent)) ? Math.round(parseInt(rateio13?.percent)) : 0) + (Math.round(parseInt(rateio14?.percent)) ? Math.round(parseInt(rateio14?.percent)) : 0) 
            percent1 = Math.round((percent1 / somaTotal) * 100)*/
      let cont = 0
      for (let i = 1; i <= 14; i++) {
        if (document.querySelector(`.rateio${i}-percent`)?.value !== '') cont++
        console.log('cont ---->', document.querySelector(`.rateio${i}-percent`)?.value)
      }
      let newvalue = Math.round(((percentTotal - 100))) / cont
      console.log('newvalue ---->', newvalue)
      let cont2 = 0
      for (let i = 1; i <= cont2; i++) {
        if (Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) - newvalue) <= 0) {
          cont2++
        }
      }

      let contfinal = cont > cont2 ? cont - cont2 : cont2 - cont
      console.log('cont ---->', cont)
      console.log('cont2 ---->', cont2)
      console.log('contfinal ---->', contfinal)

      let sub = Math.round(((percentTotal - 100))) / contfinal
      console.log('sub ---->', sub)
      for (let i = 1; i <= contfinal; i++) {
        if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') {

          if (Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) - sub) > 0) {
            console.log('valor sub ---->', Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) - sub))
            console.log('valor ---->', parseInt(document.querySelector(`.rateio${i}-percent`).value))
            let value = Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) - sub)
            document.querySelector(`.rateio${i}-percent`).value = value


          }
        }
      }



      setRateio1({ ...rateio1, percent: Math.round((parseInt(rateio1?.percent) - (percentTotal - 100))) })


    }

    if (percentTotal < 100) {
      toast.info('ajustes de valores')
      console.log('aqui +++++')
      setRateio1({ ...rateio1, percent: Math.round((parseInt(rateio1?.percent) + (100 - percentTotal))) })
      let cont = 0
      for (let i = 1; i <= 14; i++) {
        if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') cont++
        console.log('cont ---->', document.querySelector(`.rateio${i}-percent`)?.value)
      }
      let newvalue = Math.round((parseInt(rateio1?.percent) + (100 - percentTotal))) / cont
      console.log('newvalue ---->', newvalue)
      for (let i = 1; i <= cont; i++) {
        if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') {
          console.log('valor ++++>', parseInt(document.querySelector(`.rateio${i}-percent`).value))
          console.log('valor soma ++++>', Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) + newvalue))
          let value = Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) + newvalue)
          document.querySelector(`.rateio${i}-percent`).value = value

        }
      }
    }

    for (let i = 1; i <= 14; i++) {
      if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') {
        percentTotal += Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) / somaTotal * 100)
        console.log(`percent ${i} ------->`, Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) / somaTotal * 100))
      }
    }
    console.log('percentTotal ------->', percentTotal)


    somaTotal = 0
    for (let i = 1; i <= 14; i++) {
      if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') {
        somaTotal += parseInt(document.querySelector(`.rateio${i}-percent`).value)
      }
    }
    console.log('somaTotal ------->', somaTotal)
    percentTotal = 0
    for (let i = 1; i <= 14; i++) {
      if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') {
        percentTotal += Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) / somaTotal * 100)
      }
    }
    console.log('percentTotal ------->', percentTotal)


    if (percentTotal > 100) {
      if (document.querySelector(`.rateio${1}-percent`) && document.querySelector(`.rateio${1}-percent`)?.value - (percentTotal - 100) > 0) {
        document.querySelector(`.rateio${1}-percent`).value = document.querySelector(`.rateio${1}-percent`).value - (percentTotal - 100)
      }
    }
    if (percentTotal < 100) {
      if (document.querySelector(`.rateio${1}-percent`) && document.querySelector(`.rateio${1}-percent`)?.value + (100 - percentTotal) > 0) {
        document.querySelector(`.rateio${1}-percent`).value = document.querySelector(`.rateio${1}-percent`).value + (100 - percentTotal)
      }
    }

    console.log('---')

    somaTotal = 0
    for (let i = 1; i <= 14; i++) {
      if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') {
        somaTotal += parseInt(document.querySelector(`.rateio${i}-percent`).value)

      }
    }
    console.log('somaTotal ------->', somaTotal)
    percentTotal = 0
    for (let i = 1; i <= 14; i++) {
      if (document.querySelector(`.rateio${i}-percent`) && document.querySelector(`.rateio${i}-percent`)?.value !== '') {
        percentTotal += Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) / somaTotal * 100)
        //document.querySelector(`.rateio${i}Per`).innerHTML = Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value)/somaTotal * 100)
        document.querySelector(`.percentR${i}`).value = `${Math.round(parseInt(document.querySelector(`.rateio${i}-percent`).value) / somaTotal * 100)}%`

      }
    }
    setPercentTotal(percentTotal)

    console.log('percentTotal ------->', percentTotal)

    return true

  }


  const handlePdfDownloadPro = () => {
    setIsLoading(true)


    const conteudo = document.querySelector('.body-procuracao')
    const pdf = conteudo
    const opt = {
      margin: 0,
      filename: `Procuração Particular - ${document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputName')?.value : document.querySelector('#PJ-InputSocialReason')?.value}.pdf`,
      image: { type: 'png', quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }
    html2pdf().from(pdf).set(opt).save().then(() => {
      setIsLoading(false)
      toast.success('Procuração Particular gerada com sucesso', { autoClose: 2000 })
    }).catch((error) => {
      setIsLoading(false)
      toast.error('Erro ao gerar Procuração Particular', { autoClose: 2000 })
    })
    document.querySelector('.procuracao').style.display = 'none'
  }


  const handleDateValid = () => {
    var data = new Date()
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (dia < 10) {
      dia = `0${dia}`
    }
    if (mes === 1) {
      mes = 'Janeiro'
    }
    if (mes === 2) {
      mes = 'Fevereiro'
    }
    if (mes === 3) {
      mes = 'Março'
    }
    if (mes === 4) {
      mes = 'Abril'
    }
    if (mes === 5) {
      mes = 'Maio'
    }
    if (mes === 6) {
      mes = 'Junho'
    }
    if (mes === 7) {
      mes = 'Julho'
    }
    if (mes === 8) {
      mes = 'Agosto'
    }
    if (mes === 9) {
      mes = 'Setembro'
    }
    if (mes === 10) {
      mes = 'Outubro'
    }
    if (mes === 11) {
      mes = 'Novembro'
    }
    if (mes === 12) {
      mes = 'Dezembro'
    }
    var data = `${dia} de ${mes} de ${ano + 1}`
    return data
  }

  const handleDate = () => {
    var data = new Date()
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (dia < 10) {
      dia = `0${dia}`
    }
    if (mes === 1) {
      mes = 'Janeiro'
    }
    if (mes === 2) {
      mes = 'Fevereiro'
    }
    if (mes === 3) {
      mes = 'Março'
    }
    if (mes === 4) {
      mes = 'Abril'
    }
    if (mes === 5) {
      mes = 'Maio'
    }
    if (mes === 6) {
      mes = 'Junho'
    }
    if (mes === 7) {
      mes = 'Julho'
    }
    if (mes === 8) {
      mes = 'Agosto'
    }
    if (mes === 9) {
      mes = 'Setembro'
    }
    if (mes === 10) {
      mes = 'Outubro'
    }
    if (mes === 11) {
      mes = 'Novembro'
    }
    if (mes === 12) {
      mes = 'Dezembro'
    }

    var data = `${dia} de ${mes} de ${ano}`
    return data
  }

  const [inputModulos, setInputModulos] = useState([{ brand: '', model: '', qtd: '' }])
  const [inputInversores, setInputInversores] = useState([{ brand: '', model: '', qtd: '' }])

  const novoInputModulos = () => {
    setInputModulos([...inputModulos, { brand: '', model: '', qtd: '' }])
  }
  const novoInputInversores = () => {
    setInputInversores([...inputInversores, { brand: '', model: '', qtd: '' }])
  }
  const removeInputModulos = (index) => {
    const values = [...inputModulos]
    values.splice(index, 1)
    setInputModulos(values)
  }
  const removeInputInversores = (index) => {
    const values = [...inputInversores]
    values.splice(index, 1)
    setInputInversores(values)
  }



  return (
    <Sidebar>
      {isLoading && <Loading />}
      <div className='project-solar'>
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          height={50}
        />
        <div className="header-project">

          <div className='header-img-content'>
            <img src="/logosolprojetos.png" style={{
              width: '70px',
              height: '70px',
              borderRadius: '8%',
            }} alt="" />
            <div>
              <h3>SOL PROJETOS</h3>
              <p>Soluções em projetos fotovoltaicos.</p>
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingRight: '100px' }}>

            <h1>FORMULÁRIO NOVO PROJETO SOLAR</h1>
            <button className='bt-new-project' onClick={() => {

              if (document.querySelector('.select-customer-type').value === 'pf') {


                if (!document.getElementById('PF-InputName').value) {
                  document.getElementById('PF-InputName').focus()
                  document.getElementById('PF-InputName').style.border = '2px solid red'
                  toast.error('Campo nome vazio')
                  return
                } else {
                  document.getElementById('PF-InputName').style.border = '1px solid white'
                }
                if (!document.getElementById('PF-InputRG').value) {
                  document.getElementById('PF-InputRG').focus()
                  document.getElementById('PF-InputRG').style.border = '2px solid red'
                  toast.error('Campo RG vazio')
                  return
                } else {
                  document.getElementById('PF-InputRG').style.border = '1px solid white'
                }
                if (document.getElementById('PF-InputRG').value < 14) {
                  document.getElementById('PF-InputRG').focus()
                  document.getElementById('PF-InputRG').style.border = '2px solid red'
                  toast.error('RG inválido')
                  return
                } else {
                  document.getElementById('PF-InputRG').style.border = '1px solid white'
                }

                if (document.getElementById('PF-InputCPF').value < 14) {
                  document.getElementById('PF-InputCPF').focus()
                  document.getElementById('PF-InputCPF').style.border = '2px solid red'
                  toast.error('CPF inválido')
                  return
                } else {
                  document.getElementById('PF-InputCPF').style.border = '1px solid white'
                }

                if (!document.getElementById('PF-InputCPF').value) {
                  document.getElementById('PF-InputCPF').focus()
                  document.getElementById('PF-InputCPF').style.border = '2px solid red'
                  toast.error('Campo CPF vazio')
                  return
                } else {
                  document.getElementById('PF-InputCPF').style.border = '1px solid white'
                }
                if (document.getElementById('PF-InputCPF').value.length < 14) {
                  document.getElementById('PF-InputCPF').focus()
                  document.getElementById('PF-InputCPF').style.border = '2px solid red'
                  toast.error('CPF inválido')
                  return
                } else {
                  document.getElementById('PF-InputCPF').style.border = '1px solid white'
                }
                if (!document.getElementById('PF-InputEmail').value) {
                  document.getElementById('PF-InputEmail').focus()
                  document.getElementById('PF-InputEmail').style.border = '2px solid red'
                  toast.error('Campo email vazio')

                  return
                } else {
                  document.getElementById('PF-InputEmail').style.border = '1px solid white'

                }
                if (!validator.isEmail(document.getElementById('PF-InputEmail').value)) {
                  document.getElementById('PF-InputEmail').focus()
                  document.getElementById('PF-InputEmail').style.border = '2px solid red'
                  toast.error('Email inválido')

                  return
                } else {
                  document.getElementById('PF-InputEmail').style.border = '1px solid white'

                }
                if (!document.getElementById('PF-InputCivil_state').value) {
                  document.getElementById('PF-InputCivil_state').focus()
                  document.getElementById('PF-InputCivil_state').style.border = '2px solid red'
                  toast.error('Campo estado civil vazio')

                  return
                } else {
                  document.getElementById('PF-InputCivil_state').style.border = '1px solid white'

                }
                if (!document.getElementById('PF-InputNationality').value) {
                  document.getElementById('PF-InputNationality').focus()
                  document.getElementById('PF-InputNationality').style.border = '2px solid red'
                  toast.error('Campo nacionalidade vazio')

                  return
                } else {
                  document.getElementById('PF-InputNationality').style.border = '1px solid white'

                }
                if (!document.getElementById('PF-InputProfession').value) {
                  document.getElementById('PF-InputProfession').focus()
                  document.getElementById('PF-InputProfession').style.border = '2px solid red'
                  toast.error('Campo profissão vazio')

                  return
                } else {
                  document.getElementById('PF-InputProfession').style.border = '1px solid white'

                }
                if (!document.getElementById('PF-InputContact').value) {
                  document.getElementById('PF-InputContact').focus()
                  document.getElementById('PF-InputContact').style.border = '2px solid red'

                  toast.error('Campo contato vazio')

                  return
                } else {
                  document.getElementById('PF-InputContact').style.border = '1px solid white'
                }
                if (!document.getElementById('PF-InputCep').value) {
                  document.getElementById('PF-InputCep').focus()
                  document.getElementById('PF-InputCep').style.border = '2px solid red'

                  toast.error('Campo CEP vazio')
                  return
                } else {
                  document.getElementById('PF-InputCep').style.border = '1px solid white'

                }
                if (document.getElementById('PF-InputCep').value.length < 9) {
                  document.getElementById('PF-InputCep').focus()
                  document.getElementById('PF-InputCep').style.border = '2px solid red'

                  toast.error('CEP inválido')

                  return
                } else {
                  document.getElementById('PF-InputCep').style.border = '1px solid white'

                }

                if (!document.getElementById('PF-InputStreet').value) {
                  document.getElementById('PF-InputStreet').focus()
                  document.getElementById('PF-InputStreet').style.border = '2px solid red'

                  toast.error('Campo rua vazio')

                  return
                } else {
                  document.getElementById('PF-InputStreet').style.border = '1px solid white'

                }

                if (!document.getElementById('PF-InputNeighborhood').value) {
                  document.getElementById('PF-InputNeighborhood').focus()
                  document.getElementById('PF-InputNeighborhood').style.border = '2px solid red'

                  toast.error('Campo bairro vazio')

                  return
                } else {
                  document.getElementById('PF-InputNeighborhood').style.border = '1px solid white'

                }
                if (!document.getElementById('PF-InputCity').value) {
                  document.getElementById('PF-InputCity').focus()
                  document.getElementById('PF-InputCity').style.border = '2px solid red'
                  toast.error('Campo cidade vazio')


                  return
                } else {
                  document.getElementById('PF-InputCity').style.border = '1px solid white'

                }
                if (!document.getElementById('PF-InputState').value) {
                  document.getElementById('PF-InputState').focus()
                  document.getElementById('PF-InputState').style.border = '2px solid red'
                  toast.error('Campo estado vazio')

                  return
                } else {
                  document.getElementById('PF-InputState').style.border = '1px solid white'

                }


                /* HandleNewCustome({
          name: document.getElementById('PF-InputName').value,
          rg: document.getElementById('PF-InputRG').value,
          cpf: document.getElementById('PF-InputCPF').value,
          email: document.getElementById('PF-InputEmail').value,
          civil_state: document.getElementById('PF-InputCivil_state').value,
          national: document.getElementById('PF-InputNationality').value,
          profession: document.getElementById('PF-InputProfession').value,
          contact: document.getElementById('PF-InputContact').value,
          cep: document.getElementById('PF-InputCep').value,
          street: document.getElementById('PF-InputStreet').value,
          number: document.getElementById('PF-InputNumber').value ? document.getElementById('PF-InputNumber').value : 'Sem número',
          neighborhood: document.getElementById('PF-InputNeighborhood').value,
          city: document.getElementById('PF-InputCity').value,
          state: document.getElementById('PF-InputState').value,
          complement: document.getElementById('PF-InputComplement').value ? document.getElementById('PF-InputComplement').value : 'Sem complemento',
          password: document.getElementById('PF-InputPassword').value,
  
        })*/

              }




              if (document.querySelector('.select-customer-type').value === 'pj') {
                if (!document.getElementById('PJ-InputCNPJ').value) {
                  document.getElementById('PJ-InputCNPJ').focus()
                  document.getElementById('PJ-InputCNPJ').style.border = '2px solid red'
                  toast.error('Campo CNPJ vazio')
                  return
                } else {
                  document.getElementById('PJ-InputCNPJ').style.border = '1px solid white'
                }
                if (document.getElementById('PJ-InputCNPJ').value.length < 18) {
                  document.getElementById('PJ-InputCNPJ').focus()
                  document.getElementById('PJ-InputCNPJ').style.border = '2px solid red'
                  toast.error('CNPJ inválido')
                  return
                } else {
                  document.getElementById('PJ-InputCNPJ').style.border = '1px solid white'
                }

              
                if (!document.getElementById('PJ-InputSocialReason').value) {
                  document.getElementById('PJ-InputSocialReason').focus()
                  document.getElementById('PJ-InputSocialReason').style.border = '2px solid red'
                  toast.error('Campo razão social vazio')
                  return
                } else {
                  document.getElementById('PJ-InputSocialReason').style.border = '1px solid white'
                }


                if (!document.getElementById('PJ-InputEmail').value) {
                  document.getElementById('PJ-InputEmail').focus()
                  document.getElementById('PJ-InputEmail').style.border = '2px solid red'
                  toast.error('Campo email vazio')
                  return
                }
                else {
                  document.getElementById('PJ-InputEmail').style.border = '1px solid white'
                }
                if (!validator.isEmail(document.getElementById('PJ-InputEmail').value)) {
                  document.getElementById('PJ-InputEmail').focus()
                  document.getElementById('PJ-InputEmail').style.border = '2px solid red'
                  toast.error('Email inválido')
                  return
                } else {
                  document.getElementById('PJ-InputEmail').style.border = '1px solid white'
                }
                if (!document.getElementById('PJ-InputContact').value) {
                  document.getElementById('PJ-InputContact').focus()
                  document.getElementById('PJ-InputContact').style.border = '2px solid red'


                  toast.error('Campo contato vazio')
                  return
                } else {
                  document.getElementById('PJ-InputContact').style.border = '1px solid white'
                }


                if (!document.getElementById('PJ-InputCep').value) {
                  document.getElementById('PJ-InputCep').focus()
                  document.getElementById('PJ-InputCep').style.border = '2px solid red'
                  toast.error('Campo CEP vazio')
                  return
                } else {
                  document.getElementById('PJ-InputCep').style.border = '1px solid white'
                }
                if (document.getElementById('PJ-InputCep').value.length < 9) {
                  document.getElementById('PJ-InputCep').focus()
                  document.getElementById('PJ-InputCep').style.border = '2px solid red'
                  toast.error('CEP inválido')
                  return
                } else {
                  document.getElementById('PJ-InputCep').style.border = '1px solid white'
                }
                if (!document.getElementById('PJ-InputStreet').value) {
                  document.getElementById('PJ-InputStreet').focus()
                  document.getElementById('PJ-InputStreet').style.border = '2px solid red'
                  toast.error('Campo rua vazio')
                  return
                } else {
                  document.getElementById('PJ-InputStreet').style.border = '1px solid white'
                }


                if (!document.getElementById('PJ-InputNeighborhood').value) {
                  document.getElementById('PJ-InputNeighborhood').focus()
                  document.getElementById('PJ-InputNeighborhood').style.border = '2px solid red'
                  toast.error('Campo bairro vazio')
                  return
                } else {
                  document.getElementById('PJ-InputNeighborhood').style.border = '1px solid white'
                }
                if (!document.getElementById('PJ-InputCity').value) {
                  document.getElementById('PJ-InputCity').focus()
                  document.getElementById('PJ-InputCity').style.border = '2px solid red'
                  toast.error('Campo cidade vazio')
                  return
                } else {
                  document.getElementById('PJ-InputCity').style.border = '1px solid white'
                }
                if (!document.getElementById('PJ-InputState').value) {
                  document.getElementById('PJ-InputState').focus()
                  document.getElementById('PJ-InputState').style.border = '2px solid red'
                  toast.error('Campo estado vazio')
                  return
                } else {
                  document.getElementById('PJ-InputState').style.border = '1px solid white'
                }
                if (!document.getElementById('PJ-InputNameRepresentative').value) {
                  document.getElementById('PJ-InputNameRepresentative').focus()
                  document.getElementById('PJ-InputNameRepresentative').style.border = '2px solid red'
                  toast.error('Campo nome representante vazio')
                  return
                } else {
                  document.getElementById('PJ-InputNameRepresentative').style.border = '1px solid white'
                }
                if (!document.getElementById('PJ-InputRGRepresentative').value) {
                  document.getElementById('PJ-InputRGRepresentative').focus()
                  document.getElementById('PJ-InputRGRepresentative').style.border = '2px solid red'
                  toast.error('Campo RG representante vazio')
                  return
                } else {
                  document.getElementById('PJ-InputRGRepresentative').style.border = '1px solid white'
                }
                if (!document.getElementById('PJ-InputCPFRepresentative').value) {
                  document.getElementById('PJ-InputCPFRepresentative').focus()
                  document.getElementById('PJ-InputCPFRepresentative').style.border = '2px solid red'
                  toast.error('Campo CPF representante vazio')
                  return
                } else {
                  document.getElementById('PJ-InputCPFRepresentative').style.border = '1px solid white'
                }
                if (!document.getElementById('PJ-InputNationalityRepresentative').value) {
                  document.getElementById('PJ-InputNationalityRepresentative').focus()
                  document.getElementById('PJ-InputNationalityRepresentative').style.border = '2px solid red'




                  toast.error('Campo nacionalidade representante vazio')
                  return
                } else {
                  document.getElementById('PJ-InputNationalityRepresentative').style.border = '1px solid white'
                }
                if (!document.getElementById('PJ-InputProfessionRepresentative').value) {
                  document.getElementById('PJ-InputProfessionRepresentative').focus()
                  document.getElementById('PJ-InputProfessionRepresentative').style.border = '2px solid red'
                  toast.error('Campo profissão representante vazio')
                  return
                } else {
                  document.getElementById('PJ-InputProfessionRepresentative').style.border = '1px solid white'
                }

                /*   HandleNewCustome({
                     name_fantasia: document.getElementById('PJ-InputFantasyName').value,
                     corporate_name: document.getElementById('PJ-InputSocialReason').value,
                     cnpj: document.getElementById('PJ-InputCNPJ').value,
                     email: document.getElementById('PJ-InputEmail').value,
                     contact: document.getElementById('PJ-InputContact').value,
                     password: document.getElementById('PJ-InputPassword').value,
                     cep: document.getElementById('PJ-InputCep').value,
                     street: document.getElementById('PJ-InputStreet').value,
                     number: document.getElementById('PJ-InputNumber').value ? document.getElementById('PJ-InputNumber').value : 'S/N',
                     neighborhood: document.getElementById('PJ-InputNeighborhood').value,
                     city: document.getElementById('PJ-InputCity').value,
                     state: document.getElementById('PJ-InputState').value,
                     complement: document.getElementById('PJ-InputComplement').value ? document.getElementById('PJ-InputComplement').value : '---',
                     name_representative: document.getElementById('PJ-InputNameRepresentative').value,
                     rg_representative: document.getElementById('PJ-InputRGRepresentative').value,
                     cpf_representative: document.getElementById('PJ-InputCPFRepresentative').value,
                     nationality_representative: document.getElementById('PJ-InputNationalityRepresentative').value,
                     profession_representative: document.getElementById('PJ-InputProfessionRepresentative').value,
             
                   })*/

              }




              if (document.querySelector('#project-type').value === '') {
                document.querySelector('#project-type').focus()
                document.querySelector('#project-type').style.border = '2px solid red'
                toast.error('Campo tipo de projeto vazio')
                return
              } else {
                document.querySelector('#project-type').style.border = '1px solid white'
              }
              if (document.querySelector('#service-type').value === '') {
                document.querySelector('#service-type').focus()
                document.querySelector('#service-type').style.border = '2px solid red'
                toast.error('Campo atendimento vazio')
                return
              } else {
                document.querySelector('#service-type').style.border = '1px solid white'
              }
              if (document.querySelector('#modality-type').value === '') {
                document.querySelector('#modality-type').focus()
                document.querySelector('#modality-type').style.border = '2px solid red'
                toast.error('Campo autoconsumo remoto vazio')
                return
              } else {
                document.querySelector('#modality-type').style.border = '1px solid white'
              }
              if (document.querySelector('#power-rede').value === '') {
                document.querySelector('#power-rede').focus()
                document.querySelector('#power-rede').style.border = '2px solid red'
                toast.error('Campo potência da rede vazio')
                return
              } else {
                document.querySelector('#power-rede').style.border = '1px solid white'
              }

              if (document.querySelector('#disjuntor').value === '0') {
                document.querySelector('#disjuntor').focus()
                document.querySelector('#disjuntor').style.border = '2px solid red'
                toast.error('Selecione o disjuntor')
                return
              } else {
                document.querySelector('#disjuntor').style.border = '1px solid white'
              }
              if (document.querySelector('#number-uc').value === '') {
                document.querySelector('#number-uc').focus()
                document.querySelector('#number-uc').style.border = '2px solid red'
                toast.error('Campo número UC vazio')
                return
              } else {
                document.querySelector('#number-uc').style.border = '1px solid white'
              }

              for (let i; i < inputModulos.length; i++) {
                if (inputModulos[i].brand === '') {
                  document.querySelector(`.brand-modulo${i}`).focus()
                  document.querySelector(`.brand-modulo${i}`).style.border = '2px solid red'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '2px solid red'

                  toast.error('Campo marca do módulo vazio')
                  return
                } else {
                  document.querySelector(`.brand-modulo${i}`).style.border = '1px solid white'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '1px solid white'
                }
                if (inputModulos[i].model === '') {
                  document.querySelector(`.model-modulo${i}`).focus()
                  document.querySelector(`.model-modulo${i}`).style.border = '2px solid red'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '2px solid red'
                  toast.error('Campo modelo do módulo vazio')
                  return
                } else {
                  document.querySelector(`.model-modulo${i}`).style.border = '1px solid white'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '1px solid white'
                }
                if (inputModulos[i].qtd === '') {
                  document.querySelector(`.qtd-modulo${i}`).focus()
                  document.querySelector(`.qtd-modulo${i}`).style.border = '2px solid red'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '2px solid red'
                  toast.error('Campo quantidade do módulo vazio')
                  return
                } else {
                  document.querySelector(`.qtd-modulo${i}`).style.border = '1px solid white'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '1px solid white'
                }
              }
              for (let i; i < inputInversores.length; i++) {
                if (inputInversores[i].brand === '') {
                  document.querySelector(`.brand-inversor${i}`).focus()
                  document.querySelector(`.brand-inversor${i}`).style.border = '2px solid red'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '2px solid red'
                  toast.error('Campo marca do inversor vazio')
                  return
                } else {
                  document.querySelector(`.brand-inversor${i}`).style.border = '1px solid white'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '1px solid white'
                }
                if (inputInversores[i].model === '') {
                  document.querySelector(`.model-inversor${i}`).focus()
                  document.querySelector(`.model-inversor${i}`).style.border = '2px solid red'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '2px solid red'
                  toast.error('Campo modelo do inversor vazio')
                  return
                } else {
                  document.querySelector(`.model-inversor${i}`).style.border = '1px solid white'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '1px solid white'
                }
                if (inputInversores[i].qtd === '') {
                  document.querySelector(`.qtd-inversor${i}`).focus()
                  document.querySelector(`.qtd-inversor${i}`).style.border = '2px solid red'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '2px solid red'
                  toast.error('Campo quantidade do inversor vazio')
                  return
                } else {
                  document.querySelector(`.qtd-inversor${i}`).style.border = '1px solid white'
                  document.querySelector(`#abrir-instalacao-fotovoltaica`).style.border = '1px solid white'
                }
              }
              if (document.querySelector('.cep-service').value === '') {
                document.querySelector('.cep-service').focus()
                document.querySelector('.cep-service').style.border = '2px solid red'
                toast.error('Campo CEP vazio')
                return
              } else {
                document.querySelector('.cep-service').style.border = '1px solid white'
              }
              if (document.querySelector('.number-service').value === '') {
                document.querySelector('.number-service').focus()
                document.querySelector('.number-service').style.border = '2px solid red'
                toast.error('Campo número vazio')
                return
              } else {
                document.querySelector('.number-service').style.border = '1px solid white'
              }
              if (document.querySelector('.street-service').value === '') {
                document.querySelector('.street-service').focus()
                document.querySelector('.street-service').style.border = '2px solid red'
                toast.error('Campo rua vazio')
                return
              } else {
                document.querySelector('.street-service').style.border = '1px solid white'
              }
              if (document.querySelector('.district-service').value === '') {
                document.querySelector('.district-service').focus()
                document.querySelector('.district-service').style.border = '2px solid red'
                toast.error('Campo bairro vazio')
                return
              } else {
                document.querySelector('.district-service').style.border = '1px solid white'
              }
              if (document.querySelector('.state-service').value === '') {
                document.querySelector('.state-service').focus()
                document.querySelector('.state-service').style.border = '2px solid red'
                toast.error('Campo estado vazio')
                return
              } else {
                document.querySelector('.state-service').style.border = '1px solid white'
              }
              if (document.querySelector('.city-service').value === '') {
                document.querySelector('.city-service').focus()
                document.querySelector('.city-service').style.border = '2px solid red'
                toast.error('Campo cidade vazio')
                return
              } else {
                document.querySelector('.city-service').style.border = '1px solid white'
              }

              if (longitudeClick === undefined || latitudeClick === undefined || longitudeClick === '' || latitudeClick === '') {
                document.querySelector('.position-click').style.border = '2px solid red'
                toast.error('Selecione a localização do projeto no mapa')
                return
              }
              else {
                document.querySelector('.position-click').style.border = '1px solid white'
              }
              if (img_rg_cnh === '') {
                document.querySelector('.rg-cnh').style.border = '2px solid red'
                toast.error('Adicione uma imagem do RG ou CNH')
                return
              } else {
                document.querySelector('.rg-cnh').style.border = '1px solid white'
              }
              if (img_electricity_bill === '') {
                document.querySelector('.electricity-bill').style.border = '2px solid red'
                toast.error('Adicione uma imagem da conta de luz')
                return
              } else {
                document.querySelector('.electricity-bill').style.border = '1px solid white'
              }
              if (document.querySelector('.select-customer-type').value === 'pj' && img_contract_social === '') {
                document.querySelector('.contract-social').style.border = '2px solid red'
                toast.error('Adicione uma imagem do contrato social')
                return
              } else {
                document.querySelector('.contract-social').style.border = '1px solid white'
              }
              if (img_photos_breaker === '') {
                document.querySelector('.photos-breaker').style.border = '2px solid red'
                toast.error('Adicione uma imagem do disjuntor')
                return
              } else {
                document.querySelector('.photos-breaker').style.border = '1px solid white'
              }
              if (img_energy_box_photos === '') {
                document.querySelector('.energy-box-photos').style.border = '2px solid red'
                toast.error('Adicione uma imagem da caixa de energia')
                return
              } else {
                document.querySelector('.energy-box-photos').style.border = '1px solid white'
              }
              if (img_data_photovoltaic === '' && document.querySelector('#brand-module0').value !== '' && document.querySelector('#brand-inverter0').value !== '') {
                document.querySelector('#bt-popup').style.border = '2px solid red'
                toast.error('Informe dados das instalações fotovoltaicas')
                return
              } else {
                document.querySelector('#bt-popup').style.border = '1px solid white'
              }
              if (document.querySelector('.select-customer-type').value === 'pf') {
                handleNewProject({
                  name: document.querySelector('#PF-InputName').value,
                  rg: document.querySelector('#PF-InputRG').value,
                  cpf: document.querySelector('#PF-InputCPF').value,
                  email: document.querySelector('#PF-InputEmail').value,
                  profession: document.querySelector('#PF-InputProfession').value,
                  nationality: document.querySelector('#PF-InputNationality').value,
                  contact: document.querySelector('#PF-InputContact').value,
                  civil_state: document.querySelector('#PF-InputCivil_state').value,
                  cep_customer_of_customer: document.querySelector('#PF-InputCep').value,
                  number_customer_of_customer: document.querySelector('#PF-InputNumber').value,
                  street_customer_of_customer: document.querySelector('#PF-InputStreet').value,
                  neighborhood_customer_of_customer: document.querySelector('#PF-InputNeighborhood').value,
                  city_customer_of_customer: document.querySelector('#PF-InputCity').value,
                  state_customer_of_customer: document.querySelector('#PF-InputState').value,
                  complement_customer_of_customer: document.querySelector('#PF-InputComplement').value,

                  project_type: document.querySelector('#project-type').value,
                  service_type: document.querySelector('#service-type').value,
                  branch_type: document.querySelector('#branch-type').value,
                  modality_type: document.querySelector('#modality-type').value,
                  power_rede: document.querySelector('#power-rede').value,
                  disjuntor: document.querySelector('#disjuntor').value,
                  contracted_demand: document.querySelector('#project-type')?.value === 'a4_horaria_verde' || document.querySelector('#project-type').value === 'a4_horaria_azul' ? document.querySelector('#contracted-demand').value : '---',
                  number_uc: document.querySelector('#number-uc').value,
                  project_obs: document.querySelector('#project-obs').value,

                  cep: document.querySelector('.cep-service').value,
                  number: document.querySelector('.number-service').value,
                  street: document.querySelector('.street-service').value,
                  district: document.querySelector('.district-service').value,
                  state: document.querySelector('.state-service').value,
                  city: document.querySelector('.city-service').value,
                  lat: latitudeClick,
                  log: longitudeClick,



                  data_input_inverter: JSON.stringify(inputInversores),
                  data_input_module: JSON.stringify(inputModulos),
                  data_rateio: JSON.stringify([
                    rateio1.uc !== '' ? { rateio1 } : '',
                    rateio2.uc !== '' ? { rateio2 } : '',
                    rateio3.uc !== '' ? { rateio3 } : '',
                    rateio4.uc !== '' ? { rateio4 } : '',
                    rateio5.uc !== '' ? { rateio5 } : '',
                    rateio6.uc !== '' ? { rateio6 } : '',
                    rateio7.uc !== '' ? { rateio7 } : '',
                    rateio8.uc !== '' ? { rateio8 } : '',
                    rateio9.uc !== '' ? { rateio9 } : '',
                    rateio10.uc !== '' ? { rateio10 } : '',
                    rateio11.uc !== '' ? { rateio11 } : '',
                    rateio12.uc !== '' ? { rateio12 } : '',
                    rateio13.uc !== '' ? { rateio13 } : '',
                    rateio14.uc !== '' ? { rateio14 } : '',
                  ])

                })

              }
              if (document.querySelector('.select-customer-type').value === 'pj') {
                handleNewProject({
                  fantasy_name: document.querySelector('#PJ-InputFantasyName').value === '' ? '---' : document.querySelector('#PJ-InputFantasyName').value,
                  corporate_name: document.querySelector('#PJ-InputSocialReason').value,
                  cnpj: document.querySelector('#PJ-InputCNPJ').value,
                  email: document.querySelector('#PJ-InputEmail').value,
                  contact: document.querySelector('#PJ-InputContact').value,
                  cep_customer_of_customer: document.querySelector('#PJ-InputCep').value,
                  number_customer_of_customer: document.querySelector('#PJ-InputNumber').value,
                  street_customer_of_customer: document.querySelector('#PJ-InputStreet').value,
                  neighborhood_customer_of_customer: document.querySelector('#PJ-InputNeighborhood').value,
                  city_customer_of_customer: document.querySelector('#PJ-InputCity').value,
                  state_customer_of_customer: document.querySelector('#PJ-InputState').value,
                  complement_customer_of_customer: document.querySelector('#PJ-InputComplement').value,
                  name_representative: document.querySelector('#PJ-InputNameRepresentative').value,
                  rg_representative: document.querySelector('#PJ-InputRGRepresentative').value,
                  cpf_representative: document.querySelector('#PJ-InputCPFRepresentative').value,
                  nationality_representative: document.querySelector('#PJ-InputNationalityRepresentative').value,
                  profession_representative: document.querySelector('#PJ-InputProfessionRepresentative').value,





                  project_type: document.querySelector('#project-type').value,
                  service_type: document.querySelector('#service-type').value,
                  branch_type: document.querySelector('#branch-type').value,
                  modality_type: document.querySelector('#modality-type').value,
                  power_rede: document.querySelector('#power-rede').value,
                  disjuntor: document.querySelector('#disjuntor').value,
                  contracted_demand: document.querySelector('#project-type')?.value === 'a4_horaria_verde' || document.querySelector('#project-type').value === 'a4_horaria_azul' ? document.querySelector('#contracted-demand').value : '---',
                  number_uc: document.querySelector('#number-uc').value,
                  project_obs: document.querySelector('#project-obs').value,

                  cep: document.querySelector('.cep-service').value,
                  number: document.querySelector('.number-service').value,
                  street: document.querySelector('.street-service').value,
                  district: document.querySelector('.district-service').value,
                  state: document.querySelector('.state-service').value,
                  city: document.querySelector('.city-service').value,
                  lat: latitudeClick,
                  log: longitudeClick,

                  data_input_inverter: JSON.stringify(inputInversores),
                  data_input_module: JSON.stringify(inputModulos),
                  data_rateio: JSON.stringify([
                    rateio1.uc !== '' ? { rateio1 } : '',
                    rateio2.uc !== '' ? { rateio2 } : '',
                    rateio3.uc !== '' ? { rateio3 } : '',
                    rateio4.uc !== '' ? { rateio4 } : '',
                    rateio5.uc !== '' ? { rateio5 } : '',
                    rateio6.uc !== '' ? { rateio6 } : '',
                    rateio7.uc !== '' ? { rateio7 } : '',
                    rateio8.uc !== '' ? { rateio8 } : '',
                    rateio9.uc !== '' ? { rateio9 } : '',
                    rateio10.uc !== '' ? { rateio10 } : '',
                    rateio11.uc !== '' ? { rateio11 } : '',
                    rateio12.uc !== '' ? { rateio12 } : '',
                    rateio13.uc !== '' ? { rateio13 } : '',
                    rateio14.uc !== '' ? { rateio14 } : '',
                  ])

                })
              }
              /* else {
                toast.error('Sua sessão expirou, faça login para continuar.')
                setTimeout(() => {
                localStorage.removeItem('token')
                window.location.href = '/'
                }, 4000)
              }*/

            }}>Enviar Projeto</button>
          </div>
        </div>

        <div className='body-project'>
          <div className="body-content">
            <div className="body-form">
              <div className="form-project">
                <h3 className='title-section'>Informações do Cliente: </h3>
                <div style={{ width: '100%', paddingTop: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <select style={{ backgroundColor: '#000000f3', color: '#ffffff' }} className='select-customer-type' id='select-customer-type' onChange={(e) => {
                    for (let i = 0; i < 2; i++) {
                      clearFields()
                      document.querySelectorAll('.cep-customer')[i].value = ''
                      document.querySelectorAll('.number-customer')[i].value = ''
                      document.querySelectorAll('.street-customer')[i].value = ''
                      document.querySelectorAll('.neighborhood-customer')[i].value = ''
                      document.querySelectorAll('.state-customer')[i].value = ''
                      document.querySelectorAll('.city-customer')[i].value = ''
                      document.querySelectorAll('.complement-customer')[i].value = ''
                    }
                    if (e.target.value === 'pf') {
                      document.querySelector('#PJ-InputCNPJ').value = ''
                      document.querySelector('#PJ-InputFantasyName').value = ''
                      document.querySelector('#PJ-InputSocialReason').value = ''
                      document.querySelector('#PJ-InputEmail').value = ''
                      document.querySelector('#PJ-InputContact').value = ''
                      document.querySelector('#PJ-InputCep').value = ''
                      document.querySelector('#PJ-InputStreet').value = ''
                      document.querySelector('#PJ-InputNumber').value = ''
                      document.querySelector('#PJ-InputNeighborhood').value = ''
                      document.querySelector('#PJ-InputCity').value = ''
                      document.querySelector('#PJ-InputState').value = ''
                      document.querySelector('#PJ-InputComplement').value = ''

                      document.querySelector('#PJ-InputNameRepresentative').value = ''
                      document.querySelector('#PJ-InputRGRepresentative').value = ''
                      document.querySelector('#PJ-InputCPFRepresentative').value = ''
                      document.querySelector('#PJ-InputNationalityRepresentative').value = ''
                      document.querySelector('#PJ-InputProfessionRepresentative').value = ''
                      document.querySelector('#PJ-InputCNPJ').value = ''
                      document.querySelector('#PJ-InputFantasyName').value = ''
                      document.querySelector('#PJ-InputSocialReason').value = ''
                      document.querySelector('#PJ-InputEmail').value = ''
                      document.querySelector('#PJ-InputContact').value = ''
                      document.querySelector('#PJ-InputCep').value = ''
                      document.querySelector('#PJ-InputStreet').value = ''
                      document.querySelector('#PJ-InputNumber').value = ''
                      document.querySelector('#PJ-InputNeighborhood').value = ''
                      document.querySelector('#PJ-InputCity').value = ''
                      document.querySelector('#PJ-InputState').value = ''
                      document.querySelector('#PJ-InputComplement').value = ''

                      document.querySelector('#PJ-InputNameRepresentative').value = ''
                      document.querySelector('#PJ-InputRGRepresentative').value = ''
                      document.querySelector('#PJ-InputCPFRepresentative').value = ''
                      document.querySelector('#PJ-InputNationalityRepresentative').value = ''
                      document.querySelector('#PJ-InputProfessionRepresentative').value = ''

                      document.querySelector('.pf').style.display = 'flex'
                      document.querySelector('.pj').style.display = 'none'
                      document.querySelector('.contract-social').style.display = 'none'


                      setImg_rg_cnh('')
                      setImg_electricity_bill('')
                      setImg_contract_social('')
                      setImg_photos_breaker('')
                      setImg_energy_box_photos('')
                      setLatitudeClick('')
                      setLongitudeClick('')
                    }
                    else {
                      document.querySelector('#PF-InputName').value = ''
                      document.querySelector('#PF-InputRG').value = ''
                      document.querySelector('#PF-InputCPF').value = ''
                      document.querySelector('#PF-InputEmail').value = ''
                      document.querySelector('#PF-InputCivil_state').value = 'solteiro'
                      document.querySelector('#PF-InputNationality').value = ''
                      document.querySelector('#PF-InputProfession').value = ''
                      document.querySelector('#PF-InputContact').value = ''

                      document.querySelector('.pf').style.display = 'none'
                      document.querySelector('.pj').style.display = 'flex'
                      document.querySelector('.contract-social').style.display = 'table'
                      setImg_rg_cnh('')
                      setImg_electricity_bill('')
                      setImg_contract_social('')
                      setImg_photos_breaker('')
                      setImg_energy_box_photos('')
                      setLatitudeClick('')
                      setLongitudeClick('')
                    }
                  }}>
                    <option value="pf">Pessoa Física</option>
                    <option value="pj">Pessoa Jurídica</option>
                  </select>
                </div>
                <div className="pf form-content" style={{ display: document.querySelector('.select-customer-type')?.value === 'pf' ? 'flex' : 'none' }}>

                  <div className='section1'>
                    <div className="form-div">
                      <p>Nome:</p>
                      <input
                        id='PF-InputName'
                        type="text"
                        placeholder="Seu nome"
                        onChange={(e) => {
                          setGab(e.target.value)
                          if (!e.target.value) {
                            document.getElementById('PF-InputName').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputName').style.border = '1px solid white'
                          }
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>RG:</p>
                      <input
                        id='PF-InputRG'
                        type="text"
                        placeholder="Seu RG"
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(/\D/g, '')
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>CPF:</p>
                      <input
                        id='PF-InputCPF'
                        type="text"
                        placeholder="Seu CPF"
                        onChange={(e) => {
                          setGab(e.target.value)

                          e.target.value = cpfFormat(e.target.value)
                          if (e.target.value.length < 14) {
                            document.getElementById('PF-InputCPF').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputCPF').style.border = '1px solid white'
                          }
                          if (e.target.value === '') {
                            document.getElementById('PF-InputCPF').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputCPF').style.border = '1px solid white'
                          }

                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>E-mail:</p>
                      <input
                        id='PF-InputEmail'
                        type="email"
                        placeholder="Seu e-mail"
                        onChange={(e) => {
                          if (!validator.isEmail(e.target.value)) {
                            document.getElementById('PF-InputEmail').style.border = '1px solid red'
                          } else {
                            document.getElementById('PF-InputEmail').style.border = '1px solid white'
                          }
                        }}
                      />
                    </div>

                  </div>

                  <div >
                    <div className="form-div">
                      <p>Nacionalidade:</p>
                      <input
                        id='PF-InputNationality'
                        type="text"
                        placeholder="Sua nacionalidade"
                        onChange={(e) => {
                          if (!e.target.value) {
                            document.getElementById('PF-InputNationality').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputNationality').style.border = '1px solid white'
                          }
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>Profissão:</p>
                      <input
                        id='PF-InputProfession'
                        type="text"
                        placeholder="Sua profissão"
                        onChange={(e) => {
                          if (!e.target.value) {
                            document.getElementById('PF-InputProfession').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputProfession').style.border = '1px solid white'
                          }
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>Contato:</p>
                      <input
                        id='PF-InputContact'
                        type="tel"
                        placeholder="(00) 00000-0000"
                        onChange={(e) => {
                          e.target.value = celularFormat(e.target.value)
                          if (e.target.value.length < 15) {
                            document.getElementById('PF-InputContact').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputContact').style.border = '1px solid white'
                          }
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>Estado Civil:</p>
                      <select id='PF-InputCivil_state'>
                        <option value="solteiro">Solteiro</option>
                        <option value="casado">Casado</option>
                        <option value="divorciado">Divorciado</option>
                        <option value="viuvo">Viúvo</option>
                      </select>
                      <p className="errorPFcivil_state">:</p>
                    </div>
                  </div>
                  <div className="section2">
                    <div className="form-div">
                      <p>Cep:</p>
                      <input

                        id='PF-InputCep'
                        type="text"
                        className='cep-customer'
                        placeholder="Seu CEP"
                        onChange={(e) => {
                          e.target.value = cepFormat(e.target.value)

                          if (e.target.value.length > 8) {

                            fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                              .then(response => response.json())
                              .then(data => {
                                if (data.erro) {
                                  return
                                }
                                document.getElementById('PF-InputStreet').value = data.logradouro
                                document.getElementById('PF-InputNeighborhood').value = data.bairro
                                document.getElementById('PF-InputCity').value = data.localidade
                                document.getElementById('PF-InputState').value = data.uf
                              })
                          }

                          if (e.target.value.length < 9) {
                            document.getElementById('PF-InputCep').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputCep').style.border = '1px solid white'
                          }
                        }
                        }
                      />

                    </div>
                    <div className="form-div">
                      <p>Rua:</p>
                      <input
                        id='PF-InputStreet'
                        className='street-customer'
                        type="text"
                        placeholder="Sua rua"
                        onChange={(e) => {
                          if (!e.target.value) {
                            document.getElementById('PF-InputStreet').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputStreet').style.border = '1px solid white'
                          }
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>Número:</p>
                      <input

                        id='PF-InputNumber'
                        className='number-customer'
                        type="text"
                        placeholder="Número"
                        onChange={(e) => {
                          if (!e.target.value) {
                            document.getElementById('PF-InputNumber').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputNumber').style.border = '1px solid white'
                          }
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>Bairro:</p>
                      <input
                        id='PF-InputNeighborhood'
                        className='neighborhood-customer'
                        type="text"
                        placeholder="Seu bairro"
                        onChange={(e) => {
                          if (!e.target.value) {
                            document.getElementById('PF-InputNeighborhood').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputNeighborhood').style.border = '1px solid white'
                          }
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>Cidade:</p>
                      <input
                        id='PF-InputCity'
                        className='city-customer'
                        type="text"
                        placeholder="Sua cidade"
                        onChange={(e) => {
                          if (!e.target.value) {
                            document.getElementById('PF-InputCity').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputCity').style.border = '1px solid white'
                          }
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>Estado:</p>
                      <input
                        id='PF-InputState'
                        className='state-customer'
                        type="text"
                        placeholder="Seu estado"
                        onChange={(e) => {
                          if (!e.target.value) {
                            document.getElementById('PF-InputState').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('PF-InputState').style.border = '1px solid white'
                          }
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>Complemento:</p>
                      <input

                        id='PF-InputComplement'
                        className='complement-customer'
                        type="text"
                        placeholder="Complemento"
                      />

                    </div>

                  </div>



                </div>

                <div className="pj form-content" style={{ display: document.querySelector('.select-customer-type')?.value === 'pj' ? 'flex' : 'none' }}>

                  <div className='section1'>

                    <div className="form-div">
                      <p>CNPJ:</p>
                      <input
                        id='PJ-InputCNPJ'
                        type="text"
                        placeholder="Seu CNPJ"
                        onChange={(e) => {
                          setGab(e.target.value)

                          e.target.value = cnpjFormat(e.target.value)
                          if (!document.getElementById('PJ-InputCNPJ').value) {
                            document.getElementById('PJ-InputCNPJ').focus()
                            document.getElementById('PJ-InputCNPJ').style.border = '2px solid red'

                            return
                          } else {
                            document.getElementById('PJ-InputCNPJ').style.border = '1px solid white'
                          }
                          if (document.getElementById('PJ-InputCNPJ').value.length < 18) {
                            document.getElementById('PJ-InputCNPJ').focus()
                            document.getElementById('PJ-InputCNPJ').style.border = '2px solid red'
                            return
                          } else {
                            document.getElementById('PJ-InputCNPJ').style.border = '1px solid white'
                          }
                          handleCNPJ(e.target.value)
                        }}
                      />

                    </div>
                    <div className="form-div">
                      <p>Nome Fantasia:</p>
                      <input

                        id='PJ-InputFantasyName'
                        type="text"
                        placeholder="Seu nome fantasia"
                      />

                    </div>
                    <div className="form-div">
                      <p>Razão Social:</p>
                      <input

                        id='PJ-InputSocialReason'
                        type="text"
                        placeholder="Sua razão social"
                      />

                    </div>

                    <div className="form-div">
                      <p>E-mail:</p>
                      <input
                        id='PJ-InputEmail'
                        type="email"
                        placeholder="Seu e-mail"
                        onChange={(e) => {
                          if (!validator.isEmail(e.target.value)) {
                            document.getElementById('PJ-InputEmail').style.border = '2px solid red'
                          } else {
                            document.getElementById('PJ-InputEmail').style.border = '1px solid white'
                          }
                        }
                        }
                      />

                    </div>
                    <div className="form-div">
                      <p>Contato:</p>
                      <input
                        id='PJ-InputContact'
                        type="tel"
                        placeholder="(00) 00000-0000"
                        onChange={(e) => {
                          e.target.value = celularFormat(e.target.value)
                        }}
                      />
                    </div>
                    <div className="form-div">
                      <p>Estado:</p>
                      <input
                        id='PJ-InputState'
                        className='state-customer'
                        type="text"
                        placeholder="Seu estado"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="section2">
                    <div className="form-div">
                      <p>Cep:</p>
                      <input
                        id='PJ-InputCep'
                        className='cep-customer'
                        type="text"
                        placeholder="Seu CEP"
                        onChange={(e) => {
                          e.target.value = cepFormat(e.target.value)
                          if (e.target.value.length > 8) {

                            fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                              .then(response => response.json())
                              .then(data => {
                                if (data.erro) {
                                  return
                                }
                                document.getElementById('PJ-InputStreet').value = data.logradouro
                                document.getElementById('PJ-InputNeighborhood').value = data.bairro
                                document.getElementById('PJ-InputCity').value = data.localidade
                                document.getElementById('PJ-InputState').value = data.uf
                              })
                          }
                          if (document.getElementById('PJ-InputCep').value.length < 9) {
                            document.getElementById('PJ-InputCep').focus()
                            document.getElementById('PJ-InputCep').style.border = '2px solid red'
                            return
                          } else {
                            document.getElementById('PJ-InputCep').style.border = '1px solid white'
                          }
                        }
                        }
                      />

                    </div>
                    <div className="form-div">
                      <p>Logradouro:</p>
                      <input
                        id='PJ-InputStreet'
                        className='street-customer'
                        type="text"
                        placeholder="Logradouro"
                      />
                    </div>
                    <div className="form-div">
                      <p>Número:</p>
                      <input
                        id='PJ-InputNumber'
                        className='number-customer'
                        type="text"
                        placeholder="Número"
                      />
                    </div>
                    <div className="form-div">
                      <p>Complemento:</p>
                      <input
                        id='PJ-InputComplement'
                        className='complement-customer'
                        type="text"
                        placeholder="Complemento"
                      />
                    </div>
                    <div className="form-div">
                      <p>Bairro:</p>
                      <input
                        id='PJ-InputNeighborhood'
                        className='neighborhood-customer'
                        type="text"
                        placeholder="Seu bairro"
                      />
                    </div>
                    <div className="form-div">
                      <p>Cidade:</p>
                      <input
                        id='PJ-InputCity'
                        className='city-customer'
                        type="text"
                        placeholder="Sua cidade"
                      />
                    </div>


                  </div>
                  <br />
                  <div style={{ paddingTop: '10px' }}>
                    <h3>Representante Legal:</h3>
                    <div className="form-div">
                      <p>Nome Completo:</p>
                      <input
                        id='PJ-InputNameRepresentative'
                        type="text"
                        placeholder="Nome do representante"
                      />
                    </div>
                    <div className="form-div">
                      <p>RG:</p>
                      <input
                        id='PJ-InputRGRepresentative'
                        type="text"
                        placeholder="RG do representante"
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(/\D/g, '')
                        }}
                      />
                    </div>
                    <div className="form-div">
                      <p>CPF:</p>
                      <input
                        id='PJ-InputCPFRepresentative'
                        type="text"
                        placeholder="CPF do representante"
                        onChange={(e) => {
                          e.target.value = cpfFormat(e.target.value)
                          if (e.target.value.length < 14) {
                            document.getElementById('PJ-InputCPFRepresentative').style.border = '2px solid red'
                            return
                          } else {
                            document.getElementById('PJ-InputCPFRepresentative').style.border = '1px solid white'
                          }
                        }}
                      />
                    </div>

                    <div className="form-div">
                      <p>Profissão:</p>
                      <input
                        id='PJ-InputProfessionRepresentative'
                        type="text"
                        placeholder="Profissão do representante"
                      />
                    </div>
                    <div className="form-div">
                      <p>Nacionalidade:</p>
                      <input
                        id='PJ-InputNationalityRepresentative'
                        type="text"
                        placeholder="Nacionalidade"
                      />
                    </div>


                  </div>
                </div>

              </div>
              <div>
                <div className='project-info'>
                  <h3 className='title-section'>Informações do Projeto:</h3>
                  <div className='info-content'>
                    <div className='project-type form-div'>
                      <p>Tipo de Projeto:</p>
                      <select id='project-type'
                        onChange={e => {
                          setGab(e)
                          if (e.target.value === 'a4_horaria_verde' || e.target.value === 'a4_horaria_azul') {
                            document.querySelector('#service-type').value = 'trifasico'
                            document.querySelector('#service-type').disabled = true
                          } else {
                            document.querySelector('#service-type').disabled = false
                          }
                        }}>
                        <option value="b1_residencial">B1 - Residencial</option>
                        <option value="b2_rural">B2 - Rural</option>
                        <option value="b2_cooperativa">B2 - Cooperativa</option>
                        <option value="b2_servico_publico_de_irrigacao">B2 - Serviço Público de Irrigação</option>
                        <option value="b3_demais_classes">B3 - Demais Classes</option>
                        <option value="a4_horaria_verde">A4 - Horária Verde</option>
                        <option value="a4_horaria_azul">A4 - Horária Azul</option>
                      </select>
                    </div>
                    <div className='service-type form-div'>
                      <p>Atendimento:</p>
                      <select id='service-type'
                        onChange={(e) => {

                          setGab(e)
                        }}>
                        <option value="monofasico">Monofásico</option>
                        <option value="bifasico">Bifásico</option>
                        <option value="trifasico">Trifásico</option>
                      </select>
                    </div>

                    <div className='branch-type form-div'>
                      <p>Tipo de Ramal:</p>
                      <select id='branch-type'>
                        <option value="aereo">Áereo</option>
                        <option value="subterraneo">Subterrâneo</option>
                      </select>
                    </div>

                    <div className='modality-type form-div'>
                      <p>Modalidade:</p>
                      <select id='modality-type'>
                        <option value="geracao_local">Geração Local</option>
                        <option value="autoconsumo_remoto">Autoconsumo Remoto</option>
                      </select>
                    </div>
                    <div className='disjuntor form-div'>
                      <p>Disjuntor:</p>

                      {
                        document.querySelector('#service-type')?.value === 'trifasico' ?
                          <select id='disjuntor' className='corrente-instalada-trifasico' name=""
                            onChange={
                              (e) => {
                                setGab(e)
                              }}
                          >
                            <option value="0">0</option>
                            <option value="25">25</option>
                            <option value="32">32</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="63">63</option>
                            <option value="80">80</option>
                            <option value="100">100</option>


                          </select>
                          :
                          ''
                            ||
                            document.querySelector('#service-type')?.value === 'bifasico' ?
                            <select id='disjuntor' className='corrente-instalada-bifasico' name=""
                              onChange={
                                (e) => {
                                  setGab(e)
                                }}>
                              <option value="0">0</option>
                              <option value="20">20</option>
                              <option value="25">25</option>
                              <option value="32">32</option>
                              <option value="40">40</option>


                            </select>
                            :
                            ''
                              ||
                              document.querySelector('#service-type')?.value === 'monofasico' ?
                              <select id='disjuntor' className='corrente-instalada-monofasico' name=""
                                onChange={
                                  (e) => {
                                    setGab(e)
                                  }}>
                                <option value="0">0</option>
                                <option value="16">16</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="32">32</option>
                                <option value="40">40</option>

                              </select>
                              :
                              ''
                      }
                    </div>
                    {document.querySelector('#project-type')?.value === 'a4_horaria_azul' && <div style={{ marginTop: '0px' }} className='contracted-demand'>
                      <p>Demanda Contratada:</p>
                      <input type="text" id='contracted-demand' placeholder='Ex: 000kW'
                        onChange={(e) => {

                          if (!e.target.value) {
                            document.getElementById('contracted-demand').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('contracted-demand').style.border = 'none'
                          }
                          setGab(e)
                        }} />
                    </div>}
                    {document.querySelector('#project-type')?.value === 'a4_horaria_verde' && <div style={{ marginTop: '0px' }} className='contracted-demand'>
                      <p>Demanda Contratada:</p>
                      <input type="text" id='contracted-demand' placeholder='Ex: 000kW'
                        onChange={(e) => {

                          if (!e.target.value) {
                            document.getElementById('contracted-demand').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('contracted-demand').style.border = 'none'
                          }
                          setGab(e)
                        }} />
                    </div>}
                    <div className='power-rede form-div'>
                      <p>Tensão da Rede:</p>
                      <input type="text" disabled id='power-rede' value={
                        document.querySelector('#service-type')?.value === 'trifasico'
                          ? '220V/380V'
                          :
                          document.querySelector('#service-type')?.value === 'bifasico'
                            ? '220V/380V'
                            :
                            document.querySelector('#service-type')?.value === 'monofasico' ? '220V' : ''
                      } />
                    </div>
                    <div style={{ marginTop: '0px' }} className='number-uc'>
                      <p>Numero da UC:</p>
                      <input type="text" id='number-uc' placeholder='Numero da UC'
                        onChange={(e) => {

                          if (!e.target.value) {
                            document.getElementById('number-uc').style.border = '2px solid red'
                          }
                          else {
                            document.getElementById('number-uc').style.border = 'none'
                          }

                        }} />
                    </div>


                    <div id='bt-popup'>
                      <button onClick={() => { document.querySelector('#popup-instalacao-fotovoltaica').classList.add('show') }} className='botao-pulsante' id='abrir-instalacao-fotovoltaica' style={{ backgroundColor: '#000', margin: '8px 0 0 0', fontSize: '10pt' }}>Instalação fotovoltaica</button>
                    </div>
                    <div style={{ marginTop: '0px' }} className='project-obs  form-div'>
                      <p>Observações:</p>
                      <textarea id='project-obs' style={{ height: '40px', padding: '5px 0 0 5px' }} placeholder='Observações sobre o projeto' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ minWidth: '751px', maxWidth: '751px', display: 'flex' }}>
              <div className='files-project'>
                <h3 className='title-section'>Anexos:</h3>
                <div className='files-content'>
                  <div className='rg-cnh'>
                    <p style={{ fontSize: '9pt' }}>RG/CNH:</p>
                    <label for='rg_cnh' style={{ width: '90px', marginRight: '10px' }} className='label-rg_cnh style-label'>enviar arquivo</label> <input hidden type='file' className='rg_cnh' id='rg_cnh' onChange={e => {
                      const file = e.target.files[0]
                      const formdata = new FormData()
                      formdata.append('rg_cnh', file)
                      setImg_rg_cnh(formdata)

                    }} />
                  </div>
                  <div className='electricity-bill'>
                    <p style={{ fontSize: '9pt' }}>Conta de luz:</p>
                    <label for='electricity_bill' style={{ width: '90px', marginRight: '10px' }} className='label-electricity_bill style-label'>enviar arquivo</label> <input hidden type='file' className='electricity_bill' id='electricity_bill' onChange={e => {
                      const file = e.target.files[0]
                      const formdata = new FormData()
                      formdata.append('electricity_bill', file)
                      setImg_electricity_bill(formdata)
                    }} />
                  </div>
                  <div className='contract-social' style={{ display: document.querySelector('.select-customer-type')?.value === 'pj' ? 'table' : 'none' }}>
                    <p style={{ fontSize: '9pt' }}>Contrato Social:</p>
                    <label for='contract-social' style={{ width: '90px', marginRight: '10px' }} className='label-contract-social style-label'>enviar arquivo</label> <input hidden type='file' className='contract-social' id='contract-social' onChange={e => {
                      const file = e.target.files[0]
                      const formdata = new FormData()
                      formdata.append('contract_social', file)
                      setImg_contract_social(formdata)


                    }} />
                  </div>
                  <div className='photos-breaker' >
                    <p style={{ fontSize: '9pt' }}>Foto Disjuntor:</p>
                    <label for='photos-breaker' style={{ width: '90px', marginRight: '10px' }} className='label-photos-breaker style-label'>enviar arquivo</label> <input hidden type='file' className='photos-breaker' id='photos-breaker' onChange={e => {
                      const file = e.target.files[0]
                      const formdata = new FormData()
                      formdata.append('photos_breaker', file)
                      setImg_photos_breaker(formdata)

                    }} accept='image/* , application/pdf' />
                  </div>
                  <div className='energy-box-photos' >
                    <p style={{ fontSize: '8.5pt' }}>Foto Caixa de Energia:</p>
                    <label for='energy-box-photos' style={{ width: '95px', marginRight: '30px' }} className='label-energy-box-photos style-label'>enviar arquivo</label> <input hidden type='file' className='energy-box-photos' id='energy-box-photos' onChange={e => {
                      const file = e.target.files[0]
                      const formdata = new FormData()
                      formdata.append('energy_box_photos', file)
                      setImg_energy_box_photos(formdata)

                    }} accept='image/* , application/pdf' />
                  </div>
                </div>
              </div>
              <div className='doc-project'>
                <h3 className='title-section'>Documentos:</h3>
                <div className='doc-content'>


                  <button className='bt-rateio' onClick={() => {
                    document.querySelector('.form-rateio').style.display = 'flex'
                  }} >Rateio</button>



                  <button className='bt-procuracao' onClick={() => {
                    if (document.querySelector('.select-customer-type').value === 'pf') {

                      document.querySelector('.procuracao-pj').style.display = 'none'
                      document.querySelector('.procuracao-pf').style.display = 'table'
                      document.querySelector('.procuracao-pf').innerHTML = `
                            <p><strong>OUTORGANTE: ${document.querySelector('#PF-InputName')?.value}</strong>, ${document.querySelector('#PF-InputProfession')?.value}, inscrito no CPF sob nº 
                            <strong> ${document.querySelector('#PF-InputCPF')?.value}</strong> e RG nº ${document.querySelector('#PF-InputRG')?.value}, residente e domiciliado na 
                            ${document.querySelector('#PF-InputStreet')?.value}, ${document.querySelector('#PF-InputNumber')?.value} ${document.querySelector('#PF-InputNeighborhood')?.value} – ${document.querySelector('#PF-InputCity')?.value} – ${document.querySelector('#PF-InputState')?.value}
                            - CEP: ${document.querySelector('#PF-InputCep')?.value}.</p>`
                      document.querySelector('.procuracao-ass-pj').style.display = 'none'
                      document.querySelector('.procuracao-ass-pf').style.display = 'table'
                      document.querySelector('.procuracao-ass-pf').innerHTML = `
                            <p style={{textAlign:'center'}}>${document.querySelector('#PF-InputName')?.value}</p>
                            <p style={{textAlign:'center'}}>CPF nº ${document.querySelector('#PF-InputCPF')?.value}</p> 
                            `
                    } else {
                      document.querySelector('.procuracao-pf').style.display = 'none'
                      document.querySelector('.procuracao-pj').style.display = 'table'
                      document.querySelector('.procuracao-pj').innerHTML = `
                            <p><strong> OUTORGANTE: ${document.querySelector('#PJ-InputSocialReason')?.value}</strong>, inscrita no CNPJ <span>nº 
                            <strong> ${document.querySelector('#PJ-InputCNPJ')?.value}</strong></span>, situada na ${document.querySelector('#PJ-InputStreet')?.valuet}, ${document.querySelector('#PJ-InputNumber').value} – ${document.querySelector('#PJ-InputNeighborhood')?.value} – ${document.querySelector('#PJ-InputCity')?.value} - 
               ${document.querySelector('#PJ-InputState')?.value} – CEP: ${document.querySelector('#PJ-InputCep')?.value}, neste ato representado pelo <strong>Sr. ${document.querySelector('#PJ-InputNameRepresentative')?.value}</strong>, 
               ${document.querySelector('#PJ-InputNationalityRepresentative')?.value}, ${document.querySelector('#PJ-InputProfessionRepresentative')?.value}, RG nº ${document.querySelector('#PJ-InputRGRepresentative')?.value} e inscrito no CPF sob o nº 
               ${document.querySelector('#PJ-InputCPFRepresentative')?.value}.</p>`

                      document.querySelector('.procuracao-ass-pf').style.display = 'none'
                      document.querySelector('.procuracao-ass-pj').style.display = 'table'
                      document.querySelector('.procuracao-ass-pj').innerHTML = `
                            <p style={{textAlign:'center'}}>${document.querySelector('#PJ-InputNameRepresentative')?.value}</p>
                            <p style={{textAlign:'center'}}>CPF nº ${document.querySelector('#PJ-InputCPFRepresentative')?.value}</p> 
                            <p style={{textAlign:'center'}}>${document.querySelector('#PJ-InputSocialReason')?.value}</p> 
                            <p style={{textAlign:'center'}}>CNPJ nº ${document.querySelector('#PJ-InputCNPJ')?.value}</p>
                            `

                    }
                    document.querySelector('.procuracao').style.display = 'flex'
                  }}>Procuração</button>


                </div>
              </div>
            </div>
          </div>
          <div className="project-map">
            <h3 className='title-section'>Endereço da Instalação: </h3>
            <div className="map-content">
              <div className='body-address'>
                <form className='form-address' action="">
                  <div style={{ display: 'flex', margin: '2px 0' }}>
                    <h4>Usar endereço do Cliente:</h4>&nbsp;<input
                      type='checkbox'
                      className='address-customer-checkbox input-check'
                      onClick={handleAddress}
                    />
                  </div>

                  <div>
                    <input type="text" className='cep-service' placeholder='CEP' onChange={(e) => {
                      e.target.value = cepFormat(e.target.value)
                      if (e.target.value.length > 8) {
                        fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                          .then(response => response.json())
                          .then(data => {
                            if (data.erro) {
                              return
                            }
                            setAddressCep(e.target.value)
                            document.querySelector('.street-service').value = data.logradouro
                            document.querySelector('.district-service').value = data.bairro
                            document.querySelector('.city-service').value = data.localidade
                            document.querySelector('.state-service').value = data.uf
                            setAddressStreet(data.logradouro)
                            setAddressNeighborhood(data.bairro)
                            setAddressCity(data.localidade)
                            setAddressState(data.uf)


                          })
                      }
                      if (e.target.value.length < 9) {
                        document.querySelector('.cep-service').style.border = '2px solid red'
                      }
                      else {
                        document.querySelector('.cep-service').style.border = '1px solid white'
                      }
                    }} />
                  </div>
                  <div>
                    <input type="text" className='street-service' placeholder='Rua' onChange={(e) => {
                      setAddressStreet(e.target.value)
                      if (!e.target.value) {
                        document.querySelector('.street-service').style.border = '2px solid red'
                      }
                      else {
                        document.querySelector('.street-service').style.border = '1px solid white'
                      }
                    }} />
                  </div>
                  <div>
                    <input type="text" className='number-service' placeholder='Número' onChange={(e) => {
                      setAddressNumber(e.target.value)
                      if (!e.target.value) {
                        document.querySelector('.number-service').style.border = '2px solid red'
                      }
                      else {
                        document.querySelector('.number-service').style.border = '1px solid white'
                      }
                    }} />
                  </div>
                  <div>
                    <input type="text" className='district-service' placeholder='Bairro' onChange={(e) => {
                      setAddressNeighborhood(e.target.value)
                      if (!e.target.value) {
                        document.querySelector('.district-service').style.border = '2px solid red'
                      }
                      else {
                        document.querySelector('.district-service').style.border = '1px solid white'
                      }
                    }} />
                  </div>
                  <div>
                    <input type="text" className='city-service' placeholder='Cidade' onChange={(e) => {
                      setAddressCity(e.target.value)
                      if (!e.target.value) {
                        document.querySelector('.city-service').style.border = '2px solid red'
                      }
                      else {
                        document.querySelector('.city-service').style.border = '1px solid white'
                      }
                    }} />
                  </div>
                  <div>
                    <input type="text" className='state-service' placeholder='Estado' onChange={(e) => {
                      setAddressState(e.target.value)
                      if (!e.target.value) {
                        document.querySelector('.state-service').style.border = '2px solid red'
                      }
                      else {
                        document.querySelector('.state-service').style.border = '1px solid white'
                      }
                    }} />
                  </div>
                </form>

                <div className='maps'>
                  <div className=''>
                    {isLoaded ? (
                      <GoogleMap

                        mapContainerStyle={containerStyle}
                        zoom={22}
                        center={center}
                        options={options}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        <Marker position={center} />
                      </GoogleMap>
                    ) : <></>
                    }
                  </div>
                  <div className=''>
                    {isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        zoom={1000}
                        center={center}
                        options={options2}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      /*onClick={(e)=>{
                        console.log(e.latLng.lat())
                        console.log(e.latLng.lng())
                      }}*/
                      >
                        <StreetViewPanorama
                          position={center}
                          visible={true}
                          options={{
                            enableCloseButton: false,
                            addressControl: true,
                            fullscreenControl: true,
                            panControl: true,
                            zoomControl: false,
                            clickToGo: true,
                            scrollwheel: false,
                            visible: true,
                            showRoadLabels: true,
                            motionTracking: true,
                            motionTrackingControl: false,
                            motionTrackingControlOptions: true,

                          }}
                        />
                      </GoogleMap>

                    ) : <></>
                    }
                  </div>
                </div>
              </div>

              <div className='position-click' style={{ marginRight: '10px' }}>
                <h5>Confirme no mapa abaixo o telhado do cliente:</h5>
                <br />
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={selectStyle}
                    zoom={1000}
                    center={center}
                    options={options2}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onClick={(e) => {
                      setLatitudeClick(e.latLng.lat())
                      setLongitudeClick(e.latLng.lng())
                    }}

                  >
                    <Marker
                      position={positionClick}

                    />

                  </GoogleMap>

                ) : <></>
                }


              </div>
            </div>
          </div>
        </div>
        <div className='form-rateio'>

          <h2>Formulário de Rateio</h2>
          <br />
          <span className="s5">Preencha os campos abaixo de acordo com quantidade de UCs.</span>
          <p style={{ textAlign: 'center', fontSize: '8pt', fontFamily: 'serif', color: 'red' }}> A soma das pocentagens de distrubição deve ser igual a 100%</p>
          <br />
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio1-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                e.target.value = e.target.value.replace(/\D/g, '')
                var a = e.target.value
                setRateio1({ ...rateio1, uc: a, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })
              }} />

            <input className='rateio1-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                //e e.target.value = e.target.value.replace(/\D/g, '')
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio1({ ...rateio1, percent: e.target.value })
              }} />
            <input disabled className='percentR1' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' />
          </div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio2-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio2({ ...rateio2, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio2-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio2({ ...rateio2, percent: e.target.value })

              }} />
            <input disabled className='percentR2' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' />
          </div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio3-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio3({ ...rateio3, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio3-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio3({ ...rateio3, percent: e.target.value })
              }} />
            <input disabled className='percentR3' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' />
          </div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio4-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio4({ ...rateio4, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio4-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio4({ ...rateio4, percent: e.target.value })
              }} />

            <input disabled className='percentR4' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' />
          </div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio5-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio5({ ...rateio5, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio5-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio5({ ...rateio5, percent: e.target.value })
              }} />
            <input disabled className='percentR5' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' />
          </div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio6-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio6({ ...rateio6, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio6-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio6({ ...rateio6, percent: e.target.value })
              }} />
            <input disabled className='percentR6' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' /></div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio7-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio7({ ...rateio7, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio7-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio7({ ...rateio7, percent: e.target.value })
              }} />
            <input disabled className='percentR7' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' /></div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio8-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio8({ ...rateio8, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio8-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio8({ ...rateio8, percent: e.target.value })
              }} />
            <input disabled className='percentR8' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' /></div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio9-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio9({ ...rateio9, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio9-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio9({ ...rateio9, percent: e.target.value })
              }} />
            <input disabled className='percentR9' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' /></div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio10-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio10({ ...rateio10, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio10-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio10({ ...rateio10, percent: e.target.value })
              }} />
            <input disabled className='percentR10' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' /></div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio11-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio11({ ...rateio11, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio11-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio11({ ...rateio11, percent: e.target.value })
              }} />
            <input disabled className='percentR11' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' /></div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio12-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio12({ ...rateio12, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio12-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio12({ ...rateio12, percent: e.target.value })
              }} />
            <input disabled className='percentR12' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' /></div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio13-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio13({ ...rateio13, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio13-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio13({ ...rateio13, percent: e.target.value })
              }} />
            <input disabled className='percentR13' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' /></div>
          <div style={{ display: 'table', flexDirection: 'row' }}>
            <input className='rateio14-uc' placeholder='UC:' type="text" style={{ border: 'none', borderBottom: '1px solid' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio14({ ...rateio14, uc: e.target.value, cpf_cnpj: document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : document.querySelector('#PJ-InputCNPJ')?.value })


              }} />


            <input className='rateio14-percent' placeholder='kWh' type="text" style={{ border: 'none', borderBottom: '1px solid', marginLeft: '10px' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '')
                setRateio14({ ...rateio14, percent: e.target.value })
              }} />
            <input disabled className='percentR14' style={{ textAlign: 'center', fontSize: '9pt', fontFamily: 'serif', minWidth: '30px', maxWidth: '30px', marginLeft: '5px' }} placeholder='0%' /></div>
          <div style={{ display: 'flex', maxHeight: '10px' }}><input disabled value={`Pocentagem Total:`} style={{ maxHeight: '15px', marginLeft: '156px', textAlign: 'center', fontSize: '8pt', fontFamily: 'serif', marginTop: '3px' }} /><input disabled value={`${percentTotal}%`} style={{ maxHeight: '15px', minWidth: '35px', maxWidth: '35px', marginLeft: '5px' }} id='percentTotal' /></div>
          <br />
          <p style={{ textAlign: 'center', marginRight: '39px' }}>
            <button style={{ background: 'green' }} onClick={() => {
              setReplaceState({ dssds: 1 })
              for (let i = 1; i <= 14; i++) {
                if (document.querySelector(`.rateio${i}-uc`)?.value === '' && document.querySelector(`.rateio${i}-percent`)?.value === '') {
                  document.querySelector(`.rateio${i}-uc`).style.border = 'none'
                  document.querySelector(`.rateio${i}-percent`).style.border = 'none'

                  document.querySelector(`.percentR${i}`).value = ''
                  //  document.querySelector(`.rateio${i}Per`).innerHTML = ''
                }
                if (document.querySelector(`.rateio${i}-uc`)?.value !== '' && document.querySelector(`.rateio${i}-percent`)?.value !== '') {


                  document.querySelector(`.rateio${i}-uc`).style.border = 'none'
                  document.querySelector(`.rateio${i}-percent`).style.border = 'none'

                }
                if (document.querySelector(`.rateio${i}-percent`)?.value !== '' && document.querySelector(`.rateio${i}-uc`)?.value === '') {
                  toast.error('Campos não preenchidos')
                  document.querySelector(`.rateio${i}-uc`).style.border = '1px solid red'
                  return
                } else if (document.querySelector(`.rateio${i}-uc`)?.value !== '' && document.querySelector(`.rateio${i}-percent`)?.value === '') {
                  toast.error('Campos não preenchidos')
                  document.querySelector(`.rateio${i}-percent`).style.border = '1px solid red'
                  return
                }
              }
              if (document.querySelector(`.rateio${1}-uc`)?.value === '' || document.querySelector(`.rateio${1}-percent`)?.value === '') {
                toast.error('Preencha os primeiros campos')
                document.querySelector(`.rateio${1}-uc`).style.border = '1px solid red'
                document.querySelector(`.rateio${1}-percent`).style.border = '1px solid red'
                return
              }
              handlePocentagemRateio()
              if (percentTotal !== 100) {

                return
              }
              toast.success('Verificação Concluida!')
            }}>Verificar Valores</button>
            <button className='voltar' onClick={() => {
              setReplaceState({ dssds: 1 })

              for (let i = 1; i <= 14; i++) {
                if (document.querySelector(`.rateio${i}-uc`)?.value === '' && document.querySelector(`.rateio${i}-percent`)?.value === '') {
                  document.querySelector(`.rateio${i}-uc`).style.border = 'none'
                  document.querySelector(`.rateio${i}-percent`).style.border = 'none'

                  document.querySelector(`.percentR${i}`).value = ''
                  // document.querySelector(`.rateio${i}Per`).innerHTML = ''
                }
                if (document.querySelector(`.rateio${i}-uc`)?.value !== '' && document.querySelector(`.rateio${i}-percent`)?.value !== '') {


                  document.querySelector(`.rateio${i}-uc`).style.border = 'none'
                  document.querySelector(`.rateio${i}-percent`).style.border = 'none'

                }
                if (document.querySelector(`.rateio${i}-percent`)?.value !== '' && document.querySelector(`.rateio${i}-uc`)?.value === '') {
                  toast.error('Campos não preenchidos')
                  document.querySelector(`.rateio${i}-uc`).style.border = '1px solid red'
                  return
                } else if (document.querySelector(`.rateio${i}-uc`)?.value !== '' && document.querySelector(`.rateio${i}-percent`)?.value === '') {
                  toast.error('Campos não preenchidos')
                  document.querySelector(`.rateio${i}-percent`).style.border = '1px solid red'
                  return
                }
              }
              if (document.querySelector(`.rateio${1}-uc`)?.value === '' || document.querySelector(`.rateio${1}-percent`)?.value === '') {
                toast.error('Preencha os primeiros campos')
                document.querySelector(`.rateio${1}-uc`).style.border = '1px solid red'
                document.querySelector(`.rateio${1}-percent`).style.border = '1px solid red'
                return
              }
              setReplaceState({ dssds: 1 })

              handlePocentagemRateio()
              if (percentTotal !== 100) {
                toast.info('Clique em Verificar Valores')
                return
              }
              toast.success('Solicitação de Rateio Enviada!')
              document.querySelector('.rateio').style.display = 'flex'

            }}>Solicitar&nbsp;Rateio</button>
            <button style={{ backgroundColor: '#dd0000' }} onClick={() => {
              document.querySelector('.form-rateio').style.display = 'none'
              setRateio1({ uc: '', percent: '' })
              setRateio2({ uc: '', percent: '' })
              setRateio3({ uc: '', percent: '' })
              setRateio4({ uc: '', percent: '' })
              setRateio5({ uc: '', percent: '' })
              setRateio6({ uc: '', percent: '' })
              setRateio7({ uc: '', percent: '' })
              setRateio8({ uc: '', percent: '' })
              setRateio9({ uc: '', percent: '' })
              setRateio10({ uc: '', percent: '' })
              setRateio11({ uc: '', percent: '' })
              setRateio12({ uc: '', percent: '' })
              setRateio13({ uc: '', percent: '' })
              setRateio14({ uc: '', percent: '' })
              setPercentTotal(0)
              for (let i = 1; i < 15; i++) {
                document.querySelector(`.rateio${i}-uc`).value = ''
                document.querySelector(`.rateio${i}-percent`).value = ''
                document.querySelector(`.percentR${i}`).value = ''
              }

            }}>cancelar</button>

          </p>
        </div>


        
<div className="rateio">
<p style={{ textAlign: 'center' }}>
<button style={{backgroundColor:'#d28100e9'}} onClick={() => {
    document.querySelector('.rateio').style.display = 'none'
    document.querySelector('.form-rateio').style.display = 'flex'
  }}>Voltar</button>
  <button style={{background:'#03a829'}} onClick={handlePdfDownloadRateio}>Download</button>
  <button style={{ backgroundColor: '#dd0000' }} onClick={() => {
    document.querySelector('.rateio').style.display = 'none'
    setRateio1({ uc: '', percent: '' })
    setRateio2({ uc: '', percent: '' })
    setRateio3({ uc: '', percent: '' })
    setRateio4({ uc: '', percent: '' })
    setRateio5({ uc: '', percent: '' })
    setRateio6({ uc: '', percent: '' })
    setRateio7({ uc: '', percent: '' })
    setRateio8({ uc: '', percent: '' })
    setRateio9({ uc: '', percent: '' })
    setRateio10({ uc: '', percent: '' })
    setRateio11({ uc: '', percent: '' })
    setRateio12({ uc: '', percent: '' })
    setRateio13({ uc: '', percent: '' })
    setRateio14({ uc: '', percent: '' })
    setPercentTotal(0)
    for (let i = 1; i < 15; i++) {
      document.querySelector(`.rateio${i}-uc`).value = ''
      document.querySelector(`.rateio${i}-percent`).value = ''
      document.querySelector(`.percentR${i}`).value = ''
    }

  }}>cancelar</button>
  
</p>

<br />
<div className="body-rateio">


<p style={{textAlign:'center',fontFamily:'sans-serif',fontSize:'7.5pt',marginBottom:'-40px',marginTop:'20px'}}>INTERNAL</p>
  <div style={{ gap: '00px',padding:'0px 0px 20px 190px',display:'flex',justifyContent:'center',alignItems:'center' }} >
    <p style={{ textIndent: "0pt", textAlign: "center", paddingLeft:'50px',marginRight:'100px' }}>
      <span>
        <img
          width={128}
          height={47}
          alt="image"
          src={enelLogo}
        />
      </span>
    </p>
    <div>

      <p
        className="s1"
        style={{ paddingTop: "80pt", textIndent: "0pt", textAlign: "right" ,fontSize:'8pt' }}
      >
       <strong> Enel Distribuição Ceará</strong>
      </p>
 
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
    </div>
  </div>
 <h6
    style={{
      paddingTop: "11pt",
      paddingLeft: "20pt",
      textIndent: "0pt",
      textAlign: "center",
      fontSize: "18pt"
    }}
  >
    Formulário de Solicitação de Rateio - GD
  </h6>
  <p style={{textAlign:'center'}}>
  <p style={{ textIndent: "0pt", textAlign: "center" }}>
    <br />
  </p>
  <ol >
    <li >
      <h2
        style={{ paddingLeft: "48pt", textIndent: "48pt", textAlign: "left", fontSize: "12pt" }}
      >
       1. Identificação da Unidade Consumidora (UC):
      </h2>
      <p style={{ textIndent: "0pt", textAlign: "center" }}>
        <br />
      </p>
      <table
        style={{ borderCollapse: "collapse", marginLeft: "50pt" }}
        cellSpacing={0}
      >
        <tbody>
          <tr style={{ height: "15pt" }}>
            <td
              style={{
                width: "218pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left"
                }}
              >
                Código da UC: {document.getElementById('number-uc')?.value}
              </p>
            </td>
            <td
              style={{
                width: "256pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "70pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left"
                }}
              >
                Classe: {document.getElementById('project-type')?.value}
              </p>
            </td>
          </tr>
          <tr style={{ height: "14pt" }}>
            <td
              style={{
                width: "218pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left"
                }}
              >
                Titular da UC: <span style={{ fontSize: '9pt' }}>{document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputName')?.value : '' || document.querySelector('.select-customer-type')?.value === 'pj' ? document.querySelector('#PJ-InputSocialReason')?.value : ''}</span>
              </p>
            </td>
            <td
              style={{
                width: "256pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "67pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left"
                }}
              >
                CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{document.querySelector('.select-customer-type')?.value === 'pf' ? document.querySelector('#PF-InputCPF')?.value : '' || document.querySelector('.select-customer-type')?.value === 'pj' ? document.querySelector('#PJ-InputCNPJ')?.value : ''}</span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "14pt" }}>
            <td
              style={{
                width: "218pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left"
                }}
              >
                Endereço: <span style={{ fontSize: '9pt' }}>{addressStreet} - {addressNumber ? addressNumber : 'S/N'} </span>
              </p>
            </td>
            <td
              style={{
                width: "256pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "70pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left"
                }}
              >
                CEP: <span style={{ fontSize: '9pt' }}>{addressCep}</span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "14pt" }}>
            <td
              style={{
                width: "218pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left"
                }}
              >
                Bairro: <span style={{ fontSize: '9pt' }}>{addressNeighborhood}</span>
              </p>
            </td>
            <td
              style={{
                width: "256pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "70pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left"
                }}
              >
                Cidade: <span style={{ fontSize: '9pt' }}>{addressCity}</span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "14pt" }}>
            <td
              style={{
                width: "218pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left"
                }}
              >
                <a
                  href="mailto:enel@fortesol.com.br"
                  className="s4"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  E-mail: enel@fortesol.com.br
                </a>
              </p>
            </td>
            <td
              style={{
                width: "256pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "70pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left"
                }}
              >
                Telefone: (85) 4141-4900
              </p>
            </td>
          </tr>
          <tr style={{ height: "14pt" }}>
            <td
              style={{
                width: "218pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p
                className="s3"
                style={{
                  paddingLeft: "6pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left"
                }}
              >
                Celular: (85) 9.9986-0900
              </p>
            </td>
            <td
              style={{
                width: "256pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt"
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "center" }}>
                <br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ textIndent: "0pt", textAlign: "center" }}>
        <br />
      </p>
      <table style={{border:'1px solid #000',borderCollapse:'collapse',marginLeft: "50pt"}}>
        <tbody>
          <tr>
            <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'left',minWidth:'93.4px'}}>
              <p>Inclusão:</p>
            </td>
            <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'left',minWidth:'93.4px'}}>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </td>
            </tr>
            <tr>
            <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'left',minWidth:'93.4px'}}>
              <p>Alteração:</p>
            </td>
            <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'left',minWidth:'93.4px'}}>
            </td>
            </tr>
            <tr>
            <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'left',minWidth:'93.4px'}}>
              <p>Exclusão:</p>
            </td>
            <td style={{border:'1px solid #000', paddingLeft:'10px',paddingRight:'20px',textAlign:'left',minWidth:'93.4px'}}>
            </td>
            </tr>
        </tbody>
      </table>
    </li>
    <li style={{paddingLeft:'40px'}}>
      <h2
        style={{
          paddingTop: "10pt",
          paddingLeft: "48pt",
          textIndent: "18pt",
          textAlign: "justify",
          fontSize: "12pt"
        }}
      >
       
        2.  Lista de unidades consumidoras participantes do sistema de compensação
          <br />
          indicando a porcentagem de rateio dos créditos e o enquadramento:
          <br />

        <span className="s5">
          (Conforme incisos VI a VIII do art. 2º da Resolução Normativa nº
          482/2012)
        </span>
      </h2>
    </li>
  </ol>
  <p style={{ textIndent: "0pt", textAlign: "center" }}>
    <br />
  </p>
  <table
    style={{ borderCollapse: "collapse", marginLeft: "50pt" }}
    cellSpacing={0}
  >
    <tbody>

      <tr style={{ height: "16pt" }}>
        <td
          style={{
            width: "94pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingTop: "1pt",
              paddingLeft: "6pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio1?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingTop: "1pt",
              paddingLeft: "69pt",
              textIndent: "0pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF:<span style={{ fontSize: '9pt' }}>{rateio1?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              textAlign: "right"
            }}
          >
            : <span className='rateio1Per' style={{ fontSize: '9pt' }}> </span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio2?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio2?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio2Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio3?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio3?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio3Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio4?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio4?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio4Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio5?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF:  <span style={{ fontSize: '9pt' }}>{rateio5?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio5Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio6?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio6?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio6Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }} >{rateio7?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio7?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio7Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio8?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio8?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio8Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio9?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio9?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio9Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio10?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF:   <span style={{ fontSize: '9pt' }}>{rateio10?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio10Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio11?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio11?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio11Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio12?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio12?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio12Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio13?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio13?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio13Per'></span>%
          </p>
        </td>
      </tr>
      <tr style={{ height: "14pt" }}>
        <td
          style={{
            width: "94pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            UC:<span style={{ fontSize: '9pt' }}>{rateio14?.uc}</span>
          </p>
        </td>
        <td
          style={{
            width: "226pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingLeft: "69pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left"
            }}
          >
            CNPJ/CPF: <span style={{ fontSize: '9pt' }}>{rateio14?.cpf_cnpj}</span>
          </p>
        </td>
        <td
          style={{
            width: "154pt",
            borderTopStyle: "solid",
            borderTopWidth: "1pt",
            borderBottomStyle: "solid",
            borderBottomWidth: "1pt"
          }}
        >
          <p
            className="s3"
            style={{
              paddingRight: "6pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "right"
            }}
          >
            : <span style={{ fontSize: '9pt' }} className='rateio14Per'></span>%
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p style={{ textIndent: "0pt", textAlign: "center" }}>
    <br />
  </p>
  <table
    style={{ borderCollapse: "collapse", marginLeft: "50pt" }}
    cellSpacing={0}
  >
    <tbody>
      <tr style={{ height: "17pt" }}>
        <td
          style={{
            width: "474pt",
          

          }}
        >
          <h2
            className="s7"
            style={{
              paddingLeft: "10pt",
              paddingRight: "197pt",
              textIndent: "0pt",
              lineHeight: "13pt",
              textAlign: "left",
              fontSize:'11pt'
            }}
          >
            IMPORTANTE
          </h2>
        </td>
      </tr>
      <tr style={{ height: "29pt" }}>
        <td
          style={{
            width: "474pt",
   
            padding:'2px'
          }}
        >
          <ul style={{listStyleType:'circle'}}>
            <li  >
              <p
                className="s8"
                style={{
                  paddingLeft: "10pt",
                  paddingRight: "9pt",
                  textIndent: "10pt",
                  lineHeight: "110%",
                  fontSize: '9pt',
                  textAlign: "justify",
                }}
              >
              Caso a leitura da unidade beneficiaria ocorra antes ou no
                  mesmo dia que a unidade geradora, os creditos serão faturados apenas no próximo faturamento.
             
              </p>
            </li>
          </ul>
        </td>
      </tr>
     
    </tbody>
  </table>
  <p style={{ textIndent: "0pt", textAlign: "center" }}>
    <br />
  </p>
  <br />
  <br />
  <br />
  <br />
  <br />
  <p style={{ textAlign: 'center' }}>_____________________________________</p>
  <p
    style={{
      paddingLeft: "105pt",
      textIndent: "0pt",
      lineHeight: "1pt",
      textAlign: "left"
    }}
  ></p>
  <h3 style={{ paddingLeft: "0pt", textIndent: "0pt", textAlign: "center" }}>
    Rodrigo Fernandes Feijó
  </h3>
  <p
    className="s9"
    style={{
      paddingTop: "1pt",
      paddingLeft: "0pt",
      textIndent: "2pt",
      lineHeight: "120%",
      textAlign: "center",
      fontSize:'9pt',
      textDecoration:'none',
    
    }}
  >

      Engenheiro Eletricista
      <br />
      CREA-CE nº 061872146-0
      <br />

  </p>
  <h4
    className=""
    style={{
      paddingLeft: "0pt",
      textIndent: "0pt",
      lineHeight: "9pt",
      textAlign: "center"
      ,textDecoration:'none',
      fontSize:'9pt'
    }}
  >
    Representante Legal
  </h4>
  <p style={{ textIndent: "0pt", textAlign: "center" }}>
    <br />
  </p>
  <h4 style={{ paddingLeft: "0pt", textIndent: "0pt", textAlign: "center" }}>
    Enel Distribuição Ceará&nbsp;<span style={{fontSize:'9pt',fontStyle:'normal',fontFamily:'serifss',textDecoration:'none'}}>&nbsp;–&nbsp; www.enel.com.br</span>
  </h4>

</p>
</div>
</div>



        <div id="popup-instalacao-fotovoltaica">
          <div id='popup'>
            <div className="popup-inner">
              <h2>Instalação fotovoltaica</h2>
              <br />
              <form id="formularioPopup">
                <h3>Módulos</h3>

                <div style={{ minHeight: '100px', maxHeight: '100px', overflowY: 'auto' }} id="formularioPopup">
                  {
                    inputModulos && inputModulos?.map((input, index) => (
                      <div style={{ display: 'flex', paddingRight: index > 0 ? '19px' : '0px' }} key={index}>
                        <div id="formularioPopupInputs">
                          <input type="text" id={`brand-module${index}`} placeholder='Marca' onChange={(e) => {
                            const newRegister = inputModulos.map((item, i) => {
                              if (i === index) {
                                return { ...item, brand: e.target.value }
                              }
                              return item
                            }
                            )
                            setInputModulos(newRegister)
                          }}
                          />
                          <input type="text" id={`model-module${index}`} placeholder='Modelo' onChange={(e) => {
                            const newRegister = inputModulos.map((item, i) => {
                              if (i === index) {
                                return { ...item, model: e.target.value }
                              }
                              return item
                            }
                            )
                            setInputModulos(newRegister)
                          }} />
                          <input type="text" id={`qtd-module${index}`} placeholder='QTD' onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '')
                            const newRegister = inputModulos.map((item, i) => {
                              if (i === index) {
                                return { ...item, qtd: e.target.value }
                              }
                              return item
                            }
                            )
                            setInputModulos(newRegister)
                          }} />
                        </div>
                        {index === 0 ?
                          <button onClick={novoInputModulos} type='button' className='add-input-modulo'>+</button>
                          :
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <MdDelete onClick={() => {
                              const values = [...inputModulos]
                              values.splice(index, 1)
                              setInputModulos(values)
                            }
                            } />
                          </div>}
                      </div>
                    )
                    )
                  }
                </div>
                <div>
                </div>
              </form>
              <br />
              <form id="formularioPopup">
                <h3>Inversores</h3>
                <div style={{ minHeight: '100px', maxHeight: '100px', overflowY: 'auto' }} id="formularioPopup">
                  {
                    inputInversores && inputInversores?.map((input, index) => (
                      <div style={{ display: 'flex', paddingRight: index > 0 ? '19px' : '0px' }} key={index}>
                        <div id="formularioPopupInputs">
                          <input type="text" id={`brand-inverter${index}`} placeholder='Marca' onChange={e => {
                            const newRegister = inputInversores.map((item, i) => {
                              if (i === index) {
                                return { ...item, brand: e.target.value }
                              }
                              return item
                            }
                            )
                            setInputInversores(newRegister)
                          }} />
                          <input type="text" id={`model-inverter${index}`} placeholder='Modelo' onChange={e => {
                            const newRegister = inputInversores.map((item, i) => {
                              if (i === index) {
                                return { ...item, model: e.target.value }
                              }
                              return item
                            }
                            )
                            setInputInversores(newRegister)

                          }} />
                          <input type="text" id={`qtd-inverter${index}`} placeholder='QTD' onChange={e => {
                            e.target.value = e.target.value.replace(/\D/g, '')
                            const newRegister = inputInversores.map((item, i) => {
                              if (i === index) {
                                return { ...item, qtd: e.target.value }
                              }
                              return item
                            }
                            )
                            setInputInversores(newRegister)
                          }} />
                        </div>

                        {index === 0 ?
                          <button onClick={novoInputInversores} type='button' className='add-input-inversor'>+</button>
                          :
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <MdDelete onClick={() => {
                              const values = [...inputInversores]
                              values.splice(index, 1)
                              setInputInversores(values)
                            }
                            } />
                          </div>}
                      </div>
                    )
                    )
                  }
                </div>
                <div>
                </div>
                <div className='data-photovoltaic'>
                  <h1 style={{ fontSize: '9pt' }}>OU</h1>
                  <br />
                  <label for='data_photovoltaic' style={{ width: '90px', marginRight: '00px' }} className='label-data_photovoltaic style-label'>enviar arquivo</label> <input hidden type='file' className='data_photovoltaic' id='data_photovoltaic' onChange={e => {
                    const file = e.target.files[0]
                    const formdata = new FormData()
                    formdata.append('data_photovoltaic', file)
                    setImg_data_photovoltaic(formdata)

                  }} />
                </div>
              </form>
              <br />
              <button style={{ background: 'green' }} type="button" onClick={() => {


                if (img_data_photovoltaic) {
                  toast.success('Arquivo enviado com sucesso')
                } else {
                  for (let i = 0; i < inputModulos.length; i++) {
                    if (document.querySelector(`#brand-module${i}`).value === '') {
                      document.querySelector(`#brand-module${i}`).style.border = '1px solid red'
                      document.querySelector(`#brand-module${i}`).focus()
                      toast.error('Preencha todos os campos ou envie um arquivo com todas as informações')
                      return
                    } else {
                      document.querySelector(`#brand-module${i}`).style.border = '1px solid #ccc'
                    }
                    if (document.querySelector(`#model-module${i}`).value === '') {
                      document.querySelector(`#model-module${i}`).style.border = '1px solid red'
                      document.querySelector(`#model-module${i}`).focus()
                      return
                    } else {
                      document.querySelector(`#model-module${i}`).style.border = '1px solid #ccc'
                    }
                    if (document.querySelector(`#qtd-module${i}`).value === '') {
                      document.querySelector(`#qtd-module${i}`).style.border = '1px solid red'
                      document.querySelector(`#qtd-module${i}`).focus()
                      return
                    } else {
                      document.querySelector(`#qtd-module${i}`).style.border = '1px solid #ccc'

                    }
                  }
                  for (let i = 0; i < inputInversores.length; i++) {
                    if (document.querySelector(`#brand-inverter${i}`).value === '') {
                      document.querySelector(`#brand-inverter${i}`).style.border = '1px solid red'
                      document.querySelector(`#brand-inverter${i}`).focus()
                      toast.error('Preencha todos os campos ou envie um arquivo com todas as informações')

                      return
                    } else {
                      document.querySelector(`#brand-inverter${i}`).style.border = '1px solid #ccc'
                    }
                    if (document.querySelector(`#model-inverter${i}`).value === '') {
                      document.querySelector(`#model-inverter${i}`).style.border = '1px solid red'
                      document.querySelector(`#model-inverter${i}`).focus()
                      return
                    } else {
                      document.querySelector(`#model-inverter${i}`).style.border = '1px solid #ccc'
                    }
                    if (document.querySelector(`#qtd-inverter${i}`).value === '') {
                      document.querySelector(`#qtd-inverter${i}`).style.border = '1px solid red'
                      document.querySelector(`#qtd-inverter${i}`).focus()
                      return
                    } else {
                      document.querySelector(`#qtd-inverter${i}`).style.border = '1px solid #ccc'
                    }
                  }

                }
                document.querySelector('#popup-instalacao-fotovoltaica').classList.remove('show')
              }}>Salvar</button>
            </div>
          </div>
        </div>


        <div className='procuracao'>
          <div className='form-button'>
            <button className='bt-update-edit' onClick={handlePdfDownloadPro} >Download</button>
            <button className="bt-delete-edit" onClick={() => {
              document.querySelector('.procuracao').style.display = 'none'
            }}>Cancelar</button>


          </div>
          <div className="body-procuracao">
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '0px',
              width: '80%',
              maxHeight: '1123px',

              minHeight: '1123px',
              textAlign: 'center',
            }} >
              <h2>Procuração Particular</h2>
              <div className='procuracao-text procuracao-pj'>

              </div>
              <div className='procuracao-text procuracao-pf'>

              </div>
              <div className="procuracao-text">
                <p><strong>OUTORGADO</strong>: Rodrigo Fernandes Feijó, brasileiro, engenheiro eletricista, inscrito no CPF
                  sob nº 669.011.203-97, RG nº 99010500307 e RNP nº 0618721460 CREA-CE,
                  residente e domiciliado na Rua Doutor Gilberto Studart, 2189 – AP1602 – Cocó – Fortaleza-
                  CE, CEP: 60.192-115.</p>
              </div>
              <div className="procuracao-text">
                <p><strong>PODERES ESPECÍFICOS</strong>: Para representar o outorgante perante Companhia
                  Energética do Ceará, Agência Nacional de Energia Elétrica - ANEEL e Agência
                  Reguladora do Estado do Ceará - ARCE, relativo aos processos e procedimentos
                  de geração distribuída, podendo para tanto, requerer, alertar e assinar todos os
                  atos necessários ao bom e fiel cumprimento desse mandato</p>
              </div>
              <div className="procuracao-text">
                <p>Este Instrumento possuí validade até {handleDateValid()}.</p>
              </div>
              <div className="procuracao-text">
                <p style={{
                  textAlign: 'right',
                }}>Fortaleza, {handleDate()}.</p>
              </div>
              <div style={{ textAlign: 'center', marginTop: '70px', paddingBottom: '200px' }} className="procuracao-text">
                <p style={{ textAlign: 'center' }}>_____________________________________</p>
                <br />
                <div style={{ textAlign: 'center', width: '100%' }} className="procuracao-ass-pj">

                </div>
                <div style={{ textAlign: 'center', width: '100%' }} className="procuracao-ass-pf">

                </div>

              </div>
            </div>


          </div>
        </div>

      </div>



    </Sidebar>
  )
}

export default ServiceSolar
