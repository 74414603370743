
import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import './style.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Popup_demand from '../popup_details_demand';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArticleIcon from '@mui/icons-material/Article';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StartIcon from '@mui/icons-material/Start';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HeaderSolProjetos from '../headeSolProjetos';
import Start_and_Follow from '../start_and_follow';
import NotificationPopover from '../notifications';


function ListSolarServiceSystem() {
  const [linhasVisiveis, setLinhasVisiveis] = useState(0);
  const alturaLinha = 50; // Altura de cada linha da tabela (em pixels)

  useEffect(() => {
    const calcularLinhas = () => {
      const alturaTela = window.innerHeight; // Altura da janela do navegador
      const linhas = Math.floor(alturaTela / alturaLinha); // Calcula quantas linhas cabem
      setLinhasVisiveis(linhas);
    };

    // Calcula ao carregar a página
    calcularLinhas();

    // Adiciona um listener para recalcular em caso de redimensionamento da tela
    window.addEventListener('resize', calcularLinhas);

    // Remove o listener ao desmontar o componente
    return () => window.removeEventListener('resize', calcularLinhas);
  }, []);





  const [demand, setDemand] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = linhasVisiveis;

  useEffect(() => {
    // Definir a função fetchDemand fora do setInterval para que possamos chamá-la logo no início e dentro do intervalo.
    const fetchDemand = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system/?page=${currentPage}&limit=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setDemand(data);
        setTotalPages(data[1]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    // Chamada inicial para executar o fetchDemand assim que a página abrir
    fetchDemand();
  
    // Iniciar o intervalo para chamadas periódicas
    const interval = setInterval(fetchDemand, 10000);
  
    // Limpeza do intervalo ao desmontar o componente
    return () => clearInterval(interval);
  }, [currentPage, itemsPerPage]);
  


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ... renderizar a lista de produtos e a paginação


  const formatDate = (date) => {
    if(date === '---'){
      return '---'
    }
    const dateFormated = new Date(date)
    return dateFormated.toLocaleDateString() + ' ' + dateFormated.toLocaleTimeString()
  };


  document.querySelectorAll('.MuiPaginationItem-root').forEach((item) => {
    item.style.backgroundColor = '#004e64';
    item.style.color = '#fff';
    if (item.ariaCurrent === 'true') {
      item.style.backgroundColor = '#ffffff';
      item.style.color = '#004e64';
      item.style.border = '2px solid #004e64';
    }

  })
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const openClose = () => {
    setOpen(false);
  };

  const [openStart, setOpenStart] = React.useState(false);

  const handleClickOpenStart = () => {
    setOpenStart(true);
  };

  const openCloseStart = () => {
    setOpenStart(false);
  };




  const [handleData, setHandleData] = useState('');


  const searchDemand = () => {

    let value = document.querySelector('.search-demand').value;
    value = value.toLowerCase();
    value = value.replace('/', '-');
    if (value !== '') {
      fetch(`https://api.fortesol.com.br/api/demand_project_solar/search-system/?page=${currentPage}&limit=${itemsPerPage}&search=${value}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
          setDemand(data);
          setTotalPages(data[1]);

        })
      return
    }
    else {
      fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system/?page=${currentPage}&limit=${itemsPerPage}`, {
        //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
        .then((data) => {
          setDemand(data);
          setTotalPages(data[1]);
        })
    }
  }


  const [doc_proxy, setDoc_proxy] = useState(null)

     // Funções para abrir e fechar o Dialog de Notificações
     const [openNotifications, setOpenNotifications] = React.useState(false);

     const handleNotifications = () => {
         setOpenNotifications((prev) => !prev);
     };




  return (

    <Sidebar>
      <Grid id='list-project-solar-system'>
        <Grid className='project-solar'>
          <ToastContainer
            position="bottom-right"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            height={50}
          />
       <HeaderSolProjetos/>
          <br />
          <Grid sx={{ display: 'flex', padding: '0 50px 0 50px', justifyContent: 'space-between' }}>
            <Grid sx={{ display: 'flex', paddingLeft: '50px' }}>
              <Grid sx={{ display: 'flex' }}>
                <input style={{height:29}} type="text" className='search-demand' placeholder='Pesquisar'
                onChange={(e) => {
            

                  if (e.target.value === '') {
                    fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system/?page=${currentPage}&limit=${itemsPerPage}`, {
                      //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    }).then((response) => response.json())
                      .then((data) => {
                        setDemand(data);
                        setTotalPages(data[1]);
                      })
                  }
                }} 
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchDemand();
                  }
                }
                }                
                />
                <button id='bt-search' onClick={searchDemand}>Pesquisar</button>
              </Grid>
              <button onClick={() => {

setLoading(true);
try {
  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system/?page=${currentPage}&limit=${itemsPerPage}`, {
    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
    .then((data) => {
      setDemand(data);
      setTotalPages(data[1]);
    })

} catch (error) {
  console.error(error);
} finally {
  setLoading(false);
}


}} id='bt-all' >Todos</button>
         
              <button onClick={() => {


                setLoading(true);
                try {
                  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system-status/?status=${'Concluído'}&page=${currentPage}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {
                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }} id='bt-concluded'>Concluídos</button>
              <button onClick={() => {


                setLoading(true);
                try {
                  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system-status/?status=${'Em andamento'}&page=${currentPage}&limit=${itemsPerPage}`, {
                    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }).then((response) => response.json())
                    .then((data) => {
                      setDemand(data);
                      setTotalPages(data[1]);
                    })

                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }} id='bt-in-progress'>Em andamento</button>
              <button onClick={() => {

setLoading(true);
try {
  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system-status/?status=${'Solicitado'}&page=${currentPage}&limit=${itemsPerPage}`, {
    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
    .then((data) => {
      
      setDemand(data);
      setTotalPages(data[1]);
    })

} catch (error) {
  console.error(error);
} finally {
  setLoading(false);
}
}} id='bt-requested'> Solicitados</button>
              <button onClick={() => {

setLoading(true);
try {
  fetch(`https://api.fortesol.com.br/api/demand_project_solar/list-system-status/?status=${'Cancelado'}&page=${currentPage}&limit=${itemsPerPage}`, {
    //fetch(`https://api.fortesol.com.br/api/demand_project_solar/list?page=${currentPage}&limit=${itemsPerPage}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
    .then((data) => {
      
      setDemand(data);
      setTotalPages(data[1]);
    })

} catch (error) {
  console.error(error);
} finally {
  setLoading(false);
}
}} id='bt-canceled'> Cancelados</button>
   
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>

            <NotificationPopover handleClick={handleNotifications} open={openNotifications} id={''} destination='system' name_user={''} />

            <Link to='/services/new-project-solar'><button style={{ background: '#218380' }} > Novo Projeto</button></Link>
            </Grid>
          </Grid>

          <Grid id='tableDemands' style={{
            padding: '0 0px 0 50px',
          }}>
            {

              (
                <>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">COD.</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Numero UC</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Nome</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">CPF/CNPJ</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Status</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Data da Solicitação</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Data de Conclusão</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Cliente Integrador</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Detalhes</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 14 }}align="center">Projeto</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {demand[0]?.map((item, index) => (
                          <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#eaeaea' } }}>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.id_demand}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.number_uc_demand}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.name_customers_of_customers ? item.name_customers_of_customers : item.corporate_reason_customers_of_customers}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.cpf_customers_of_customers ? item.cpf_customers_of_customers : item.cnpj_customers_of_customers}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12, gap: 1 }} >
                            <Grid style={{display:'flex',alignItems:'center', justifyContent:'center',flexDirection:'row',gap:5}}>
                            {item.status}

{(item.status === 'Concluído') ? <CheckCircleOutlineIcon sx={{ fontSize: 15, color: '#0b532acf' }} /> : item.status === 'Cancelado' ? <CheckCircleOutlineIcon sx={{ fontSize: 15, color: '#9a0000' }} /> 
  : <Box
    sx={{
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: item.status === 'Solicitado' ? '#DAA520' : item.status === 'Em andamento' ? '#178948cf' : item.status === 'Concluído' ? '#228b22df' : item.status === 'Cancelamento Solicitado' ? '#c93a3a' : '',
      animation: 'pulse 1s infinite',
    }}
  />}
                                </Grid>
                              {/*<span className='span_check' style={{fontWeight:1000}}>&#10003;</span>*/}
                            </TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{formatDate(item.date_demand)}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">{item.date_conclusion_demand === '---' ? '---' : formatDate(item.date_conclusion_demand)}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">
                              {
                             item.name ? item.name : item.corporate_reason
                             }
                               </TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">
                           {/*   <button className='bt-details' onClick={() => {
                                //  document.querySelector(`.popup`).style.display = 'block';
                                handleClickOpen();
                                setHandleData(item);
                              }}>Detalhes</button>
                                 */}
                              <IconButton sx={{margin:'0px!important'}} className='bt-details' onClick={() => {
                                //  document.querySelector(`.popup`).style.display = 'block';
                                handleClickOpen();
                                setHandleData(item);
                              }
                              }><ArticleIcon sx={{ fontSize: 15 }}/></IconButton>
                              
                            </TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }}align="center">
                              <button id={`${item.id_demand}`} style={{margin:'0px!important'}} className='bt-start' onClick={() => {
                                //  document.querySelector(`.popup`).style.display = 'block';
                                handleClickOpenStart();
                                setHandleData(item);
                                if (item.status === 'Solicitado') {
                                  fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-status-demand/${item.id_demand}`, {
                                      method: 'PUT',
                                      headers: {
                                          'Content-Type': 'application/json',
                                      },
                                      body: JSON.stringify({ status: 'Em andamento' })
                                  }).then((response) => response.json())
                                      .then((data) => {
                                          document.getElementById('bt-all')?.click()
                                      })

                                      fetch(`https://api.fortesol.com.br/api/users/customer-or-employee/${item.id}`, {
                                        method: 'GET',
                                        headers: {
                                          'Content-Type': 'application/json',
                                        },
                                        })
                                    .then((response) => response.json())
                                    .then((data_) => {
                                      
                                    fetch(`https://api.fortesol.com.br/api/notifications/create`, {
                                        method: 'POST',
                                        headers: {
                                          'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                            
                                          customers_id: parseInt(item.id),
                                          title: `Projeto Iniciado`,
                                            message: `Projeto cod: ${item.id_demand} iniciado.`,                      
                                          view: false,
                                          view_date: '',
                                          creation_date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
                                          send_by: 'system',
                                          send_to: data_[0].name_user,
                                          id_demand_notifications: item.id_demand,
                                        }),
                                      }).then((response) => response.json())
                                        .then((data) => {})
                                    }).catch((error) => {
                                        console.error('Error:', error);
                                    });

                              }

                              


                              }}>
                                {item.status === 'Solicitado' ? 'Iniciar ' : item.status === 'Em andamento' ? 'Continuar ' : item.status === 'Concluído' ? ' Concluído' : item.status === 'Cancelado' ? ' Cancelado' :item.status === 'Cancelamento Solicitado' ? 'Responder' : 'Iniciar '}      
                                </button>      
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Start_and_Follow open={openStart} close={openCloseStart} data={handleData} />
                    <Popup_demand
                      data={handleData}
                      open={open}
                      handleClose={openClose}
                    />

                  </Paper>

                  <Pagination style={{ marginTop: '10px' }}
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, page) => handlePageChange(page)}
                    showFirstButton
                    showLastButton
                    boundaryCount={1} // Número de páginas antes e depois das ellipses
                    siblingCount={1} // Número de páginas antes e depois da página atual
                  />
                </>
              )}


          </Grid>


        </Grid>
      </Grid>
    </Sidebar>
  )
}

export default ListSolarServiceSystem
