import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React from 'react'

export default function ConfirmDialog(props) {
    

  return (
    <Dialog
    open={props.open}
    onClose={props.close}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" textAlign={'center'}    >{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <Typography variant="p" component="div">

        {props.msg1}
        </Typography>
        {
          props.msgCabo && <Alert severity="error">{props.msgCabo}</Alert>
        }
        {
          props.msgDisjuntor && <Alert severity="error">{props.msgDisjuntor}</Alert>
        }
        <Typography variant="p" component="div">

        {props.msg2}
        </Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.close} color="primary">
        Cancelar
      </Button>
      <Button onClick={props.confirm} color="error" autoFocus>
        confirmar
      </Button>
    </DialogActions>
  </Dialog>
  )
}
