import { createGlobalStyle} from "styled-components"

const GlobalStyle = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}
body{
  
   
    background-color: #d4d1d1;
    font-family: Arial, Helvetica, sans-serif;
    
}


`;
export default GlobalStyle