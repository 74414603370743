import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField } from '@mui/material'
import React from 'react'
import { toast } from 'react-toastify'

export default function Updade_Art(props) {
    const [numberArt, setNumberArt] = React.useState('')
    const [file, setFile] = React.useState(null)

    const updateArt = async () => {
    
        if (file && numberArt) {
          await  fetch(`https://api.fortesol.com.br/api/demand_project_solar/uploadDataArt/${props.id}`, {
                method: 'POST',
                body: file
            }).then(response => response.json())
                .then(data => {
                    if (data.message === 'success') {
                        fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-number-art/${props.id}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ number_art_demand: numberArt })
                        }).then(response => response.json())
                            .then(data => {
                                if (data.message === 'success') {
                                    toast.success('Informações atualizadas com sucesso')
                                }
                                document.getElementById('bt-search').click()
                                setTimeout(() => {
                                document.getElementById(`${props.id}`).click()
                                props.refresh()
                                if(props.click) document.getElementById('btn-art').click()
                                props.close()
                                }, 100)
                            }).catch(err => {
                               
                            })

                    }
                }).catch(err => {
                    toast.error('Erro ao enviar a Art')
                })
        } 
    }
    
  return (
    <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
        fullWidth={true}
    >
        <DialogContent>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}> Envio de Art </DialogTitle>
            <Paper sx={{ padding: 2, margin: 1 }}>
                <TextField
                    margin='dense'
                    fullWidth
                    id="outlined-basic"
                    label="Número da Art"
                    variant="outlined"
                    value={numberArt}
                    onChange={(e) =>{
                        e.target.value = e.target.value.replace(/\D/g, "") 
                        setNumberArt(e.target.value)}}
                />
                <Button variant="contained" component="label" fullWidth>
                    Selecione o arquivo
                    <input type="file"  accept="image/*, .pdf" hidden 
                    onChange={(e) =>{
                        const file = e.target.files[0]
                        const formdata = new FormData()
                        formdata.append('data_art', file)
                        setFile(formdata)
                    }}/>
                </Button>
            </Paper>            
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close} color="primary">
                Fechar
            </Button>
            <Button color="primary" autoFocus variant='contained' disabled={!file || !numberArt} onClick={updateArt}>
                Enviar
            </Button>
        </DialogActions>
    </Dialog>

  )
}
