import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./styleNavbar.css";
import {
 

  FaUser ,
     FaRegFileAlt,
    FaArrowRight,
    FaArrowLeft,
    FaRegSun,
    FaUserAlt,
    FaIdCardAlt,
    FaHome,
  
    FaRegCalendarAlt
  
  } from 'react-icons/fa'
  import { GiHamburgerMenu } from "react-icons/gi";
export const Navigation = (props) => {
  const[isOpen ,setIsOpen] = useState(false);
  const toggle = () => setIsOpen (!isOpen);
  const[isOpenHanb ,setIsOpenHanb] = useState(false);
  const toggleHanb = () => setIsOpenHanb (!isOpenHanb);
  const menuItem=[

 
    {
      path:"/reports/aterramento",
      name:"Laudo de Aterramento",
      icon:<FaHome />
  },
  {
      path:"/reports/solar",
      name:"Projeto Solar",
      icon:<FaIdCardAlt/>
  },

  ]
  return (
 
    <nav id='navbar'>
 
    <div className='menuHanb-navbar' style={{display: isOpenHanb ? "none" : "flex"}} >
      <GiHamburgerMenu size={20} onClick={toggleHanb} />
        </div>
        <div className='opMenuHanb-navbar' style={{display: isOpenHanb ? "block" : "none"}}>
        <div className='menuHanb-navbar' style={{display: isOpenHanb ? "flex" : "none"}} >
      <GiHamburgerMenu size={20} onClick={toggleHanb} />
        </div>
        {
               menuItem.map((item, index)=>(
                     <NavLink to={item.path} key={index} className="link-navbar" activeclassName="activee-navbar">
                         <div className="icon-navbar">{item.icon}</div>
                         <div style={{display: isOpenHanb ? "block" : "none"}} className="link_text-navbar">{item.name}</div>
                     </NavLink>
                 ))
                }
                 
              
        </div>
    <div className="container-navbar" >
      <div className="sidebar-navbar">
          
             <div className="top_section-navbar" >
             </div>
             {
               menuItem.map((item, index)=>(
                 <NavLink to={item.path} key={index} className="link-navbar " activeclassName="active-navbar">
                         <div  className="link_text-navbar">{item.name}</div>
                       
                     </NavLink>
                 ))
                }
  
         </div>
    
         <main style={{
            marginLeft: isOpen ? "0px" : "",
            transition: "all 0.5s",
            minWidth: isOpen ? "calc(100% - 200px)" : "100%",
            
            
         }}>
          {props.children}
         </main>
       </div>
            
    </nav>
  );
};
