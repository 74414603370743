
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdDelete } from 'react-icons/md';
import ApexChart from 'react-apexcharts'
import './style.css'

import makeAnimated from 'react-select/animated';
import { Alert, Box, Button, CircularProgress, Grid, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ListModules from '../../list_Modules';
import ListInverters from '../../list_Inverters';
import Preview from '../../file_preview';
import HideImageIcon from '@mui/icons-material/HideImage';

import { Tooltip, Viewer, Worker } from '@react-pdf-viewer/core';

const animatedComponents = makeAnimated();

function PhotovoltaicInformationProject(props) {

  const [openListModules, setOpenListModules] = useState(false)
  const handleClickOpenModules = () => {
    setOpenListModules(true)
  }
  const handleCloseModules = () => {
    setOpenListModules(false)
  }

  const [openListInverters, setOpenListInverters] = useState(false)
  const handleClickOpenInverters = () => {
    setOpenListInverters(true)
  }
  const handleCloseInverters = () => {
    setOpenListInverters(false)
  }


  const [recordsInverter, setRecordsInverter] = useState()
  //Pegando dados dos inversores
  useEffect(() => {
    const inverterList = async () => {
      fetch('https://api.fortesol.com.br/api/inverters/list', {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
          console.log(data)
          setRecordsInverter(data)

        }
        )
    }
    inverterList();
  }, []);




  //buscando inversores por id
  const handleAddInverterToList = (id) => {
    fetch(`https://api.fortesol.com.br/api/inverters/list/${id}`, {
      method: 'GET',
    }).then(response => response.json())
      .then(data => {
        console.log('-------', data)
        handleSelectMPPT(data.id_inverters, data.mppt_by_string)
        props?.P_setSelectedInverter([...props.P_selectedInverter, ...data])
      }
      )
  }

  //Calculando e gerando mppts para leitura posterior
  const handleSelectMPPT = (id_inv, mppt_string) => {
    console.log('id_inv', id_inv, 'mppt_string', mppt_string)
    console.log('selectedInverter', props.P_selectedInverter)
    //tratando valores indesejados
    if (!mppt_string || mppt_string === undefined || typeof mppt_string === 'number' || mppt_string === '') {
      return
    }
    //separando valores
    if (id_inv, mppt_string) {
      //dividindo valores em array
      var number_mppt = mppt_string.split('/')
      //dividindo valores em array se houver valores de string diferentes por mppt
      var number_string = number_mppt[1].split('+')
      //verificando se há apenas um valor de string
      if (number_mppt.length === 2 && number_string.length === 1) {
        var array = []
        //criando array de mppt
        for (let i = 0; i < parseFloat(number_mppt[0]); i++) {
          array.push(i)
        }
        //criando array de string
        var arrayStr = []
        for (let i = 0; i < parseFloat(number_mppt[1]); i++) {
          arrayStr.push(i + 1)
        }
        //setando valores
        props?.P_setArrayMppt([...props.P_arrayMppt, { id: id_inv, mppt: array?.map((data) => { return arrayStr?.map((str) => { return { string: str } }) }) }])

      }
      //verificando se há mais de um valor de string
      if (number_string.length > 1) {

        var array = []
        //criando array de mppt
        for (let i = 0; i < parseFloat(number_mppt[0]); i++) {
          array.push(i)
        }
        //criando array de string
        var arrayStr = []
        var arrayStr2 = []
        //criando array de string
        for (let i = 0; i < number_string.length; i++) {
          for (let j = 0; j < parseFloat(number_string[i]); j++) {
            arrayStr2.push(j + 1)
            if (arrayStr2.length === parseFloat(number_string[i])) {
              arrayStr.push(arrayStr2)
              arrayStr2 = []
            }

          }

        }
        //setando valores
        props?.P_setArrayMppt([...props.P_arrayMppt, { id: id_inv, mppt: array?.map((data, index) => { return arrayStr[index].map((str) => { return { string: str } }) }) }])





        //        props?.P_setArrayMppt(array.map((data) => { return { string: data } }))
      }
    }
  }


  const [work_around, setWork_around] = useState()

  useEffect(() => {
    //calculando a quantidade de potência dos inversores
    const handleTotalInverter = () => {
      let qtd = props.P_selectedInverter?.length
      console.log('slectedInverter', props.P_selectedInverter)
      let saida_t = props.P_selectedInverter?.reduce((acc, cur) => acc + parseFloat(cur.output_power) / 1000, 0)
      let entrada_t = props.P_selectedInverter?.reduce((acc, cur) => acc + parseFloat(cur.maximum_photovoltaic_power), 0)
      console.log('saida_t', saida_t)
      console.log('entrada_t', entrada_t)

      document.querySelector('.qtd_total').textContent = qtd
      document.querySelector('.saida_total').textContent = saida_t
      document.querySelector('.entrada_total').textContent = entrada_t

    }
    handleTotalInverter()
  }, [recordsInverter, props.P_selectedInverter, work_around, props.P_listSelectedInverter])

  //repassando valores do contador automaticamente
  useEffect(() => {
    const handleCounterInverter = () => {

      props.P_listSelectedInverter?.map((record, i) => {
        var cont = 0
        document.querySelector(`.contInverter${record.id_inverters}`).textContent = `${cont}`
        document.querySelector(`.contInversor${record.id_inverters}`).textContent = ` QTD : ${cont}`
        document.querySelector(`.bt-${record.id_inverters}`).disabled = false
        for (let i = 0; i < props.P_selectedInverter.length; i++) {
          if (props.P_selectedInverter[i].id_inverters === record.id_inverters) {
            cont++
            document.querySelector(`.contInversor${record.id_inverters}`).textContent = ``
            document.querySelector(`.contInversor${record.id_inverters}`).textContent = ` QTD : ${cont}`
            document.querySelector(`.contInverter${record.id_inverters}`).textContent = `${cont}`
          }
        }

      }
      )
    }
    handleCounterInverter()
  }
    , [props.P_selectedInverter, props.P_listSelectedInverter, work_around])


  //Modules Part
  const [recordsModule, setRecordsModule] = useState()


  //pegando dados dos módulos
  useEffect(() => {
    const moduleList = async () => {
      fetch('https://api.fortesol.com.br/api/modules/list-modules', {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
          setRecordsModule(data)
        }
        )
    }
    moduleList();
  }, [work_around]);



  //calculando a quantidade de potência dos módulos

  useEffect(() => {
    const handleTotalModules = () => {
      let qtd = props.P_selectedModules?.reduce((acc, cur) => acc + parseFloat(cur.qtd), 0)
      let saida_t = props.P_selectedModules?.reduce((acc, cur) => acc + (parseFloat(cur.maximum_power) * qtd) / 1000, 0)


      document.querySelector('.qtd_total_mod').textContent = qtd
      document.querySelector('.saida_total_mod').textContent = saida_t?.toFixed(3)

    }
    handleTotalModules()
  }, [props.P_selectedModules, work_around])



  //Graph Part


  const [cargaMax, setCargaMax] = useState(0)
  const [cargaMaxAtual, setCargaMaxAtual] = useState(0)
  const [cargaNominal, setCargaNominal] = useState(0)
  const [cargaNominalAtual, setCargaNominalAtual] = useState(0)
  const [saldoTotal, setSaldoTotal] = useState(0)
  const [saldoAtual, setSaldoAtual] = useState(0)

  const seriesCargaMax = [cargaMax, cargaMaxAtual]

  const optionsCargaMax = {
    chart: {
      type: 'pie',
    },
    labels: [`Carga Máxima (100%)`, `Carga Atual (${cargaMaxAtual}%)`],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }
  const seriesCargaNominal = [cargaNominal, cargaNominalAtual]
  const optionsCargaNominal = {
    chart: {
      type: 'pie',
    },
    labels: [`Carga Nominal (140%)`, `Carga Nominal Atual (${cargaNominalAtual}%)`],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }
  const seriesSaldoTotal = [saldoTotal, saldoAtual]
  const optionsSaldoTotal = {
    chart: {
      type: 'pie',
    },
    labels: [`Potencia Total (${parseFloat(document.querySelector('.entrada_total')?.textContent)} kW)`, `Potencia Usada (${saldoAtual} kW)`],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }
  useEffect(() => {
    //resultado total de potencia
    const handleTotal = () => {

      let saldo = parseFloat(document.querySelector('.entrada_total').textContent) - parseFloat(document.querySelector('.saida_total_mod').textContent)
      let nominal = ((parseFloat(document.querySelector('.saida_total_mod').textContent) / parseFloat(document.querySelector('.saida_total').textContent)) * 100).toFixed(2)
      let max = ((parseFloat(document.querySelector('.saida_total_mod').textContent)) / (parseFloat(document.querySelector('.entrada_total').textContent)) * 100).toFixed(2)

      console.log('nominal', nominal)
      console.log('max', max)
      let s = document.querySelector('.saldo')
      let n = document.querySelector('.nominal')
      let m = document.querySelector('.max')

      if (isNaN(saldo) || saldo === 'Infinity') saldo = 0
      if (isNaN(nominal) || nominal === 'Infinity') nominal = "0.00"
      if (isNaN(max) || max === 'Infinity') max = '0.00'

      let input = document.querySelectorAll(`#contModule`)

      if (max > 99 || max === 100) {
        for (let i = 0; i < input.length; i++) {
          input[i].style.border = '1px solid red'
        }
      } if (max < 100) {
        for (let i = 0; i < input.length; i++) {
          input[i].style.border = '1px solid #ccc'


        }
      }
      if (max > 98 && max < 100) {
        message('Atenção! Você já ultrapassou em 98% da potência máxima do inversor!',
          {
            autoClose: 3000,
            position: 'bottom-right'
          }
        )
      }
      if (max > 100) {
        message('Atenção! Carga máxima de 100% de potência do inversor ultrapassada!',
          {
            autoClose: 3000,
            position: 'bottom-right'
          }
        )
      }
      if (max === 100.00) {
        message('Atenção! Carga máxima de 100% de potência do inversor atingida!',
          {
            autoClose: 3000,
            position: 'bottom-right'
          }
        )
      }

      if (s && n && m) {
        s.textContent = saldo.toFixed(2)
        n.textContent = `${nominal}%`
        m.textContent = `${max}%`
      }

      setCargaMax(100 - parseFloat(max))
      setCargaMaxAtual(parseFloat(max))
      setCargaNominal(140 - parseFloat(nominal))
      setCargaNominalAtual(parseFloat(nominal))
      setSaldoTotal(parseFloat(saldo.toFixed(2)))
      setSaldoAtual(parseFloat(document.querySelector('.saida_total_mod').textContent))
    }
    handleTotal()
  }, [props.P_selectedInverter, props.P_selectedModules, work_around])

  const message = (msg) => {

    document.getElementById('message1').style.display = 'block'
    document.getElementById('message1').innerHTML = `<strong>Atenção!</strong>${msg}`
    document.getElementById('message2').style.display = 'block'
    document.getElementById('message2').innerHTML = `<strong>Atenção!</strong>${msg}`
    setTimeout(() => {
      if (document.getElementById('message1')) document.getElementById('message1').style.display = 'none'
      if (document.getElementById('message2')) document.getElementById('message2').style.display = 'none'
    }, 10000)
  }



  props?.handleTestInformation(() => {

    const contModules = document.querySelectorAll('#contModule')
    if (cargaMaxAtual === 0) {
      return false
    }
    for (let i = 0; i < contModules.length; i++) {
      if (contModules[i].value === '' || contModules[i].value === '0' || contModules[i].value < cargaMaxAtual * 0.10) {
        message('Preencha todos os campos de quantidade de módulos!')
        contModules[i].style.border = '1px solid red'
        return false
      } else {
        contModules[i].style.border = '1px solid #ccc'
      }
      if (cargaMaxAtual < 10) {
        message('Carga Máxima não pode ser menor que 10%!')
        contModules[i].style.border = '1px solid red'
        return false
      } else {
        contModules[i].style.border = '1px solid #ccc'
      }
      if (cargaMaxAtual > 100) {
        message('Carga máxima de 100% de potência do inversor ultrapassada!!')
        contModules[i].style.border = '1px solid red'
        return false
      } else {
        contModules[i].style.border = '1px solid #ccc'
      }
    }

    if (cargaMaxAtual > 100) {
      message('Carga máxima de 100% de potência do inversor ultrapassada!'
      )
      return false
    }
    if (cargaMaxAtual === 100) {
      message('Carga máxima de 100% de potência do inversor atingida!'
      )
      return false
    }
    if (cargaMaxAtual < 10) {
      message('Carga Máxima não pode ser menor que 10%!'
      )
      return false
    }
    return true
  })






  const [open, setOpen] = useState(false)
  const [src, setSrc] = useState('')
  const [typeFile, setTypeFile] = useState('')
  const [typeFileName, setTypeFileName] = useState('')
  const handleClose = () => {
    setOpen(false)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  //Passando de JSON para Array
  const arrayModules = JSON.parse(props.data?.info_module_demand)
  const arrayInverters = JSON.parse(props.data?.info_inverter_demand)

  //Pegando token
  const user = JSON.parse(localStorage.getItem('token'))
  const token = user.token


  const [search, setSearch] = useState(true)

  //Pegando dados do arquivo
  if (search) {
    const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/data_photovoltaic/${props.data?.id_demand}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => response.blob())
      .then((blob) => {
        setTypeFile(blob.type.split('/')[1])
        setTypeFileName(blob.type.split('/')[0])
        const url = window.URL.createObjectURL(new Blob([blob]));
        setSrc(url)
      }).catch((error) => {
        console.log(error)
      })

    setSearch(false)
  }

  const service_type = (service) => {
    if (service === 'monofasico') return 'Monofásico'
    if (service === 'bifasico') return 'Bifásico'
    if (service === 'trifasico') return 'Trifásico'
  }
  return (

    <Grid container spacing={2} justifyContent='center' style={{ width: '100%', height: '100%' }}>
      <Preview open={open} close={handleClose} title={''} src={src} typeFile={typeFile} typeFileName={typeFileName} />
      <br />
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

        <List>
          <Paper sx={{ padding: 2, margin: 1 }}>

            {/* <ListItem >
            <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Módulos</Typography>
              <List>
              {
                
             arrayModules?.map((item, index) => (
                  <ListItemText key={index} primary={`${item.marca} - ${item.modelo}`} secondary={`Quantidade: ${item.quantidade}`} />
                ))
              }
              {
                arrayModules.length === 0 ? <ListItemText primary="Nenhum módulo selecionado" /> : null
              }
              </List>
            </ListItem>
*/}

            <ListItem >
              <List>
                {
                  arrayInverters?.map((item, index) => (
                    <ListItemText key={index} primary={`Inversor ${index + 1}:`} secondary={`Fase: ${service_type(item.fase)} - Potência: ${item.ptc}`} />
                  ))
                }
                {
                  arrayInverters.length === 0 ? <ListItemText primary="Nenhum inversor selecionado" /> : null
                }
                <ListItemText primary={`Informações do Kit Fotovoltaico:`} secondary={`${props.data.info_kit_fotovoltaic_demand}`} />
              </List>
            </ListItem>

          </Paper>
        </List>
        <List>
          <Paper sx={{ padding: 2, margin: 1 }}>
            <ListItem >
              {

                src ?
                  (typeFile === 'pdf' ? (


                    <Worker workerUrl="pdf.worker.min.js">
                      <Viewer fileUrl={src} />
                    </Worker>


                  ) : (
                    typeFileName === 'image' ? (
                      <img src={src} alt="Imagem" style={{ width: '100%' }} />
                    ) : (
                      <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                        <HideImageIcon />
                      </Box>
                    )
                  )
                  )
                  : (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <CircularProgress />
                    </Box>
                  )
              }
            </ListItem>
            <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Mídias</Typography>
            <ListItem>
              <Button disabled={typeFile ? (typeFile === 'json' ? true : false) : true} size='small' variant="contained" color="primary" fullWidth
                onClick={() => {

                  setTimeout(() => {
                    const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/data_photovoltaic/${props.data?.id_demand}`, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                      }
                    }).then((response) => response.blob())
                      .then((blob) => {
                        setTypeFile(blob.type.split('/')[1])
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        setSrc(url)
                      }).catch((error) => {
                        console.log(error)
                      })
                  }, 1000)


                  handleClickOpen()
                }}
              >Detalhes</Button>

            </ListItem>
          </Paper>
        </List>
      </Grid>

      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
        <div className='pointMedicoes' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>

          <div style={{ minHeight: 35 }}>
            <Alert id='message1' severity="warning" style={{ width: '100%', display: 'none' }} />
          </div>
          <div className="select-system" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div className="select-inverters">
              <h3 className='title-section' style={{ background: '#001849', color: '#ffffff' }}>Selecione um Inversor:</h3 >
              <div className='select-inv-content'>
                <div style={{
                  minWidth: '285px',
                  maxWidth: '285px',
                  minHeight: '56px',
                  maxHeight: '56px',
                  paddingLeft: '15px',
                  paddingTop: '10px',

                }}>

                  <Select
                    options={recordsInverter}
                    //onChange={item =>  setControllerInverter(item) }
                    onChange={item => {
                      props?.P_setListSelectedInverter(item)


                    }}
                    getOptionLabel={(option) => option.brand + ' - ' + option.model}
                    getOptionValue={(option) => option.id_inverters}
                    placeholder="Selecione o inversor"
                    isMulti
                    isSearchable={true}
                    //value={controllerInverter}
                    value={props.P_listSelectedInverter}
                  />

                  {/*<input type="text" onChange={handleFilterInversor} />*/}
                  {/*<select onChange={e => handleSelectList(parseFloat(e.target.value))}>
                <option value="0">Selecione</option>
                {
                  controllerInverter?.map((record, i) => {
                    return (
                      <option key={i} value={record.id_inverters}>{record.brand} - {record.model}</option>
                    )
                  })
                }
              </select>*/}





                </div>
                <div style={{
                  paddingLeft: '15px',
                  minHeight: '56px',
                  maxHeight: '56px',
                  minWidth: '100px',
                  maxWidth: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>

                  <button style={{ backgroundColor: '#cab903', cursor: 'pointer' }} onClick={() => {
                    handleClickOpenInverters()
                  }}>Inversores</button>
                  <ListInverters open={openListInverters} handleClose={handleCloseInverters} setWork_around={setWork_around} />
                </div>
              </div>

              <ul className='limit-range'>
                {
                  props.P_listSelectedInverter?.map((record, i) => {

                    return (
                      <li key={i} className='list-inverters'>


                        <div>
                          <div style={{
                            display: 'flex',
                            margin: '5px',
                            padding: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                            <h4 style={{
                              display: 'flex',
                              alignItems: 'center',
                              minWidth: '214px',
                              maxWidth: '214px',
                            }}>{record.brand} - {record.model}

                            </h4>
                            <span style={{
                              display: 'flex',
                              alignItems: 'center',
                              minWidth: '155px',
                              maxWidth: '155px',
                            }}>
                              <button style={{ backgroundColor: 'green', fontSize: '10px', marginRight: 5 }} className={`bt+${record.id_inverters}`}
                                onClick={(e) => {
                                  setWork_around(props.P_selectedInverter.length)
                                  handleAddInverterToList(record.id_inverters)
                                  handleSelectMPPT(record.id_inverters, record.mppt_by_string)


                                }}
                              >+</button>
                              <button style={{ backgroundColor: 'red', fontSize: '10px' }} className={`bt-${record.id_inverters}`}
                                onClick={(e) => {
                                  setWork_around(e.target.value)
                                  const index = props.P_selectedInverter?.map((inv, index) => { if (inv.id_inverters === record.id_inverters) return index })
                                  const filter = index.filter((inv, index) => { if (inv !== undefined) return inv })
                                  if (filter.length - 1 === 0 || !filter.length || filter.length === undefined || filter.length === null || filter.length === '' || filter.length === ' ' || filter.length === '0' || !filter) {
                                    document.querySelector(`.bt-${record.id_inverters}`).disabled = true
                                  }
                                  if (filter[filter.length - 1] === 2 || filter.length === 2) document.querySelector(`.bt-${record.id_inverters}`).disabled = false

                                  const newselectedInverter = props.P_selectedInverter.filter((inv, index) => index !== (filter[filter.length - 1] === undefined ? 0 : filter[filter.length - 1]))
                                  console.log('newselectedInverter', newselectedInverter)
                                  props?.P_setSelectedInverter(newselectedInverter)

                                  const arrayMpptFilter = props.P_arrayMppt.filter((inv, index) => index !== (filter[filter.length - 1] === undefined ? 0 : filter[filter.length - 1]))
                                  props?.P_setArrayMppt(arrayMpptFilter)

                                }}
                              >-</button>
                              &nbsp;
                              &nbsp;
                              <p style={{ fontSize: '10px', minWidth: '41px' }} className={`contInversor${record.id_inverters}`}> QTD : 0 </p>
                              &nbsp;
                              <MdDelete
                                style={{
                                  textAlign: "center",
                                  height: "100%",
                                  marginTop: "-2pt",
                                  marginLeft: '8pt',
                                  cursor: 'pointer'
                                }}
                                color='red'
                                onClick={() => {

                                  const newselectedInverter = props.P_selectedInverter.filter((inv, index) => inv.id_inverters !== record.id_inverters)
                                  props?.P_setSelectedInverter(newselectedInverter)
                                  const arrayMpptFilter = props.P_arrayMppt.filter((mppt, index) => mppt.id !== record.id_inverters)
                                  props?.P_setArrayMppt(arrayMpptFilter)
                                  props.P_listSelectedInverter.splice(i, 1)
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="select-modules">
              <h3 className='title-section' style={{ background: '#001849', color: '#ffffff' }}>Selecione um Módulo:</h3>

              <div className='select-mod-content'>
                <div style={{
                  minWidth: '285px',
                  maxWidth: '285px',
                  minHeight: '56px',
                  maxHeight: '56px',
                  paddingLeft: '15px',
                  paddingTop: '10px',
                }}>
                  <Select
                    options={recordsModule}
                    onChange={item => {
                      props?.P_setSelectedModules(item)
                      props?.P_setCountMod(item)
                    }}
                    getOptionLabel={(option) => option.brand_modules + ' - ' + option.model_modules}
                    getOptionValue={(option) => option.id_modules}
                    placeholder="Selecione o módulo"
                    isMulti
                    isSearchable={true}
                    components={animatedComponents}
                    value={props.P_selectedModules}
                  />

                </div>
                <div style={{
                  paddingLeft: '15px',
                  minHeight: '56px',
                  maxHeight: '56px',
                  minWidth: '100px',
                  maxWidth: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <button style={{ backgroundColor: '#cab903', cursor: 'pointer' }}
                    onClick={() => {
                      handleClickOpenModules()
                    }}
                  >Módulos</button>
                  <ListModules open={openListModules} handleClose={handleCloseModules} setWork_around={setWork_around} />
                </div>
              </div>

              <ul className='limit-range'>
                {
                  props.P_selectedModules?.map((record, i) => {
                    return (
                      <li key={i} className='list-modules'>
                        <div style={{
                          display: 'flex',
                          margin: '5px',
                          padding: '5px',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <h4 style={{
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: '214px',
                            maxWidth: '214px',
                          }}>{record.brand_modules} - {record.model_modules}&nbsp;&nbsp;

                          </h4>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: '155px',
                            maxWidth: '155px',

                          }}>
                            <input id={`contModule`} onChange={(e) => {

                              const newContMod = props.P_countMod?.map((mod, index) => {
                                if (mod.id_modules === record.id_modules) {
                                  return { ...mod, qtd: parseFloat(e.target.value) }
                                }
                                return mod
                              })
                              props?.P_setCountMod(newContMod)
                              const newSelectedModules = props.P_selectedModules?.map((mod, index) => {
                                if (mod.id_modules === record.id_modules) {
                                  return { ...mod, qtd: parseFloat(e.target.value) }
                                }
                                return mod
                              })
                              props?.P_setSelectedModules(newSelectedModules)

                            }
                            }
                              placeholder='QTD' type="number" style={{ width: '60px' }} min={0} value={record.qtd} className={`qtd${record.id_modules}`} />
                            <MdDelete
                              style={{
                                textAlign: "center",
                                height: "100%",
                                marginTop: "-2pt",
                                marginLeft: '8pt',
                                cursor: 'pointer'
                              }}
                              color='red'
                              onClick={() => {
                                const newSelectModules = props.P_selectedModules.filter((mod, index) => mod.id_modules !== record.id_modules)
                                props?.P_setSelectedModules(newSelectModules)
                              }}
                            />
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>

            </div>
          </div>



          <div className="controller">

            <div style={{
              minWidth: '500px',
              maxWidth: '500px',
              minHeigth: '550px',
              maxHeigth: '550px',
              display: 'flex',
              justifyContent: 'start',
              flexDirection: 'column',
              marginRight: '0px'
            }}>
              <table style={{
                border: '1px solid #000', backgroundColor: 'white', minWidth: '500px',
                maxWidth: '500px',
                minHeight: '275px',
                maxHeight: '275px',
              }}>

                <thead style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', borderBottom: '1px solid #000', minWidth: '495px', maxWidth: '495px', background: '#004fa39c' }}>
                  <tr>
                    <th style={{ textAlign: 'center', minWidth: '495px', background: 'rgb(0, 24, 73)', color: '#ffffff' }}>
                      Controle de Potência Inversores
                    </th>
                    <br />

                  </tr>
                  <tr>
                    <th>
                      ID&nbsp;&nbsp;
                    </th>
                    <th style={{ width: '120px' }} >
                      Inversor
                    </th>
                    <th >
                      &nbsp;&nbsp;QTD&nbsp;&nbsp;
                    </th>
                    <th>&nbsp;&nbsp;Nominal de Saída (CA)&nbsp;</th>
                    <th>&nbsp;Máxima de entrada (CC)</th>
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: '180px' }}>Und (kW)&nbsp;</th>
                    <th>&nbsp;Total (kW)&nbsp;&nbsp;</th>
                    <th>&nbsp;Und (kW)&nbsp;</th>
                    <th>&nbsp;Total (kW)</th>
                  </tr>
                </thead>
                <tbody style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#efefefe9',
                  minWidth: '495px',
                  maxWidth: '495px',
                  minHeight: '167px',
                  maxHeight: '167px',
                  overflowY: 'auto',
                }}>
                  {
                    props.P_listSelectedInverter?.map((record, i) => {
                      return (
                        <tr key={i} style={{ display: 'flex', borderBottom: '1px solid #000', background: "#fff", }} >
                          <tr style={{ display: 'flex' }}>
                            <td style={{ maxWidth: '30px', fontSize: '12px', paddingTop: '2px' }}>
                              {i + 1}
                              &nbsp;&nbsp;</td>
                            <td
                              style={{ maxWidth: '120px', minWidth: '120px', fontSize: '9px', textAlign: 'center', paddingTop: '4px' }} >
                              {record.brand} - {record.model}
                            </td>
                          </tr>
                          <tr style={{ minWidth: '340px', maxWidth: '340px' }}>
                            <td className={`qtd${i} `} style={{ marginLeft: '60px', fontSize: '12px', maxWidth: '53px', minWidth: '53px' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={`contInverter${record.id_inverters} qtd-inversor`}></span>
                              &nbsp;&nbsp;</td>
                            <td className={`potencia_nominal${i}`}
                              style={{ paddingLeft: '45px', fontSize: '12px', maxWidth: '84px', minWidth: '84px' }}>{

                                document.querySelector(`.qtd${i}`)
                                  &&
                                  (document.querySelector(`.qtd${i}`).textContent) !== '0'
                                  ?
                                  (parseFloat(record.output_power) / 1000)
                                  :
                                  0}
                            </td>
                            <td className={`potencia_nominalTotal${i} nominal-total`}
                              style={{ paddingLeft: '35px', fontSize: '12px', maxWidth: '67px', minWidth: '67px' }}>{

                                parseFloat((document.querySelector(`.qtd${i}`)
                                  ?
                                  document.querySelector(`.qtd${i}`).textContent
                                  :
                                  0))
                                *
                                (parseFloat(document.querySelector(`.potencia_nominal${i}`)
                                  ?

                                  document.querySelector(`.potencia_nominal${i}`).textContent
                                  :
                                  0))
                              }
                            </td>
                            <td className={`potencia_max${i}`}
                              style={{ paddingLeft: '35px', fontSize: '12px', maxWidth: '74px', minWidth: '74px' }}>{
                                document.querySelector(`.qtd${i}`)
                                  &&
                                  document.querySelector(`.qtd${i}`).textContent !== '0'
                                  ?
                                  parseFloat(record.maximum_photovoltaic_power)
                                  :
                                  0
                              }</td>

                            <td className={`potencia_maxTotal${i} max-total`}
                              style={{ paddingLeft: '30px', paddingRight: '0px', fontSize: '12px', maxWidth: '50px', minWidth: '50px' }}>{

                                (parseFloat(document.querySelector(`.qtd${i}`)
                                  ?
                                  document.querySelector(`.qtd${i}`).textContent : 0))
                                *
                                (parseFloat(document.querySelector(`.potencia_max${i}`)
                                  ?
                                  document.querySelector(`.potencia_max${i}`).textContent : 0))
                              }</td>
                          </tr>
                        </tr>
                      )
                    })
                  }



                </tbody>
                <tfoot style={{ minHeight: '28px', maxHeight: '28px', display: 'flex', flexDirection: 'column', alignItems: 'start', borderTop: '1px solid #000', background: '#0036709c', color: '#ffffff' }}>
                  <tr style={{ width: '147px', display: 'table', paddingTop: '4px' }}>
                    <td style={{ maxWidth: '100px', minWidth: '100px', fontSize: '12px' }} >&nbsp;&nbsp;Total:&nbsp;&nbsp;</td>
                    <td style={{ paddingLeft: '0px', paddingRight: '20px', fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td style={{ paddingLeft: '20px', paddingRight: '0px', fontSize: '12px', maxWidth: '60px', minWidth: '60px' }}>
                      &nbsp;&nbsp;
                      <span className={`qtd_total`} >1</span>
                    </td>
                    <td style={{ paddingLeft: '45px', paddingRight: '25px', fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td style={{ paddingLeft: '20px', paddingRight: '20px', fontSize: '12px', maxWidth: '67px', minWidth: '67px' }}>
                      &nbsp;&nbsp;<span className={`saida_total`}>0</span>
                    </td>
                    <td style={{ paddingLeft: '20px', paddingRight: '30px', fontSize: '12px', maxWidth: '54px', minWidth: '54px' }}>
                      &nbsp;
                    </td>
                    <td style={{ paddingLeft: '40px', paddingRight: '0px', fontSize: '12px' }}><span className={`entrada_total`}>0</span></td>
                  </tr>
                </tfoot>
              </table>

              <table style={{
                border: '1px solid #000', backgroundColor: 'white', minWidth: '500px',
                maxWidth: '500px',
                minHeight: '275px',
                maxHeight: '275px',

              }}>

                <thead style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'start', borderBottom: '1px solid #000', background: '#004fa39c' }}>
                  <tr>
                    <th style={{ textAlign: 'center', minWidth: '492px', background: 'rgb(0, 24, 73)', color: '#ffffff' }}>
                      Controle de Potência Modulos
                    </th>
                    <br />

                  </tr>
                  <tr  >
                    <th style={{ maxWidth: '30px' }} >
                      ID&nbsp;&nbsp;
                    </th>
                    <th style={{ minWidth: '270px' }}>
                      Modulo
                    </th>
                    <th>
                      &nbsp;&nbsp;QTD&nbsp;&nbsp;
                    </th>
                    <th style={{ paddingLeft: '20px' }}>&nbsp;&nbsp;Nominal de Saída</th>

                  </tr>
                  <tr>
                    <th style={{ paddingLeft: '340px', paddingRight: '20px' }}>Und (kW)&nbsp;</th>
                    <th>&nbsp;Total (kW)</th>

                  </tr>

                </thead>
                <tbody style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#efefefe9',
                  minHeight: '167px',
                  maxHeight: '167px',
                  overflowY: 'auto',
                }}>
                  {
                    props.P_selectedModules?.map((record, i) => {
                      return (
                        <tr key={i} style={{ display: 'flex', borderBottom: '1px solid #000', padding: '2px', background: '#fff' }}>
                          <tr style={{ display: 'flex' }}>
                            <td style={{ maxWidth: '30px', fontSize: '12px' }}>{i + 1}&nbsp;&nbsp;</td>
                            <td style={{ minWidth: '270px', maxWidth: '270px', textAlign: 'center', fontSize: '9px', paddingTop: '3px' }} > {record.brand_modules} - {record.model_modules}</td>
                          </tr>
                          <tr>
                            <td style={{ marginLeft: '0px', textAlign: 'center', minWidth: '60px', maxWidth: '60px', fontSize: '12px' }}><span className='qtd-modulos'>{record.qtd ? record.qtd : 0}</span>&nbsp;&nbsp;</td>
                            <td style={{ paddingLeft: '12px', textAlign: 'center', minWidth: '66px', maxWidth: '66px', fontSize: '12px' }}>{parseFloat(record.maximum_power) ? parseFloat(record.maximum_power) : 0}</td>
                            <td className='max-saida-mod' style={{ paddingLeft: '15px', paddingRight: '0px', textAlign: 'center', minWidth: '66px', maxWidth: '66px', fontSize: '12px' }}>{((parseFloat(record.maximum_power) * (record.qtd ? record.qtd : 0)) / 1000).toFixed(3)}</td>
                          </tr>
                        </tr>
                      )
                    })
                  }

                </tbody>
                <tfoot style={{ minHeight: '28px', maxHeight: '28px', display: 'flex', flexDirection: 'column', alignItems: 'start', borderTop: '1px solid #000', background: '#0036709c', color: '#ffffff' }}>
                  <tr style={{ width: '150px', display: 'table', paddingTop: '4px' }}>
                    <td style={{ maxWidth: '270px', minWidth: '270px', fontSize: '12px' }} >&nbsp;&nbsp;Total:&nbsp;&nbsp;</td>
                    <td style={{ paddingLeft: '0px', paddingRight: '0px', fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td style={{ paddingLeft: 'px', paddingRight: '0px', minWidth: '52px', maxWidth: '52px', fontSize: '12px', textAlign: 'center' }}>&nbsp;&nbsp;<span className={`qtd_total_mod`} >1</span>&nbsp;&nbsp;</td>
                    <td style={{ paddingLeft: '40px', paddingRight: '20px', fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td style={{ paddingLeft: '15px', paddingRight: '0px', minWidth: '66px', maxWidth: '66px', fontSize: '12px', textAlign: 'center' }}>&nbsp;&nbsp;<span className={`saida_total_mod`} >1</span>&nbsp;&nbsp;</td>

                  </tr>
                </tfoot>
              </table>
            </div>
            <div className='content-graf'>
              <table style={{ minWidth: '399px', maxWidth: '399px', minHeight: '78.5px', maxHeight: '78.5px', display: 'table', border: '1px solid #000', backgroundColor: 'white' }}>
                <thead style={{ background: '#004fa39c', minWidth: '394px', maxWidth: '394px', display: 'table', borderBottom: '1px solid #000' }}>
                  <tr style={{ textAlign: 'center', minWidth: '399px', background: 'rgb(0, 24, 73)', color: '#ffffff' }}>
                    <th style={{ minWidth: '39px' }}>INVERSORES</th>
                  </tr>
                  <tr style={{ width: '150px', display: 'table' }}>
                    <th style={{ minWidth: '100px', maxWidth: '100px', paddingRight: '90px' }}>SALDO (kW)</th>
                    <th style={{ paddingLeft: '0px' }}>CARGA</th>
                    <tr style={{ textAlign: 'center', minWidth: '394px', maxWidth: '394px', }}>
                      <th style={{ paddingLeft: '180px', fontSize: '10px', minWidth: '180px', maxWidth: '180px' }}>NOMINAL*</th>
                      <th style={{ fontSize: '10px', paddingLeft: '150px' }}>MÁXIMA</th>
                    </tr>
                  </tr>
                </thead>
                <tbody style={{ width: '150px', display: 'table' }}>
                  <tr style={{ paddingRight: '0px', display: 'table' }}>
                    <td className='saldo' style={{ paddingLeft: '20px', maxWidth: '30px', fontSize: '13px' }}>0</td>
                    <td className='nominal' style={{ paddingLeft: '150px', maxWidth: '30px', fontSize: '13px' }}>0.00%</td>
                    <td className='max' style={{ paddingLeft: '150px', maxWidth: '30px', fontSize: '13px' }}>0.00%</td>
                  </tr>
                </tbody>
              </table>
              <div style={{
                minWidth: '400px',
                maxWidth: '400px',
                minHeight: '472px',
                maxHeight: '472px',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                padding: '10px 0',
                flexDirection: 'column',
              }}>

                <div style={{ minHeight: '157px', maxHeight: '157px', minWidth: '399px', maxWidth: '399px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ApexChart options={optionsCargaMax} series={seriesCargaMax} type="pie" height={150} width={350} /></div>
                <div style={{ minHeight: '157px', maxHeight: '157px', minWidth: '399px', maxWidth: '399px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '15px' }}><ApexChart options={optionsCargaNominal} series={seriesCargaNominal} type="pie" height={150} width={370} /></div>
                <div style={{ minHeight: '157px', maxHeight: '157px', minWidth: '399px', maxWidth: '399px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ApexChart options={optionsSaldoTotal} series={seriesSaldoTotal} type="pie" height={150} width={350} /></div>
              </div>
            </div>
          </div>
          <div style={{ minHeight: 35, paddingTop: '5px' }}>
            <Alert id='message2' severity="warning" style={{ width: '100%', display: 'none' }} />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default PhotovoltaicInformationProject
