
import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Popup_demand from '../popup_details_demand';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import DropDialog from '../popup_drop_dialog';
import New_Edit_Customer from '../new_edit_customer';
import New_Edit_Employee from '../new_edit_employee';
import Details_Employee from '../details_employee';


function EmployeeList() {
  const [openDetails, setOpenDetails] = useState(false)
  const [dataEmployee, setDataEmployee] = useState('')
  const handleClickOpenDetails = (item) => {
    setDataEmployee(item)
    setOpenDetails(true)
  }
  const handleCloseDetails = () => {
    setOpenDetails(false)
  }


  const [data, setData] = useState('')
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const handleClickOpenNewEmployee = () => {
    setData('')
    setTitle('new')
    setOpen(true)
  }
  const handleClickOpenEditEmployee = (item) => {

    setData(item)
    setOpen(true)
    setTitle('edit')
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [linhasVisiveis, setLinhasVisiveis] = useState(0);
  const alturaLinha = 49; // Altura de cada linha da tabela (em pixels)

  useEffect(() => {
    const calcularLinhas = () => {
      const alturaTela = window.innerHeight; // Altura da janela do navegador
      const linhas = Math.floor(alturaTela / alturaLinha); // Calcula quantas linhas cabem
      setLinhasVisiveis(linhas);
    };

    // Calcula ao carregar a página
    calcularLinhas();

    // Adiciona um listener para recalcular em caso de redimensionamento da tela
    window.addEventListener('resize', calcularLinhas);

    // Remove o listener ao desmontar o componente
    return () => window.removeEventListener('resize', calcularLinhas);
  }, []);

  const [demand, setDemand] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = linhasVisiveis;

  useEffect(() => {
    const fetchDemand = async () => {
      setLoading(true);
      try {
        fetch(`https://api.fortesol.com.br/api/employees/list?page=${currentPage}&limit=${itemsPerPage}`, {
          //fetch(`https://api.fortesol.com.br/api/employees/list?page=${currentPage}&limit=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
          .then((data) => {
            setDemand(data);
            setTotalPages(data[1]);
          })

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDemand();
  }, [currentPage, itemsPerPage]);
 

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ... renderizar a lista de produtos e a paginação





  document.querySelectorAll('.MuiPaginationItem-root').forEach((item) => {
    item.style.backgroundColor = '#004e64';
    item.style.color = '#fff';
    if (item.ariaCurrent === 'true') {
      item.style.backgroundColor = '#ffffff';
      item.style.color = '#004e64';
      item.style.border = '2px solid #004e64';
    }

  })



  const [handleData, setHandleData] = useState('');



  const searchDemand = () => {

    let value = document.querySelector('.search-demand').value;
    if (value !== '') {
      value = value.toLowerCase();
      value = value.replace('/', '-');
      fetch(`https://api.fortesol.com.br/api/employees/search?page=${currentPage}&limit=${itemsPerPage}&search=${value}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => {
         
          setDemand(data);
          setTotalPages(data[1]);

        })
      return
    }
    else {
      fetch(`https://api.fortesol.com.br/api/employees/list?page=${currentPage}&limit=${itemsPerPage}`, {
        //fetch(`https://api.fortesol.com.br/api/employees/list?page=${currentPage}&limit=${itemsPerPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
        .then((data) => {
          setDemand(data);
          setTotalPages(data[1]);
        })
    }
  }


  const [doc_proxy, setDoc_proxy] = useState(null)

  const [openDrop, setOpenDrop] = useState(false)
  const [titleDrop, setTitleDrop] = useState('')
  const [msgDrop, setMsgDrop] = useState('')
  const [idDrop, setIdDrop] = useState('')
  const handleClickOpenDrop = () => {
    setOpenDrop(true)
  }
  const handleCloseDrop = () => {
    setOpenDrop(false)
  }
  const deleteEmployee = () => {
    fetch(`https://api.fortesol.com.br/api/employees/delete/${idDrop}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
      .then((data) => {
       

        fetch(`https://api.fortesol.com.br/api/employees/list?page=${currentPage}&limit=${itemsPerPage}`, {
          //fetch(`https://api.fortesol.com.br/api/modules/list?page=${currentPage}&limit=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
          .then((data) => {
            
            setDemand(data);
            setTotalPages(data[0]);
          })

      })
    setOpenDrop(false)
    handleCloseDrop()

  }
  return (

    <Sidebar>
      <div id='list-project-solar-system'>
        <div className='project-solar'>
          <ToastContainer
            position="bottom-right"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            height={50}
          />

          <br />
          <div style={{ display: 'flex', padding: '0 50px 0 50px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', paddingLeft: '50px' }}>
              <div style={{ display: 'flex' }}>
                <input type="text" className='search-demand' placeholder='Pesquisar'
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      searchDemand()
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      searchDemand()
                    }
                  }}
                />
                <button id='bt-search' onClick={searchDemand}>Pesquisar</button>
              </div>


            </div>
            <button style={{ background: '#218380' }} onClick={handleClickOpenNewEmployee}>Novo Funcionário</button>
          </div>

          <div id='tableDemands' style={{
            padding: '0 0px 0 50px',
          }}>
            {

              (
                <>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{p:'5px',fontSize:14}}align="center">Nome</TableCell>
                          <TableCell sx={{p:'5px',fontSize:14}}align="center">CPF</TableCell>
                          <TableCell sx={{p:'5px',fontSize:14}}align="center">Email</TableCell>
                          <TableCell sx={{p:'5px',fontSize:14}}align="center">Contato</TableCell>
                          <TableCell sx={{p:'5px',fontSize:14}}align="center">Função</TableCell>
                          <TableCell sx={{p:'5px',fontSize:14}}align="center">Departamento</TableCell>
                          <TableCell sx={{p:'5px',fontSize:14}}align="center">Detalhes</TableCell>
                          <TableCell sx={{p:'5px',fontSize:14}}align="center">Opções</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {demand[0]?.map((item, index) => (
                          <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#eaeaea' } }}>

                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{item.name_employee}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{item.cpf_employee}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center"> {item.email_employee}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center"> {item.contact_employee}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center"> {item.name_role}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center"> {item.name_departments}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">
                              <IconButton title='DETALHES' style={{ color: '#218380' }} onClick={() => handleClickOpenDetails(item)}>
                                <SwitchAccountIcon />
                              </IconButton>


                            </TableCell>
                            <TableCell align="center">
                              <IconButton id={`${item.id_employee}`} title='EDITAR' style={{ color: '#218380' }} onClick={() => handleClickOpenEditEmployee(item)}>
                                <EditIcon />
                              </IconButton>

                              <IconButton title='DELETAR' style={{ color: 'red' }} onClick={() => {
                                setTitleDrop('Deletar Funcionário')
                                setMsgDrop('Deseja realmente deletar o funcionário?')
                                setIdDrop(item.id_employee)
                                handleClickOpenDrop()
                              }
                              }>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <New_Edit_Employee open={open} handleClose={handleClose} title={title} data={data} setData={setData} />
                    <Details_Employee openDetails={openDetails} closeDetails={handleCloseDetails} data={dataEmployee} />
                    <DropDialog openDrop={openDrop} handleCloseDrop={handleCloseDrop} title={titleDrop} msg={msgDrop} delete={deleteEmployee} />

                  </Paper>

                  <Pagination style={{ marginTop: '10px' }}
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, page) => handlePageChange(page)}
                    showFirstButton
                    showLastButton
                    boundaryCount={1} // Número de páginas antes e depois das ellipses
                    siblingCount={1} // Número de páginas antes e depois da página atual
                  />
                </>
              )}


          </div>


        </div>
      </div>
    </Sidebar>
  )
}

export default EmployeeList
