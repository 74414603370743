import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@mui/material';
import { Label } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';   
import { toast, ToastContainer } from 'react-toastify';

function Nem_Edit_Module(props) {






  const typeOptions = [
    { label: 'Monocristalino', value: 'monocristalino' },
    { label: 'Policristalino', value: 'policristalino' },
  
  ];

  const bifacialOptions = [
    { label: 'Sim', value: 'sim' },
    { label: 'Não', value: 'nao' },

  ];


const [brand_modules, setBrand_modules] = useState('')
const [model_modules, setModel_modules] = useState('')
const [type, setType] = useState(null)
const [bifacial, setBifacial] = useState(null)
const [maximum_power, setMaximum_power] = useState('')
const [tower_tolerance, setTower_tolerance] = useState('')
const [open_circuit_voltage_modules, setOpen_circuit_voltage_modules] = useState('')
const [short_circuit_current_modules, setShort_circuit_current_modules] = useState('')
const [maximum_operating_voltage, setMaximum_operating_voltage] = useState('')
const [maximum_operating_current, setMaximum_operating_current] = useState('')
const [maximum_fuse_in_series, setMaximum_fuse_in_series] = useState('')
const [maximum_system_voltage, setMaximum_system_voltage] = useState('')
const [nominal_cell_operating_temperature, setNominal_cell_operating_temperature] = useState('')
const [module_weight, setModule_Weight] = useState('')
const [module_dimensions, setModule_dimensions] = useState('')
const [datasheet, setDatasheet] = useState('')


const handleClose = () => {
    setBrand_modules('')
    setModel_modules('')
    setType('')
    setBifacial('')
    setMaximum_power('')
    setTower_tolerance('')
    setOpen_circuit_voltage_modules('')
    setShort_circuit_current_modules('')
    setMaximum_operating_voltage('')
    setMaximum_operating_current('')
    setMaximum_fuse_in_series('')
    setMaximum_system_voltage('')
    setNominal_cell_operating_temperature('')
    setModule_Weight('')
    setModule_dimensions('')
    setDatasheet('')
    props.setData('')
    props.handleClose()
}

const handleNewModule = async () => {
/*  
    if(!brand_modules || !model_modules || !type || !bifacial || !maximum_power  || !open_circuit_voltage_modules  || !maximum_operating_voltage || !maximum_operating_current  || !module_weight || !module_dimensions){
        return toast.error('Preencha todos os campos')
    }
*/

    //const response = await fetch('https://api-aplication-fortesol.vercel.app/api/photovoltaic_modules', {
    const response = await fetch('https://api.fortesol.com.br/api/modules/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({brand_modules, model_modules, type, maximum_power, tower_tolerance, open_circuit_voltage_modules, short_circuit_current_modules, maximum_operating_voltage, maximum_operating_current, maximum_fuse_in_series, maximum_system_voltage, nominal_cell_operating_temperature, module_weight, module_dimensions, bifacial } )
    }).then((response) => response.json())
    .then((data) => {
      
        if(data.message === 'success'){
          if(datasheet !== ''){
            fetch(`https://api.fortesol.com.br/api/modules/upload/datasheet_modules/${data.id}`, {
                method: 'POST',
                body: datasheet
              }).then((response) => response.json())
              .then((data) => {
                  if(data.message === 'success'){
                      //toast.success('Datasheet enviado com sucesso!')
                     
                  }
                  }
              ).catch((error) => {
                  //toast.error('Erro ao enviar.')
              }
              )
            }
          document.getElementById('bt-search').click()
           handleClose()
        }
    }).catch((error) => {
        console.log(error)
    })
    return response 
}


const handleEditModule = async () => {
  const brand_modules = document.getElementById('brand-module').value
  const model_modules = document.getElementById('model-module').value
  const type_ = document.getElementById('type-module').innerText.toLowerCase()
  const bifacial_ = document.getElementById('bifacial-module').innerText.toLowerCase()
  const maximum_power = document.getElementById('maximum-power-module').value
  const tower_tolerance = document.getElementById('tower-tolerance-module').value
  const open_circuit_voltage_modules = document.getElementById('open-circuit-voltage-module').value
  const short_circuit_current_modules = document.getElementById('short-circuit-current-module').value
  const maximum_operating_voltage = document.getElementById('maximum-operating-voltage-module').value
  const maximum_operating_current = document.getElementById('maximum-operating-current-module').value
  const maximum_fuse_in_series = document.getElementById('maximum-fuse-in-series-module').value
  const maximum_system_voltage = document.getElementById('maximum-system-voltage').value
  const nominal_cell_operating_temperature = document.getElementById('nominal-cell-operating-temperature-module').value
  const module_weight = document.getElementById('module_weight-module').value
  const module_dimensions = document.getElementById('module_dimensions-module').value
  
/*
    if(!brand_modules || !model_modules || !type_ || !bifacial_ || !maximum_power || !tower_tolerance || !open_circuit_voltage_modules || !short_circuit_current_modules || !maximum_operating_voltage || !maximum_operating_current || !maximum_fuse_in_series || !nominal_cell_operating_temperature || !module_weight || !module_dimensions){
        return toast.error('Preencha todos os campos')
    }
 */
   
    //const response = await fetch(`https://api-aplication-fortesol.vercel.app/api/modules/${props?.data.id}`, {
    const response = await fetch(`https://api.fortesol.com.br/api/modules/update/${props?.data.id_modules}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({brand_modules, model_modules, type:type_, maximum_power, tower_tolerance, open_circuit_voltage_modules, short_circuit_current_modules, maximum_operating_voltage, maximum_operating_current, maximum_fuse_in_series, maximum_system_voltage, nominal_cell_operating_temperature, module_weight, module_dimensions, bifacial:bifacial_} )
    }).then((response) => response.json())
    .then((data) => {
      
        if(data.message === 'success'){
          if(datasheet !== ''){
            fetch(`https://api.fortesol.com.br/api/modules/upload/datasheet_modules/${props?.data.id_modules}`, {
                method: 'POST',
                body: datasheet
              }).then((response) => response.json())
              .then((data) => {
                  if(data.message === 'success'){
                      //toast.success('Datasheet enviado com sucesso!')
                     
                  }
                  }
              ).catch((error) => {
                  //toast.error('Erro ao enviar.')
              }
              )
            }
          document.getElementById('bt-search').click()
            handleClose()
        }
    }).catch((error) => {
        console.log(error)
    })
    return response
}

  

 

  return (
<>
<Dialog
                            open={props.open}
                            onClose={props.handleClose}
                        
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth='1200px'
                            sx={{ml:5}}
                        >
                            <DialogTitle id="alert-dialog-title">{props.title === 'new' ? 'Cadastro de Módulos' : 'Editar Módulo'}</DialogTitle>
                            <DialogContent>
    <ToastContainer
            position="bottom-right"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            height={50}
            
          />
<Alert severity="info">Preencha os campos abaixo de acordo com as referências exemplificadas para salvar o módulo corretamente.</Alert>
    <Grid sx={{mt:1}} container spacing={2}>
    <Grid item xs={12} sm={6}>
      <TextField
        id='brand-module'
        label="Marca"
        value={brand_modules ? brand_modules : (props?.data?.brand_modules ? props?.data?.brand_modules : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setBrand_modules(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='model-module'
        label="Modelo"
        value={model_modules ? model_modules : (props?.data?.model_modules ? props?.data?.model_modules : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setModel_modules(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
      select
        id='type-module'
        label="Tipo"
        value={type ? type : (props?.data?.type ? props?.data?.type : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setType(e.target.value)
          }
          }
        >
            {typeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        select
        id='bifacial-module'
        label="Bifacial"
     
        value={bifacial ? bifacial : (props?.data?.bifacial ? props?.data?.bifacial : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setBifacial(e.target.value)
          }
          }
        >
            {bifacialOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}

        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='maximum-power-module'
        label="Potência Máxima(Pmax)"
        placeholder='Ex : 000 Wp'
        value={maximum_power ? maximum_power : (props?.data?.maximum_power ? props?.data?.maximum_power : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setMaximum_power(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='tower-tolerance-module'
        label="Tolerância de Potência"
        placeholder='Ex : ~0W'
        value={tower_tolerance ? tower_tolerance : (props?.data?.tower_tolerance ? props?.data?.tower_tolerance : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setTower_tolerance(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='open-circuit-voltage-module'
        label="Tensão de Circuito Aberto(Voc)"
        placeholder='Ex : 00,0V'
        value={open_circuit_voltage_modules ? open_circuit_voltage_modules : (props?.data?.open_circuit_voltage_modules ? props?.data?.open_circuit_voltage_modules : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setOpen_circuit_voltage_modules(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='short-circuit-current-module'
        label="Corrente de Curto-Circuito (Isc)"
        placeholder='Ex : 00,0A'
        value={short_circuit_current_modules ? short_circuit_current_modules : (props?.data?.short_circuit_current_modules ? props?.data?.short_circuit_current_modules : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setShort_circuit_current_modules(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='maximum-operating-voltage-module'
        label="Tensão Máxima de Operação (Vmpp)"
        placeholder='Ex : 00,0V'
        value={maximum_operating_voltage ? maximum_operating_voltage : (props?.data?.maximum_operating_voltage ? props?.data?.maximum_operating_voltage : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setMaximum_operating_voltage(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='maximum-operating-current-module'
        label="Corrente Máxima de Operação (Impp)"
        placeholder='Ex : 00,0A'
        value={maximum_operating_current ? maximum_operating_current : (props?.data?.maximum_operating_current ? props?.data?.maximum_operating_current : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setMaximum_operating_current(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='maximum-fuse-in-series-module'
        label="Fusível Máximo em Série"
        placeholder='Ex : 00,0A'
        value={maximum_fuse_in_series ? maximum_fuse_in_series : (props?.data?.maximum_fuse_in_series ? props?.data?.maximum_fuse_in_series : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setMaximum_fuse_in_series(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='maximum-system-voltage'
        label="Tensão Máxima do Sistema"
        placeholder='Ex : 0000 V'
        value={maximum_system_voltage ? maximum_system_voltage : (props?.data?.maximum_system_voltage ? props?.data?.maximum_system_voltage : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setMaximum_system_voltage(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='nominal-cell-operating-temperature-module'
        label="Temperatura Nominal de Operação da Célula (NOCT)"
        placeholder='Ex : 45 ± 2°C'
        value={nominal_cell_operating_temperature ? nominal_cell_operating_temperature : (props?.data?.nominal_cell_operating_temperature ? props?.data?.nominal_cell_operating_temperature : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setNominal_cell_operating_temperature(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='module_weight-module'
        label=" Peso do Módulo"
        placeholder='Ex : 0 KG'
        value={module_weight ? module_weight : (props?.data?.module_weight ? props?.data?.module_weight : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setModule_Weight(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='module_dimensions-module'
        label="Dimensões do Módulo"
        placeholder='Ex : 2094 / 1038 / 35 mm'
        value={module_dimensions ? module_dimensions : (props?.data?.module_dimensions ? props?.data?.module_dimensions : '')}
          fullWidth
           size="small"
          onChange={(e) => {
            setModule_dimensions(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
      <Button component="label" 
        variant="contained"
      >
      Datasheet
        <input type="file" hidden onChange={
          (e) => {
            const file = e.target.files[0]
            const formdata = new FormData()
            formdata.append('datasheet_modules', file)
            setDatasheet(formdata)
            toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
          }
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Grid>
      </Grid>



    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancelar
      </Button>
     {props?.title ==='edit' && <Button onClick={handleEditModule} variant='contained' color="primary"
      autoFocus>
        Salvar
      </Button>}
 {props?.title === 'new'&&  <Button onClick={handleNewModule} variant='contained' color="primary"
      autoFocus>
        Cadastrar
      </Button>}
    </DialogActions>
    </Dialog>
   
  </>
  
  )
}

export default Nem_Edit_Module
