import Sidebar from "../../Components/Sidebar"
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import NoteContainer from "../../Components/NoteApp/NoteContainer";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const events = [
  {
      title: "Big Meeting",
      allDay: true,
      start: new Date(2021, 6, 0),
      end: new Date(2021, 6, 0),
  },
  {
      title: "Vacation",
      start: new Date(2021, 6, 7),
      end: new Date(2021, 6, 10),
  },
  {
      title: "Conference",
      start: new Date(2021, 6, 20),
      end: new Date(2021, 6, 23),
  },
];
const CalendarSystem = () => {
  const [newEvent, setNewEvent] = useState({ title: "", start: "", end: "" });
 const [allEvents, setAllEvents] = useState(events);
 function handleAddEvent() {
        
  for (let i=0; i<allEvents.length; i++){

      const d1 = new Date (allEvents[i].start);
      const d2 = new Date(newEvent.start);
      const d3 = new Date(allEvents[i].end);
      const d4 = new Date(newEvent.end);
/*
    console.log(d1 <= d2);
    console.log(d2 <= d3);
    console.log(d1 <= d4);
    console.log(d4 <= d3);
      */

       if (
        ( (d1  <= d2) && (d2 <= d3) ) || ( (d1  <= d4) &&
          (d4 <= d3) )
        )
      {   
          alert("CLASH"); 
          break;
       }

  }
  
  
  setAllEvents([...allEvents, newEvent]);
}

 return (

    <Sidebar>
      
        <div className="OrganizeGroup">
          <div className="app-containerr">
            <div className="headerCalendar">

            <h1>Calendar</h1>
            <h2>Add New Event</h2>
            </div>
            <div  className='form-groupppp' >
                <input 
                type="text"
                placeholder="Add Title" 
                style={{ width: "150px", marginRight: "10px" }} 
                value={newEvent.title} onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} />

                <DatePicker 
                placeholderText="Start Date" 
                style={{ marginRight: "10px" }} 
                selected={newEvent.start} 
                onChange={(start) => setNewEvent({ ...newEvent, start })} />

                <DatePicker 
                placeholderText="End Date" 
                selected={newEvent.end} 
                onChange={(end) => setNewEvent({ ...newEvent, end })} />

                <button className="EventButton" stylye={{ marginTop: "10px" }} onClick={handleAddEvent}>
                    Add Event
                </button>
            </div >
            <div className="calendarContent">

            <Calendar  localizer={localizer} events={allEvents} startAccessor="start" endAccessor="end" style={{ height: 500, margin: "50px" }} />
            </div>
            
          </div>
        <div className="OrganizeNotes">
          <NoteContainer/>
        </div>
        </div>
    </Sidebar>
  )
}
export default CalendarSystem