import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import { Button, Grid, MenuItem } from '@mui/material';
import { Label } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';   

function ProjectInformation(props) {
 
  const options1 = [
    { label: 'B1 - Residencial', value: 'b1_residencial' },
    { label: 'B2 - Rural', value: 'b2_rural' },
    { label: 'B2 - Cooperativa', value: 'b2_cooperativa' },
    { label: 'B2 - Serviço Público de Irrigação', value: 'b2_servico_publico_de_irrigacao' },
    { label: 'B3 - Demais Classes', value: 'b3_demais_classes' },
    { label: 'A4 - Horária Verde', value: 'a4_horaria_verde' },
    { label: 'A4 - Horária Azul', value: 'a4_horaria_azul' },
  ];

  const options2 = [
    { label: 'Trifásico', value: 'trifasico' },
    { label: 'Bifásico', value: 'bifasico' },
    { label: 'Monofásico', value: 'monofasico' },
  ];
  
  const options3 = [
    { label: 'Áereo', value: 'aereo' },
    { label: 'Subterrâneo', value: 'subterraneo' },
  ];

  const options4 = [
    { label: 'Geração Local', value: 'geracao_local' },
    { label: 'Autoconsumo Remoto', value: 'autoconsumo_remoto' },
  ];



  const disjuntorTrifasico = [
    { label: '0', value: '0' },
    { label: '25', value: '25' },
    { label: '32', value: '32' },
    { label: '40', value: '40' },
    { label: '50', value: '50' },
    { label: '63', value: '63' },
    { label: '80', value: '80' },
    { label: '100', value: '100' },
  ];

  const disjuntorBifasico = [
    { label: '0', value: '0' },
    { label: '20', value: '20' },
    { label: '25', value: '25' },
    { label: '32', value: '32' },
    { label: '40', value: '40' },
  ];

  const disjuntorMonofasico = [
    { label: '0', value: '0' },
    { label: '16', value: '16' },
    { label: '20', value: '20' },
    { label: '25', value: '25' },
    { label: '32', value: '32' },
    { label: '40', value: '40' },
  ];


  const [proJectType, setProjectType] = useState(props.selectedProjectType);
  const [serviceType, setServiceType] = useState(props.selectedServiceType);
  const [branchType, setBranchType] = useState(props.selectedBranchType);
  const [modalityType, setModalityType] = useState(props.selectedModalityType);
  const [disjuntor, setDisjuntor] = useState(props.selectedDisjuntor);
  const [contractedDemand, setContractedDemand] = useState(props.selectedContractedDemand);
  const [powerRede, setPowerRede] = useState(props.selectedPowerRede);
  const [numberUC, setNumberUC] = useState(props.selectedNumberUC);
  const [numberART, setNumberART] = useState(props.selectedNumberART);
  const [projectObs , setProjectObs] = useState(props.selectedProjectOBS);
  
  const [file_electricity_bill, setFileElectricityBill] = useState(props.selectedFile_electricity_bill);
  const [file_disjuntor, setFileDisjuntor] = useState(props.selectedFile_disjuntor);
  const [file_energy_box, setFileEnergyBox] = useState(props.selectedFile_energy_box);
  const [fileArt, setFileArt] = useState(props.selectedFile_ART);

  
  props.setSelectedProjectType(proJectType);
  props.setSelectedServiceType(serviceType);
  props.setSelectedBranchType(branchType);
  props.setSelectedModalityType(modalityType);
  props.setSelectedDisjuntor(disjuntor);
  props.setSelectedContractedDemand(contractedDemand);
  props.setSelectedPowerRede(powerRede);
  props.setSelectedNumberUC(numberUC);
  props.setSelectedNumberART(numberART);
  props.setSelectedProjectOBS(projectObs);
  props.setSelectedFile_electricity_bill(file_electricity_bill);
  props.setSelectedFile_disjuntor(file_disjuntor);
  props.setSelectedFile_energy_box(file_energy_box);
  props.setSelectedFile_ART(fileArt);


 

  return (
<>
    <Grid sx={{mt:1}} container spacing={2}>
    <Grid sx={{mt:1}} container spacing={2}>
      <Grid item xs={12} sm={6}>
        
        <TextField
          id='project-type'
          select
          label="Tipo de Projeto:"
          value={proJectType}
          onChange={(e) => setProjectType(e.target.value)}
          fullWidth
           size="small"
        >
          {options1.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
        id='service-type'
          select
          label="Atendimento:"
          value={serviceType}
          onChange={(e) => {
            setServiceType(e.target.value)
            if(e.target.value === 'trifasico') setPowerRede('220V/380V')  
            if(e.target.value === 'bifasico')   setPowerRede('220V/380V')  
            if(e.target.value === 'monofasico')   setPowerRede('220V') 

          }}
          fullWidth
           size="small"
        >
          {options2.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
        id='branch-type'
          select
          label="Tipo de Ramal:"
          value={branchType}
          onChange={(e) => setBranchType(e.target.value)}
          fullWidth
           size="small"
        >
          {options3.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
        id='modality-type'
          select
          label="Modalidade:"
          value={modalityType}
          onChange={(e) => setModalityType(e.target.value)}
          fullWidth
           size="small"
        >
          {options4.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
        id='disjuntor'
          select
          label="Disjuntor:"
          value={disjuntor}
          onChange={(e) => setDisjuntor(e.target.value)}
          fullWidth
           size="small"
        >
          { serviceType === 'trifasico' ? disjuntorTrifasico.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
          : null
        }
          {serviceType === 'bifasico' && disjuntorBifasico.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {serviceType === 'monofasico' && disjuntorMonofasico.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    {proJectType === 'a4_horaria_azul'  &&
     <Grid item xs={12} sm={6}>
      <TextField
        id='contracted-demand'
        label="Demanda Contratada"
        fullWidth
        value={contractedDemand}
         size="small"
        onChange={(e) => { e.target.value = e.target.value.replace(/\D/g, "")
        setContractedDemand(e.target.value)
        }
      }
      />
      </Grid>}
    {proJectType === 'a4_horaria_verde'  &&
     <Grid item xs={12} sm={6}>
      <TextField
        id='contracted-demand'
        label="Demanda Contratada"
        value={contractedDemand}
        fullWidth
         size="small"
         onChange={(e) => { e.target.value = e.target.value.replace(/\D/g, "")
          setContractedDemand(e.target.value)
          }
        }
      />
      </Grid>}

      <Grid item xs={12} sm={6}>
        <TextField
        disabled
        id='power-rede'

          fullWidth
           size="small"
          value={
            serviceType === 'trifasico' ? '220V/380V' : 
            serviceType === 'bifasico' ? '220V/380V' : 
            serviceType === 'monofasico' ? '220V' : 'Tenção da Rede'
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id='number-uc'
        label="Número de UC"
        value={numberUC}
          fullWidth
           size="small"
          onChange={(e) => {
            e.target.value = e.target.value.replace(/\D/g, "")
            setNumberUC(e.target.value)
          }
          }
        />
    </Grid>
      </Grid>
      <Grid sx={{mt:1}} container spacing={2}>
  
    <Grid item xs={12} sm={6}>
      <TextField
        id='number-art'
        label="N° ART"
        value={numberART}
          fullWidth
           size="small"
          onChange={(e) => {
            e.target.value = e.target.value.replace(/\D/g, "")
            setNumberART(e.target.value)
          }
          }
        />
    </Grid>
    <Grid item xs={12} sm={6}
    sx={{display:'flex',alignItems:'center'}}
    >
      <Button component="label" 
        variant="contained"
      >
        Carregar arquivo
        <input type="file" hidden onChange={
          (e) => setFileArt(e.target.files[0])
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Grid>
    <Grid item xs={12} sm={12} >
      <TextField
    
        id='project-obs'
        label="Observações"
        value={projectObs}
          fullWidth
           size="small"
          onChange={(e) => setProjectObs(e.target.value)}
        />
    </Grid>
    </Grid>
    <Grid item xs={12} sm={6} md={20}
    sx={{display:'flex',alignItems:'center'}}
    >
      <Button component="label" 
        variant="contained"
      >
        Carregar Conta de Luz
        <input type="file" hidden onChange={
          (e) => setFileElectricityBill(e.target.files[0])
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Grid>

    <Grid item xs={12} sm={6} md={20}
    sx={{display:'flex',alignItems:'center'}}
    >
      <Button component="label" 
        variant="contained"
      >
        Carregar Foto Disjuntor
        <input type="file" hidden onChange={
          (e) => setFileDisjuntor(e.target.files[0])
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Grid>

    <Grid item xs={12} sm={6} md={20}
    sx={{display:'flex',alignItems:'center'}}
    >
      <Button component="label" 
        variant="contained"
      >
        Carregar Foto Caixa de Energia
        <input type="file" hidden onChange={
          (e) => setFileEnergyBox(e.target.files[0])
        } />
        &nbsp;
        <CloudUploadIcon />
      </Button>
    </Grid>
    </Grid>

  </>
  
  )
}

export default ProjectInformation
