import { Fragment, useEffect, useState } from "react"
import { BrowserRouter, Route, Routes, useNavigate, Navigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Signin from "../pages/Signin/index.jsx"

import Employees from "../pages/Employees/index.jsx"
import Services from "../pages/Services/index.jsx"
import Home from "../pages/Home/index.jsx"
import CalendarSystem from "../pages/Calendar/index.jsx"
import Customers from "../pages/Customers/index.jsx"
import Settings from "../pages/Settings/index.jsx"
import Aterramento from "../Components/reportAterramento/index.jsx";
import Solar from "../Components/solar/index.jsx";
import ServiceSolar from "../pages/SolarService/index.jsx";
import ListSolarService from "../Components/ListSolarService/index.jsx";
import ListSolarServiceSystem from "../Components/ListSolarServiceSystem/index.jsx";
import ServiceProjectSolar from "../Components/form_mult_step_system/main.jsx";
import CustomerList from "../Components/customerList/index.jsx";
import SidebarMui from "../Components/sidebarMui/index.jsx";
import useAuth  from '../hooks/useAuth'
import EmployeeList from "../Components/employeeList/index.jsx";
import ServiceProjectSolarCustomer from "../Components/form_mult_step_system_customer/main.jsx";

const token = localStorage.getItem('token') 
const tokenParse = JSON.parse(token) 

console.log(tokenParse)


const RoutesApp = () => {

    <ToastContainer />

    const [auth, setAuth] = useState(false)
    useEffect(() => {
        verifyToken()
    }, [])

 const verifyToken = async () => {
try{
    if(!tokenParse){
        setAuth(false)
    }
    //const response = await fetch(`https://api-aplication-fortesol.vercel.app/api/users/home/${tokenParse.id}`,{
    const response = await fetch(`https://api.fortesol.com.br/api/users/home/${tokenParse.id}`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json   ',
            'Authorization': `Bearer ${tokenParse.token}`
        }}).then((response) => response.json())
        .then((data) => {
      
        //    console.log(data.user._id, tokenParse.id)
           // if(String(data.user._id) === String(tokenParse.id)){
            if(data.message === 'ok'){
                setAuth(true)
            }
        }).catch((error) => {
          
            setAuth(false)
        }
        
    )
 
    return response
}
catch(error){
    toast.info('Faça login para entrar no sistema')
}
 




    }

    return (
        <BrowserRouter>
            <Fragment>
                <Routes>

                    <Route path="*" element={ auth ? (tokenParse.typeUser !== 'Cliente' ? <Home/> : <ListSolarService id={tokenParse.id} />):<Signin />} />
                    <Route path="/" element={ auth ? (tokenParse.typeUser !== 'Cliente' ? <Home/> : <ListSolarService id={tokenParse.id} />) :<Signin />} />
                    <Route path="/home" element={ auth ? (tokenParse.typeUser !== 'Cliente' ? <Home/> :   (<Navigate to="/solar-service" replace />)) :<Signin />} />
                    <Route path="/customers" element={ auth && tokenParse.typeUser !== 'Cliente' ? <CustomerList/> : <Signin />} />
                    <Route path="/calendar" element={ auth && tokenParse.typeUser !== 'Cliente' ? <CalendarSystem/> : <Signin />} />
                    <Route path="/employees" element={ auth && tokenParse.typeUser !== 'Cliente' ? <EmployeeList/> : <Signin />} />
                    <Route path="/services" element={ auth && tokenParse.typeUser !== 'Cliente'? <Services/> : <Signin />} />
                    <Route path="/settings" element={ auth && tokenParse.typeUser !== 'Cliente' ? <Settings/> : <Signin />} />
                    <Route path="/services/grounding-report" element={ auth && tokenParse.typeUser !== 'Cliente' ? <Aterramento/> : <Signin />} />
                    <Route path="/services/new-project-solar" element={ auth && tokenParse.typeUser !== 'Cliente' ?  <ServiceProjectSolar/> : <Signin />} />
                    <Route path="/solar-service" element={ auth && tokenParse.typeUser === 'Cliente'? <ListSolarService id={tokenParse.id} /> : <Signin />} />
                    <Route path="/solar-service/demand" element={ auth && tokenParse.typeUser === 'Cliente'? <ServiceProjectSolarCustomer/> : <Signin />} />
                    <Route path="/services/project-solar-list" element={auth && tokenParse.typeUser !== 'Cliente' ?  <ListSolarServiceSystem  /> : <Signin />} />                    
                </Routes>

            </Fragment>
    
        </BrowserRouter>
    )
}

export default RoutesApp