
import React, { useState } from 'react'
import html2pdf from 'html2pdf.js'
import jsPDF from 'jspdf';
import { Avatar, Badge, Box, boxClasses, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, Paper, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import PhotovoltaicInformationProject from './photovoltaicInformation/index';
import ConfigSystemProject from './configSystem/index';
import DescriptiveMemorialProject from './descriptiveMemorial/index';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Loading from '../loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Preview from '../file_preview';

function System_Project_Dialog(props) {
  const token = JSON.parse(localStorage.getItem('token'));

  //Preview Detalhes Projeto fotovoltico

  //Pegando dados do arquivo
  const [search, setSearch] = useState(true)

  if (search) {
    const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/data_photovoltaic/${props.data?.id_demand}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => response.blob())
      .then((blob) => {
        setTypeFile(blob.type.split('/')[1])
        setTypeFileName(blob.type.split('/')[0])
        const url = window.URL.createObjectURL(new Blob([blob]));
        setSrc(url)
      }).catch((error) => {
        console.log(error)
      })

    setSearch(false)
  }
  const [open, setOpen] = useState(false)
  const [src, setSrc] = useState('')
  const [typeFile, setTypeFile] = useState('')
  const [typeFileName, setTypeFileName] = useState('')
  const handleClose = () => {
    setOpen(false)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }





  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isInformation, setIsInformation] = useState([]);
  const valid = () => {
    setIsValid(true)

  }

  const steps = ['Inversores / Módulos', 'Configuração do Sistema', 'Download do Projeto'];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (
          selectedInvertersArray.length > 0 &&
          selectedModulesArray.length > 0 &&
          selectedArrayMppt.length > 0 &&
          selectedCountModules.length > 0
        ) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        if (selectedCountModules.map((item) => item.qtd !== item.qtd_selecionada).includes(true)) {
          toast.error('Atenção, preencha todos os campos destacados e selecione a quantidade de módulos corretamente')
          return
        }
        if (!validFormConfigSystem) return toast.error('Atenção, preencha todos os campos destacados e selecione a quantidade de módulos corretamente')
        else setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      default:

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
    }
  };






  // Pag 1 : Estados para armazenar os dados do cliente
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedRG_CNH, setSelectedRG_CNH] = useState('');
  const [selectedSocial_Contract, setSelectedSocial_Contract] = useState('');

  // Page 2 : Estados para armazenar os dados do projeto
  const [selectedProjectType, setSelectedProjectType] = useState('');
  const [selectedServiceType, setSelectedServiceType] = useState('');
  const [selectedBranchType, setSelectedBranchType] = useState('');
  const [selectedModalityType, setSelectedModalityType] = useState('');
  const [selectedDisjuntor, setSelectedDisjuntor] = useState('');
  const [selectedContractedDemand, setSelectedContractedDemand] = useState('');
  const [selectedPowerRede, setSelectedPowerRede] = useState('');
  const [selectedNumberUC, setSelectedNumberUC] = useState('');
  const [selectedNumberART, setSelectedNumberART] = useState('');
  const [selectedProjectOBS, setSelectedProjectOBS] = useState('');

  const [selectedFile_electricity_bill, setSelectedFile_electricity_bill] = useState('');
  const [selectedFile_disjuntor, setSelectedFile_disjuntor] = useState('');
  const [selectedFile_energy_box, setSelectedFile_energy_box] = useState('');
  const [selectedFile_ART, setSelectedFile_ART] = useState('');


  // Pag 3 : Estados para armazenar os dados de endereço
  const [selectedAddressCep, setSelectedAddressCep] = useState('');
  const [selectedAddressStreet, setSelectedAddressStreet] = useState('');
  const [selectedAddressNumber, setSelectedAddressNumber] = useState('');
  const [selectedAddressDistrict, setSelectedAddressDistrict] = useState('');
  const [selectedAddressCity, setSelectedAddressCity] = useState('');
  const [selectedAddressState, setSelectedAddressState] = useState('');

  const [selectedLatitudeClick, setSelectedLatitudeClick] = useState('');
  const [selectedLongitudeClick, setSelectedLongitudeClick] = useState('');

  // Pag 4 : Estados para armazenar os dados dos módulos e inversores
  const [selectedlistinverters, setSelectedlistinverters] = useState([]);
  const [selectedInvertersArray, setSelectedInvertersArray] = useState([]);
  const [selectedArrayMppt, setSelectedArrayMppt] = useState([]);
  const [selectedModulesArray, setSelectedModulesArray] = useState([]);
  const [selectedCountModules, setSelectedCountModules] = useState([]);

  const [inforTest, setInforTest] = useState(false);
  const handleTestInformation = (e) => {
    setInforTest(e);
  }
  const [stateSelectedInverters, setStateSelectedInverters] = useState('');
  const [stateSelectedModules, setStateSelectedModules] = useState('');
  const [stateSelectedCountModules, setStateSelectedCountModules] = useState('');


  // Pag 5 : Estados html para salvar dados do sistema
  const [disjuntor, setDisjuntor] = useState(parseInt(props.data?.disjuntor_demand));

  const [checkMppt, setCheckMppt] = useState('');
  const [selectModules, setSelectModules] = useState('');
  const [checkString, setCheckString] = useState('');
  const [unidades, setUnidades] = useState('');
  const [p_unidades, setP_unidades] = useState('');
  const [azimute, setAzimute] = useState('');
  const [p_azimute, setP_azimute] = useState('');
  const [inclincao, setInclincao] = useState('');
  const [p_inclincao, setP_inclincao] = useState('');
  const [potenciaM, setPotenciaM] = useState('');
  const [potenciaMppt, setPotenciaMppt] = useState('');
  const [correnteStr, setCorrenteStr] = useState('');
  const [correnteMppt, setCorrenteMppt] = useState('');
  const [tensao, setTensao] = useState('');
  const [secaoN, setSecaoN] = useState('');
  const [secaoP, setSecaoP] = useState('');
  const [comprimento, setComprimento] = useState('');
  const [tensaoPorce, setTensaoPorce] = useState('');
  const [PerdasCabos, setPerdasCabos] = useState('');
  const [comprimentoFinal, setComprimentoFinal] = useState('');
  const [comprimentoQuadro, setComprimentoQuadro] = useState('');
  const [structureType, setStructureType] = useState('');

  //Elemento salvará todas as informações do sistema para leitura e gera o memorial descritivo
  const [elementConfigSystem, setElementConfigSystem] = useState('');


  //Função para validação de formulário da configuração do sistema
  const [validFormConfigSystem, setValidFormConfigSystem] = useState(false);
  const handleTestDataConfigSystem = (e) => {
    setValidFormConfigSystem(e);
  }



  //Meio para salvar valores das variáveis de estado para não perder os valores
  const [saveInverters, setSaveInverters] = useState('');
  const [saveModules, setSaveModules] = useState('');
  const [saveMppt, setSaveMppt] = useState('');
  const [saveCountModules, setSaveCountModules] = useState('');
  const [saveCheckMppt, setSaveCheckMppt] = useState('');
  const [saveSelectModules, setSaveSelectModules] = useState('');
  const [saveCheckString, setSaveCheckString] = useState('');
  const [saveUnidades, setSaveUnidades] = useState('');
  const [saveP_unidades, setSaveP_unidades] = useState('');
  const [saveAzimute, setSaveAzimute] = useState('');
  const [saveP_azimute, setSaveP_azimute] = useState('');
  const [saveInclincao, setSaveInclincao] = useState('');
  const [saveP_inclincao, setSaveP_inclincao] = useState('');
  const [savePotenciaM, setSavePotenciaM] = useState('');
  const [savePotenciaMppt, setSavePotenciaMppt] = useState('');
  const [saveCorrenteStr, setSaveCorrenteStr] = useState('');
  const [saveCorrenteMppt, setSaveCorrenteMppt] = useState('');
  const [saveTensao, setSaveTensao] = useState('');
  const [saveSecaoN, setSaveSecaoN] = useState('');
  const [saveSecaoP, setSaveSecaoP] = useState('');
  const [saveComprimento, setSaveComprimento] = useState('');
  const [saveTensaoPorce, setSaveTensaoPorce] = useState('');
  const [savePerdasCabos, setSavePerdasCabos] = useState('');
  const [saveComprimentoFinal, setSaveComprimentoFinal] = useState('');
  const [saveComprimentoQuadro, setSaveComprimentoQuadro] = useState('');
  const [saveStructureType, setSaveStructureType] = useState('');

  //Função para salvar valores das variáveis de estado para não perder os valores
  const handleSaveData = (e) => {
    setSaveInverters(selectedInvertersArray);
    setSaveModules(selectedModulesArray);
    setSaveMppt(selectedArrayMppt);
    setSaveCountModules(selectedCountModules);
    setSaveCheckMppt(checkMppt);
    setSaveSelectModules(selectModules);
    setSaveCheckString(checkString);
    setSaveUnidades(unidades);
    setSaveP_unidades(p_unidades);
    setSaveAzimute(azimute);
    setSaveP_azimute(p_azimute);
    setSaveInclincao(inclincao);
    setSaveP_inclincao(p_inclincao);
    setSavePotenciaM(potenciaM);
    setSavePotenciaMppt(potenciaMppt);
    setSaveCorrenteStr(correnteStr);
    setSaveCorrenteMppt(correnteMppt);
    setSaveTensao(tensao);
    setSaveSecaoN(secaoN);
    setSaveSecaoP(secaoP);
    setSaveComprimento(comprimento);
    setSaveTensaoPorce(tensaoPorce);
    setSavePerdasCabos(PerdasCabos);
    setSaveComprimentoFinal(comprimentoFinal);
    setSaveComprimentoQuadro(comprimentoQuadro);
    setSaveStructureType(structureType);
  }

  //Função para retornar valores das variáveis de estado para não perder os valores
  const handleReturnData = (e) => {

    setCheckMppt(saveCheckMppt);
    setSelectModules(saveSelectModules);
    setCheckString(saveCheckString);
    setUnidades(saveUnidades);
    setP_unidades(saveP_unidades);
    setAzimute(saveAzimute);
    setP_azimute(saveP_azimute);
    setInclincao(saveInclincao);
    setP_inclincao(saveP_inclincao);
    setPotenciaM(savePotenciaM);
    setPotenciaMppt(savePotenciaMppt);
    setCorrenteStr(saveCorrenteStr);
    setCorrenteMppt(saveCorrenteMppt);
    setTensao(saveTensao);
    setSecaoN(saveSecaoN);
    setSecaoP(saveSecaoP);
    setComprimento(saveComprimento);
    setTensaoPorce(saveTensaoPorce);
    setPerdasCabos(savePerdasCabos);
    setComprimentoFinal(saveComprimentoFinal);
    setComprimentoQuadro(saveComprimentoQuadro);
    setStructureType(saveStructureType);
  }

  //Função para converter base64 em blob
  const base64ToBlob = (base64, type) => {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

  const [systemValues, setSystemValues] = React.useState([])
  const [quadroValues, setQuadroValues] = React.useState([])



  const save_memorial = async (e) => {
    const array_inverters = JSON.stringify(selectedInvertersArray);
    const array_modules = JSON.stringify(selectedModulesArray);
    const array_mppt = JSON.stringify(selectedArrayMppt);
    const array_count_modules = JSON.stringify(selectedCountModules);

    const array_check_mppt = JSON.stringify(saveCheckMppt);
    const array_select_modules = JSON.stringify(saveSelectModules);
    const array_check_string = JSON.stringify(saveCheckString);
    const array_unidades = JSON.stringify(saveUnidades);
    const array_p_unidades = JSON.stringify(saveP_unidades);
    const array_azimute = JSON.stringify(saveAzimute);
    const array_p_azimute = JSON.stringify(saveP_azimute);
    const array_inclincao = JSON.stringify(saveInclincao);
    const array_p_inclincao = JSON.stringify(saveP_inclincao);
    const array_potenciaM = JSON.stringify(savePotenciaM);
    const array_potenciaMppt = JSON.stringify(savePotenciaMppt);
    const array_correnteStr = JSON.stringify(saveCorrenteStr);
    const array_correnteMppt = JSON.stringify(saveCorrenteMppt);
    const array_tensao = JSON.stringify(saveTensao);
    const array_secaoN = JSON.stringify(saveSecaoN);
    const array_secaoP = JSON.stringify(saveSecaoP);
    const array_comprimento = JSON.stringify(saveComprimento);
    const array_tensaoPorce = JSON.stringify(saveTensaoPorce);
    const array_perdasCabos = JSON.stringify(savePerdasCabos);
    const array_comprimentoFinal = JSON.stringify(saveComprimentoFinal);
    const array_comprimentoQuadro = JSON.stringify(saveComprimentoQuadro);
    const array_structureType = JSON.stringify(saveStructureType);

    if (!array_check_mppt
      || !array_select_modules
      || !array_check_string
      || !array_unidades
      || !array_p_unidades
      || !array_azimute
      || !array_p_azimute
      || !array_inclincao
      || !array_p_inclincao
      || !array_potenciaM
      || !array_potenciaMppt
      || !array_correnteStr
      || !array_correnteMppt
      || !array_tensao
      || !array_secaoN
      || !array_secaoP
      || !array_comprimento
      || !array_tensaoPorce
      || !array_perdasCabos
      || !array_comprimentoFinal
      || !array_comprimentoQuadro
      || !array_structureType
    ) return

    await fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-project-descriptive-memorial/${props.data.id_demand}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ array_inverters_project: array_inverters, array_modules_project: array_modules, array_mppt_project: array_mppt, array_count_modules_project: array_count_modules, array_check_mppt: array_check_mppt, array_select_modules: array_select_modules, array_check_string: array_check_string, array_unidades: array_unidades, array_p_unidades: array_p_unidades, array_azimute: array_azimute, array_p_azimute: array_p_azimute, array_inclinacao: array_inclincao, array_p_inclinacao: array_p_inclincao, array_potencia_m: array_potenciaM, array_potencia_mppt: array_potenciaMppt, array_corrente_str: array_correnteStr, array_corrente_mppt: array_correnteMppt, array_tensao: array_tensao, array_secao_n: array_secaoN, array_secao_p: array_secaoP, array_comprimento: array_comprimento, array_tensao_porce: array_tensaoPorce, array_perdas_cabos: array_perdasCabos, array_comprimento_final: array_comprimentoFinal, array_comprimento_quadro: array_comprimentoQuadro, array_structure_type: array_structureType })
    })
      .then(response => response.json())

      .catch(error => console.log('error', error))

    if (!elementConfigSystem) return




    const handleDate = () => {
      var data = new Date()
      var dia = data.getDate()
      var mes = data.getMonth() + 1
      var ano = data.getFullYear()

      if (dia < 10) {
        dia = `0${dia}`
      }
      if (mes === 1) {
        mes = 'Janeiro'
      }
      if (mes === 2) {
        mes = 'Fevereiro'
      }
      if (mes === 3) {
        mes = 'Março'
      }
      if (mes === 4) {
        mes = 'Abril'
      }
      if (mes === 5) {
        mes = 'Maio'
      }
      if (mes === 6) {
        mes = 'Junho'
      }
      if (mes === 7) {
        mes = 'Julho'
      }
      if (mes === 8) {
        mes = 'Agosto'
      }
      if (mes === 9) {
        mes = 'Setembro'
      }
      if (mes === 10) {
        mes = 'Outubro'
      }
      if (mes === 11) {
        mes = 'Novembro'
      }
      if (mes === 12) {
        mes = 'Dezembro'
      }

      var data = `${dia} de ${mes} de ${ano}`
      return data
    }
    const project_type = (project) => {
      if (project === 'b1_residencial') return 'B1 - Residencial'
      if (project === 'b2_rural') return 'B2 - Rural'
      if (project === 'b2_cooperativa') return 'B2 - Cooperativa'
      if (project === 'b2_servico_publico_de_irrigaca') return 'B2 - Serviço Público de Irrigação'
      if (project === 'b3_demais_classes') return 'B3 - Demais Classes'
      if (project === 'a4_horaria_verde') return 'A4 - Horária Verde'
      if (project === 'a4_horaria_azul') return 'A4 - Horária Azul'
    }
    const service_type = (service) => {
      if (service === 'monofasico') return 'Monofásico'
      if (service === 'bifasico') return 'Bifásico'
      if (service === 'trifasico') return 'Trifásico'
    }

    const decimalParaGrausMinutosSegundos = (decimal) => {
      /*  // Separando a parte inteira (graus)
        const graus = parseInt(decimal);
        // Calculando os minutos a partir da parte decimal
        const minutosDecimais = (decimal - graus) * 60;
        const minutos = Math.floor(minutosDecimais);
        // Calculando os segundos a partir da parte decimal dos minutos
        const segundos = Math.round((minutosDecimais - minutos) * 60);
        return `${graus}° ${minutos}' ${segundos}"`;*/

      // Extrai o sinal da coordenada
      const sinal = decimal >= 0 ? 'N' : 'S'; // Para latitude
      // const sinal = coordenadaDecimal >= 0 ? 'E' : 'W'; // Para longitude

      // Garante que a coordenada seja positiva para os cálculos
      const coordenadaAbsoluta = Math.abs(decimal);

      // Extrai os graus
      const graus = Math.floor(coordenadaAbsoluta);

      // Calcula os minutos
      const minutosDecimal = (coordenadaAbsoluta - graus) * 60;
      const minutos = Math.floor(minutosDecimal);

      // Calcula os segundos
      const segundos = (minutosDecimal - minutos) * 60;

      // Formata a saída
      return `-${graus}° ${minutos}' ${segundos.toFixed(2)}"`;
    }






    function calcularArea(entrada) {
      // Verifica se a entrada é válida
      if (!entrada) {
        return 0;
      }

      // Divide a entrada em dimensões (largura, comprimento, altura)
      const dimensoes = entrada.split(" / ")

      // Converte milímetros para metros
      const largura = parseFloat(dimensoes[0]) / 1000;
      const comprimento = parseFloat(dimensoes[1]) / 1000;

      // Calcula a área
      const area = largura * comprimento;

      // Retorna a área formatada em metros quadrados
      return area.toFixed(2);
    }



    const potenciaModulosInv = (index) => {
      let select_mppt = elementConfigSystem?.querySelectorAll(`#select_modules${index}`)

      let arrayPotencias = []
      let arrayModulosId = []
      for (let i = 0; i < select_mppt?.length; i++) {
        if (parseInt(select_mppt[i].value) !== 0) arrayModulosId.push(select_mppt[i].value)
      }
      selectedModulesArray?.map((data) => {
        arrayModulosId?.map((id) => {
          if (data.id_modules === parseInt(id)) arrayPotencias.push(('  ' + data.maximum_power))
        })
      })
      let total = 0
      for (let i = 0; i < arrayPotencias.length; i++) {
        total = total + parseFloat(arrayPotencias[i])
      }
      let result = [...new Set(arrayPotencias)]

      return result
    }

    const contModulosInv = (index) => {

      let unidades = elementConfigSystem?.querySelectorAll(`#unidade${index}`)
      let p_unidades = elementConfigSystem?.querySelectorAll(`.p-unidade${index}`)

      //Contagem Geral
      let cont = 0
      let cont2 = 0
      for (let i = 0; i < unidades?.length; i++) {
        if (!isNaN(parseInt(unidades[i].value))) cont = cont + parseInt(unidades[i].value)
      }
      for (let i = 0; i < p_unidades?.length; i++) {
        if (!isNaN(parseInt(p_unidades[i].innerHTML))) cont2 = cont2 + parseInt(p_unidades[i].innerHTML)
      }
      return cont + cont2
    }
    const mpptEmUso = (index) => {
      let checkMppt = elementConfigSystem?.querySelectorAll(`.checkMppt${index}`)
      let cont = 1
      for (let i = 0; i < checkMppt?.length; i++) {
        if (checkMppt[i].checked) cont = cont + 1
      }
      return cont
    }


    const numeroDeModulosPorMppt = (index) => {
      let arrayValoresPorArranjo = []
      let arrayValoresPorMppt = []
      let arrayTestStg = []
      let arrayTestMppt = []

      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {

            let checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`)
            let checkMppt = elementConfigSystem?.querySelectorAll(`.checkMppt${index}${indexMppt}`)


            if (parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value) !== 0 && !isNaN(parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value))) arrayValoresPorArranjo.push(' ' + parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value))
            for (let i = 0; i < checkStr.length; i++) {

              if (checkMppt[i]?.checked) {
                if (checkMppt[i].classList[0].slice(-3).slice(0, 2) === `${index}${indexMppt}`) arrayTestMppt.push(`${index}${indexMppt}`)
              }
              if (checkStr[i].checked) {
                if (checkStr[i].classList[0].slice(-3).slice(0, 2) === `${index}${indexMppt}` && checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}0`) {
                  arrayTestStg.push(`${index}${indexMppt}`)
                  //aparentemente esse código é desnecessário, que loucura!
                  //  if (checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}${0}`) arrayValoresPorArranjo.push(` ` + parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value))
                }
              }
            }
          })
        }
      })
      let newArray = []
      newArray.push(arrayTestStg[0])
      for (let i = 0; i < arrayTestStg.length; i++) {
        newArray.push(arrayTestStg[i])
      }
      for (let i = 0; i < arrayTestMppt?.length; i++) {
        newArray.push(arrayTestMppt[i])
      }


      let arrayMpptEmUso = []
      arrayMpptEmUso.push('00')
      for (let i = 0; i < arrayTestMppt?.length; i++) {
        arrayMpptEmUso.push(arrayTestMppt[i])
      }


      arrayValoresPorMppt = arrayValoresPorArranjo


      const resultArray = arrayValoresPorMppt.toString().replace(/,/g, ' / ')
      return resultArray
    }



    const numeroDeModulosPorArranjo = (index) => {
      let arrayValoresPorArranjo = []
      let arrayValoresPorMppt = []
      let arrayTestStg = []
      let arrayTestMppt = []

      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {

            let checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`)
            let checkMppt = elementConfigSystem?.querySelectorAll(`.checkMppt${index}${indexMppt}`)


            if (parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value) !== 0 && !isNaN(parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value))) arrayValoresPorArranjo.push(' ' + parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value))
            for (let i = 0; i < checkStr.length; i++) {

              if (checkMppt[i]?.checked) {
                if (checkMppt[i].classList[0].slice(-3).slice(0, 2) === `${index}${indexMppt}`) arrayTestMppt.push(`${index}${indexMppt}`)
              }
              if (checkStr[i].checked) {
                if (checkStr[i].classList[0].slice(-3).slice(0, 2) === `${index}${indexMppt}` && checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}0`) {
                  arrayTestStg.push(`${index}${indexMppt}`)
                  //aparentemente esse código é desnecessário, que loucura!
                  //  if (checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}${0}`) arrayValoresPorArranjo.push(` ` + parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value))
                }
              }
            }
          })
        }
      })
      let newArray = []
      newArray.push(arrayTestStg[0])
      for (let i = 0; i < arrayTestStg.length; i++) {
        newArray.push(arrayTestStg[i])
      }
      for (let i = 0; i < arrayTestMppt?.length; i++) {
        newArray.push(arrayTestMppt[i])
      }


      let arrayMpptEmUso = []
      arrayMpptEmUso.push('00')
      for (let i = 0; i < arrayTestMppt?.length; i++) {
        arrayMpptEmUso.push(arrayTestMppt[i])
      }










      //Aqui esta tirando os valos iguais e deixando somente um, problema é que não separa string de mppt 
      // arrayValoresPorMppt = [...new Set(arrayValoresPorArranjo)] (precisei tirar para fazer teste, aparemente é desnecessário como o código acima, que loucura!)
      //colocando este no lugar para testar
      arrayValoresPorMppt = arrayValoresPorArranjo // Cara, ficou perfeito assim. Em resumo, o arrayValoresPorArranjo dentro do for estava pegando mais valores do o esperado e o new set array acima estava cortando valores demais, foi só tirar o arrayValoresPorArranjo for e pegar os valores secos do arrayValoresPorArranjo que deu certo, provavelmente o for abaixo seja inutil, mas vou deixar por garantia, para me lembrar.

      //após nova observação o for abaixo é inutil e as informações abaixo são apenas para informação pois são antigas a data de hoje que é 26/09/2024
      //-----
      //Foi preciso fazer este for para pegar somente os valores de mppt 
      /*Foi preciso fazer este if por que as vezes o arrayValoresPorMppt quando
       todos os valores selecionados no sistema são iguais ele fica com a penas
       1 valor, ou seja apenas uma mppt, mesmo tendo mais ativas, então quando 
       o arrayValoresPorMppt tem apenas 1 valor, ele faz a leitura do for para completar
       */
      // if(arrayValoresPorMppt.length ===1 ){
      // for(let i = 0; i < arrayMpptEmUso.length-1; i++){
      //   arrayValoresPorMppt.push(parseInt(elementConfigSystem?.querySelector(`.unidade`+arrayMpptEmUso[i]+'0')?.value))
      // }}            # obsoleto em 26/09/2024
      //------


      //Passando valores para o elemento p que irá mostrar a quantidade de modulos por arranjo

      const resultArray = arrayValoresPorMppt.toString().replace(/,/g, ' / ')
      if (document.getElementById(`modulosPorArranjo${index}`)) document.getElementById(`modulosPorArranjo${index}`).innerHTML = resultArray


      // if(document.querySelector('.stringsParalelo'))document.querySelector('.stringsParalelo').innerHTML = quantidadeDeValoresRepedidosArray(arrayValoresPorArranjo)

      return arrayValoresPorMppt

    }
    /*
     const numeroDeModulosPorArranjo = (index) => {
       const arrayValoresPorArranjo = [];
       const arrayTestStg = [];
       const arrayTestMppt = [];
     
       arrayMppt?.forEach((mpptData, i) => {
         if (i === index) {
           mpptData.mppt?.forEach((stringData, indexMppt) => {
             const unidadeValue = parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value);
             if (unidadeValue !== 0 && !isNaN(unidadeValue)) {
               arrayValoresPorArranjo.push(unidadeValue);
             }
     
             const checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`);
             const checkMppt = elementConfigSystem?.querySelectorAll(`.checkMppt${index}${indexMppt}`);
     
             if (checkMppt[i]?.checked && checkStr[i]?.checked) {
               const className = checkStr[i].classList[0].slice(-3);
               if (className !== `${index}${indexMppt}0`) {
                 arrayTestStg.push(className);
                 arrayValoresPorArranjo.push(unidadeValue);
               }
               arrayTestMppt.push(className.slice(0, 2));
             }
           });
         }
       });
     
       const newArray = [...arrayTestStg, ...arrayTestMppt];
       const arrayMpptEmUso = ['00', ...arrayTestMppt];
    
       const arrayValoresPorMppt = [...new Set(arrayValoresPorArranjo)];
     
       if (document.querySelector(`.stringsParalelo${index}`)) {
         document.querySelector(`.stringsParalelo${index}`).innerHTML = quantidadeDeValoresRepedidosArray(newArray);
       }
     
       return arrayValoresPorMppt;
     };
    */


    const quantidadeDeValoresRepedidosArray = (arr) => {

      let counts = {};
      let array = []
      arr.forEach(function (x) { counts[x] = (counts[x] || 0) + 1 });
      //Object.entries(counts)?.map(([value, count]) => (array.push(count-1))) 
      Object.entries(counts)?.map(([value, count]) => (array.push(count === 1 ? 0 : count)))

      //return counts ;

      return array;
    }
    const stringAtivasPorMppt = (index) => {

      let array = []
      let cont = 1
      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {
            let checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`)
            for (let i = 0; i < checkStr.length; i++) {

              if (checkStr[i].checked) {
                if (checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}0`) cont = cont + 1
              }
            }
            array.push(cont)
            cont = 1
          })
        }
      })

      let arrayValoresPorArranjo = numeroDeModulosPorArranjo(index)

      const resultArray = []
      for (let i = 0; i < arrayValoresPorArranjo.length; i++) {
        resultArray.push(array[i])
        if (i !== arrayValoresPorArranjo.length - 1) resultArray.push(' / ')
      }

      return resultArray
    }

    const contArranjos = (index) => {

      let array = stringAtivasPorMppt(index)


      //Somando os valores do array
      let soma = 0;
      for (let i = 0; i < array.length; i++) {
        if (typeof array[i] === 'number') {
          soma += array[i];
        }
      }
      return soma;

    }



    const stringsEmParalelo = (index) => {

      let array = []
      let cont = 1
      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {
            let checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`)
            for (let i = 0; i < checkStr.length; i++) {

              if (checkStr[i].checked) {
                if (checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}0`) cont = cont + 1
              }
            }
            if (cont > 1) {
              array.push(cont)

            } else {
              array.push(0)

            }
            cont = 1
          })
        }
      })

      let arrayValoresPorArranjo = numeroDeModulosPorArranjo(index)
      const resultArray = []
      for (let i = 0; i < arrayValoresPorArranjo.length; i++) {
        resultArray.push(array[i])
        if (i !== arrayValoresPorArranjo.length - 1) resultArray.push(' / ')
      }


      return resultArray
    }
    const inclinacaoPorMppt = (index) => {
      let arrayInclinacao = []
      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {
            if (elementConfigSystem?.querySelector(`.inclinacao${index}${indexMppt}${0}`)) arrayInclinacao.push(' ' + elementConfigSystem?.querySelector(`.inclinacao${index}${indexMppt}${0}`).value)
          })
        }
      })

      arrayInclinacao = arrayInclinacao.map((data, i) => {
        if (data !== ' ') return ' / ' + data
        return
      })
      arrayInclinacao = arrayInclinacao.map((data, i) => {
        if (i === 0) return data?.replace(' / ', '')
        return data
      })
      return arrayInclinacao
    }

    const azimutePorMppt = (index) => {
      let arrayAzimute = []
      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {
            if (elementConfigSystem?.querySelector(`.azimute${index}${indexMppt}${0}`) && elementConfigSystem?.querySelector(`.azimute${index}${indexMppt}${0}`).value !== ' ' || elementConfigSystem?.querySelector(`.azimute${index}${indexMppt}${0}`).value !== '') arrayAzimute.push(' ' + elementConfigSystem?.querySelector(`.azimute${index}${indexMppt}${0}`).value)

          })
        }
      })

      //tirando ' ' do array

      arrayAzimute = arrayAzimute.map((data, i) => {
        if (data !== ' ') return ' / ' + data
        return
      })
      arrayAzimute = arrayAzimute.map((data, i) => {
        if (i === 0) return data?.replace(' / ', '')
        return data
      })


      return arrayAzimute
    }


    const tesaoMaximaVOC = (index) => {
      let arrayVOC = []
      let cont = 1
      let array = []
      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {
            let checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`)
            for (let i = 0; i < checkStr.length; i++) {

              if (checkStr[i].checked) {
                if (checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}0`) cont = cont + 1
              }
            }
            array.push(cont)
            cont = 1
          })
        }
      })

      let select_mppt = elementConfigSystem?.querySelectorAll(`#select_modules${index}`)
      let arrayTensao = []
      let arrayModulosId = []
      for (let i = 0; i < select_mppt?.length; i++) {
        if (parseInt(select_mppt[i].value) !== 0) arrayModulosId.push(select_mppt[i].value)
      }
      selectedModulesArray?.map((data) => {
        arrayModulosId?.map((id) => {
          if (data.id_modules === parseInt(id)) arrayTensao.push(('  ' + (data.open_circuit_voltage_modules).replace(',', '.')))
        })
      })

      let arrayValoresPorArranjo = numeroDeModulosPorArranjo(index)



      for (let i = 0; i < arrayValoresPorArranjo.length; i++) {
        arrayVOC.push(' ' + Math.round(parseFloat(arrayTensao[i]) * ((parseFloat(arrayValoresPorArranjo[i])))))

        //arrayVOC.push(' ' + parseFloat(arrayTensao[i]) * ((parseFloat(arrayValoresPorArranjo[i])))).toFixed(1)
        //hojé 26/09/2024 após nova observação, Dr Rodrigo pediu não multiplicar pelos valores de modulos, então vou deixar o código abaixo comentado, pois tensão não se soma, apenas corrente
        //-----------------------------------
        // arrayVOC.push(' ' + (isNaN(parseFloat(arrayTensao[i]) * (parseFloat(array[i] * ((parseFloat(arrayValoresPorArranjo[i]))))).toFixed(1)) ? '' : (parseFloat(arrayTensao[i]) * (parseFloat(array[i] * ((parseFloat(arrayValoresPorArranjo[i])))))).toFixed(1)))
        //-----------------------------------

        //arrayVOC.push(' '+(parseFloat(arrayTensao[i])*(parseFloat(array[i])).toFixed(1)))
        if (i !== arrayValoresPorArranjo.length - 1) arrayVOC.push(' / ')

      }

      return arrayVOC
    }


    const tesaoMaximaVMP = (index) => {
      let arrayVMP = []
      let cont = 1
      let array = []
      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {
            let checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`)
            for (let i = 0; i < checkStr.length; i++) {

              if (checkStr[i].checked) {
                if (checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}0`) cont = cont + 1
              }
            }
            array.push(cont)
            cont = 1
          })
        }
      })


      let select_mppt = elementConfigSystem?.querySelectorAll(`#select_modules${index}`)
      let arrayTensao = []
      let arrayModulosId = []
      for (let i = 0; i < select_mppt?.length; i++) {
        if (parseInt(select_mppt[i].value) !== 0) arrayModulosId.push(select_mppt[i].value)
      }
      selectedModulesArray?.map((data) => {
        arrayModulosId?.map((id) => {
          if (data.id_modules === parseInt(id)) arrayTensao.push(('  ' + (data.maximum_operating_voltage).replace(',', '.')))
        })
      })



      let arrayValoresPorArranjo = numeroDeModulosPorArranjo(index)

      for (let i = 0; i < arrayValoresPorArranjo.length; i++) {

        arrayVMP.push(' ' + Math.round(parseFloat(arrayTensao[i]) * ((parseFloat(arrayValoresPorArranjo[i])))))
        //  arrayVMP.push(' ' +parseFloat(arrayTensao[i]) * ((parseFloat(arrayValoresPorArranjo[i]))).toFixed(2))
        //hojé 26/09/2024 após nova observação, Dr Rodrigo pediu não multiplicar pelos valores de modulos, então vou deixar o código abaixo comentado, pois tensão não se soma, apenas corrente
        //-----------------------------------
        //arrayVMP.push(' ' + (isNaN(parseFloat(arrayTensao[i]) * (parseFloat(array[i]) * ((parseFloat(arrayValoresPorArranjo[i])))).toFixed(1)) ? '' : (parseFloat(arrayTensao[i]) * (parseFloat(array[i] * ((parseFloat(arrayValoresPorArranjo[i])))))).toFixed(1)))
        //-----------------------------------

        //arrayVMP.push(' '+(parseFloat(arrayTensao[i])*(parseFloat(array[i])).toFixed(1)))
        if (i !== arrayValoresPorArranjo.length - 1) arrayVMP.push(' / ')
      }

      return arrayVMP
    }

    const correntMaximaISC = (index) => {
      let arrayISC = []
      let cont = 1
      let array = []
      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {
            let checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`)
            for (let i = 0; i < checkStr.length; i++) {

              if (checkStr[i].checked) {
                if (checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}0`) cont = cont + 1
              }
            }
            array.push(cont)
            cont = 1
          })
        }
      })

      let select_mppt = elementConfigSystem?.querySelectorAll(`#select_modules${index}`)
      let arrayCorrente = []
      let arrayModulosId = []
      for (let i = 0; i < select_mppt?.length; i++) {
        if (parseInt(select_mppt[i].value) !== 0) arrayModulosId.push(select_mppt[i].value)
      }
      selectedModulesArray?.map((data) => {
        arrayModulosId?.map((id) => {
          if (data.id_modules === parseInt(id)) arrayCorrente.push(('  ' + (data.short_circuit_current_modules).replace(',', '.')))
        })
      })

      let arrayValoresPorArranjo = numeroDeModulosPorArranjo(index)


      //Tinha o erro pois a variável array estava vindo sempre com a contagem 1 mesmo quando o mppt não estava ativo. Vou usar a variável arrayValoresPorArranjo para fazer a contagem correta
      let arrayAjuste = []
      for (let i = 0; i < arrayValoresPorArranjo.length; i++) {
        arrayAjuste.push(array[i])
      }







      for (let i = 0; i < arrayValoresPorArranjo.length; i++) {
        //arrayISC.push(' '+(isNaN(parseFloat(arrayCorrente[i])*(parseFloat(array[i]*((parseFloat(arrayValoresPorArranjo[i]) )))).toFixed(1)) ? '' : (parseFloat(arrayCorrente[i])*(parseFloat(array[i]*((parseFloat(arrayValoresPorArranjo[i]) ))))).toFixed(1)))
        arrayISC.push(' ' + (isNaN(parseFloat(arrayCorrente[i]) * (parseFloat(arrayAjuste[i])).toFixed(1)) ? '' : (parseFloat(arrayCorrente[i]) * (parseFloat(arrayAjuste[i]))).toFixed(1)))
        if (i !== arrayValoresPorArranjo.length - 1) arrayISC.push(' / ')
      }

      return arrayISC
    }



    const correnteMaximaIMP = (index) => {
      let arrayIMP = []
      let cont = 1
      let array = []
      selectedArrayMppt?.map((data, i) => {
        if (i === index) {
          data.mppt?.map((mppt, indexMppt) => {
            let checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`)
            for (let i = 0; i < checkStr.length; i++) {

              if (checkStr[i].checked) {
                if (checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}0`) cont = cont + 1
              }
            }
            array.push(cont)
            cont = 1
          })
        }
      })

      let select_mppt = elementConfigSystem?.querySelectorAll(`#select_modules${index}`)
      let arrayCorrente = []
      let arrayModulosId = []
      for (let i = 0; i < select_mppt?.length; i++) {
        if (parseInt(select_mppt[i].value) !== 0) arrayModulosId.push(select_mppt[i].value)
      }
      selectedModulesArray?.map((data) => {
        arrayModulosId?.map((id) => {
          if (data.id_modules === parseInt(id)) arrayCorrente.push(('  ' + (data.maximum_operating_current).replace(',', '.')))
        })
      })

      let arrayValoresPorArranjo = numeroDeModulosPorArranjo(index)

      //Tinha o erro pois a variável array estava vindo sempre com a contagem 1 mesmo quando o mppt não estava ativo. Vou usar a variável arrayValoresPorArranjo para fazer a contagem correta
      let arrayAjuste = []
      for (let i = 0; i < arrayValoresPorArranjo.length; i++) {
        arrayAjuste.push(array[i])
      }

      for (let i = 0; i < arrayValoresPorArranjo.length; i++) {
        //arrayIMP.push(' '+(isNaN(parseFloat(arrayTensao[i])*(parseFloat(array[i]*((parseFloat(arrayValoresPorArranjo[i]) )))).toFixed(1)) ? '' : (parseFloat(arrayTensao[i])*(parseFloat(array[i]*((parseFloat(arrayValoresPorArranjo[i]) ))))).toFixed(1)))
        arrayIMP.push((isNaN(parseFloat(arrayCorrente[i]) * (parseFloat(arrayAjuste[i])).toFixed(1)) ? '' : (parseFloat(arrayCorrente[i]) * (parseFloat(arrayAjuste[i]))).toFixed(1)))
        if (i !== arrayValoresPorArranjo.length - 1) arrayIMP.push(' / ')
      }

      return arrayIMP
    }
    const valoresPorModulos = (index) => {
      let unidades = elementConfigSystem.querySelectorAll(`#unidade${index}`)
      let p_unidades = elementConfigSystem.querySelectorAll(`.p-unidade${index}`)
      let selectModules = elementConfigSystem.querySelectorAll(`#select_modules${index}`)

      //Separando Modulos
      let array = []
      for (let i = 0; i < selectModules.length; i++) {
        array.push(selectModules[i].value)
      }
      let resultIdMod = [...new Set(array)]

      //Separando valores por modulo
      let arrayMod = []
      let arrayPuni = []
      for (let i = 0; i < selectModules.length; i++) {
        if (selectModules[i]?.classList[0].slice(-2) === unidades[i]?.classList[1].slice(-2) && selectModules[i].value !== '0' && !isNaN(parseInt(unidades[i].value))) {
          arrayMod.push({ id: selectModules[i].value, valueUni: parseInt(unidades[i].value) })
        }
        for (let j = 0; j < p_unidades.length; j++) {
          if (p_unidades[j]?.classList[1].slice(-2) === unidades[i]?.classList[1].slice(-2)) {
            arrayPuni.push({ id: p_unidades[j]?.classList[5].replace(/[^0-9]/g, ''), valueUni: parseInt(p_unidades[j].textContent) })
          }
        }
      }



      let arrayConcat = arrayMod.concat(arrayPuni)

      let resultadoFinal = arrayConcat.reduce((acc, item) => {
        // Se o ID já existe no acumulador, soma o valor
        if (acc[item.id]) {
          acc[item.id] += item.valueUni;
        } else {
          // Se não existe, cria uma nova entrada no acumulador
          acc[item.id] = item.valueUni;
        }
        return acc;
      }, {});

      let arrayFinal = []
      for (let i = 0; i < resultIdMod.length; i++) {
        arrayFinal.push({ id: resultIdMod[i], valor: resultadoFinal[resultIdMod[i]] })
      }

      /*
      let usinaFotovoltaica = []
      selectedModulesArray.map((data) => {
        arrayFinal.map((data2) => {
          if (data.id_modules === parseInt(data2.id)) {
            usinaFotovoltaica.push({id_module:data.id_modules ,brand_modules:data.brand_modules, model:data.model_modules, maximum_power:parseInt(data.maximum_power), qtd:data2.valor})
          }
        })
      })*/


      let usinaFotovoltaica = [];

      selectedModulesArray.forEach(data => {

        const matchingData2 = arrayFinal.find(data2 => data.id_modules === parseInt(data2.id));

        //   document.getElementById(`qtd-mod-${matchingData2.id}`).innerHTML = matchingData2.valor
        if (matchingData2) {
          usinaFotovoltaica.push({
            id_module: data.id_modules,
            brand_modules: data.brand_modules,
            model: data.model_modules,
            maximum_power: parseInt(data.maximum_power),
            qtd: matchingData2.valor,
            module_dimensions: data.module_dimensions,
          });
        }
      });



      let newSystemValues = selectedInvertersArray?.map((inv, i) => {
        if (i === index) {
          return { ...inv, usinaFotovoltaica }
        }
        return inv
      })

      setSelectedInvertersArray(newSystemValues)
      return usinaFotovoltaica
    }




    const pegandoValoresDoSistema = () => {

      selectedInvertersArray?.map((data, index) => {


        //informações inversor para o quadro

        let potenciaQuadro = elementConfigSystem?.querySelector('.potencia-maxima-quadro')
        let correnteQuadro = elementConfigSystem?.querySelector('.corrente-maxima-quadro')
        let connection_quadro = elementConfigSystem?.querySelector('.connection-quadro')
        let secao_nominal_quadro = elementConfigSystem?.querySelector('.secao-nominal-quadro')
        let comprimento_quadro = elementConfigSystem?.querySelector('.comprimento-quadro')
        let tensao_quadro_quadro = elementConfigSystem?.querySelector('.tensao-porce-quadro')
        let perdas_cabos_quadro = elementConfigSystem?.querySelector('.perdas-cabos-quadro')

        let sistema_padrao = elementConfigSystem?.querySelector('.sistema-padrao')
        let conexao_instalada = elementConfigSystem?.querySelector('.conexao-instalada')
        let corrente_instalada_trifasico = elementConfigSystem?.querySelector('.corrente-instalada-trifasicoFALSE')
        let corrente_instalada_bifasico = elementConfigSystem?.querySelector('.corrente-instalada-bifasicoFALSE')
        let corrente_instalada_monofasico = elementConfigSystem?.querySelector('.corrente-instalada-monofasicoFALSE')
        let potencia_kva = elementConfigSystem?.querySelector('.potencia-kva')
        let potencia_kw = elementConfigSystem?.querySelector('.potencia-kw')
        let secao_nominal_padrao = elementConfigSystem?.querySelector('.secao-nominal-padrao')

        let correnteDisjuntor = parseFloat(correnteQuadro?.textContent) * 1.3
        let AmpereDisjuntor = 0
        if (correnteDisjuntor <= 10) AmpereDisjuntor = 10
        if (correnteDisjuntor > 10 && correnteDisjuntor <= 16) AmpereDisjuntor = 16
        if (correnteDisjuntor > 16 && correnteDisjuntor <= 20) AmpereDisjuntor = 20
        if (correnteDisjuntor > 20 && correnteDisjuntor <= 25) AmpereDisjuntor = 25
        if (correnteDisjuntor > 25 && correnteDisjuntor <= 32) AmpereDisjuntor = 32
        if (correnteDisjuntor > 25 && correnteDisjuntor <= 32) AmpereDisjuntor = 32
        if (correnteDisjuntor > 32 && correnteDisjuntor <= 40) AmpereDisjuntor = 40
        if (correnteDisjuntor > 40 && correnteDisjuntor <= 50) AmpereDisjuntor = 50
        if (correnteDisjuntor > 50 && correnteDisjuntor <= 63) AmpereDisjuntor = 63
        if (correnteDisjuntor > 63 && correnteDisjuntor <= 70) AmpereDisjuntor = 70
        if (correnteDisjuntor > 70 && correnteDisjuntor <= 80) AmpereDisjuntor = 80
        if (correnteDisjuntor > 80 && correnteDisjuntor <= 90) AmpereDisjuntor = 90
        if (correnteDisjuntor > 90 && correnteDisjuntor <= 100) AmpereDisjuntor = 100

        let stringsEmParalelo = document.querySelector(`.stringsParalelo${index}`)?.textContent
        let arrayStrings = stringsEmParalelo?.split('/')

        let condition = false
        for (let i = 0; i < arrayStrings?.length; i++) {

          if (parseInt(arrayStrings[i]) >= 3) condition = true
        }
        let disjuntor_Ah = 0
        if (corrente_instalada_trifasico) disjuntor_Ah = corrente_instalada_trifasico
        if (corrente_instalada_bifasico) disjuntor_Ah = corrente_instalada_bifasico
        if (corrente_instalada_monofasico) disjuntor_Ah = corrente_instalada_monofasico

        setQuadroValues({
          potencia_quadro: potenciaQuadro?.textContent,
          corrente_quadro: correnteQuadro?.textContent,
          ampereDisjuntorCA: AmpereDisjuntor,
          connection_quadro: connection_quadro?.textContent,
          secao_nominal_quadro: secao_nominal_quadro?.textContent,
          comprimento_quadro: comprimento_quadro?.value,
          tensao_porce_quadro: tensao_quadro_quadro?.textContent,
          perdas_cabos_quadro: perdas_cabos_quadro?.textContent,

          sistema_padrao: sistema_padrao?.value,
          conexao_instalada: conexao_instalada?.textContent,
          corrente_disjuntor: disjuntor_Ah?.value,
          potencia_kva: potencia_kva?.textContent,
          potencia_kw: potencia_kw?.textContent,
          secao_nominal_padrao: secao_nominal_padrao?.textContent,
        })






        //transformando strings ativas em tabelas organizadas por mppt

        let arrayValoresPorArranjo = []
        let arrayTestStg = ['00']
        let arrayTestMppt = ['00']
        selectedArrayMppt?.map((data, i) => {
          if (i === index) {
            data.mppt?.map((mppt, indexMppt) => {
              let checkStr = elementConfigSystem?.querySelectorAll(`#checkStr${index}${indexMppt}`)
              let checkMppt = elementConfigSystem?.querySelectorAll(`.checkMppt${index}${indexMppt}`)
              if (parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value) !== 0 && !isNaN(parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value))) arrayValoresPorArranjo.push(' ' + parseInt(elementConfigSystem?.querySelector(`.unidade${index}${indexMppt}${0}`)?.value))
              for (let i = 0; i < checkStr?.length; i++) {

                if (checkMppt[i]?.checked) {
                  if (checkMppt[i].classList[0].slice(-3).slice(0, 2) === `${index}${indexMppt}`) {
                    arrayTestMppt.push(`${index}${indexMppt}`)

                  }
                }
                if (checkStr[i].checked) {
                  if (checkStr[i].classList[0].slice(-3).slice(0, 2) === `${index}${indexMppt}` && checkStr[i].classList[0].slice(-3) !== `${index}${indexMppt}0`) {
                    arrayTestStg.push(`${index}${indexMppt}`)
                  }
                }
              }

            })
          }
        })
        let newArray = []
        let arrayMpptEmUso = []

        let qtdMpptAtivas = []
        let qtdStrAtivas = []
        for (let i = 0; i < arrayTestMppt?.length; i++) {
          qtdMpptAtivas.push(i + 1)
        }
        /*for (let i = 0; i < arrayTestStg.length; i++) {
          qtdStrAtivas.push(i)
        }*/
        let arrayStr = []
        let arrayStr2 = []
        arrayStrings?.map((data) => {
          arrayStr.push(data === '0' ? '1' : data)
        })

        for (let i = 0; i < arrayTestMppt?.length; i++) {
          for (let j = 0; j < arrayStrings?.length; j++) {
            for (let k = 0; k < parseInt(arrayStr[j]); k++) {
              if (arrayTestStg[k] === arrayTestMppt[i]) {
                /*if(qtdStrAtivas.length > 0){
                 arrayStr2.push(qtdStrAtivas[qtdStrAtivas.length-1][qtdStrAtivas[qtdStrAtivas.length-1].length-1]+1+k)
                 }else{
                   arrayStr2.push(k+1)
                 } */
                arrayStr2.push(k + 1)
                if (arrayStr2.length === parseInt(arrayStr[j])) {
                  qtdStrAtivas.push(arrayStr2)
                  arrayStr2 = []
                }
              }
            }
          }
        }

        /* if(qtdStrAtivas.length > 0){
                  arrayStr2.push(qtdStrAtivas[qtdStrAtivas.length-1][qtdStrAtivas[qtdStrAtivas.length-1].length-1]+1+k)
                  }else{
                    arrayStr2.push(k+1)
                  } */
        if (qtdStrAtivas.length > 0) arrayMpptEmUso.push([{ id: data.id_inverters, mppt: qtdMpptAtivas?.map((data, i) => { return qtdStrAtivas[i]?.map((str) => { return { string: str } }) }) }])


        // pegando informações do sistema

        let array = []

        let unidadesAll = elementConfigSystem?.querySelectorAll(`.unidadesAll${index}`)
        let azimuteAll = elementConfigSystem?.querySelectorAll(`.azimuteAll${index}`)
        let inclinacaoAll = elementConfigSystem?.querySelectorAll(`.inclinacaoAll${index}`)
        let secao_nominalAll = elementConfigSystem?.querySelectorAll(`.secao_nominalAll${index}`)
        let comprimento = elementConfigSystem?.querySelectorAll(`.comprimento${index}`)
        let tensao = elementConfigSystem?.querySelectorAll(`.tensao${index}`)
        let potencia = elementConfigSystem?.querySelectorAll(`.potencia${index}`)
        let corrente = elementConfigSystem?.querySelectorAll(`.corrente${index}`)
        let tensao_porce = elementConfigSystem?.querySelectorAll(`.tensaoPorce${index}`)
        let perdas_cabos = elementConfigSystem?.querySelectorAll(`.perdasCabos${index}`)

        let secao_nominalFinal = elementConfigSystem?.querySelector(`.secao_nominalFinal${index}`)
        let comprimentoFinal = elementConfigSystem?.querySelector(`.comprimentoFinal${index}`)
        let tensaoFinal = elementConfigSystem?.querySelector(`.tensaoFinal${index}`)
        let potenciaFinal = elementConfigSystem?.querySelector(`.potenciaFinal${index}`)
        let correnteFinal = elementConfigSystem?.querySelector(`.correnteFinal${index}`)
        let tensao_porceFinal = elementConfigSystem?.querySelector(`.tensaoPorceFinal${index}`)
        let perdas_cabosFinal = elementConfigSystem?.querySelector(`.perdasCabosFinal${index}`)

        let newArrayUnidades = []
        let newArrayAzimute = []
        let newArrayInclinacao = []
        let newArrayPotencia = []
        let newArrayCorrente = []
        let newArrayTensao = []
        let newArraySecaoNominal = []
        let newArrayComprimento = []
        let newArrayTensaoPorce = []
        let newArrayPerdasCabos = []
        let newArrayInversorQuadro = []


        for (let i = 0; i < unidadesAll.length; i++) {

          if (unidadesAll[i]?.value !== '0' && unidadesAll[i]?.value !== '' && unidadesAll[i]?.value
            || unidadesAll[i]?.textContent !== '0' && unidadesAll[i]?.textContent
            && unidadesAll[i]?.textContent.split(',')[0] !== '0'

          ) {
            newArrayUnidades.push(unidadesAll[i]?.value ? unidadesAll[i]?.value : unidadesAll[i]?.textContent)
            newArrayAzimute.push(azimuteAll[i]?.value ? azimuteAll[i]?.value : azimuteAll[i]?.textContent)
            newArrayInclinacao.push(inclinacaoAll[i]?.value ? inclinacaoAll[i]?.value : inclinacaoAll[i]?.textContent)
            newArrayPotencia.push(potencia[i]?.textContent)
            newArrayCorrente.push(corrente[i]?.textContent)
            newArrayTensao.push(tensao[i]?.textContent)
            newArraySecaoNominal.push(secao_nominalAll[i]?.value ? secao_nominalAll[i]?.value : secao_nominalAll[i]?.textContent)
            //newArraySecaoNominal.push(secao_nominalAll[i]?.value)
            newArrayComprimento.push(comprimento[i]?.value)
            newArrayTensaoPorce.push(tensao_porce[i]?.textContent)
            newArrayPerdasCabos.push(perdas_cabos[i]?.textContent)
          }

        }

        newArrayInversorQuadro.push({
          potencia: potenciaFinal?.textContent,
          corrente: correnteFinal?.textContent,
          tensao: tensaoFinal?.textContent,
          secao_nominal: secao_nominalFinal?.textContent,
          comprimento: comprimentoFinal?.value,
          tensao_porce: tensao_porceFinal?.textContent,
          perdas_cabos: perdas_cabosFinal?.textContent
        })


        for (let i = 0; i < newArrayUnidades?.length; i++) {
          if (newArrayUnidades[i] !== '0' && newArrayUnidades[i] !== '' && newArrayUnidades[i] !== null) {

            array.push({
              unidades: newArrayUnidades[i],
              azimute: newArrayAzimute[i],
              inclinacao: newArrayInclinacao[i],
              potencia: newArrayPotencia[i],
              corrente: newArrayCorrente[i],
              tensao: newArrayTensao[i],
              secao_nominal: newArraySecaoNominal[i],
              comprimento: newArrayComprimento[i],
              tensao_porce: newArrayTensaoPorce[i],
              perdas_cabos: newArrayPerdasCabos[i]
            })
          }
        }
        if (array.length > 0) {
          array.push({
            potencia: potenciaFinal?.textContent,
            corrente: correnteFinal?.textContent,
            tensao: tensaoFinal?.textContent,
            secao_nominal: secao_nominalFinal?.textContent,
            comprimento: comprimentoFinal?.value,
            tensao_porce: tensao_porceFinal?.textContent,
            perdas_cabos: perdas_cabosFinal?.textContent
          })
        }
        //tratando as proteções do sistema

        let arrayProtecao = []
        for (let i = 0; i < array.length; i++) {
          let correnteDisjuntorCC = parseFloat(array[i].corrente) * 1.3
          let TesaoDisjuntorCC = parseFloat(array[i].tensao) * 1.1

          let AhDisjuntor = 0
          let TDisjuntor = 0

          if (data.type_of_network_connection === 'trifásico' || data.type_of_network_connection === 'trifasico') {

            /*  if (correnteDisjuntorCC <= 25) AhDisjuntor = 25
              if (correnteDisjuntorCC > 25 && correnteDisjuntorCC <= 32) AhDisjuntor = 32
              if (correnteDisjuntorCC > 32 && correnteDisjuntorCC <= 40) AhDisjuntor = 40
              if (correnteDisjuntorCC > 40 && correnteDisjuntorCC <= 50) AhDisjuntor = 50
              if (correnteDisjuntorCC > 50 && correnteDisjuntorCC <= 63) AhDisjuntor = 63
              if (correnteDisjuntorCC > 63 && correnteDisjuntorCC <= 70) AhDisjuntor = 70
              if (correnteDisjuntorCC > 70 && correnteDisjuntorCC <= 80) AhDisjuntor = 80
              if (correnteDisjuntorCC > 80 && correnteDisjuntorCC <= 90) AhDisjuntor = 90
              if (correnteDisjuntorCC > 90 && correnteDisjuntorCC <= 100) AhDisjuntor = 100
              if (correnteDisjuntorCC > 100 && correnteDisjuntorCC <= 125) AhDisjuntor = 125
              if (correnteDisjuntorCC > 125 && correnteDisjuntorCC <= 150) AhDisjuntor = 150
              if (correnteDisjuntorCC > 150 && correnteDisjuntorCC <= 175) AhDisjuntor = 175
              if (correnteDisjuntorCC > 175 && correnteDisjuntorCC <= 200) AhDisjuntor = 200
              if (correnteDisjuntorCC > 200 && correnteDisjuntorCC <= 225) AhDisjuntor = 225
              if (correnteDisjuntorCC > 225 && correnteDisjuntorCC <= 250) AhDisjuntor = 250
              if (correnteDisjuntorCC > 250 && correnteDisjuntorCC <= 300) AhDisjuntor = 300
              if (correnteDisjuntorCC > 300 && correnteDisjuntorCC <= 350) AhDisjuntor = 350
              if (correnteDisjuntorCC > 350 && correnteDisjuntorCC <= 400) AhDisjuntor = 400
              if (correnteDisjuntorCC > 400 && correnteDisjuntorCC <= 450) AhDisjuntor = 450
              if (correnteDisjuntorCC > 450 && correnteDisjuntorCC <= 500) AhDisjuntor = 500*/

            if (correnteDisjuntorCC <= 10) AhDisjuntor = 10
            if (correnteDisjuntorCC > 10 && correnteDisjuntorCC <= 16) AhDisjuntor = 16
            if (correnteDisjuntorCC > 16 && correnteDisjuntorCC <= 20) AhDisjuntor = 20
            if (correnteDisjuntorCC > 20 && correnteDisjuntorCC <= 25) AhDisjuntor = 25
            if (correnteDisjuntorCC > 25 && correnteDisjuntorCC <= 32) AhDisjuntor = 32
            if (correnteDisjuntorCC > 25 && correnteDisjuntorCC <= 32) AhDisjuntor = 32
            if (correnteDisjuntorCC > 32 && correnteDisjuntorCC <= 40) AhDisjuntor = 40
            if (correnteDisjuntorCC > 40 && correnteDisjuntorCC <= 50) AhDisjuntor = 50
            if (correnteDisjuntorCC > 50 && correnteDisjuntorCC <= 63) AhDisjuntor = 63
            if (correnteDisjuntorCC > 63 && correnteDisjuntorCC <= 70) AhDisjuntor = 70
            if (correnteDisjuntorCC > 70 && correnteDisjuntorCC <= 80) AhDisjuntor = 80
            if (correnteDisjuntorCC > 80 && correnteDisjuntorCC <= 90) AhDisjuntor = 90
            if (correnteDisjuntorCC > 90 && correnteDisjuntorCC >= 100) AhDisjuntor = 100

            if (TesaoDisjuntorCC < 600) TDisjuntor = 600
            if (TesaoDisjuntorCC > 600 && TesaoDisjuntorCC <= 800) TDisjuntor = 800
            if (TesaoDisjuntorCC > 800 && TesaoDisjuntorCC <= 1000) TDisjuntor = 1000
            if (TesaoDisjuntorCC > 1000 && TesaoDisjuntorCC <= 1500) TDisjuntor = 1500

          }
          if (data.type_of_network_connection === 'monofásico' || data.type_of_network_connection === 'monofasico') {

            if (correnteDisjuntorCC <= 10) AhDisjuntor = 10
            if (correnteDisjuntorCC > 10 && correnteDisjuntorCC <= 16) AhDisjuntor = 16
            if (correnteDisjuntorCC > 16 && correnteDisjuntorCC <= 20) AhDisjuntor = 20
            if (correnteDisjuntorCC > 20 && correnteDisjuntorCC <= 25) AhDisjuntor = 25
            if (correnteDisjuntorCC > 25 && correnteDisjuntorCC <= 32) AhDisjuntor = 32
            if (correnteDisjuntorCC > 32 && correnteDisjuntorCC <= 40) AhDisjuntor = 40
            if (correnteDisjuntorCC > 40 && correnteDisjuntorCC <= 50) AhDisjuntor = 50




            if (TesaoDisjuntorCC < 600) TDisjuntor = 600
            if (TesaoDisjuntorCC > 600 && TesaoDisjuntorCC <= 800) TDisjuntor = 800
            if (TesaoDisjuntorCC > 800 && TesaoDisjuntorCC <= 1000) TDisjuntor = 1000
            if (TesaoDisjuntorCC > 1000 && TesaoDisjuntorCC <= 1500) TDisjuntor = 1500


          }
          arrayProtecao.push({
            AhDisjuntor: AhDisjuntor,
            TDisjuntor: TDisjuntor,
          })


        }



        //atualizando os valores do sistema
        if (array.length > 0 && index === 0) {
          setSystemValues([{ ...data, system: array, protecao: arrayProtecao, mpptAtiva: arrayMpptEmUso, conditionProtection: condition, horario: new Date().toLocaleString() }])
        }

        valoresPorModulos(index)


        let newSystemValues = selectedInvertersArray?.map((inv, i) => {
          if (i === index) {
            return { ...inv, system: array, protecao: arrayProtecao, mpptAtiva: arrayMpptEmUso, conditionProtection: condition, horario: new Date().toLocaleString() }
          }
          return inv
        })


        setSelectedInvertersArray(newSystemValues)
        if (systemValues.length > 0) {
          selectedInvertersArray.splice(0, 1, systemValues[0])
        }
      })
    }
    await pegandoValoresDoSistema()

    //combinando os dados para gerar somente um objeto com todas as informações para colocar no memorial abaixo
    const combinandoDados = async (data, quadroValues, inverters, modules) => {

      try {


        const informations = {
          number_uc_demand: data.number_uc_demand,
          number_art_demand: data.number_art_demand,
          street_service: data.street_service_demand,
          number_service: data.number_service_demand,
          complement_service: data.complement_service_demand,
          district_service: data.district_service_demand,
          city_service: data.city_service_demand,
          state_service: data.state_service_demand,
          cep_service: data.cep_service_demand,
          name_customers_of_customers: data?.cnpj_customers_of_customers ? data && data?.corporate_reason_customers_of_customers?.toUpperCase() : data && data?.name_customers_of_customers?.toUpperCase(),
          cpf_customers_of_customers: data.cpf_customers_of_customers,
          cnpj_customers_of_customers: data.cnpj_customers_of_customers,
          name_representative_customers_of_customers: data.name_representative_customers_of_customers,
          cpf_representative_customers_of_customers: data.cpf_representative_customers_of_customers,
          project_type_demand: project_type(props.data.project_type_demand),
          disjuntor_demand: data.disjuntor_demand,
          lat_graus_min_seg: decimalParaGrausMinutosSegundos(data.lat_demand),
          log_graus_min_seg: decimalParaGrausMinutosSegundos(data.log_demand),
          lat_decimais: parseFloat(data.lat_demand).toFixed(6),
          log_decimais: parseFloat(data.log_demand).toFixed(6),
          nivel_de_tensao: (data.project_type_demand === 'a4_horaria_verde' || data.project_type_demand === 'a4_horaria_azul') ? 'Média Tensão' : 'Baixa Tensão',
          modality_type_demand: data.modality_type_demand === 'geracao_local' ? 'Geração Local' : 'Autoconsumo Remoto',
          p_q: quadroValues.potencia_quadro.toString().replace(/\./g, ','),
          c_q: quadroValues.corrente_quadro.toString().replace(/\./g, ','),
          ctn_q: quadroValues.connection_quadro,
          s_n_q: quadroValues.secao_nominal_quadro,
          comp_q: quadroValues.comprimento_quadro,
          t_p_q: quadroValues.tensao_porce_quadro.toString().replace(/\./g, ','),
          p_c_q: quadroValues.perdas_cabos_quadro.toString().replace(/\./g, ','),
          ptc_m_q: ((parseFloat(quadroValues.potencia_quadro) - (parseFloat(quadroValues.perdas_cabos_quadro) / 1000)).toFixed(3)).toString().replace(/\./g, ','),
          s_p: service_type(quadroValues?.sistema_padrao),
          c_i: quadroValues.conexao_instalada,
          c_d: quadroValues.corrente_disjuntor,
          p_kva: quadroValues.potencia_kva.toString().replace(/\./g, ','),
          p_kw: quadroValues.potencia_kw.toString().replace(/\./g, ','),
          s_n_p: quadroValues.secao_nominal_padrao,
          b_t_d: data.branch_type_demand === 'aereo' ? 'Aéreo' : 'Subterrâneo',
          //src_image_street: imageStreet,
          //src_image_sat: imageSat,    
          potencia_total_inversor: (inverters.reduce((acc, curr) => acc + parseFloat(curr.output_power), 0) / 1000).toString().replace(/\./g, ','),
          potencia_total_modulos: (inverters.map((inversor) => inversor.usinaFotovoltaica.map((mod) => (mod.qtd) * (mod.maximum_power)).reduce((acc, curr) => acc + curr, 0)).reduce((acc, curr) => acc + curr, 0) / 1000).toString().replace(/\./g, ','),
          u_g: inverters.map((inversor, index) => ({
            brand_inverter: inversor.brand,
            model_inverter: inversor.model,
            u_g_index: index + 1,
            estrutura: inversor.structure_type ? inversor.structure_type : 'telhado',
            potencia_inversor: (parseInt(inversor.output_power) / 1000).toString().replace(/\./g, ','),
            qtd_mod: (inverters.map((inversor) => inversor.usinaFotovoltaica.map((mod) => (mod.qtd)).reduce((acc, curr) => acc + curr, 0))).toString(),
            ptc_mod: (inversor.usinaFotovoltaica.map((usina) => (usina?.qtd) * (usina?.maximum_power)).reduce((acc, curr) => acc + curr, 0) / 1000).toString().replace(/\./g, ','),//potencia total dos modulos
            area_modulos: ((inversor.usinaFotovoltaica.map((mod) => calcularArea(mod.module_dimensions)).reduce((acc, curr) => acc + curr, 0) * (inversor.usinaFotovoltaica.map((mod) => mod.qtd).reduce((acc, curr) => acc + curr, 0)))?.toFixed(2)).toString().replace(/\./g, ','),
            modulos: inversor.usinaFotovoltaica.map((mod, index) => ({
              uni_mod: index + 1,//unidade do modulo
              qtd_mod: mod.qtd,
              marca_mod: mod.brand_modules,
              modelo_mod: mod.model,
              ptc_mod: ((mod.maximum_power * mod.qtd) / 1000).toString().replace(/\./g, ','),//potencia total dos modulos
            }))
          })),
          numero_de_inversores: inverters.length,
          potencia_total_inversores: inverters.reduce((acc, curr) => acc + parseFloat(curr.output_power), 0) / 1000,
          numero_de_modulos: inverters.map((inversor) => inversor.usinaFotovoltaica.map((mod) => mod.qtd).reduce((acc, curr) => acc + curr, 0)).reduce((acc, curr) => acc + curr, 0),
          //potencia_total_modulos: inverters.map((inversor) => inversor.usinaFotovoltaica.map((mod) => (mod.qtd)*(mod.maximum_power)).reduce((acc, curr) => acc + curr, 0)).reduce((acc, curr) => acc + curr, 0)/1000,
          corrente_maxima_de_saida: inverters.reduce((acc, curr) => acc + parseFloat(curr.maximum_output_current), 0),
          inversores_usina: inverters.map((inversor, index) => ({
            index_inversor: index + 1,
            potencia_inversor: parseInt(inversor.output_power) / 1000,
            potencia_modulos: potenciaModulosInv(index).toString(),
            numero_total_modulos: contModulosInv(index),
            numero_de_mppt_utilizadas: mpptEmUso(index),
            numero_de_strings_ultilizdas_por_mppt: stringAtivasPorMppt(index).toString().replace(/,/g, '').replace(/\./g, ','),
            numero_de_arranjos: contArranjos(index),
            numero_de_strings_em_paralelo: stringsEmParalelo(index).toString().replace(/,/g, '').replace(/\./g, ','),
            numero_de_modulos_por_arranjo: numeroDeModulosPorMppt(index),
            inclinacao: inclinacaoPorMppt(index).toString().replace(/,/g, '').replace(/\./g, ','),
            azimute: azimutePorMppt(index).toString().replace(/,/g, '').replace(/\./g, ','),
            tensao_maxima_voc: tesaoMaximaVOC(index).toString().replace(/,/g, '').replace(/\./g, ','),
            tensao_maxima_vmp: tesaoMaximaVMP(index).toString().replace(/,/g, '').replace(/\./g, ','),
            corrente_maxima_isc: correntMaximaISC(index).toString().replace(/,/g, '').replace(/\./g, ','),
            corrente_maxima_imp: correnteMaximaIMP(index).toString().replace(/,/g, '').replace(/\./g, ','),
          })),
          systems: inverters.map((inversor, index) => inversor.system.map((sistema, i) => ({
            system_length: inversor.system.length,
            index_system: i + 1,
            origem: (inversor.system.length - 1) !== i ? `SÉRIE ${i + 1}` : `INVERSOR${' '}${index + 1}`,
            destino: (inversor.system.length - 1) !== i ? `INV ${index + 1}` : 'Q.G.B.T.',
            s_potencia: sistema.potencia.toString().replace(/\./g, ','),
            s_corrente: sistema.corrente.toString().replace(/\./g, ','),
            s_tensao: sistema.tensao.toString().replace(/\./g, ','),
            s_uni: sistema.unidades ? sistema.unidades : '-',
            s_s_n: sistema.secao_nominal,
            s_c: sistema.comprimento,
            s_t_p: sistema.tensao_porce.toString().replace(/\./g, ','),
            s_p_c: sistema.perdas_cabos.toString().replace(/\./g, ','),
            ptc_max: ((inversor.system.length - 1) !== i ? (parseFloat(sistema.potencia) - (parseFloat(sistema.perdas_cabos) / 1000)).toFixed(3) : (parseFloat(sistema.potencia) - (parseFloat(sistema.perdas_cabos) / 1000)).toFixed(3)).toString().replace(/\./g, ','),
          }))).flat(),
          protection: inverters.map((inversor, index) => inversor.protecao.map((protecao, i) => ({
            origem: (inversor.protecao.length - 1) !== i ? `SÉRIE ${i + 1}` : `INVERSOR${' '}${index + 1}`,
            destino: (inversor.protecao.length - 1) !== i ? `INV ${index + 1}` : 'Q.G.B.T.',
            dps: (inversor.protecao.length - 1) !== i ? `DPS CC Classe II 1000V / 40kA` : 'DPS CA Classe II 275V / 45 kA',
            dtm: (inversor.protecao.length - 1) !== i ? (inversor.conditionProtection ? `DTM BIPOLAR 1000V / 25A` : 'Seccionadora CC 1000V / 25A') : (inversor.type_of_network_connection === 'trifasico' && `DTM TRIPOLAR ${protecao.AhDisjuntor} A`) || (inversor.type_of_network_connection === 'bifasico' && `DTM BIPOLAR ${protecao.AhDisjuntor} A`) || (inversor.type_of_network_connection === 'monofasico' && `DTM MONOPOLAR ${protecao.AhDisjuntor} A`),
          }))).flat(),
          inversores: inverters.map((inversor, index) => ({
            index_inv: index + 1,
            brand: inversor.brand,
            model: inversor.model,
            ptc_inv: inversor.maximum_photovoltaic_power,
            t_m_s: inversor.maximum_supported_voltage,//tensão máxima suportada
            f_t_mppt: inversor.mppt_voltage_range,//faixa de tensão mppt
            m_c_e: inversor.maximum_input_current,//maxima corrente de entrada
            m_c_cc: inversor.maximum_short_circuit_current,//maxima corrente de curto circuito
            p_s: inversor.output_power,//potencia de saida
            p_a_m: inversor.maximum_apparent_power,//potencia aparente maxima
            f_t: inversor.rated_voltage_range,//faixa de tensão nominal
            f_r: inversor.frequency_range,//faixa de frequencia
            c_m: inversor.maximum_output_current,//corrente maxima de saida
            thd: inversor.thd,//distorção harmonica total
            f_p_a: inversor.apparent_power_factor,//fator de potencia aparente
            t_c_r: service_type(inversor.type_of_network_connection),//tipo de conexão de rede
            p_c_s_s: inversor.undervoltage_and_overvoltage_protection,//proteção contra sobretensão e subtensão
            p_i_cc: inversor.cc_insulation_protection,//proteção de isolamento cc
            m_p_f_a: inversor.ground_fault_protection_monitoring,//monitoramento de proteção contra falha de terra
            p_c_f_r: inversor.network_failure_protection,//proteção contra falha de rede
            m_i_cc: inversor.cc_injection_monitoring,//monitoramento de injeção cc
            m_c_r: inversor.return_current_monitoring,//monitoramento de corrente de retorno
            p_c_c_r: inversor.residual_current_protection,//proteção de corrente residual
            p_c_a: inversor.anti_islanding_protection,//proteção anti-ilhamento
            p_c_s: inversor.overload_protection,//proteção de sobrecarga
            p_c_sa: inversor.overheat_protection,//proteção de superaquecimento
            d_l_a_c: inversor.dimension_l_a_c,//dimensão l a c
            p_l: inversor.net_weight,//peso liquido                                 
            f_t_o: inversor.operating_temperature_range,//faixa de temperatura de operação
            u_r_a: inversor.storage_relative_humidity,//umidade relativa de armazenamento
            g_p: inversor.degree_of_protection,//grau de proteção
            r_: inversor.cooling,//refrigeração
            n_r: inversor.noise_level,//nivel de ruido
          })),
          modulos: modules.map((mod, index) => ({
            index_mod: index + 1,
            brand: mod.brand_modules,
            model: mod.model_modules,
            t_c: mod.type,//Tipo de Célula
            bi_: mod.bifacial === 'sim' ? 'Sim' : 'Não',
            p_m: mod.maximum_power,//Potência Máxima
            t_p: mod.tower_tolerance,//Tolerância de Potência
            v_o_c: mod.open_circuit_voltage_modules,//Tensão de Circuito Aberto (Voc)
            i_s_c: mod.short_circuit_current_modules,//Corrente de Curto Circuito (Isc)
            v_m_p: mod.maximum_operating_voltage,//Tensão Máxima de Operação (Vmp)
            i_m_p: mod.maximum_power_current,//Corrente Máxima de Potência (Imp)
            m_f_s: mod.maximum_fuse_in_series,//Fusível Máximo em Série
            t_m_s: mod.maximum_system_voltage,//Tensão Máxima do Sistema
            t_n_o_c: mod.nominal_cell_operating_temperature,//Temperatura Nominal de Operação da Célula
            kg: mod.module_weight,//Peso do Módulo
            d_m: mod.module_dimensions,//Dimensões do Módulo
          })),
        }


        setIsInformation(informations)


      } catch (error) {
        console.error(error);
      }
      finally {

      }
    }
    combinandoDados(props.data, quadroValues, selectedInvertersArray, selectedModulesArray)


    //document.getElementById(`${props.data.id_demand}`).click()


    /*
        const element = document.getElementById('memorial-descritivo');
        const opt = {
            margin: 0,
            filename: `memorial-descritivo-${props.data?.cnpj_customers_of_customers
                ?
                props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
                :
                props.data?.name_customers_of_customers.replace(/\s/g, '-')
                }.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
    
        //salvando o memorial descritivo no banco de dados
    
        const dadosPDF = await html2pdf().from(element).set(opt).output('datauristring').then(function (data) {
            const pdf = data.split('base64,')[1];
            const blob = base64ToBlob(pdf, 'application/pdf');
            const file = new File([blob], `memorial-descritivo-${props.data?.cnpj_customers_of_customers
                ?
                props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
                :
                props.data?.name_customers_of_customers.replace(/\s/g, '-')
                }.pdf`, { type: 'application/pdf' });
            return file;
        });
    
        const formData = new FormData();
        formData.append('descriptive_memorial', dadosPDF);
    
        const data_descriptive_memorial = formData
        const requestOptions = {
            method: 'POST',
            body: data_descriptive_memorial
        }
    
        await fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-data-descriptive-memorial/${props.data.id_demand}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                
                        event: 'Memorial Descritivo',
                        details: 'Memorial Descritivo Editado', 
                        demand_id: props.data?.id_demand,
                        name_user: token.name
                    })
                })
                document.getElementById('bt-search').click();
                setTimeout(() => {
                    setIsLoading(false);
                    document.getElementById(`${props.data.id_demand}`).click()
                    props.close();
                }, 100)
            })
            .catch(error =>{
                setIsLoading(false);
                console.log('error', error)})
                
                */
  }


  const upload_memorial = async (e) => {
    //salvando o array de informações no banco de dados
    await fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-array-data-descriptive-memorial/${props.data.id_demand}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(isInformation),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });



    document.getElementById('bt-search').click();
    document.getElementById(`${props.data.id_demand}`).click()
  }

  const download_word_memorial = async (e) => {
    setIsLoading(true);

    fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/word_descriptive_memorial/${props.data.id_demand}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      },
    })
      .then(response => {
        // Extrai o header 'Content-Disposition' da resposta
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'Memorial_Descritivo.docx'; // Nome padrão, caso o nome não seja encontrado
        if (contentDisposition) {
          // Extrai o nome do arquivo do header 'Content-Disposition'
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          console.log('fileNameMatch', fileNameMatch)
          if (fileNameMatch.length > 1) {
            fileName = fileNameMatch[1];  // Define o nome do arquivo
          }
        }

        if (response.ok) {
          return response.blob().then(blob => ({ blob, fileName }));
        }
        throw new Error('Erro ao baixar o arquivo.');
      })
      .then(({ blob, fileName }) => {
        // Cria um link temporário para baixar o arquivo
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Define o nome do arquivo extraído
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);  // Remove o link depois de clicar
        document.getElementById('bt-search').click();
        document.getElementById(`${props.data.id_demand}`).click()
        setIsLoading(false);

        props.close();
      })
      .catch(err => console.error('Erro ao baixar o arquivo:', err));
  }

  const download_pdf_memorial = async () => {
    setIsLoading(true);
    await fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/pdf_descriptive_memorial/${props.data.id_demand}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      }
    }).then((response) => response.blob())
      .then((blob) => {
        // Cria um link temporário para baixar o arquivo
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${props.data?.cnpj_customers_of_customers
          ? props.data?.corporate_reason_customers_of_customers.replace(/\s/g, '-')
          : props.data?.name_customers_of_customers.replace(/\s/g, '-')
          }.pdf`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        document.getElementById('bt-search').click();
        document.getElementById(`${props.data.id_demand}`).click()
        setIsLoading(false);
        props.close();
      })
      .catch(err => console.error('Erro ao baixar o arquivo:', err));
  };

  function Export2Doc(element, filename = 'toDocx') {
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + document.getElementById(element).innerHTML + postHtml;
    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
    filename = filename ? filename + '.doc' : 'document.doc';
    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }
    document.body.removeChild(downloadLink);
  }


  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth='100%'

    >
      <DialogContent>
        <dialogTitle>
          <Typography variant='h5'>Gerar Projeto</Typography>
        </dialogTitle>
        {isLoading && <Loading />}

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Stepper activeStep={activeStep} >
          <Preview open={open} close={handleClose} title={''} src={src} typeFile={typeFile} typeFileName={typeFileName} />

          {activeStep === 0 && <PhotovoltaicInformationProject
            P_listSelectedInverter={selectedlistinverters}
            P_selectedInverter={selectedInvertersArray}
            P_arrayMppt={selectedArrayMppt}
            P_selectedModules={selectedModulesArray}
            P_countMod={selectedCountModules}
            P_setListSelectedInverter={setSelectedlistinverters}
            P_setSelectedInverter={setSelectedInvertersArray}
            P_setArrayMppt={setSelectedArrayMppt}
            P_setSelectedModules={setSelectedModulesArray}
            P_setCountMod={setSelectedCountModules}
            handleTestInformation={handleTestInformation}

            data={props.data}
          />}
          {activeStep === 1 && <ConfigSystemProject

            setElementConfigSystem={setElementConfigSystem}

            array_inverters={selectedInvertersArray}
            array_modules={selectedModulesArray}
            array_mppt={selectedArrayMppt}
            array_count_modules={selectedCountModules}


            P_setSelectedInverter={setSelectedInvertersArray}
            P_setSelectedModules={setSelectedModulesArray}
            P_setArrayMppt={setSelectedArrayMppt}
            P_setCountMod={setSelectedCountModules}

            P_checkMppt={checkMppt}
            P_selectModules={selectModules}
            P_checkString={checkString}
            P_unidades={unidades}
            P_p_unidades={p_unidades}
            P_azimute={azimute}
            P_p_azimute={p_azimute}
            P_inclincao={inclincao}
            P_p_inclincao={p_inclincao}
            P_potenciaM={potenciaM}
            P_potenciaMppt={potenciaMppt}
            P_correnteStr={correnteStr}
            P_correnteMppt={correnteMppt}
            P_tensao={tensao}
            P_secaoN={secaoN}
            P_secaoP={secaoP}
            P_comprimento={comprimento}
            P_tensaoPorce={tensaoPorce}
            P_perdasCabos={PerdasCabos}
            P_comprimentoFinal={comprimentoFinal}
            P_comprimentoQuadro={comprimentoQuadro}
            P_structureType={structureType}

            P_setCheckMppt={setCheckMppt}
            P_setSelectModules={setSelectModules}
            P_setCheckString={setCheckString}
            P_setUnidades={setUnidades}
            P_setP_unidades={setP_unidades}
            P_setAzimute={setAzimute}
            P_setP_azimute={setP_azimute}
            P_setInclincao={setInclincao}
            P_setP_inclincao={setP_inclincao}
            P_setPotenciaM={setPotenciaM}
            P_setPotenciaMppt={setPotenciaMppt}
            P_setCorrenteStr={setCorrenteStr}
            P_setCorrenteMppt={setCorrenteMppt}
            P_setTensao={setTensao}
            P_setSecaoN={setSecaoN}
            P_setSecaoP={setSecaoP}
            P_setComprimento={setComprimento}
            P_setTensaoPorce={setTensaoPorce}
            P_setPerdasCabos={setPerdasCabos}
            P_setComprimentoFinal={setComprimentoFinal}
            P_setComprimentoQuadro={setComprimentoQuadro}
            P_setStructureType={setStructureType}

            //Variáveis comparar se os estados são iguais

            //Variavel para definir informações do padrão de entrada
            selectedServiceType={selectedServiceType}
            selectedDisjuntor={selectedDisjuntor}



            //Função para validação de formulário da configuração do sistema
            handleTestDataConfigSystem={handleTestDataConfigSystem}

            data={props.data}
            disjuntor={disjuntor}
            setDisjuntor={setDisjuntor}
            valid={valid}
            setIsValid={setIsValid}
            handleSaveData={handleSaveData}
            save_memorial={save_memorial}
          />}
          {activeStep === 2 && <DescriptiveMemorialProject
            data={props.data}

          />}

        </Stepper>


      </DialogContent>
      <DialogActions>


        {activeStep === 2 ? <Button
          id='btn-back'
          disabled={activeStep === 0}
          onClick={() => {
            handleReturnData();
            handleBack();
          }}
        >
          Voltar
        </Button>
          :

          <Button
            id=''
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Voltar
          </Button>

        }
        {activeStep === 1 ? <>
          <Button disabled={typeFile ? (typeFile === 'json' ? true : false) : true} size='small' variant="contained" color="secondary"
            onClick={() => {

              setTimeout(() => {
                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/data_photovoltaic/${props.data?.id_demand}`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                  }
                }).then((response) => response.blob())
                  .then((blob) => {
                    setTypeFile(blob.type.split('/')[1])
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    setSrc(url)
                  }).catch((error) => {
                    console.log(error)
                  })
              }, 1000)


              handleClickOpen()
            }}
          >Detalhes</Button>
          <Button
            size='small'
            variant='contained'
            id='btn-next'
            disabled={!isValid || !validFormConfigSystem || !isInformation}
            onClick={async () => {
              await handleSaveData()
              await save_memorial()
              await upload_memorial()
              setTimeout(() => {
                handleNext();

                fetch(`https://api.fortesol.com.br/api/timeline/new`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({

                    event: 'Memorial Descritivo',
                    details: 'Memorial Descritivo Gerado',
                    demand_id: props.data?.id_demand,
                    name_user: token.name
                  })
                })
              }, 1000)
            }}
          >
            Gerar Projeto
          </Button>
        </>
          :
          (activeStep === 2 ?

            <>
              <Button size='small' variant='contained' color='error' onClick={download_pdf_memorial}>pdf&nbsp;<CloudDownloadIcon /></Button>
              <Button size='small' variant='contained' color='primary' onClick={download_word_memorial}>word&nbsp;<CloudDownloadIcon /></Button>


            </>
            :
            activeStep === 0 && <Button
              id=''
              disabled={activeStep === steps.length - 1}
              onClick={handleNext}
            >
              Próximo
            </Button>

          )}
        <Button
          onClick={props.handleClose}
        >
          Fechar
        </Button>

      </DialogActions>

    </Dialog>

  )
}
export default System_Project_Dialog