import React, { useState } from 'react';
import './style.css';

// Páginas do projeto
import CustomerSelect from './customerSelect.jsx';
import ProjectInformation from './projectInformation.jsx';
import AddressInformation from './addressInformation.jsx';
import PhotovoltaicInformation from './photovoltaicInformation/photovoltaicInformation.jsx';
import ConfigSystem from './configSystem/configSystem.jsx';
import DescriptiveMemorial from './descriptiveMemorial/descriptiveMemorial.jsx';
import Loading from '../loading/index.jsx';

// Importando biblioteca para gerar PDF
import html2pdf from 'html2pdf.js';

// Importando componentes do Material UI

import   
 {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
} from '@mui/material';
import Sidebar from '../Sidebar/index.jsx';

// Importando componentes de notificação
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderSolProjetos from '../headeSolProjetos/index.jsx';

// Função principal do componente

function ServiceProjectSolar() {
    
    // Pag 1 : Estados para armazenar os dados do cliente
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedRG_CNH, setSelectedRG_CNH] = useState(''); 
    const [selectedSocial_Contract, setSelectedSocial_Contract] = useState('');
    
    // Page 2 : Estados para armazenar os dados do projeto
    const [selectedProjectType, setSelectedProjectType] = useState('');
    const [selectedServiceType, setSelectedServiceType] = useState('');
    const [selectedBranchType, setSelectedBranchType] = useState('');
    const [selectedModalityType, setSelectedModalityType] = useState('');
    const [selectedDisjuntor, setSelectedDisjuntor] = useState('');
    const [selectedContractedDemand, setSelectedContractedDemand] = useState('');
    const [selectedPowerRede, setSelectedPowerRede] = useState('');
    const [selectedNumberUC, setSelectedNumberUC] = useState('');
    const [selectedNumberART, setSelectedNumberART] = useState('');
    const [selectedProjectOBS, setSelectedProjectOBS] = useState('');

    const [selectedFile_electricity_bill, setSelectedFile_electricity_bill] = useState('');
    const [selectedFile_disjuntor, setSelectedFile_disjuntor] = useState('');
    const [selectedFile_energy_box, setSelectedFile_energy_box] = useState('');
    const [selectedFile_ART, setSelectedFile_ART] = useState('');


    // Pag 3 : Estados para armazenar os dados de endereço
    const [selectedAddressCep, setSelectedAddressCep] = useState('');
    const [selectedAddressStreet, setSelectedAddressStreet] = useState('');
    const [selectedAddressNumber, setSelectedAddressNumber] = useState('');
    const [selectedAddressDistrict, setSelectedAddressDistrict] = useState('');
    const [selectedAddressCity, setSelectedAddressCity] = useState('');
    const [selectedAddressState, setSelectedAddressState] = useState('');

    const [selectedLatitudeClick, setSelectedLatitudeClick] = useState('');
    const [selectedLongitudeClick, setSelectedLongitudeClick] = useState('');

    // Pag 4 : Estados para armazenar os dados dos módulos e inversores
    const [selectedlistinverters, setSelectedlistinverters] = useState([]);
    const [selectedInvertersArray, setSelectedInvertersArray] = useState([]);
    const [selectedArrayMppt, setSelectedArrayMppt] = useState([]);
    const [selectedModulesArray, setSelectedModulesArray] = useState([]);
    const [selectedCountModules, setSelectedCountModules] = useState([]);

    const [inforTest, setInforTest] = useState(false);
    const handleTestInformation = (e) => {
      setInforTest(e);
    }
    const [stateSelectedInverters, setStateSelectedInverters] = useState('');
    const [stateSelectedModules, setStateSelectedModules] = useState('');
    const [stateSelectedCountModules, setStateSelectedCountModules] = useState('');


    // Pag 5 : Estados html para salvar dados do sistema
    const [checkMppt, setCheckMppt] = useState('');
    const [selectModules, setSelectModules] = useState('');
    const [checkString, setCheckString] = useState('');
    const [unidades, setUnidades] = useState('');
    const [p_unidades, setP_unidades] = useState('');
    const [azimute, setAzimute] = useState('');
    const [p_azimute, setP_azimute] = useState('');
    const [inclincao, setInclincao] = useState('');
    const [p_inclincao, setP_inclincao] = useState('');
    const [potenciaM, setPotenciaM] = useState('');
    const [potenciaMppt, setPotenciaMppt] = useState('');
    const [correnteStr, setCorrenteStr] = useState(''); 
    const [correnteMppt, setCorrenteMppt] = useState('');
    const [tensao, setTensao] = useState('');
    const [secaoN, setSecaoN] = useState('');
    const [secaoP, setSecaoP] = useState('');
    const [comprimento, setComprimento] = useState('');
    const [tensaoPorce, setTensaoPorce] = useState('');
    const [PerdasCabos, setPerdasCabos] = useState('');
    const [comprimentoFinal, setComprimentoFinal] = useState('');
    const [comprimentoQuadro, setComprimentoQuadro] = useState('');
    const [structureType, setStructureType] = useState('');

    //Elemento salvará todas as informações do sistema para leitura e gera o memorial descritivo
    const [elementConfigSystem, setElementConfigSystem] = useState('');

    //Função para validação de formulário da configuração do sistema
    const [validFormConfigSystem, setValidFormConfigSystem] = useState(false);
    const handleTestDataConfigSystem = (e) => {
      setValidFormConfigSystem(e);
    }
    //Função para download do memorial descritivo
    const [isLoading, setIsLoading] = useState(false);
    const handlePdfDownloadMemorial = () => {
      setIsLoading(true)
      const pdf = document.getElementById('memorial-descritivo')
      const opt = {
        margin: 0,
        filename: `Memorial Descritivo - ${selectedCustomer.name ? selectedCustomer.name : selectedCustomer.corporate_reason} .pdf`,
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: { scale: 1.5 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }
      html2pdf().from(pdf).set(opt).save().then(() => {
        setIsLoading(false)
       
        toast.success('Memorial Descritivo gerado com sucesso', { autoClose: 3000 })
      }).catch((error) => {
        setIsLoading(false)
        toast.error('Erro ao gerar Memorial Descritivo', { autoClose: 3000 })
      })
    }


    const [activeStep, setActiveStep] = useState(0);
  
    const steps = ['Informações do Cliente', 'Informações do Projeto', 'Informações de Endereço','Módulos/Inversores','Configuração','Gerar Projeto'];
  
    const handleNextasd_ = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);   
    };

    
    const handleNext = () => {
      // Verificando as condições para cada etapa antes de avançar
      switch (activeStep) {
        case 0:
          // Condições para a página de informações do cliente
          if (selectedCustomer && selectedRG_CNH  ) {
            if(selectedCustomer[0].cnpj) {
             if(selectedSocial_Contract !== '') {return setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }else{
                return toast.error('Envie o contrato social');
              }}
              setActiveStep((prevActiveStep) => prevActiveStep + 1);

          }else{
            if(!selectedCustomer){
              return toast.error('Selecione um cliente');
            }
         
              if(selectedRG_CNH === ''){
                return toast.error('Envie o RG ou CNH');
            }
          }
          break;
        case 1:
          // Condições para a página de informações do projeto
          if (
            selectedProjectType !== '' &&
            selectedServiceType !== '' &&
            selectedBranchType !== '' &&
            selectedModalityType !== '' &&
            selectedDisjuntor !== '' &&
            selectedDisjuntor !== '0' &&
            selectedDisjuntor !== 0 &&
            selectedPowerRede !== '' &&
            selectedNumberUC !== '' &&
            selectedNumberART !== '' &&
            selectedProjectOBS !== '' &&
            selectedFile_electricity_bill !== '' &&
            selectedFile_disjuntor !== '' &&
            selectedFile_energy_box !== '' &&
            selectedFile_ART !== ''

          ) {
            if(selectedProjectType === 'a4_horaria_verde' || selectedProjectType === 'a4_horaria_azul'){
              if(selectedContractedDemand !== ''){
               return setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }else{
               return toast.error('Campo Demanda Contratada é obrigatório');
              }
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }else{
            if(selectedProjectType === ''){
             return toast.error('Selecione o tipo de projeto');
            }
            if(selectedServiceType === ''){
              return toast.error('Selecione o tipo de atendimento');
              }
            if(selectedBranchType === ''){
              return toast.error('Selecione o tipo de ramal');
              }
            if(selectedModalityType === ''){
              return toast.error('Selecione o tipo de modalidade');
              }
            if(selectedDisjuntor === ''){
              return toast.error('Selecione o disjuntor');
              }
            if(selectedDisjuntor === '0'){
              return toast.error('Campo disjuntor não pode ser 0');
              }
            if(selectedDisjuntor === 0){
              return toast.error('Campo disjuntor não pode ser 0');
              }
         
            if(selectedNumberUC === ''){
              return toast.error('Preencha o número da UC');
              }
            if(selectedNumberART === ''){
              return toast.error('Preencha o número da ART');
              }
            if(selectedProjectOBS === ''){
              return toast.error('Preencha a observação do projeto');
              }
            }
          if(selectedFile_ART === ''){
            return toast.error('Envie o arquivo ART');
            }
            if(selectedFile_electricity_bill === ''){
              return toast.error('Envie uma foto conta de luz');
              }
            if(selectedFile_disjuntor === ''){
              return toast.error('Envie uma foto do disjuntor');
              }
            if(selectedFile_energy_box === ''){
              return toast.error('Envie uma foto da caixa de energia');

          }
          break;
        case 2:
          // Condições para a página de informações de endereço
          if (
            selectedAddressCep !== '' &&
            selectedAddressStreet !== '' &&
            selectedAddressNumber !== '' &&
            selectedAddressDistrict !== '' &&
            selectedAddressCity !== '' &&
            selectedAddressState !== '' &&
            selectedLatitudeClick !== '' &&
            selectedLongitudeClick !== ''
          ) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }else{
            if(selectedAddressCep === ''){
             return toast.error('Preencha o campo CEP');
            }
            if(selectedAddressStreet === ''){
             return toast.error('Preencha o campo Rua');
            }
            if(selectedAddressNumber === ''){
             return toast.error('Preencha o campo Número');
            }
            if(selectedAddressDistrict === ''){
             return toast.error('Preencha o campo Bairro');
            }
            if(selectedAddressCity === ''){
             return toast.error('Preencha o campo Cidade');
            }
            if(selectedAddressState === ''){
             return toast.error('Preencha o campo Estado');
            }
            if(!selectedLatitudeClick && !selectedLongitudeClick ){
             return toast.error('Clique no mapa para definir a localização');
            }
          }
          break;
        case 3:
          // Condições para a página de informações de módulos e inversores
          if (
            selectedlistinverters[0]  &&
            selectedInvertersArray[0] &&
            selectedArrayMppt[0]  &&
            selectedModulesArray[0]  &&
            selectedCountModules[0] &&
            inforTest
            
          ) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }else{
            if(!selectedlistinverters[0]){
             return toast.error('Ateção! Selecionar Inversor');
            }
            if(!selectedInvertersArray[0]){
             return toast.error('Ateção! Selecionar Inversor');
            }
            if(!selectedCountModules[0]){
             return toast.error('Ateção! Selecionar Módulo e Determinar quantidade');
            }
          }
          break;
        case 4:
          // Condições para a página de configuração do sistema
          if (
            checkMppt !== '' &&
            selectModules !== '' &&
            checkString !== '' &&
            unidades !== '' &&
            p_unidades !== '' &&
            azimute !== '' &&
            p_azimute !== '' &&
            inclincao !== '' &&
            p_inclincao !== '' &&
            potenciaM !== '' &&
            potenciaMppt !== '' &&
            correnteStr !== '' &&
            correnteMppt !== '' &&
            tensao !== '' &&
            secaoN !== '' &&
            secaoP !== '' &&
            comprimento !== '' &&
            tensaoPorce !== '' &&
            PerdasCabos !== '' &&
            comprimentoFinal !== '' &&
            comprimentoQuadro !== '' &&
            structureType !== '' &&
            elementConfigSystem !== '' &&
           validFormConfigSystem
          ) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          break;

        
        default:
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    };
  return (
    <Sidebar>
<ToastContainer 
 position="bottom-right"
 autoClose={5000}
 zIndex={9999999999}
 /> 
 {isLoading && <Loading />}
 <HeaderSolProjetos/>
<br />
<Container maxWidth="md" >
     {window.innerWidth > 800 && <Stepper activeStep={activeStep} orientation='horizontal'>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>}
      {window.innerWidth <= 800 && <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>}
      <div >
      <Container maxWidth="md">

    <Stepper activeStep={activeStep}>
    {activeStep === 0 && 
    <CustomerSelect 
    selectedCustomer={selectedCustomer}
    selectedRG_CNH={selectedRG_CNH}
    selectedSocial_Contract={selectedSocial_Contract} 
    setSelectedCustomer={setSelectedCustomer} 
    setSelectedRG_CNH={setSelectedRG_CNH} 
    setSelectedSocial_Contract={setSelectedSocial_Contract} />}
    {activeStep === 1 && 
    <ProjectInformation 
    selectedProjectType={selectedProjectType}
    selectedServiceType={selectedServiceType}
    selectedBranchType={selectedBranchType}
    selectedModalityType={selectedModalityType}
    selectedDisjuntor={selectedDisjuntor}
    selectedContractedDemand={selectedContractedDemand}
    selectedPowerRede={selectedPowerRede}
    selectedNumberUC={selectedNumberUC}
    selectedNumberART={selectedNumberART}
    selectedProjectOBS={selectedProjectOBS}
    selectedFile_electricity_bill={selectedFile_electricity_bill}
    selectedFile_disjuntor={selectedFile_disjuntor}
    selectedFile_energy_box={selectedFile_energy_box}
    selectedFile_ART={selectedFile_ART}
    setSelectedProjectType={setSelectedProjectType} 
    setSelectedServiceType={setSelectedServiceType} 
    setSelectedBranchType={setSelectedBranchType}
    setSelectedModalityType={setSelectedModalityType}
    setSelectedDisjuntor={setSelectedDisjuntor}
    setSelectedContractedDemand={setSelectedContractedDemand}
    setSelectedPowerRede={setSelectedPowerRede}
    setSelectedNumberUC={setSelectedNumberUC}
    setSelectedNumberART={setSelectedNumberART}
    setSelectedProjectOBS={setSelectedProjectOBS}
    setSelectedFile_electricity_bill={setSelectedFile_electricity_bill}
    setSelectedFile_disjuntor={setSelectedFile_disjuntor}
    setSelectedFile_energy_box={setSelectedFile_energy_box}
    setSelectedFile_ART={setSelectedFile_ART}
    />}

    {activeStep === 2 && 
    <AddressInformation 
    customer={selectedCustomer[0]}
    selectedAddressCep={selectedAddressCep}
    selectedAddressStreet={selectedAddressStreet}
    selectedAddressNumber={selectedAddressNumber}
    selectedAddressDistrict={selectedAddressDistrict}
    selectedAddressCity={selectedAddressCity}
    selectedAddressState={selectedAddressState}
    selectedLatitudeClick={selectedLatitudeClick}
    selectedLongitudeClick={selectedLongitudeClick}
    setSelectedAddressCep={setSelectedAddressCep}
    setSelectedAddressStreet={setSelectedAddressStreet}
    setSelectedAddressNumber={setSelectedAddressNumber}
    setSelectedAddressDistrict={setSelectedAddressDistrict}
    setSelectedAddressCity={setSelectedAddressCity}
    setSelectedAddressState={setSelectedAddressState}
    setSelectedLatitudeClick={setSelectedLatitudeClick}
    setSelectedLongitudeClick={setSelectedLongitudeClick}
  
    />}
    {activeStep === 3 
    && 
    <PhotovoltaicInformation
    P_listSelectedInverter={selectedlistinverters}
    P_selectedInverter={selectedInvertersArray}
    P_arrayMppt={selectedArrayMppt}
    P_selectedModules={selectedModulesArray}
    P_countMod={selectedCountModules}
    P_setListSelectedInverter={setSelectedlistinverters}
    P_setSelectedInverter={setSelectedInvertersArray}
    P_setArrayMppt={setSelectedArrayMppt}
    P_setSelectedModules={setSelectedModulesArray}
    P_setCountMod={setSelectedCountModules}
    handleTestInformation={handleTestInformation}
    />}
    {activeStep === 4 && 
    <ConfigSystem
   
    setElementConfigSystem={setElementConfigSystem}

    P_selectedInverter={selectedInvertersArray}
    P_selectedModules={selectedModulesArray}
    P_arrayMppt={selectedArrayMppt}
    P_countMod={selectedCountModules}

    P_checkMppt={checkMppt}
    P_selectModules={selectModules}
    P_checkString={checkString}
    P_unidades={unidades}
    P_p_unidades={p_unidades}
    P_azimute={azimute}
    P_p_azimute={p_azimute}
    P_inclincao={inclincao}
    P_p_inclincao={p_inclincao}
    P_potenciaM={potenciaM}
    P_potenciaMppt={potenciaMppt}
    P_correnteStr={correnteStr}
    P_correnteMppt={correnteMppt}
    P_tensao={tensao}
    P_secaoN={secaoN}
    P_secaoP={secaoP}
    P_comprimento={comprimento}
    P_tensaoPorce={tensaoPorce}
    P_perdasCabos={PerdasCabos}
    P_comprimentoFinal={comprimentoFinal}
    P_comprimentoQuadro={comprimentoQuadro}
    P_structureType={structureType} 

    P_setCheckMppt={setCheckMppt}
    P_setSelectModules={setSelectModules}
    P_setCheckString={setCheckString}
    P_setUnidades={setUnidades}
    P_setP_unidades={setP_unidades}
    P_setAzimute={setAzimute}
    P_setP_azimute={setP_azimute}
    P_setInclincao={setInclincao}
    P_setP_inclincao={setP_inclincao}
    P_setPotenciaM={setPotenciaM}
    P_setPotenciaMppt={setPotenciaMppt}
    P_setCorrenteStr={setCorrenteStr}
    P_setCorrenteMppt={setCorrenteMppt}
    P_setTensao={setTensao}
    P_setSecaoN={setSecaoN}
    P_setSecaoP={setSecaoP}
    P_setComprimento={setComprimento}
    P_setTensaoPorce={setTensaoPorce}
    P_setPerdasCabos={setPerdasCabos}
    P_setComprimentoFinal={setComprimentoFinal}
    P_setComprimentoQuadro={setComprimentoQuadro}
    P_setStructureType={setStructureType}

     //Variáveis comparar se os estados são iguais
     stateSelectedInverters={stateSelectedInverters}
     stateSelectedModules={stateSelectedModules}
     stateSelectedCountModules={stateSelectedCountModules}
    setStateSelectedInverters={setStateSelectedInverters}
    setStateSelectedModules={setStateSelectedModules}
    setStateSelectedCountModules={setStateSelectedCountModules}

    //Variavel para definir informações do padrão de entrada
    selectedServiceType={selectedServiceType}
    selectedDisjuntor={selectedDisjuntor}

    

    //Função para validação de formulário da configuração do sistema
    handleTestDataConfigSystem={handleTestDataConfigSystem}

    />}
    {activeStep === 5 && 
      <DescriptiveMemorial 
      elementConfigSystem={elementConfigSystem}
      customer={selectedCustomer}
      inverters={selectedInvertersArray}
      arrayMppt={selectedArrayMppt}
      modules={selectedModulesArray}
      numberUC={selectedNumberUC}
      numberART={selectedNumberART}
      addressCep={selectedAddressCep}
      addressStreet={selectedAddressStreet}
      addressNumber={selectedAddressNumber}
      addressDistrict={selectedAddressDistrict}
      addressCity={selectedAddressCity}
      addressState={selectedAddressState}
      projectType={selectedProjectType}
      branchType={selectedBranchType}
      modalityType={selectedModalityType}
      latitudeClick={selectedLatitudeClick}
      longitudeClick={selectedLongitudeClick}
      disjuntor={selectedDisjuntor}

      
    
      />
    }
    </Stepper>
  
  </Container>
<Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mt: 3 }}
        >
          Voltar
        </Button>
 
        <Box sx={{ display: 'flex', justifyContent: 'flex-end',mt:3 }}>
          {activeStep === steps.length - 1 ? (
             
            <Button  id='btn-download' type="button" variant="contained" color="primary" onClick={handlePdfDownloadMemorial}>
              Salvar Projeto
            </Button>
          ) : (
            <Button
              id='btn-next'
              type='button'
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              Próximo
            </Button>
          )}
        </Box>
        </Grid>
      </div>
    </Container>
</Sidebar>
  )
}

export default ServiceProjectSolar
