import './style.css';

const Notes = (props) => {
  
    return (
        <div className="note" style={{backgroundColor: props.note.color}}> 
        <textarea className="note-textarea" placeholder="Write your note here..." defaultValue={props.note.text}/>
        <p>{props.note.time}</p>
       </div>
    )
}
export default Notes;