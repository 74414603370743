import { Avatar, Badge, Box, boxClasses, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Preview from '../file_preview'
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkIcon from '@mui/icons-material/Link';

//teste


import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';






import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItemAvatar
    from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import Collapse from '@mui/material/Collapse';
import
InboxIcon from '@mui/icons-material/Inbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Timeline_Demand from '../timeline';

import Stepper_Project from '../stepper_project';
import System_Project_Dialog from '../system_project_dialog';
import Updade_Art from '../update_art';
import RateioForm from '../rateio_form';
import Edit_System_Project_Dialog from '../system_project_edit_dialog';
import Form_Highest_10 from '../entry_form/form+10';
import Form_Minor_10 from '../entry_form/form-10';
import Edit_Demand from '../edit_demand';
import CachedIcon from '@mui/icons-material/Cached';
import { toast } from 'react-toastify';
import DropDialog from '../popup_drop_dialog';
import ConfirmDialog from '../popup_confirm_dialog';
import MapView from '../map_view';
import LayersIcon from '@mui/icons-material/Layers';

function Start_and_Follow(props) {


    //função para atualizar dados

    const [reload, setReload] = React.useState(false)
    const refresh = () => {
        document.getElementById('bt-all')?.click();
        document.getElementById(`${props.data?.id_demand}`)?.click();
        setReload(Date.now());
    };
    
    // useEffect para controlar o intervalo com base na prop de abertura do popup
    useEffect(() => {
        if (props.open  && props.data.status !== 'Solicitado') {
            refresh(); // Executa uma atualização inicial ao abrir o popup
    
            const interval = setInterval(() => {
                refresh(); // Atualização periódica enquanto o popup está aberto
            }, 2500);
    
            // Limpa o intervalo ao fechar o popup
            return () => clearInterval(interval);
        }
    }, [props.open, props.data]);


    const user = JSON.parse(localStorage.getItem('token'))
    const token = user.token
    const project_type = (project) => {
        if (project === 'b1_residencial') return 'B1 - Residencial'
        if (project === 'b2_rural') return 'B2 - Rural'
        if (project === 'b2_cooperativa') return 'B2 - Cooperativa'
        if (project === 'b2_servico_publico_de_irrigaca') return 'B2 - Serviço Público de Irrigação'
        if (project === 'b3_demais_classes') return 'B3 - Demais Classes'
        if (project === 'a4_horaria_verde') return 'A4 - Horária Verde'
        if (project === 'a4_horaria_azul') return 'A4 - Horária Azul'
    }
    const service_type = (service) => {
        if (service === 'monofasico') return 'Monofásico'
        if (service === 'bifasico') return 'Bifásico'
        if (service === 'trifasico') return 'Trifásico'
    }
    const branch_type = (branch) => {
        if (branch === 'aereo') return 'Aéreo'
        if (branch === 'subterraneo') return 'Subterrâneo'
    }
    const modality_type = (modality) => {
        if (modality === 'geracao_local') return 'Geração Local'
        else return 'Autoconsumo Remoto'
    }

    // Open Map
    const [openMap, setOpenMap] = useState(false)

  const handleClickOpenMap = () => {
    setOpenMap(true)
  }
  const handleCloseMap = () => {
    setOpenMap(false)
  }

    //Open Dialog

    const [src, setSrc] = React.useState('')
    const [typeFile, setTypeFile] = React.useState('')
    const [typeFileName, setTypeFileName] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('')

    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        document.getElementById('bt-search').click();
        document.getElementById(`${props.data.id_demand}`)?.click()
        setOpen(false);
        setSrc('')
        setTypeFile('')
        setTitle('')
        refresh()
    }



    const [openProject, setOpenProject] = React.useState(false);

    const handleClickProject = () => {
        document.getElementById('bt-search').click();
        document.getElementById(`${props.data.id_demand}`)?.click()
        setOpenProject((prev) => !prev);
    }

    //Funções para abrir e fechar o Dialog de ART

    const [openArt, setOpenArt] = React.useState(false);

    const handleClickArt = () => {
        setOpenArt(true);
    }

    const closeArt = () => {
        document.getElementById('bt-search').click();
        document.getElementById(`${props.data.id_demand}`)?.click()
        setOpenArt(false);
    }


    //Função para abrir e fechar o Dialog Rateio

    const [openRateio, setOpenRateio] = React.useState(false);

    const handleClickRateio = () => {
        setOpenRateio(true);
    }

    const closeRateio = () => {
        document.getElementById('bt-search').click();
        document.getElementById(`${props.data.id_demand}`)?.click()
        setOpenRateio(false);
    }

    //função para gerar formulário maior que 10 kw

    const [openFormPlus10, setOpenFormPlus10] = React.useState(false);

    const handleClickFormPlus10 = () => {
        setOpenFormPlus10(true);
    }

    const closeFormPlus10 = () => {
        setOpenFormPlus10(false);
    }

    //função para gerar formulário menor que 10 kw

    const [openFormMinor10, setOpenFormMinor10] = React.useState(false);

    const handleClickFormMinor10 = () => {
        setOpenFormMinor10(true);
    }

    const closeFormMinor10 = () => {
        setOpenFormMinor10(false);
    }




    //Passando de JSON para Array
    let arrayModules = []
    let arrayInverters = []
    let array_qgbt_demand = []
    if (props.data?.info_inverter_demand) {
      
        arrayInverters = JSON.parse(props.data?.info_inverter_demand)
        array_qgbt_demand = JSON.parse(props.data?.array_qgbt_demand)
    }





    
   
    let inverters
    if (props.data) inverters = JSON.parse(props.data?.array_inverters_project)

    const result_maximum_photovoltaic_power = (inverters?.map((item) => item.output_power).reduce((acc, item) => acc + parseFloat(item), 0) / 1000)
    const result_maximum_power = inverters?.map((inversor) => inversor.usinaFotovoltaica.map((mod) => (mod.qtd) * (mod.maximum_power)).reduce((acc, curr) => acc + curr, 0)).reduce((acc, curr) => acc + curr, 0) / 1000

    let result_minor
    if (result_maximum_photovoltaic_power > result_maximum_power) result_minor = result_maximum_power
    else result_minor = result_maximum_photovoltaic_power

    // open edit Demand
    const [openEditDemand, setOpenEditDemand] = React.useState(false);

    const handleClickEditDemand = () => {
        setOpenEditDemand(true);
    }

    const closeEditDemand = () => {
        document.getElementById('bt-search').click();
        document.getElementById(`${props.data.id_demand}`)?.click()
        setOpenEditDemand(false);
        refresh()
    }

    //Download procuração
    const handleDateValid = () => {
        var data = new Date()
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()

        if (dia < 10) {
            dia = `0${dia}`
        }
        if (mes === 1) {
            mes = 'Janeiro'
        }
        if (mes === 2) {
            mes = 'Fevereiro'
        }
        if (mes === 3) {
            mes = 'Março'
        }
        if (mes === 4) {
            mes = 'Abril'
        }
        if (mes === 5) {
            mes = 'Maio'
        }
        if (mes === 6) {
            mes = 'Junho'
        }
        if (mes === 7) {
            mes = 'Julho'
        }
        if (mes === 8) {
            mes = 'Agosto'
        }
        if (mes === 9) {
            mes = 'Setembro'
        }
        if (mes === 10) {
            mes = 'Outubro'
        }
        if (mes === 11) {
            mes = 'Novembro'
        }
        if (mes === 12) {
            mes = 'Dezembro'
        }
        var data = `${dia} de ${mes} de ${ano + 1}`
        return data
    }
    const handleDate = () => {
        var data = new Date()
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()

        if (dia < 10) {
            dia = `0${dia}`
        }
        if (mes === 1) {
            mes = 'Janeiro'
        }
        if (mes === 2) {
            mes = 'Fevereiro'
        }
        if (mes === 3) {
            mes = 'Março'
        }
        if (mes === 4) {
            mes = 'Abril'
        }
        if (mes === 5) {
            mes = 'Maio'
        }
        if (mes === 6) {
            mes = 'Junho'
        }
        if (mes === 7) {
            mes = 'Julho'
        }
        if (mes === 8) {
            mes = 'Agosto'
        }
        if (mes === 9) {
            mes = 'Setembro'
        }
        if (mes === 10) {
            mes = 'Outubro'
        }
        if (mes === 11) {
            mes = 'Novembro'
        }
        if (mes === 12) {
            mes = 'Dezembro'
        }

        var data = `${dia} de ${mes} de ${ano}`
        return data
    }

    // Informações para a geração da procuração
    const informations = {
        cnpj: props.data.cnpj_customers_of_customers,
        name: props.data.cpf_customers_of_customers ? props.data.name_customers_of_customers : props.data.corporate_reason_customers_of_customers,
        cpf: props.data.cpf_customers_of_customers,
        profession: props.data.profession_customers_of_customers,
        identify: props.data.identify_customers_of_customers,
        type_identify: props.data.type_doc_identify_customers_of_customers,
        ssp: props.data.type_doc_identify_customers_of_customers === 'RG' ? 'SSP-CE' : '',
        street: props.data.street_customers_of_customers,
        number: props.data.number_customers_of_customers,
        cpmt: props.data.complement_customers_of_customers,
        district: props.data.neighborhood_customers_of_customers,
        city: props.data.city_customers_of_customers,
        state: props.data.state_customers_of_customers,
        cep: props.data.cep_customers_of_customers,
        name_r: props.data.name_representative_customers_of_customers,
        cpf_r: props.data.cpf_representative_customers_of_customers,
        identify_r: props.data.identify_representative_customers_of_customers,
        type_identify_r: props.data.type_doc_identify_representative_customers_of_customers,
        spp_r: props.data.type_doc_identify_representative_customers_of_customers === 'RG' ? 'SSP-CE' : '',
        profession_r: props.data.profession_representative_customers_of_customers,
        nationality_r: props.data.nationality_representative_customers_of_customers,
        validity: handleDateValid(),
        date: handleDate()
    };


    const handleDownloadWord = async () => {
        try {
            // Faz a requisição para o backend
            const response = await fetch(`https://api.fortesol.com.br/api/demand_project_solar/generate-procuration_word`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(informations), // Envia os dados para o backend
            });

            // Verifica se a resposta está ok
            if (!response.ok) {
                throw new Error('Erro ao gerar o documento');
            }

            // Converte a resposta para Blob (arquivo binário)
            const blob = await response.blob();

            // Cria um link temporário para download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;

            // Define o nome do arquivo para download
            const contentDisposition = response.headers.get('Content-Disposition');
            const fileName = contentDisposition
                ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
                : `procuracao_${informations.name}.docx`;

            link.setAttribute('download', fileName);

            // Simula o clique no link para iniciar o download
            document.body.appendChild(link);
            link.click();

            // Remove o link após o download
            link.parentNode.removeChild(link);
            props.handleClick()
        } catch (error) {
            console.error('Erro ao baixar o documento:', error);
        }
    };



    const handleDownloadPDF = async () => {
        try {
            const response = await fetch(`https://api.fortesol.com.br/api/demand_project_solar/generate-procuration_pdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(informations),
            });

            if (response.ok) {
                // Cria um Blob a partir da resposta
                const blob = await response.blob();

                const url = window.URL.createObjectURL(new Blob([blob]));


                const a = document.createElement('a');
                a.href = url;
                a.download = `procuracao_${informations.name}.pdf`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url); // Limpa o objeto URL
                props.handleClick()
            } else {
                console.error('Erro ao gerar o PDF:', await response.json());

            }
        } catch (error) {
            console.error('Erro ao enviar a requisição:', error);

        }
    };


    // Lidando com a solicitação de cancelamento 

    const handle_confirm_cancel_demand = () => {
        fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-status-demand/${props.data.id_demand}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: 'Cancelado' })
        }).then((response) => response.json())
            .then((data) => {
                document.getElementById('bt-all')?.click()
                document.getElementById(props.data.id_demand)?.click()
                toast.success('Solicitação de cancelamento confirmada.')
                fetch(`https://api.fortesol.com.br/api/users/customer-or-employee/${props.data.id}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    })
                .then((response) => response.json())
                .then((data_) => {
                    
                fetch(`https://api.fortesol.com.br/api/notifications/create`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        
                      customers_id: parseInt(props.data.id),
                      title: 'Cancelamento de Projeto',
                      message: `Solicitação de cancelamento do projeto cod: ${props.data.id_demand} confirmada.`,
                      view: false,
                      view_date: '',
                      creation_date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
                      send_by: 'system',
                      send_to: data_[0].name_user,
                      id_demand_notifications: props.data.id_demand,
                    }),
                  }).then((response) => response.json())
                    .then((data) => {refresh()})
                }).catch((error) => {
                    console.error('Error:', error);
                });
            })
            
    }
    const handle_reject_cancel_demand = async () => {
       await fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-status-demand/${props.data.id_demand}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: 'Em andamento' })
        }).then((response) => response.json())
            .then((data) => {
                document.getElementById('bt-all')?.click()
                document.getElementById(props.data.id_demand)?.click()
                toast.success('Solicitação de cancelamento rejeitada.')
                fetch(`https://api.fortesol.com.br/api/users/customer-or-employee/${props.data.id}`)
                .then((response) => response.json())
                .then((data) => {
                    fetch(`https://api.fortesol.com.br/api/users/customer-or-employee/${props.data.id}`, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        })
                    .then((response) => response.json())
                    .then((data_) => {
                      
                    fetch(`https://api.fortesol.com.br/api/notifications/create`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            
                          customers_id: parseInt(props.data.id),
                          title: 'Cancelamento de Projeto',
                          message: `Solicitação de cancelamento do projeto cod: ${props.data.id_demand} cancelada.`,
                          view: false,
                          view_date: '',
                          creation_date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
                          send_by: 'system',
                          send_to: data_[0].name_user,
                          id_demand_notifications: props.data.id_demand,
                        }),
                      }).then((response) => response.json())
                        .then((data) => {refresh()})
                    }).catch((error) => {
                        console.error('Error:', error);
                    });
                
                })
            })
    }

    const [openDrop, setOpenDrop] = useState(false)
    const [titleDrop, setTitleDrop] = useState('')
    const [msgDrop, setMsgDrop] = useState('')
    const [idDrop, setIdDrop] = useState('')

    const handleClickOpenDrop = () => {

        setOpenDrop(true)
    }
    const handleCloseDrop = () => {
        setOpenDrop(false)
    }

   
    const handle_cancel_demand = async () => {
       
       await fetch(`https://api.fortesol.com.br/api/users/customer-or-employee/${props.data.id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            })
        .then((response) => response.json())
        .then((data_) => {
            
        fetch(`https://api.fortesol.com.br/api/notifications/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                
              customers_id: parseInt(props.data.id),
              title: 'Projeto Cancelado',
              message: `Projeto cod: ${props.data.id_demand} cancelado.`,
              view: false,
              view_date: '',
              creation_date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
              send_by: 'system',
              send_to: data_[0].name_user,
              id_demand_notifications: props.data.id_demand,
            }),
          }).then((response) => response.json())
            .then((data) => {})
        }).catch((error) => {
            console.error('Error:', error);
        });


       await fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-status-demand/${props.data.id_demand}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: 'Cancelado' })
        }).then((response) => response.json())
            .then((data) => {
                document.getElementById('bt-all')?.click()
                setOpenDrop(false)
                handleCloseDrop()
                toast.success('Projeto cancelado.')
                refresh()
             
            })

    }
    const [openConfirm, setOpenConfirm] = useState(false)
    const [titleConfirm, setTitleConfirm] = useState('')
    const [msgConfirm, setMsgConfirm] = useState('')


    const handleOpenConfirm = () => {

        setOpenConfirm(true)
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }
    const handle_returm_demand = () => {
        fetch(`https://api.fortesol.com.br/api/users/customer-or-employee/${props.data.id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            })
        .then((response) => response.json())
        .then((data_) => {
          
        fetch(`https://api.fortesol.com.br/api/notifications/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                
              customers_id: parseInt(props.data.id),
              title: `Projeto Retomado`,
                message: `Projeto cod: ${props.data.id_demand} retomado.`,                      
              view: false,
              view_date: '',
              creation_date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
              send_by: 'system',
              send_to: data_[0].name_user,
              id_demand_notifications: props.data.id_demand,
            }),
          }).then((response) => response.json())
            .then((data) => {})
        }).catch((error) => {
            console.error('Error:', error);
        });
        fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-status-demand/${props.data.id_demand}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: 'Em andamento' })
        }).then((response) => response.json())
            .then((data) => {
                document.getElementById('bt-all')?.click()
                setOpenConfirm(false)
                handleCloseConfirm()
                toast.success('Projeto retomado.')
                refresh()
        
            })
    }


    //SALVANDO LINK DO PROJETO NA ENEL 
    const [linkEnel, setLinkEnel] = useState(props.data.link_enel_project || '');

    const handleSaveLink = () => {
        fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-link-enel/${props.data.id_demand}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ link_enel_project: linkEnel })
        }).then((response) => response.json())
            .then((data) => {
                toast.success('Link salvo com sucesso.');
                setLinkEnel('');
                refresh();
            });
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="100%"
            sx={{ '& .MuiDialog-paper': { padding: "0 0 0 20px", backgroundColor: '#f5f5f5' } }}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{"Projeto Solar"}</DialogTitle>
            <DialogContent >

                <br />

                <Form_Highest_10 open={openFormPlus10} close={closeFormPlus10} data={props.data} refresh={refresh} result_minor={result_minor} />
                <Form_Minor_10 open={openFormMinor10} close={closeFormMinor10} data={props.data} refresh={refresh} result_minor={result_minor} />
                <Preview open={open} close={handleClose} title={title} src={src} typeFile={typeFile} typeFileName={typeFileName} data={props.data} refresh={refresh} user={user} />
                <RateioForm open={openRateio} close={closeRateio} data={props.data} />
                <System_Project_Dialog open={openProject} handleClose={handleClickProject} data={props.data} />
                <Updade_Art open={openArt} close={closeArt} id={props.data.id_demand} refresh={refresh} />
                <Edit_Demand open={openEditDemand} close={closeEditDemand} data={props.data} refresh={refresh} />
                <MapView   open={openMap} close={handleCloseMap} data={props.data} title='Localização do Projeto' />

                <DropDialog openDrop={openDrop} handleCloseDrop={handleCloseDrop} title={titleDrop} msg={msgDrop} delete={handle_cancel_demand} />
                <ConfirmDialog open={openConfirm} close={handleCloseConfirm} title={titleConfirm} msg1={msgConfirm} confirm={handle_returm_demand} />

                <Grid container spacing={2} sx={{ backgroundColor: '#f5f5f5', gap: 1 }}>
                    <Grid item xs={12} sm={3} sx={{ backgroundColor: 'white' }}>
                        {/*        
                        <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Procurando título:</Typography>
                <List>
                            <Paper sx={{ padding: 2, margin: 1 }}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 15 }}>Enviar Notificação:</Typography>
                                <TextField
                                    margin="dense"
                                    id="outlined-basic"
                                    label="Notificação"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    rowsMax={3}
                                />
                                <Button size='small' variant="contained" color="primary" fullWidth>Enviar</Button>
                            </Paper>

                </List>*/}
                        {
                            props.data.status === 'Cancelamento Solicitado' &&
                            <List>
                                <Paper sx={{ padding: 2, margin: 1 }}>
                                    <Box
                                        sx={{
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            backgroundColor: '#c93a3a',
                                            animation: 'pulse 1s infinite',
                                        }}
                                    >  </Box>    <Typography variant="h6" component="div" textAlign={'center'} sx={{ p: '5px', fontSize: 15 }}>
                                        Solicitação de Cancelamento:</Typography>
                                    <ListItem sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                        <Button size='small' variant="contained" color="primary" onClick={handle_reject_cancel_demand}>Rejeitar</Button>
                                        <Button size='small' variant="contained" color="error" onClick={handle_confirm_cancel_demand}>confirmar</Button>
                                    </ListItem>
                                </Paper>
                            </List>
                        }
                        {
                            props.data.status === 'Cancelado' &&
                            <List>
                                <Paper sx={{ padding: 2, margin: 1 }}>
                                    <Box
                                        sx={{
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            backgroundColor: '#c93a3a',
                                            animation: 'pulse 1s infinite',
                                        }}
                                    >  </Box>    <Typography variant="h6" color={'error'} component="div" textAlign={'center'} sx={{ p: '5px', fontSize: 15 }}>
                                        PROJETO CANCELADO</Typography>
                                </Paper>
                            </List>
                        }

                  
                         {props.data.link_enel_project ? (
                <List>
                    <Paper elevation={1} sx={{ padding: 3, margin: 2, textAlign: 'center' }}>
                        <Typography variant="h6" component="div" sx={{ marginBottom: 2, fontSize: 16, fontWeight: 'bold', color: '#02557e' }}>
                            PROJETO ENEL
                        </Typography>
                        <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                startIcon={<LinkIcon />}
                                fullWidth
                                sx={{ fontWeight: 'bold' }}
                                onClick={() => window.open(props.data.link_enel_project, '_blank')}
                            >
                                Acessar Projeto
                            </Button>
                        </ListItem>
                    </Paper>
                </List>
            ) : (
                <List>
                    <Paper elevation={1} sx={{ padding: 3, margin: 2 }}>
                        <Typography variant="h6" component="div" textAlign={'center'} sx={{ marginBottom: 2, fontSize: 16, fontWeight: 'bold', color: '#02557e' }}>
                            PROJETO ENEL
                        </Typography>
                        <ListItem sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                label="Link do Projeto"
                                value={linkEnel}
                                onChange={(e) => setLinkEnel(e.target.value)}
                                sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                            />
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                fullWidth
                                startIcon={<AddLinkIcon />}
                                sx={{ fontWeight: 'bold' }}
                                onClick={handleSaveLink}
                            >
                                Salvar Link
                            </Button>
                        </ListItem>
                    </Paper>
                </List>
            )}
                        <List>
                            <Paper sx={{ padding: 2, margin: 1 }}>
                            <Typography variant="h6" component="div" textAlign={'center'} sx={{ marginBottom: 2, fontSize: 16, fontWeight: 'bold', color: '#02557e' }}>
                            DOCUMENTOS
                        </Typography>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth
                                        disabled={props.data.array_data_descriptive_memorial ? false : true}
                                        id='btn-m-d'
                                        onClick={() => {

                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/pdf_descriptive_memorial/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {

                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })
                                            }, 10)

                                            setTitle('MEMORIAL DESCRITIVO')
                                            handleClickOpen()
                                        }}
                                    >memorial descritivo</Button>

                                </ListItem>
                                {result_minor && result_minor <= 10
                                    ?
                                    <ListItem>
                                        <Button disabled={props.data.array_data_descriptive_memorial ? false : true} name='-10' size='small' variant="contained" color="primary" fullWidth
                                            onClick={() => {
                                                handleClickFormMinor10()
                                            }}
                                        >formulário de ingresso</Button>

                                    </ListItem>
                                    :
                                    <ListItem>
                                        <Button disabled={props.data.array_data_descriptive_memorial ? false : true} name='+10' size='small' variant="contained" color="primary" fullWidth
                                            onClick={() => {
                                                handleClickFormPlus10()
                                            }}
                                        >formulário de ingresso</Button>

                                    </ListItem>}
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth disabled={props.data.data_art_demand ? false : true} id='btn-art'
                                        onClick={() => {

                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/data_art/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {

                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })
                                            }, 1000)

                                            setTitle('ART')
                                            handleClickOpen()
                                        }}
                                    >ART</Button>

                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth id='btn-doc-proxy-demand'
                                        onClick={() => {

                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/doc_proxy_demand/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {

                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })
                                            }, 1000)

                                            setTitle('PROCURAÇÃO ASSINADA')
                                            handleClickOpen()
                                        }}
                                    >Procuração</Button>
                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth onClick={handleClickRateio}>Rateio</Button>
                                </ListItem>





                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth id='btn-identify'
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/customers_of_customers/download/identify/${props.data.id_customer_of_customers}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('Documento de Identificação')
                                            handleClickOpen()
                                        }}
                                    >DOC de Identificação</Button>
                                </ListItem>
                                {props.data.cnpj_customers_of_customers &&
                                    <ListItem>
                                        <Button size='small' variant="contained" color="primary" fullWidth id='btn-contract-social'
                                            onClick={() => {
                                                setTimeout(() => {
                                                    const response = fetch(`https://api.fortesol.com.br/api/customers_of_customers/download/contract-social/${props.data.id_customer_of_customers}`, {
                                                        method: 'GET',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': `Bearer ${token}`
                                                        }
                                                    }).then((response) => response.blob())
                                                        .then((blob) => {
                                                            setTypeFile(blob.type.split('/')[1])
                                                            setTypeFileName(blob.type.split('/')[0])
                                                            const url = window.URL.createObjectURL(new Blob([blob]));
                                                            setSrc(url)

                                                        }).catch((error) => {
                                                            console.log(error)
                                                        })

                                                }, 1000)
                                                setTitle('CONTRATO SOCIAL')
                                                handleClickOpen()
                                            }}
                                        >Contrato Social</Button>
                                    </ListItem>}
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth id='btn-electricity_bill'
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/electricity_bill/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('CONTA DE LUZ')
                                            handleClickOpen()
                                        }}
                                    >Conta de Luz</Button>
                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth id='btn-photos-breaker'
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/photos_breaker/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())

                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })
                                            }, 1000)

                                            setTitle('DISJUNTOR')
                                            handleClickOpen()
                                        }}
                                    >Disjuntor</Button>

                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth id='btn-energy-box'
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/energy_box_photos/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('UNIDADE CONSUMIDORA')
                                            handleClickOpen()
                                        }}
                                    >Unidade Consumidora</Button>

                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color="primary" fullWidth id='btn-data-photovoltaic'
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/data_photovoltaic/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('KIT FOTOVOLTAICO')
                                            handleClickOpen()
                                        }}
                                    >KIT FOTOVOLTAICO</Button>

                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color={props.data.signed_descriptive_memorial ? 'primary' : 'warning'} fullWidth id='btn-signed-descriptive-memorial'
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/signed-descriptive-memorial/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('MEMORIAL DESCRITIVO ASSINADO')
                                            handleClickOpen()
                                        }}
                                        >MEMORIAL DESCRITIVO ASSINADO</Button>
                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color={props.data.single_line_diagram ? 'primary' : 'warning'} fullWidth id='btn-single-line-diagram'
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/single-line-diagram/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('DIAGRAMA UNIFILAR')
                                            handleClickOpen()
                                        }}
                                        >diagrama unifilar</Button>
                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color={props.data.access_opinion ? 'primary' : 'warning'} fullWidth id='btn-access-opinion'
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/access-opinion/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('PARECER DE ACESSO')
                                            handleClickOpen()
                                        }}
                                        >PARECER DE ACESSO</Button>
                                </ListItem>
                                <ListItem>
                                    <Button size='small' variant="contained" color={props.data.operational_relationship ? 'primary' : 'warning'} fullWidth id='btn-operational-relationship'
                                        onClick={() => {
                                            setTimeout(() => {
                                                const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/operational-relationship/${props.data.id_demand}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`
                                                    }
                                                }).then((response) => response.blob())
                                                    .then((blob) => {
                                                        setTypeFile(blob.type.split('/')[1])
                                                        setTypeFileName(blob.type.split('/')[0])
                                                        const url = window.URL.createObjectURL(new Blob([blob]));
                                                        setSrc(url)
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    })

                                            }, 1000)
                                            setTitle('RELACIONAMENTO OPERACIONAL')
                                            handleClickOpen()
                                        }}
                                        >relacionamento operacional</Button>
                                </ListItem>
                            </Paper>
                        </List>
                        <List >
                            <Paper sx={{ padding: 2, margin: 0, width: 'auto' }}>
                            <Typography variant="h6" component="div" textAlign={'center'} sx={{ marginBottom: 2, fontSize: 16, fontWeight: 'bold', color: '#02557e' }}>
                            LINHA DO TEMPO
                        </Typography>
                                <Grid container spacing={2} sx={{ maxHeight: 735, overflowY: 'auto' }}>
                                    <Timeline_Demand data={props.data} />
                                </Grid>
                            </Paper>
                        </List>
                    </Grid>

                    <Grid item xs={12} sm={8.8} >
                        {
                            //voltar para ajeitar botões de status
                        }



                        <Grid item xs={12} sm={12} >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 0,
                                    mb: 4,
                                    p: 1,
                                    backgroundColor: '#02557e', // Azul escuro
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    align="center"
                                    color="white"
                                    sx={{
                                        fontWeight: 'bold',
                                        fontFamily: 'Roboto, sans-serif',
                                        textTransform: 'uppercase',
                                        letterSpacing: '1.5px',
                                    }}
                                >
                                    Dados do Projeto
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container spacing={1} sx={{ backgroundColor: '#f5f5f5' }}>
                            <Grid item xs={12} sm={2} sx={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                                {props.data?.array_data_descriptive_memorial ? '' : <Button size='small' variant="contained" color="primary" fullWidth onClick={handleClickProject}>Gerar Projeto</Button>}
                                {!props.data?.data_art_demand && props.data.array_data_descriptive_memorial ? <Button size='small' variant="contained" color="primary" fullWidth onClick={handleClickArt}>Enviar Art</Button> : ''}

                            </Grid>
                            <Grid item xs={12} sm={10} sx={{ backgroundColor: 'white' }}>
                                <Stepper_Project data={props.data} />
                            </Grid>
                        </Grid>






                        <Grid sx={{ backgroundColor: 'white' }} container spacing={1}>


                            <TableContainer component={Paper}  >

                                <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                    <Box sx={{ mt: 3, mb: 1, p: 2 }}> <Typography variant="h6" color="#02557e" sx={{ fontWeight: 'medium', mb: 1 }}>Cliente Integrador:</Typography></Box>
                                    <Button size='small' variant="contained" sx={{ mr: 3 }} onClick={handleClickEditDemand}>Editar Projeto</Button>
                                </Grid>
                                <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table" >
                                    <TableHead >
                                        <TableRow sx={{ background: '#02557e' }}>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">{props.data.cpf ? 'Nome' : 'Rasão Social'}</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">{props.data.cpf ? 'CPF' : 'CNPJ'}</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">E-mail</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Contato</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Endereço</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center" component="th" scope="row">
                                                {props.data.name ? props.data.name : props.data.corporate_reason}
                                            </TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cpf ? props.data.cpf : props.data.cnpj}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.email}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.contact}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 10 }} align="center">{`${props.data.street}, ${props.data.number} - ${props.data.neighborhood}, ${props.data.city} - ${props.data.state}`}</TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {props.data.cnpj &&
                                <TableContainer component={Paper}>
                                    <Box sx={{ mt: 3, mb: 1, p: 2 }}> <Typography variant="subtitle1" color="#02557e" sx={{ fontWeight: 'medium', mb: 1 }}>Representante Legal:</Typography></Box>
                                    <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={{ background: '#02557e' }}>
                                                <TableCell sx={{ p: '5px', color: 'white' }} align="center">Nome</TableCell>
                                                <TableCell sx={{ p: '5px', color: 'white' }} align="center">CPF</TableCell>
                                                <TableCell sx={{ p: '5px', color: 'white' }} align="center">Profissão</TableCell>
                                                <TableCell sx={{ p: '5px', color: 'white' }} align="center">Nacionalidade</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                key={props.data.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center" component="th" scope="row">
                                                    {props.data.name_representative}
                                                </TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cpf_representative}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.profession_representative}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.nationality_representative}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>}



                            <TableContainer component={Paper}>
                                <Box sx={{ mt: 2, mb: 0, p: 2 }}> <Typography variant="h6" color="#02557e" sx={{ fontWeight: 'medium', mb: 1 }}>Cliente Terceiro:</Typography></Box>
                                <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table" >
                                    <TableHead >
                                        <TableRow sx={{ background: '#02557e' }}>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">{props.data.cpf_customers_of_customers ? 'Nome' : 'Rasão Social'}</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">{props.data.cpf_customers_of_customers ? 'CPF' : 'CNPJ'}</TableCell>
                                            {props.data.email_customers_of_customers && <TableCell sx={{ p: '5px', color: 'white' }} align="center">E-mail</TableCell>}
                                            {props.data.contact_customers_of_customers && <TableCell sx={{ p: '5px', color: 'white' }} align="center">Contato</TableCell>}
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Procuração</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center" component="th" scope="row">
                                                {props.data.name_customers_of_customers ? props.data.name_customers_of_customers : props.data.corporate_reason_customers_of_customers}
                                            </TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cpf_customers_of_customers ? props.data.cpf_customers_of_customers : props.data.cnpj_customers_of_customers}</TableCell>
                                            {props.data.email_customers_of_customers && <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.email_customers_of_customers}</TableCell>}
                                            {props.data.contact_customers_of_customers && <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.contact_customers_of_customers}</TableCell>}
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center"><IconButton onClick={handleDownloadWord} ><CloudDownloadIcon color='primary' /></IconButton><IconButton onClick={handleDownloadPDF} ><CloudDownloadIcon color='error' /></IconButton></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>



                            {props.data.cnpj_customers_of_customers &&
                                <TableContainer component={Paper}>
                                    <Box sx={{ mt: 3, mb: 1, p: 2 }}> <Typography variant="subtitle1" color="#02557e" sx={{ fontWeight: 'medium', mb: 1 }}>Representante Legal:</Typography></Box>
                                    <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={{ background: '#02557e' }}>
                                                <TableCell sx={{ p: '5px', color: 'white' }} align="center">Nome</TableCell>
                                                <TableCell sx={{ p: '5px', color: 'white' }} align="center">CPF</TableCell>
                                                <TableCell sx={{ p: '5px', color: 'white' }} align="center">Profissão</TableCell>
                                                <TableCell sx={{ p: '5px', color: 'white' }} align="center">Nacionalidade</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                key={props.data.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center" component="th" scope="row">
                                                    {props.data.name_representative_customers_of_customers}
                                                </TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cpf_representative_customers_of_customers}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.profession_representative_customers_of_customers}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.nationality_representative_customers_of_customers}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>}



                            {/*<br />

                            <TableContainer component={Paper}>
                                <Typography variant="h6" component="div" sx={{ p: '5px', fontSize: 16, mb: 2 }}>Módulos::</Typography>
                                <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{background:'#02557e'}}>
                                            <TableCell sx={{ p: '5px' }} align="center">Marca</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Modelo</TableCell>
                                            <TableCell sx={{ p: '5px' }} align="center">Quantidade</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    {arrayModules?.map((item, index) => (
                                        <TableBody key={index}>

                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center" sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                                                    {item.marca}
                                                </TableCell>
                                                <TableCell align="center" sx={{ p: '5px', fontSize: 13 }}>{item.modelo}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{item.quantidade}</TableCell>

                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                            */}




                            <TableContainer component={Paper}>
                                <Box sx={{ mt: 3, mb: 0, p: 2 }}> <Typography variant="h6" color="#02557e" sx={{ fontWeight: 'medium', mb: 1 }}>Informações do Projeto:</Typography></Box>

                                {
                                    props.data.msgCabo && <Alert severity="warning" sx={{ mb: 2 }}>Atenção: {props.data.msgCabo}</Alert>
                                }
                                {
                                    props.data.msgDisjuntor && <Alert severity="warning" sx={{ mb: 2 }}>Atenção: {props.data.msgDisjuntor}</Alert>
                                }
                                <Box sx={{ mt: 0, mb: 0, p: 2 }}> <Typography variant="subtitle1" color="#02557e" sx={{ fontWeight: 'medium', mb: 1 }}>Inversores:</Typography></Box>
                                <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ background: '#02557e' }}>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Inversor</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Fase</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Potência</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    {arrayInverters?.map((item, index) => (
                                        <TableBody key={index}>
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center" sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="center" sx={{ p: '5px', fontSize: 13 }}>{service_type(item.fase)}</TableCell>
                                                <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{item.ptc}</TableCell>

                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                            {(array_qgbt_demand && array_qgbt_demand[0]?.disjuntor) && <TableContainer component={Paper}>
                                <Table size="small" aria-label="Tabela pequena">
                                    <TableHead>
                                        <TableRow sx={{ background: '#02557e' }}>
                                            <TableCell align="center" style={{ padding: '4px 8px', color: 'white' }}>Fase</TableCell>
                                            <TableCell align="center" style={{ padding: '4px 8px', color: 'white' }}>Potência</TableCell>
                                            <TableCell align="center" style={{ padding: '4px 8px', color: 'white' }}>Corrente (A)</TableCell>
                                            <TableCell align="center" style={{ padding: '4px 8px', color: 'white' }}>Disjuntor Mínimo (A)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {array_qgbt_demand.map((qgbt, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.fase}</TableCell>
                                                <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.ptc}</TableCell>
                                                <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.corrente}</TableCell>
                                                <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.disjuntor}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>}
                            <TableContainer component={Paper}>

                                <Box sx={{ mt: 0, mb: 1, p: 2 }}> <Typography variant="subtitle1" color="#02557e" sx={{ fontWeight: 'medium', mb: 1 }}>Unidade Consumidora:</Typography></Box>


                                <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ background: '#02557e' }}>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">N° UC</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">N° Cliente</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Tipo de Projeto</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Tipo de Ramal</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Modalidade</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center" sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                                                {props.data.number_uc_demand}
                                            </TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 11 }} align="center">{props.data.number_customer_uc}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 11 }} align="center">{project_type(props.data.project_type_demand)}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{branch_type(props.data.branch_type_demand)}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{modality_type(props.data.modality_type_demand)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper}>
                                <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ background: '#02557e' }}>

                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Atendimento</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Tensão da Rede</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Disjuntor (A)</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Seção Nominal (mm²)</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Tipo de Cabo</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{service_type(props.data.service_type_demand)}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.power_rede_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.disjuntor_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.nominal_section_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.type_cable_demand}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>





                            <TableContainer component={Paper}>
                                <Box sx={{ mt: 3, mb: 1, p: 2 }}> <Typography variant="h6" color="#02557e" sx={{ fontWeight: 'medium', mb: 1 }}>Endereço do Projeto:</Typography></Box>
                                <Table sx={{ minWidth: 500, height: 50, mb: 2 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ background: '#02557e' }}>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Logradouro</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Numero</TableCell>
                                            {props.data?.complement_service_demand ? <TableCell sx={{ p: '5px', color: 'white' }} align="center">Complemento</TableCell> : ''}
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Bairro</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Cidade</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Estado</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">CEP</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Latitude</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Longitude</TableCell>
                                            <TableCell sx={{ p: '5px', color: 'white' }} align="center">Mapa</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            key={props.data.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center" component="th" scope="row">
                                                {props.data.street_service_demand}
                                            </TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.number_service_demand}</TableCell>
                                            {props.data?.complement_service_demand ? <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.complement_service_demand}</TableCell> : ''}
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.district_service_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.city_service_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.state_service_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.cep_service_demand}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{parseFloat(props.data.lat_demand).toFixed(6)}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{parseFloat(props.data.log_demand).toFixed(6)}</TableCell>
                                            <TableCell sx={{ p: '5px', fontSize: 12 }} align="center"><IconButton onClick={handleClickOpenMap} ><LayersIcon color='secondary'/></IconButton></TableCell>


                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <br />
                            <br />
                      
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                {
                    (props.data.status === 'Solicitado' || props.data.status === 'Em andamento')
                        ?
                        <Button variant='contained' sx={{ mr: 2 }}
                            onClick={() => {
                                setTitleDrop('Cancelar Projeto')
                                setMsgDrop('Deseja realmente cancelar o projeto?')
                                handleClickOpenDrop()
                            }} autoFocus color='error'>
                            cancelar projeto
                        </Button>
                        :

                        (props.data.status !== 'Solicitado' && props.data.status !== 'Em andamento' && props.data.status !== 'Cancelamento Solicitado')
                            ?
                            <Button variant='contained' sx={{ mr: 2 }}
                                onClick={() => {
                                    setTitleConfirm('Retomar Projeto')
                                    setMsgConfirm('Deseja realmente retomar o projeto?')
                                    handleOpenConfirm()
                                }} autoFocus color='success'>
                                retomar projeto
                            </Button>
                            :
                            <p style={{ minWidth: 200 }}></p>
                }
                <Button id='bt-refresh' onClick={() => {
                    
                    refresh(); toast.success('Dados Atualizados', {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                  
                }} color="primary"> <CachedIcon /> </Button>
                <Button onClick={()=>{
      
                    props.close()}} color="primary"> Fechar </Button>

            </DialogActions>
        </Dialog>
    )
}

export default Start_and_Follow
