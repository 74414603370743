import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField, Button, Typography, List, Box, stepConnectorClasses, Table, TableCell, TableRow, TableBody, TableHead, TableContainer, Paper, Alert } from '@mui/material';
import ListSelectCustomer from '../ListSelectCustomer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CustomerSelect(props) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const openClose = () => {
        setOpen(false);
    };
    const [customer, setCustomer] = useState(props.selectedCustomer)
    const [fileRG_CNH, setFileRG_CNH] = useState(props.selectedRG_CNH)
    const [fileSocial_Contract, setFileSocial_Contract] = useState(props.selectedSocial_Contract)

    const handleClearFiles = () => {
        setFileRG_CNH('')
        setFileSocial_Contract('')
    }
    props.setSelectedCustomer(customer)
    props.setSelectedRG_CNH(fileRG_CNH)
    props.setSelectedSocial_Contract(fileSocial_Contract)
    return (
        <>
            <ListSelectCustomer
                setCustomer={setCustomer}
                handleClearFiles={handleClearFiles}
                handleClose={openClose}
                open={open}
                />

            <Grid container spacing={2} justifyContent="center" sx={{ paddingTop: '22px' }}>

                <Button variant="contained" color='success' onClick={handleClickOpen}>Selecionar Cliente</Button>
                <Grid item xs={12} sm={10} md={20} >
                
                    {customer && customer[0]?.cpf && <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }}>Nome</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">CPF</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Telefone</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Email</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Estado Civil</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Nacionalidade</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Profissão</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {customer && customer?.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" sx={{ fontSize: 10, }} component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.cpf}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.contact}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.email}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.civil_state}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.nationality}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.profession}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}

                    {customer && customer[0]?.cnpj && <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Razão Social</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">CNPJ</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Telefone</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {customer && customer?.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" sx={{ fontSize: 10, minWidth: 100 }} component="th" scope="row">
                                            {row.corporate_reason}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.cnpj}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.contact}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.email}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}

                    {customer && customer[0]?.cnpj && <TableContainer component={Paper}>
                        <br />
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Representante Legal</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">CPF</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">RG</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Naciodalidade</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Profissão</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {customer?.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center"sx={{ fontSize: 10, minWidth: 100 }} component="th" scope="row">
                                            {row.name_representative}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.cpf_representative}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.rg_representative}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.nationality_representative}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.profession_representative}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}

                    {customer && customer[0].cep && <TableContainer component={Paper}>
                              <br />
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Logradouro</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Numero</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Bairro</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Cidade</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">Estado</TableCell>
                                    <TableCell sx={{ fontSize: 13, minWidth: 100 }} align="center">CEP</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {customer?.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center"sx={{ fontSize: 10, minWidth: 100 }} component="th" scope="row">
                                            {row.street}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.number}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.neighborhood}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.city}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.state}</TableCell>
                                        <TableCell sx={{ fontSize: 10, minWidth: 100 }} align="center">{row.cep}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}




                </Grid>
               
               {customer && <Grid item xs={12} sm={4} md={20} 
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <Button component="label"
                        variant="contained"
                    >
                        Carregar RG/CNH
                        <input type="file" hidden onChange={
                            (e) => setFileRG_CNH(e.target.files[0])
                        } />
                        &nbsp;
                        <CloudUploadIcon />
                    </Button>
                </Grid>}
              {customer && customer[0]?.cnpj &&  <Grid item xs={12} sm={5} md={20} 
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <Button component="label"
                        variant="contained"
                    >
                        Carregar Contrato Social
                        <input type="file" hidden onChange={
                            (e) => setFileSocial_Contract(e.target.files[0])
                        } />
                        &nbsp;
                        <CloudUploadIcon />
                    </Button>
                </Grid>}
          
            </Grid>
        </>
    )
}

export default CustomerSelect
