import React from 'react'
import './style.css'
import { Box, CircularProgress } from '@mui/material'

function Loading() {
  return (
    <div className="loader-container">
   <Box display="flex" justifyContent="center" alignItems="center">
    <CircularProgress />
</Box>
  {/*<div className="loader">
    <div className="inner-circle"></div>
  </div>*/}
</div>
  )
}

export default Loading
