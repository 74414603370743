import styled from 'styled-components';

export const Container = styled.div`

  background-color: #042f75;
  position: fixed;
  height: 100vh!important;
 width: 200px;
 transition: all 0.5s;
  animation: showSidebar 0.9s;
  img{
    width: 150px;
    height: 55px;
    margin: 10px 0px 0px 16px;
    box-shadow: 0px 0px 20px 0px #fcdb03;
    transition: 3s;
    &:hover{
     transition: 3s;
    box-shadow: 0px 0px 80px 50px #fcdb03; 
    }}

    > svg {
    position: fixed;
    color: white;
    width: 30px;
    height: 30px;
    margin-top: 32px;
    margin-left: 32px;
    cursor: pointer;
    
  }

  @keyframes showSidebar {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 230px;
    }
  }
`;

export const Content = styled.div`
  margin-top: 100px;
`;
export const Li = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1A202C; 
  font-size: 20px;
  color: #000;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 15px 20px;
  list-style: none;
  
 a{text-decoration: none; color: white;
}
span{
  margin-left: 20px;
}
  > svg {
    margin: 0 20px;
  }

  &:hover {
    background-color: black;
  }
`;
export const Button = styled.button`
padding: 10px 5px 8px 5px; 
outline: none;
border:none;
border-radius: 10px;
margin-right: 7%;
margin-bottom: 0%;
cursor: pointer;
background-color:  #042f75;
color:white;
font-weight: 600;
font-size: 16px;
max-width: 350px;
&:hover{
    background-color: brown;
}
`
export const Div = styled.div`
margin-top: 20px;
display: flex;
align-items: center;
justify-content: space-between;
gap: 15px;
background-color: white;
width: 160px;
height: 45px;
border-radius: 10px;
margin-left: 10px;
li{
  list-style: none;
  a{
    text-decoration: none;
    color: #000;
  }
}
span{
 color: #000;
  margin-left: 10px;
}

` 