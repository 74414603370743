import { Button, Grid, Step, StepLabel, Stepper } from '@mui/material'
import React from 'react'
const steps = ['Projeto Solicitado','Projeto Iniciado','Geração de ART', 'Projeto Gerado', 'Projeto Enviado', 'Análise Técnica', 'Projeto Aprovado', 'Prazo de Vistoria', 'Solicitação de Vistoria', 'Concluído'] 


export default function Stepper_Project(props) {
  
  let number_stepper = 1;
  if(props.data?.data_descriptive_memorial ) number_stepper = 2;
    const [activeStep, setActiveStep] = React.useState(number_stepper);
  return (
    <Grid sx={{ backgroundColor: 'white' }}>   

        
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  
    <br />
  </Grid>
  )
}
