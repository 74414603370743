import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { Alert, AlertTitle, Box, Button, Collapse, FormControlLabel, Grid, IconButton, ListItemText, MenuItem, Paper, Radio, RadioGroup, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Label } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import tableEnel from '../../assets/tableEnel.jpeg';
import ImageViewer from '../imageViewer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DeleteIcon from '@mui/icons-material/Delete';
function ProjectInformation(props) {




  //Kit Fotovoltaico
  const conexao = [
    { label: 'Monofásico', value: 'monofasico' },

    { label: 'Trifásico', value: 'trifasico' },
  ];




  const handleAddInverter = () => {
    props.setInverters([...props.inverters, { fase: '', qtd: '', ptc: '' }]);
  };

  const handleAddModule = () => {
    props.setModules([...props.modules, { brand: '', model: '', qtd: '' }]);
  };

  const handleChange = (event, index, type) => {
    const { name, value } = event.target;
    const newArray = [...(type === 'inverter' ? props.inverters : props.modules)];
    newArray[index][name] = value;
    if (type === 'inverter') {
      props.setInverters(newArray);
    } else {
      props.setModules(newArray);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

  };

  const handleRemoveInverter = (index) => {
    const newArray = [...props.inverters];
    newArray.splice(index, 1);
    props.setInverters(newArray);
  }
  const handleRemoveModule = (index) => {
    const newArray = [...props.modules];
    newArray.splice(index, 1);
    props.setModules(newArray);
  }



  const correnteInversor = (fase, qtd, ptc, index) => {
    let corrente = 0
    let potencia = parseFloat(ptc)

    if (fase === 'monofasico') {
      corrente = ((potencia * 1000) / 220).toFixed(2)
    }
    if (fase === 'trifasico') {
      corrente = ((potencia * 1000) / (380 * 1.732050807568877)).toFixed(2)
    }
    console.log(corrente)
    const newArray = [...props.inverters];
    newArray[index]['corrente_real'] = corrente
    newArray[index]['corrente_30pct'] = (corrente * 1.3).toFixed(2)
    props.setInverters(newArray);

  }

  const selectDisjuntor = (corrente, fase) => {
    let correnteDisjuntorCA = parseFloat(corrente)
    console.log('co',correnteDisjuntorCA, fase)
    let disjuntor = 0
    if (fase === 'monofásico' || fase === 'monofasico' || fase === 'Monofásico') {
      if (correnteDisjuntorCA <= 10) return disjuntor = 10
      if (correnteDisjuntorCA > 10 && correnteDisjuntorCA <= 16) return disjuntor = 16
      if (correnteDisjuntorCA > 16 && correnteDisjuntorCA <= 20) return disjuntor = 20
      if (correnteDisjuntorCA > 20 && correnteDisjuntorCA <= 25) return disjuntor = 25
      if (correnteDisjuntorCA > 25 && correnteDisjuntorCA <= 32) return disjuntor = 32
      if (correnteDisjuntorCA > 32 && correnteDisjuntorCA <= 40) return disjuntor = 40
      if (correnteDisjuntorCA > 40 && correnteDisjuntorCA <= 50) return disjuntor = 50

    }
    if (fase === 'trifásico' || fase === 'trifasico' || fase === 'Trifásico') {

      if (correnteDisjuntorCA <= 25) return disjuntor = 25
      if (correnteDisjuntorCA > 25 && correnteDisjuntorCA <= 32) return disjuntor = 32
      if (correnteDisjuntorCA > 32 && correnteDisjuntorCA <= 40) return disjuntor = 40
      if (correnteDisjuntorCA > 40 && correnteDisjuntorCA <= 50) return disjuntor = 50
      if (correnteDisjuntorCA > 50 && correnteDisjuntorCA <= 63) return disjuntor = 63
      if (correnteDisjuntorCA > 63 && correnteDisjuntorCA <= 70) return disjuntor = 70
      if (correnteDisjuntorCA > 70 && correnteDisjuntorCA <= 80) return disjuntor = 80
      if (correnteDisjuntorCA > 80 && correnteDisjuntorCA <= 90) return disjuntor = 90
      if (correnteDisjuntorCA > 90 && correnteDisjuntorCA <= 100) return disjuntor = 100
      if (correnteDisjuntorCA > 100 && correnteDisjuntorCA <= 125) return disjuntor = 125
      if (correnteDisjuntorCA > 125 && correnteDisjuntorCA <= 150) return disjuntor = 150
      if (correnteDisjuntorCA > 150 && correnteDisjuntorCA <= 175) return disjuntor = 175
      if (correnteDisjuntorCA > 175 && correnteDisjuntorCA <= 200) return disjuntor = 200
      if (correnteDisjuntorCA > 200 && correnteDisjuntorCA <= 225) return disjuntor = 225
      if (correnteDisjuntorCA > 225 && correnteDisjuntorCA <= 250) return disjuntor = 250
      if (correnteDisjuntorCA > 250 && correnteDisjuntorCA <= 300) return disjuntor = 300
      if (correnteDisjuntorCA > 300 && correnteDisjuntorCA <= 350) return disjuntor = 350
      if (correnteDisjuntorCA > 350 && correnteDisjuntorCA <= 400) return disjuntor = 400
      if (correnteDisjuntorCA > 400 && correnteDisjuntorCA <= 450) return disjuntor = 450
      if (correnteDisjuntorCA > 450 && correnteDisjuntorCA <= 500) return disjuntor = 500
    }
  }

  const disjuntorInversor = (corrente, fase, index) => {
    const newArray = [...props.inverters];
    newArray[index]['disjuntorEnel'] = selectDisjuntor(corrente, fase)
    newArray[index]['disjuntorSugerido'] = selectDisjuntor(corrente * 1.3, fase)
    props.setInverters(newArray);
  }

  const secaoNominalInversorPVC = (disjuntor, fase, index) => {
    let secaoNominal = 0
    if (fase === 'monofásico' || fase === 'monofasico'|| fase === 'Monofásico') {
      if (disjuntor <= 24) secaoNominal = 4 //2.5
      if (disjuntor > 24 && disjuntor <= 31) secaoNominal = 4 //4
      if (disjuntor > 31 && disjuntor <= 41) secaoNominal = 6 //6
      if (disjuntor > 41 && disjuntor <= 62) secaoNominal = 10 //10
      if (disjuntor > 62 && disjuntor <= 78) secaoNominal = 16 //16
    }
    if (fase === 'trifásico' || fase === 'trifasico'|| fase === 'Trifásico') {
      if (disjuntor < 32) secaoNominal = 4
      if (disjuntor >= 32 && disjuntor < 40) secaoNominal = 6
      if (disjuntor >= 40 && disjuntor <= 50) secaoNominal = 10
      if (disjuntor > 50 && disjuntor <= 63) secaoNominal = 16
      if (disjuntor > 63 && disjuntor < 80) secaoNominal = 25
      if (disjuntor >= 80 && disjuntor <= 100) secaoNominal = 35
      if (disjuntor > 100 && disjuntor <= 125) secaoNominal = 50
      if (disjuntor > 125 && disjuntor <= 160) secaoNominal = 70
      if (disjuntor > 160 && disjuntor <= 200) secaoNominal = 95
      if (disjuntor > 200 && disjuntor <= 250) secaoNominal = 120
      if (disjuntor > 250 && disjuntor <= 315) secaoNominal = 150
    }

    const newArray = [...props.inverters];
    newArray[index]['secaoNominalPVC'] = secaoNominal
    props.setInverters(newArray);
  }
  const secaoNominalInversorHEPR = (disjuntor, fase, index) => {
    let secaoNominal = 0
    if (fase === 'monofásico' || fase === 'monofasico'|| fase === 'Monofásico') {
      if (disjuntor <= 24) secaoNominal = 2.5 //2.5
      if (disjuntor > 24 && disjuntor <= 31) secaoNominal = 2.5 //4
      if (disjuntor > 31 && disjuntor <= 41) secaoNominal = 4 //6
      if (disjuntor > 41 && disjuntor <= 62) secaoNominal = 6 //10
      if (disjuntor > 62 && disjuntor <= 78) secaoNominal = 10 //16
    }
    if (fase === 'trifásico' || fase === 'trifasico'|| fase === 'Trifásico') {
      if (disjuntor < 32) secaoNominal = 2.5
      if (disjuntor >= 32 && disjuntor < 40) secaoNominal = 4
      if (disjuntor >= 40 && disjuntor <= 50) secaoNominal = 6
      if (disjuntor > 50 && disjuntor <= 63) secaoNominal = 10
      if (disjuntor > 63 && disjuntor < 80) secaoNominal = 10
      if (disjuntor >= 80 && disjuntor <= 100) secaoNominal = 16
      if (disjuntor > 100 && disjuntor <= 125) secaoNominal = 25
      if (disjuntor > 125 && disjuntor <= 160) secaoNominal = 35
    }

    const newArray = [...props.inverters];
    newArray[index]['secaoNominalHEPR'] = secaoNominal
    props.setInverters(newArray);
  }



  const faseQGBT = () => {
    let contMono = 0
    let contTri = 0
    props.inverters.filter((inv) => {
      if (inv.fase === 'monofásico' || inv.fase === 'monofasico' || inv.fase === 'Monofásico') {
        contMono++
      }
      if (inv.fase === 'trifásico' || inv.fase === 'trifasico' || inv.fase === 'Trifásico') {
        contTri++
      }
    })
    if (contTri > 0) return 'Trifásico'
    if (contTri === 0 && contMono > 0) return 'Monofásico'
  }

  const potenciaQGBT = () => {
    let corrente = props.inverters.reduce((acc, inverter) => acc + parseFloat(inverter.corrente_real), 0)
    const fase = faseQGBT()
    if (fase === 'Trifásico') return ((corrente / 1000) * (380 * 1.732050807568877)).toFixed(2)
    if (fase === 'Monofásico') return ((corrente / 1000) * 220).toFixed(2)
  }
  const correnteQGBT = () => {
    let ptc = potenciaQGBT()
    const fase = faseQGBT()
    if (fase === 'Trifásico') return ((ptc * 1000) / (380 * 1.732050807568877)).toFixed(2)
    if (fase === 'Monofásico') return ((ptc * 1000) / 220).toFixed(2)
  }

  const [msgPadrao, setMsgPadrao] = useState('')
  const [msgErroFase, setMsgErroFase] = useState('')


  useEffect(() => {
    const msgCaboError = () => {
      if (props.serviceType === 'monofasico' || props.serviceType === 'monofásico') {
        //cabos de cobre
        if (props.disjuntor === '32' && props.typeCable === 'cobre' && parseFloat(props.nominalSection) < 6) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '40' && props.typeCable === 'cobre' && parseFloat(props.nominalSection) < 6) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        }
        //cabos de aluminio
        else if (props.disjuntor === '32' && props.typeCable === 'aluminio' && parseFloat(props.nominalSection) < 10) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '40' && props.typeCable === 'aluminio' && parseFloat(props.nominalSection) < 10) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        }
        else {
          props.setMsgCabo('')
        }
      } if (props.serviceType === 'bifasico' || props.serviceType === 'bifásico') {
        //cabos de cobre
        if (props.disjuntor === '32' && props.typeCable === 'cobre' && parseFloat(props.nominalSection) < 6) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '40' && props.typeCable === 'cobre' && parseFloat(props.nominalSection) < 6) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        }
        //cabos de aluminio
        else if (props.disjuntor === '32' && props.typeCable === 'aluminio' && parseFloat(props.nominalSection) < 10) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        }
        else if (props.disjuntor === '40' && props.typeCable === 'aluminio' && parseFloat(props.nominalSection) < 10) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        }
        else {
          props.setMsgCabo('')
        }
      }
      if (props.serviceType === 'trifasico' || props.serviceType === 'trifásico') {
        //cabos de cobre
        if (props.disjuntor === '32' && props.typeCable === 'cobre' && parseInt(props.nominalSection) < 6) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '40' && props.typeCable === 'cobre' && parseInt(props.nominalSection) < 6) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '50' && props.typeCable === 'cobre' && parseInt(props.nominalSection) < 16) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '63' && props.typeCable === 'cobre' && parseInt(props.nominalSection) < 16) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '80' && props.typeCable === 'cobre' && parseInt(props.nominalSection) < 35) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 35mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '100' && props.typeCable === 'cobre' && parseInt(props.nominalSection) < 35) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 35mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        }
        //cabos de aluminio
        else if (props.disjuntor === '32' && props.typeCable === 'aluminio' && parseInt(props.nominalSection) < 10) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '40' && props.typeCable === 'aluminio' && parseInt(props.nominalSection) < 10) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '50' && props.typeCable === 'aluminio' && parseInt(props.nominalSection) < 16) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '63' && props.typeCable === 'aluminio' && parseInt(props.nominalSection) < 16) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        } else if (props.disjuntor === '80' && props.typeCable === 'aluminio' && parseInt(props.nominalSection) < 35) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 35mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        }
        else if (props.disjuntor === '100' && props.typeCable === 'aluminio' && parseInt(props.nominalSection) < 35) {
          props.setMsgCabo(`Seção Nominal do Cabo Incorreta para Disjuntor de ${props.disjuntor}(A). Segundo o padão enel, o mínimo é 35mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        }
        else {
          props.setMsgCabo('')
        }

      }
    }
    msgCaboError()

    const msgDisjuntorError = () => {
      setMsgErroFase('')
      if (parseInt(props.disjuntor) !== 0) props.setMsgDisjuntor('')
      if ((props.serviceType === 'monofasico' || props.serviceType === 'monofásico') && (props.QGBT[0].fase === 'trifásico' || props.QGBT[0].fase === 'trifasico' || props.QGBT[0].fase === 'Trifásico')) {
        //  setMsgErroFase('Fase do sistema fotovoltaico (trifásico), não compatível com a Fase do Padrão de Entrada (monofásico).')
        setMsgErroFase(`O Tipo de conexão necessária para o Gerador Fotovoltaico é Trifásico, não compatível com o tipo de fornecimento do padrão de entrada que é monofásico. Será necessário solicitar acréscimo de carga com mudança de fase para disjuntor trifásico ${props.QGBT[0].disjuntor}(A). `)
      } else if ((props.serviceType === 'trifasico' || props.serviceType === 'trifásico') && (props.QGBT[0].fase === 'trifásico' || props.QGBT[0].fase === 'trifasico' || props.QGBT[0].fase === 'Trifásico')) {
        props.setMsgDisjuntor('')
      } else if ((props.serviceType === 'monofasico' || props.serviceType === 'monofásico') && (props.QGBT[0].fase === 'monofásico' || props.QGBT[0].fase === 'monofasico' || props.QGBT[0].fase === 'Monofásico')) {
        props.setMsgDisjuntor('')
      }
      if ((props.serviceType === 'monofasico' || props.serviceType === 'monofásico' || props.serviceType === 'trifasico' || props.serviceType === 'trifásico') && (props.QGBT[0].fase === 'monofásico' || props.QGBT[0].fase === 'monofasico' || props.QGBT[0].fase === 'Monofásico')) {

        if (parseFloat(props.QGBT[0].disjuntor) > parseFloat(props.disjuntor) && parseFloat(props.disjuntor) !== 0) {
          props.setMsgDisjuntor(`Disjuntor de ${props.QGBT[0].disjuntor}(A), necessário para o sistema fotovoltaico é maior que o Disjuntor de ${props.disjuntor}(A), referente ao Padrão de Entrada. Será necessário solicitar um acréscimo de carga.   <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`)

        } else {
          props.setMsgDisjuntor('')
        }
      }
      if ((props.serviceType === 'trifasico' || props.serviceType === 'trifásico') && (props.QGBT[0].fase === 'trifásico' || props.QGBT[0].fase === 'trifasico' || props.QGBT[0].fase === 'Trifásico')) {
        if (parseFloat(props.QGBT[0].disjuntor) > parseFloat(props.disjuntor) && parseFloat(props.disjuntor) !== 0) {
          props.setMsgDisjuntor(`Disjuntor de ${props.QGBT[0].disjuntor}(A), necessário para o sistema fotovoltaico é maior que o Disjuntor de ${props.disjuntor}(A), referente ao Padrão de Entrada. Será necessário solicitar um acréscimo de carga.   <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`)
        } else {
          props.setMsgDisjuntor('')
        }
      }
      if (parseInt(props.disjuntor) === 0) props.setMsgDisjuntor('Disjuntor da unidade consumidora não informado. Será considerado como ligação nova.')


    }
    msgDisjuntorError()

    const msgPadraoInfo = () => {
      if (props.serviceType === 'monofasico' || props.serviceType === 'monofásico') {
        if (parseInt(props.disjuntor) === 16 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 16(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 4mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 6mm². <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 20 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 20(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 4mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 25 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 25(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 4mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 32 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 32(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 40 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 40(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);

        if (parseInt(props.disjuntor) === 16 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 16(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 20 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 20(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 25 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 25(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 32 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 32(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 10mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 40 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 40(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 10mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);

      } if (props.serviceType === 'bifasico' || props.serviceType === 'bifásico') {
        if (parseInt(props.disjuntor) === 20 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 20(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 4mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 25 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 25(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 4mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 32 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 32(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 40 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 40(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);

        if (parseInt(props.disjuntor) === 20 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 20(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 25 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 25(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 32 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 32(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 10mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 40 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 40(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 10mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);

      } else if (props.serviceType === 'trifasico' || props.serviceType === 'trifásico') {
        if (parseInt(props.disjuntor) === 25 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 25(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 4mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 6mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 32 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 32(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 40 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 40(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 50 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 50(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 63 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 63(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 80 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 80(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 35mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 100 && props.typeCable === 'cobre') setMsgPadrao(`Para o disjuntor de 100(A), a seção do ramal de conexão com cabo do tipo cobre, o mínimo é de 35mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);

        if (parseInt(props.disjuntor) === 25 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 25(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 6mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 10mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 32 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 32(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 10mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 40 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 40(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 10mm², se o comprimento do ramal de conexão for maior que 30m e menor ou igual a 40m, deve-se utilizar 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 50 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 50(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 63 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 63(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 16mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 80 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 80(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 35mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
        if (parseInt(props.disjuntor) === 100 && props.typeCable === 'aluminio') setMsgPadrao(`Para o disjuntor de 100(A), a seção do ramal de conexão com cabo do tipo aluminio, o mínimo é de 35mm².  <span id="saiba-mais" style="cursor:pointer; text-decoration: underline;">Saiba Mais.</span>`);
      }
    }

    msgPadraoInfo()


  }, [props.disjuntor, props.typeCable, props.nominalSection, props.serviceType, props.QGBT[0].disjuntor, props.QGBT[0].fase])


  useEffect(() => {
    props.inverters.map((inverter, index) => {
      correnteInversor(inverter.fase, inverter.qtd, inverter.ptc, index)
      disjuntorInversor(inverter.corrente_real, inverter.fase, index)
      secaoNominalInversorPVC(inverter.disjuntorSugerido, inverter.fase, index)
      secaoNominalInversorHEPR(inverter.disjuntorSugerido, inverter.fase, index)
    })

    const newArrayQGBT = [...props.QGBT]
    const ptc = props.inverters.reduce((acc, inverter) => acc + parseFloat(inverter.ptc), 0)

    newArrayQGBT[0]['fase'] = faseQGBT()
    newArrayQGBT[0]['ptc'] = potenciaQGBT()
    newArrayQGBT[0]['corrente'] = correnteQGBT()
    newArrayQGBT[0]['disjuntor'] = selectDisjuntor(correnteQGBT(), faseQGBT())

    props.setQGBT(newArrayQGBT)
    console.log(newArrayQGBT)
  }, [
    props.inverters[0].fase, props.inverters[0].qtd, props.inverters[0].ptc,
    props.inverters[1] ? (props.inverters[1].fase, props.inverters[1].qtd, props.inverters[1].ptc) : null,
    props.inverters[2] ? (props.inverters[2].fase, props.inverters[2].qtd, props.inverters[2].ptc) : null,
    props.inverters[3] ? (props.inverters[3].fase, props.inverters[3].qtd, props.inverters[3].ptc) : null,
    props.inverters[4] ? (props.inverters[4].fase, props.inverters[4].qtd, props.inverters[4].ptc) : null,
    props.inverters[5] ? (props.inverters[5].fase, props.inverters[5].qtd, props.inverters[5].ptc) : null,
    props.inverters[6] ? (props.inverters[6].fase, props.inverters[6].qtd, props.inverters[6].ptc) : null,
    props.inverters[7] ? (props.inverters[7].fase, props.inverters[7].qtd, props.inverters[7].ptc) : null,
    props.inverters[8] ? (props.inverters[8].fase, props.inverters[8].qtd, props.inverters[8].ptc) : null,
    props.inverters[9] ? (props.inverters[9].fase, props.inverters[9].qtd, props.inverters[9].ptc) : null,
    props.inverters[10] ? (props.inverters[10].fase, props.inverters[10].qtd, props.inverters[10].ptc) : null,

  ])


  //ação para mostrar tabela enel
  const [showTable, setShowTable] = useState(false)
  const handleShowTable = () => {
    setShowTable(true)
  }
  const handleHideTable = () => {
    setShowTable(false)
  }


  //Informações do Padrao de entrada


  const options1 = [
    { label: 'Não Informado', value: 'Não Informado' },
    { label: 'B1 - Residencial', value: 'b1_residencial' },
    { label: 'B2 - Rural', value: 'b2_rural' },
    { label: 'B2 - Cooperativa', value: 'b2_cooperativa' },
    { label: 'B2 - Serviço Público de Irrigação', value: 'b2_servico_publico_de_irrigacao' },
    { label: 'B3 - Demais Classes', value: 'b3_demais_classes' },
    { label: 'A4 - Horária Verde', value: 'a4_horaria_verde' },
    { label: 'A4 - Horária Azul', value: 'a4_horaria_azul' },
  ];

  const options2 = [
    { label: 'Trifásico', value: 'trifasico' },
    { label: 'Bifásico', value: 'bifasico' },
    { label: 'Monofásico', value: 'monofasico' },
  ];

  const options3 = [
    { label: 'Áereo', value: 'aereo' },
    { label: 'Subterrâneo', value: 'subterraneo' },
  ];

  const options4 = [
    { label: 'Geração Local', value: 'geracao_local' },
    { label: 'Autoconsumo Remoto', value: 'autoconsumo_remoto' },
  ];

  const optionsCableType = [
    { label: 'Não Informado', value: 'Não Informado' },
    { label: 'Cobre', value: 'cobre' },
    { label: 'Alumínio', value: 'aluminio' },
  ];


  const optionsCable = [
    { label: 'Não Informado', value: 'Não Informado' },
    { label: '4 mm²', value: '4' },
    { label: '6 mm²', value: '6' },
    { label: '10 mm²', value: '10' },
    { label: '16 mm²', value: '16' },
    { label: '35 mm²', value: '35' },
  ];


  const disjuntorTrifasico = [
    { label: 'sem disjuntor', value: '0' },
    { label: '25(A)', value: '25' },
    { label: '32(A)', value: '32' },
    { label: '40(A)', value: '40' },
    { label: '50(A)', value: '50' },
    { label: '63(A)', value: '63' },
    { label: '80(A)', value: '80' },
    { label: '100(A)', value: '100' },
  ];

  const disjuntorBifasico = [
    { label: 'sem disjuntor', value: '0' },
    { label: '20(A)', value: '20' },
    { label: '25(A)', value: '25' },
    { label: '32(A)', value: '32' },
    { label: '40(A)', value: '40' },
  ];

  const disjuntorMonofasico = [
    { label: 'sem disjuntor', value: '0' },
    { label: '16(A)', value: '16' },
    { label: '20(A)', value: '20' },
    { label: '25(A)', value: '25' },
    { label: '32(A)', value: '32' },
    { label: '40(A)', value: '40' },
  ];


  const handleChangeServiceType = (e) => {
    if (e.target.value === 'trifasico') props.setPowerRede('220V/380V')
    if (e.target.value === 'bifasico') props.setPowerRede('220V/380V')
    if (e.target.value === 'monofasico') props.setPowerRede('220V')
    props.setServiceType(e.target.value)
  }


  const handleChangeBranchType = (e) => {
    props.setBranchType(e.target.value)
  }

  //RATEIO

  //Escrever porcentagens
  const handleChangeRateioType = (e) => {
    setErrorMessageRateio('')
    props.setWriteRateio([{ uc: '', percentage: '' }])
    props.setResultWriteRateio(0)
    props.setUcs([])
    props.setPercentages([])
    props.setKwhValues([])
    props.setResultPercentages(0)
    props.setRateioType(e.target.value)
  }

  const handleChangeRateio = (e) => {
    props.setModalityType(e.target.value)
  }
  // Função para atualizar o valor do rateio de cada item
  const handleChangeWrite = (index, event) => {
    const { name, value } = event.target;
    const updatedRateio = [...props.writeRateio];
    updatedRateio[index][name] = value;
    props.setWriteRateio(updatedRateio);
  };

  // Função para calcular e validar a soma das porcentagens
  const [errorMessageRateio, setErrorMessageRateio] = useState('');
  const handleCalculate = () => {
    const totalPercentage = props.writeRateio.reduce(
      (acc, item) => acc + parseFloat(item.percentage || 0),
      0
    );

    props.setResultWriteRateio(totalPercentage);

    if (totalPercentage === 100) {
      setErrorMessageRateio('');
    } else {
      setErrorMessageRateio('A soma das porcentagens deve ser igual a 100%.');
    }
  };

  // Função para adicionar um novo campo de rateio
  const handleAddRateio = () => {
    props.setWriteRateio([...props.writeRateio, { uc: '', percentage: '' }]);
  };
  const handleRemoveRateio = (index) => {
    const updatedRateio = props.writeRateio.filter((_, i) => i !== index);
    props.setWriteRateio(updatedRateio);
  };

  //Calcular por kWh

  const handleExpandClick = () => {
    props.setExpanded(!props.expanded);
  };





  const handleClear = () => {
    props.setUcs([]);
    props.setPercentages([]);
    props.setKwhValues([]);
    props.setResultPercentages(0);
  }


  //separar


  const handleAddKwh = () => {
    props.setUcs([...props.ucs, '']);
    props.setKwhValues([...props.kwhValues, '']);
  };

  const handleRemoveKwh = (index) => {
    const newUcs = [...props.ucs];
    const newKwhValues = [...props.kwhValues];
    newUcs.splice(index, 1);
    newKwhValues.splice(index, 1);
    props.setUcs(newUcs);
    props.setKwhValues(newKwhValues);
  };

  const handleSubmitRateio = () => {
    const totalKwh = props.kwhValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
    const newPercentages = props.kwhValues.map(value => ((parseFloat(value) / totalKwh) * 100));

    // Adjust last percentage for 100% sum
    const adjustedPercentages = [...newPercentages];
    props.setPercentages(adjustedPercentages);
    let totalPercentage = adjustedPercentages.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr), 0);

    adjustedPercentages.forEach((percentage, index) => {
      adjustedPercentages[index] = parseFloat(percentage).toFixed(2);
    });

    if (totalPercentage !== 100) {
      adjustedPercentages[adjustedPercentages.length - 1] = parseFloat(adjustedPercentages[adjustedPercentages.length - 1]) + (100 - totalPercentage);
      props.setPercentages(adjustedPercentages);
    }

    adjustedPercentages.forEach((percentage, index) => {
      adjustedPercentages[index] = parseFloat(percentage).toFixed(2);
    });

    if (totalPercentage !== 100) {
      if (totalPercentage < 100) {
        adjustedPercentages[adjustedPercentages.length - 1] = parseFloat(adjustedPercentages[adjustedPercentages.length - 1]) + 1;
        props.setPercentages(adjustedPercentages);
      } else {
        adjustedPercentages[adjustedPercentages.length - 1] = parseFloat(adjustedPercentages[adjustedPercentages.length - 1]) - 1;
        props.setPercentages(adjustedPercentages);
      }
    }

    props.setResultPercentages(totalPercentage.toFixed(2))
    if (totalPercentage.toFixed(2) === 100.00 || totalPercentage.toFixed(2) === 100) {
      setErrorMessageRateio('');
    } else {
      setErrorMessageRateio('A soma das porcentagens deve ser igual a 100%.');
    }
  };

  //NOME DOS ARQUIVOS
  const [cLuz, setCLuz] = useState('')
  const [disj, setDisj] = useState('')
  const [caixaE, setCaixaE] = useState('')
  const [kit, setKit] = useState('')
  return (
    <Grid>
      <Grid >


        <Typography variant="h6" sx={{ mt: 3, mb: 3 }}>DADOS DOS INVERSOR(ES):</Typography>
        <Grid container sx={{ mt: 0, mb: 2 }} justifyContent={'center'} alignItems={'center'}>

          <Grid item xs={11} >

            {/* Seção dos Inversores */}
            {props.inverters.map((inverter, index) => (
              <Grid key={index} sx={{ mt: 1 }} container spacing={2} fullWidth>
                <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="h6" sx={{ fontSize: 16 }}>Inversor {index + 1}:</Typography>
                </Grid>

                <Grid item xs={12} sm={3} key={index}>
                  <TextField
                    select
                    size='small'
                    id={`fase-inverter-${index}`}
                    label="Conexão: *"
                    variant="outlined"
                    fullWidth
                    name="fase"
                    value={inverter.fase}
                    onChange={(event) => {
                      document.getElementById(`fase-inverter-${index}`).style.border = 'transparent'

                      handleChange(event, index, 'inverter')
                    }}
                  >
                    {conexao.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={3} key={index}>
                  <TextField
                    size='small'
                    id={`ptc-inverter-${index}`}
                    label="Potência: * "
                    variant="outlined"
                    fullWidth
                    name="ptc"
                    type='number'
              
                    value={inverter.ptc}
                    onChange={(event) => {
                     //event.target.value = event.target.value.replace(/\D/g, '')
                      document.getElementById(`ptc-inverter-${index}`).style.border = 'transparent'

                      handleChange(event, index, 'inverter')
                    }}
                  />
                </Grid>
                {index === 0 && <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                  <IconButton onClick={handleAddInverter} size='small' >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Grid>}
                <Grid item xs={0.5} key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                  {index !== 0 && <Button size='small' onClick={() => handleRemoveInverter(index)}><RemoveCircleOutlineIcon color='error' /></Button>}
                </Grid>
              </Grid>
            ))}
          </Grid>

        </Grid>

        {/* Seção dos Módulos */}
        {/*
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 2,mb:2}}>
      <Typography  sx={{ mt: 3, mb: 3 }}variant="h5">Módulos</Typography>
    </Box>
          <Grid container sx={{ mt: 2,mb:2 }}>

              <Grid item xs={11} >
          {props.modules.map((module, index) => (
    <Grid container key={index} spacing={2} sx={{ mt: 1 }}>

            <Grid item xs={3.5} >
              <TextField
              size='small'
              id={`brand-module-${index}`}
                label="Marca"
                variant="outlined"
                fullWidth
                name="marca"
                value={module.marca}
                onChange={(event) => handleChange(event, index, 'module')}
              />
              </Grid>
            <Grid item xs={3.5} key={index}>
                <TextField
                id={`model-module-${index}`}
                size='small'
                label="Modelo"
                variant="outlined"
                fullWidth
                name="modelo"
                value={module.modelo}
                onChange={(event) => handleChange(event, index, 'module')}
                />
                </Grid>
            <Grid item xs={3.5} key={index}>
                <TextField
                id={`quantity-module-${index}`}
                size='small'
                label="Quantidade"
                variant="outlined"
                fullWidth
                name="quantidade"
                value={module.quantidade}
                onChange={(event) => {
                  event.target.value = event.target.value.replace(/\D/g, '')
                  handleChange(event, index, 'module')}}
                />

                </Grid>
            <Grid item xs={1} key={index}>
            {index !== 0 &&    <Button onClick={() => handleRemoveModule(index)}>Remover</Button>}
            </Grid>
            </Grid>
          ))}
          </Grid>
          <Grid item xs={1} >
            <IconButton onClick={handleAddModule} size='small' >
                    <AddCircleOutlineIcon />
                    </IconButton>

          </Grid>
          </Grid>
*/}


{/*
        <TableContainer component={Paper}>
          <Table size="small" aria-label="Tabela pequena">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Inversor</TableCell>
                {
                // <TableCell align="center" style={{ padding: '4px 8px' }}>Quantidade</TableCell>
                }
                <TableCell align="center" style={{ padding: '4px 8px' }}>Corrente Real (A)</TableCell>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Disjuntor Enel (A)</TableCell>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Corrente Ideal (A)</TableCell>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Disjuntor Ideal (A)</TableCell>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Cabo PVC</TableCell>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Cabo HERP</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.inverters.map((inverter, index) => (
                inverter.disjuntorEnel && <TableRow key={index}>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{index + 1}</TableCell>
                  {
                  //<TableCell align="center" style={{ padding: '4px 8px' }}>{inverter.qtd}</TableCell>
                  }
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{inverter.corrente_real}</TableCell>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{inverter.disjuntorEnel}</TableCell>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{inverter.corrente_30pct}</TableCell>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{inverter.disjuntorSugerido}</TableCell>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{inverter.secaoNominalPVC}</TableCell>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{inverter.secaoNominalHEPR}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {props.QGBT[0].disjuntor && <TableContainer component={Paper}>
          <Table size="small" aria-label="Tabela pequena">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Fase</TableCell>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Potência</TableCell>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Corrente (A)</TableCell>
                <TableCell align="center" style={{ padding: '4px 8px' }}>Disjuntor Mínimo (A)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.QGBT.map((qgbt, index) => (
                <TableRow key={index}>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.fase}</TableCell>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.ptc}</TableCell>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.corrente}</TableCell>
                  <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.disjuntor}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}*/}

        
      </Grid>




      <Typography variant="h6" sx={{ mt: 10, mb: 3 }}>INFORMAÇÕES DA UNIDADE CONSUMIDORA:</Typography>
      <Grid sx={{ mt: 1 }} container spacing={2} fullWidth>
        <Collapse in={showTable} timeout="auto" unmountOnExit>
          <Grid item xs={22} sm={12}>
            <ImageViewer src={tableEnel} alt={'Tabela Enel.'} maxHeight={'300px'} />
            <Alert severity='info' sx={{ mt: 2, mb: 2 }}>
              <AlertTitle>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Tabela Enel para referência de valores.
                </Typography>
              </AlertTitle>
              <Typography variant="body2" color="textSecondary">
                &bull; Considere a tabela acima para a escolha do disjuntor e seção nominal do cabo.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                &bull; Importante lembrar que a escolha do mm² do cabo deve ser feita de acordo com a seção do ramal de conexão e de acordo com o tipo de cabo escolhido se cobre ou alumínio.
              </Typography>
              <Typography variant="body2" color="error">
                &bull; Ex: Tipo de fornecimento (Monofásico) e disjuntor de 16(A), a seção do ramal de conexão no mínimo é de 4mm².
              </Typography>
              <Typography variant="body2" color="error">
                &bull; OBS:  Porém se o comprimento do ramal de conexão  <b>for maior que 30m e menor ou igual a 40m</b>, deve-se utilizar a segunda opção de condutor, identificado conforme exemplo: dentre os condutores 4 ou 6mm², <b>utilizar o condutor de 6mm²</b>. Essa observação é válida para todos os casos, seja cabos de cobre ou alumínio.
              </Typography>
              <Typography onClick={() => setShowTable(false)} variant="body2" color="#014361" textAlign={'end'} sx={{ textDecoration: 'underline', mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'end', cursor: 'pointer' }}><VisibilityOff />&nbsp; ESCONDER TABELA</Typography>
            </Alert>
          </Grid>
        </Collapse>
      </Grid>
      <Grid sx={{ mt: 1 }} container spacing={2}>



        { /*<Grid item xs={12} sm={6}>
        <TextField
        id='branch-type'
          select
          label="Tipo de Ramal:"
          value={props.branchType}
          onChange={(e) => props.setBranchType(e.target.value)}
          fullWidth
           size="small"
        >
          {options3.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>*/}



        <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              id='number-uc'
              label="N° UC: *"
              value={props.numberUC}
              fullWidth
              size="small"
              onChange={(e) => {
                document.getElementById(`number-uc`).style.border = 'transparent'

                e.target.value = e.target.value.replace(/\D/g, "")
                props.setNumberUC(e.target.value)
              }
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='number-customer-uc'
              label="N° Cliente Enel: *"
              value={props.numberCustomerUC}
              fullWidth
              size="small"
              onChange={(e) => {
                document.getElementById(`number-customer-uc`).style.border = 'transparent'

                e.target.value = e.target.value.replace(/\D/g, "")
                props.setNumberCustomerUC(e.target.value)
              }
              }
            />
          </Grid>
        </Grid>


        <Grid item xs={12} sm={6}>

          <TextField
            id='project-type'
            select
            label="Classificação: *"
            value={props.projectType}
            onChange={(e) => {
              document.getElementById(`project-type`).style.border = 'transparent'

              props.setProjectType(e.target.value)
            }}
            fullWidth
            size="small"
          >
            {options1.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Alert severity="info" sx={{ mt: 0, mb: 2 }}>Caso seja ligação nova, o número da UC é igual ao número do cliente.</Alert>
        </Grid>
        {props.projectType === 'a4_horaria_azul' &&
          <Grid item xs={12} sm={6}>
            <TextField
              id='contracted-demand'
              label="Demanda Contratada: *"
              fullWidth
              value={props.contractedDemand}
              size="small"
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, "")
                props.setContractedDemand(e.target.value)
              }
              }
            />
          </Grid>}
        {props.projectType === 'a4_horaria_verde' &&
          <Grid item xs={12} sm={6}>
            <TextField
              id='contracted-demand'
              label="Demanda Contratada: *"
              value={props.contractedDemand}
              fullWidth
              size="small"
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, "")
                props.setContractedDemand(e.target.value)
              }
              }
            />
          </Grid>}

        {
          <Grid item xs={12} sm={6}>
            <TextField
              id='service-type'
              select
              label="Tipo de Fornecimento: *"
              value={props.serviceType}
              onChange={(e) => {
                document.getElementById(`service-type`).style.border = 'transparent'

                props.setServiceType(e.target.value)
                if (e.target.value === 'trifasico') props.setPowerRede('220V/380V')
                if (e.target.value === 'bifasico') props.setPowerRede('220V/380V')
                if (e.target.value === 'monofasico') props.setPowerRede('220V')

              }}
              fullWidth
              size="small"
            >
              {options2.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        }
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            id='power-rede'

            fullWidth
            size="small"
            value={
              props.serviceType === 'trifasico' ? '220V/380V' :
                props.serviceType === 'bifasico' ? '220V/380V' :
                  props.serviceType === 'monofasico' ? '220V' : 'Tenção da Rede'
            }
          />
        </Grid>
        {msgErroFase && <Grid item xs={12} sm={12}>
          <Alert severity="error" sx={{}}>
            <Typography variant="subtitle2" dangerouslySetInnerHTML={{ __html: msgErroFase }}
              onClick={(e) => {
                if (e.target.id === "saiba-mais") {
                  setShowTable(true);
                }
              }}></Typography>

          </Alert>
        </Grid>}
      </Grid>


      <Typography variant="h6" sx={{ mt: 10, mb: 3 }}>CAIXA DO PADRÃO DE ENTRADA:</Typography>
      <Grid sx={{ mt: 1 }} container spacing={2} fullWidth>



        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <Grid item xs={12} sm={4}>
            <TextField
              id='type_cable'
              select
              label="Tipo de Cabo: *"
              value={props.typeCable}
              onChange={(e) => {
                document.getElementById(`type_cable`).style.border = 'transparent'

                props.setTypeCable(e.target.value)
              }}
              fullWidth
              size="small"
            >
              {
                optionsCableType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              }
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id='nominal_section'
              select
              label="Seção do Ramal de Conexão: *"
              value={props.nominalSection}
              onChange={(e) => {
                document.getElementById(`nominal_section`).style.border = 'transparent'

                props.setNominalSection(e.target.value)
              }}
              fullWidth
              size="small"
            >
              {
                optionsCable.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              }
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id='disjuntor'
              select
              label="Disjuntor: *"
              value={props.disjuntor}
              onChange={(e) => {
                document.getElementById(`disjuntor`).style.border = 'transparent'

                props.setDisjuntor(e.target.value)
              }}
              fullWidth
              size="small"
            >
              {props.serviceType === 'trifasico' ? disjuntorTrifasico.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
                : null
              }
              {props.serviceType === 'bifasico' && disjuntorBifasico.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              {props.serviceType === 'monofasico' && disjuntorMonofasico.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          {<Grid item xs={12} sm={12}>
            <Alert severity="info" sx={{}}>
              <Typography variant="subtitle2"
                onClick={(e) => {

                }}>
                Verificar se a caixa está de acordo com o padrão da Enel, não podendo estar danificada nem com a tubulação do ramal de entrada embutida na parede.
              </Typography>

            </Alert>
          </Grid>}


          {props.msgDisjuntor && <Grid item xs={12} sm={12}>
            <Alert severity="error" sx={{}}>
              <Typography variant="subtitle2" dangerouslySetInnerHTML={{ __html: props.msgDisjuntor }}
                onClick={(e) => {
                  if (e.target.id === "saiba-mais") {
                    setShowTable(true);
                  }
                }}></Typography>

            </Alert>
          </Grid>}
          {props.msgCabo && <Grid item xs={12} sm={12}>
            <Alert severity="error" sx={{}}>
              <Typography variant="subtitle2" dangerouslySetInnerHTML={{ __html: props.msgCabo }}
                onClick={(e) => {
                  if (e.target.id === "saiba-mais") {
                    setShowTable(true);
                  }
                }}></Typography>
            </Alert>
          </Grid>}
          {(props.typeCable && props.typeCable !== 'Não Informado' && props.nominalSection && props.nominalSection !== 'Não Informado' && props.disjuntor && props.disjuntor !== '0' && !props.msgCabo) && <Grid item xs={12} sm={12}>
            <Alert severity="info" sx={{}}>
              <Typography variant="subtitle2" dangerouslySetInnerHTML={{ __html: msgPadrao }}
                onClick={(e) => {
                  if (e.target.id === "saiba-mais") {
                    setShowTable(true);
                  }
                }}></Typography>
            </Alert>
          </Grid>}

        </Grid>


        {/*       <Grid item xs={12} sm={6}>
<RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    name="service"
    defaultValue={'monofasico'}
    value={props.serviceType}
    onChange={handleChangeServiceType}
    row={true}
  ><Typography sx={{mt:1,mr:1}}>Atendimento:</Typography>
    <FormControlLabel value="monofasico" control={<Radio />} label="Monofásico" />
    <FormControlLabel value="bifasico" control={<Radio />} label="Bifásico" />
    <FormControlLabel value="trifasico" control={<Radio />} label="Trifásico" />  
  </RadioGroup>
</Grid>*/}




        <Grid item xs={12} sm={6}>
          <RadioGroup
            id='rateio'
            aria-labelledby="demo-radio-buttons-group-label"
            name="rateio"
            defaultValue={'geracao_local'}
            value={props.modalityType}
            onChange={(e) => {
              document.getElementById(`rateio`).style.border = 'transparent'

              handleChangeRateio(e)
            }}
            row={true}
          ><Typography sx={{ mt: 1, mr: 1 }}>Rateio:</Typography>
            <FormControlLabel value="geracao_local" control={<Radio />} label="Não" />
            <FormControlLabel value="autoconsumo_remoto" control={<Radio />} label="Sim" />
          </RadioGroup>
        </Grid>

        {/*<Grid item xs={12} sm={6}>
        <TextField
        id='modality-type'
          select
          label="Modalidade:"
          value={props.modalityType}
          onChange={(e) => props.setModalityType(e.target.value)}
          fullWidth
           size="small"
        >
          {options4.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>*/}
        <Grid item xs={12} sm={6}>
          <RadioGroup
            id='branch-type'
            aria-labelledby="demo-radio-buttons-group-label"
            name="branch"
            defaultValue={'aereo'}
            value={props.branchType}
            onChange={(e) => {
              document.getElementById(`branch-type`).style.border = 'transparent'

              handleChangeBranchType(e)
            }}
            row={true}
          ><Typography sx={{ mt: 1, mr: 1 }}>Tipo de Ramal:</Typography>
            <FormControlLabel value="aereo" control={<Radio />} label="Aéreo" />
            <FormControlLabel value="subterraneo" control={<Radio />} label="Subterrâneo" />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={12}>
          {/*<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',mt: 20,mb:2, width: '100%'}}>
    <Typography  sx={{ mt: 3, mb: 3 }}variant="h5">Solicitar Rateio</Typography>
      <Switch
checked={props.checked}
onChange={handleChange}
inputProps={{ 'aria-label': 'controlled'   }}
/>
    </Box>  */}


          <Collapse in={props.modalityType === 'autoconsumo_remoto' ? true : false} timeout="auto" unmountOnExit>
            <div>
              <Alert severity="info">
                <ListItemText primary='Informações de Rateio' secondary='O resultado das porcentagens precia ser 100%' />
              </Alert>


              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={'write'}
                name="radio-buttons-group"
                onChange={handleChangeRateioType}
                sx={{ mt: 2, flexDirection: 'row', justifyContent: 'center' }}
              >
                <FormControlLabel value="write" control={<Radio />} label="Digitar Porcentagem" />
                <FormControlLabel value="calc" control={<Radio />} label="Calcular Porcentagem Por kWh" />
              </RadioGroup>

              {
                props.rateioType === 'write'
                  ?
                  <>
                    <Box sx={{ p: 3 }}>
                      {props.writeRateio.map((rateio, index) => (
                        <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                          <TextField
                            size='small'
                            label="UC"
                            name="uc"
                            value={rateio.uc}
                            onChange={(e) => handleChangeWrite(index, e)}
                          />
                          <TextField
                            size='small'
                            label="Porcentagem"
                            name="percentage"
                            value={rateio.percentage}
                            onChange={(e) => handleChangeWrite(index, e)}
                            type="number"
                          />
                          {
                            index !== 0 ?
                              <IconButton color="error" onClick={() => handleRemoveRateio(index)}>
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                              :
                              <>
                                <IconButton variant="outlined" onClick={handleAddRateio}>
                                  <AddCircleOutlineIcon />
                                </IconButton>
                                <Button variant="contained" color="success" onClick={handleCalculate} sx={{ ml: 2 }}>
                                  Calcular E salvar
                                </Button>
                              </>
                          }
                        </Box>
                      ))}


                      <Typography
                         variant="sans-serif"
                        sx={{
                          color: props.resultWriteRateio === 100 ? 'green' : 'red',
                          mt: 2,
                        }}
                      >
                        RESULTADO: {props.resultWriteRateio}%
                      </Typography>
                      {errorMessageRateio && (
                        <Typography color="error" variant="body2">
                          {errorMessageRateio}
                        </Typography>
                      )}
                    </Box>
                  </>
                  :
                  <>  
                  <br />
                  <Button onClick={handleAddKwh} variant='contained' color='primary' autoFocus>Adicionar Unidade Cosumidora (UC)</Button>
                  <Button onClick={handleSubmitRateio} variant='contained' color='success' sx={{ ml: 1 }}>Calcular e Salvar</Button>
                  <Button onClick={handleClear} variant='contained' color='error' sx={{ ml: 1 }}>Limpar</Button>
                  <br />
                    {props.ucs?.map((uc, index) => (
                      <Grid container key={index} spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={3}>
                          <TextField size='small' label={`UC ${index + 1}`} value={uc} onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                            const newUcs = [...props.ucs];
                            newUcs[index] = e.target.value;
                            props.setUcs(newUcs);
                          }} />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField size='small' label={`kWh ${index + 1}`} type="number" value={props.kwhValues[index]} onChange={(e) => {
                            const newKwhValues = [...props.kwhValues];
                            newKwhValues[index] = e.target.value;
                            props.setKwhValues(newKwhValues);
                          }} />
                        </Grid>
                        <Grid item xs={3}>
                          <Button onClick={() => handleRemoveKwh(index)}> <RemoveCircleOutlineIcon color='error' /></Button>
                        </Grid>
                      </Grid>
                    ))}
                    <br />
                 

                    {props.percentages?.map((percentage, index) => (
                      <Typography key={index}>
                        kWh {index + 1} - UC: {props.ucs[index]} - Porcentagem: {percentage}%
                      </Typography>
                    ))}
                    <br/>
                     <Typography
                        variant="sans-serif"
                        sx={{
                          color: parseFloat(props.resultPercentages) === 100 ? 'green' : 'red',
                          mt: 2,
                        }}
                      >
                        RESULTADO: {props.resultPercentages}%
                      </Typography>
                    
                  </>
              }

            </div>
          </Collapse>

        </Grid>
        <Grid sx={{ mt: 1 }} container spacing={2}>


          <Grid item xs={12} sm={12} >
            <TextField

              id='project-obs'
              label="Observações"
              value={props.projectObs}
              fullWidth
              size="small"
              onChange={(e) => props.setProjectObs(e.target.value)}
            />
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} sm={6} md={20}

      >
        <Typography variant="h6" sx={{ mt: 10 }}>ANEXOS <Typography variant="span" sx={{ fontSize: 15, color: '#0404048e' }}>(IMAGEM OU PDF)</Typography>:</Typography>

        <Box sx={{ display: 'flex', mt: 3 }}
          id='electricity-bill'
        >

          <Button component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Conta de Luz *
            <input type="file"
              accept='image/* , application/pdf'
              hidden onChange={
                (e) => {
                  document.getElementById(`electricity-bill`).style.border = 'transparent'

                  const file = e.target.files[0]
                  const formdata = new FormData()
                  formdata.append('electricity_bill', file)
                  props.setFileElectricityBill(formdata)
                  toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
                  setCLuz(file.name)
                }
              }

            />
            &nbsp;

          </Button>
          {
            props.file_electricity_bill && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 1 }} ><CheckCircleIcon fontSize="small" color='success' />&nbsp; <p>{cLuz}</p></Box>
          }
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} md={20}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Box sx={{ display: 'flex', mt: 2 }}>

          <Button component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Foto Disjuntor
            <input type="file"
              accept='image/* , application/pdf'
              hidden onChange={
                (e) => {
                  const file = e.target.files[0]
                  const formdata = new FormData()
                  formdata.append('photos_breaker', file)
                  props.setFileDisjuntor(formdata)
                  toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
                  setDisj(file.name)
                }
              } />

          </Button>
          {
            props.file_disjuntor && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 1 }} ><CheckCircleIcon fontSize="small" color='success' />&nbsp; <p>{disj}</p></Box>
          }
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} md={20}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Button component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Quadro do Medidor
            <input type="file"
              accept='image/* , application/pdf'
              hidden onChange={
                (e) => {
                  const file = e.target.files[0]
                  const formdata = new FormData()
                  formdata.append('energy_box_photos', file)
                  props.setFileEnergyBox(formdata)
                  toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
                  setCaixaE(file.name)
                }
              } />

          </Button>
          {
            props.file_energy_box && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 1 }} ><CheckCircleIcon fontSize="small" color='success' />&nbsp; <p>{caixaE}</p></Box>
          }
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} >
        <Grid item xs={12} sm={6} >

          <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>

            <Button component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Kit Fotovoltaico
              <input type="file"
                accept='image/* , application/pdf'
                hidden onChange={
                  (e) => {
                    const file = e.target.files[0]
                    const formdata = new FormData()
                    formdata.append('data_photovoltaic', file)
                    props.setImage(formdata)
                    toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
                    setKit(file.name)
                  }
                } />

            </Button>
            {
              props.image && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 1 }} ><CheckCircleIcon fontSize="small" color='success' />&nbsp; <p>{kit}</p></Box>
            }
          </Box>
        </Grid>
        <br />
        <Grid item xs={12} sm={6} >
          <TextField

            id='info-kit'
            label="Informações do Kit Fotovoltaico"
            value={props.info_kit}
            fullWidth
            size="small"
            onChange={(e) => props.setInfo_kit(e.target.value)}
          />
        </Grid>
        <Alert severity="info" sx={{ mt: 4, mb: 4 }}>*Obrigatório ou anexar a imagem da descrição do material do kit ou descrever no campo acima.</Alert>

      </Grid>
    </Grid>




  )
}

export default ProjectInformation
