import React from 'react'
import Sidebar from '../../Components/Sidebar'

const Home = () => {
  return (
    <Sidebar>
    <div>
      
    </div>
    </Sidebar>
  )
}

export default Home
