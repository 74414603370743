import { Alert, Box, Button, FormControlLabel, Grid, ListItemText, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import validator from 'validator';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Customers_Of_Customers_Create(props) {

    const token = localStorage.getItem('token')
    const tokenParse = JSON.parse(token)
    const id_customer_integrator = tokenParse.id
    const handleChange = (event) => {
        props.setTipoPessoa(event.target.value)
        props.clearFields()
        props.setCustomerCheck(false)
        props.setSelectedCustomer('')
    };
    const handleUcUser = (event) => {
        props.setUsuario_uc(event.target.value)
    };


    const doc_identify = [
        { label: 'RG', value: 'RG' },
        { label: 'CNH', value: 'CNH' },
        { label: 'Carteira de Identidade Nacional - (C.I.N.)', value: 'C.I.N.' },
        { label: 'Carteira de Identidade Profissional', value: 'Carteira de Identidade Profissional' },
        { label: 'Outro', value: 'Outro' },
    ];


    const civil_states = [
        { label: 'Não Informado', value: 'Não Informado' },
        { label: 'Solteiro(a)', value: 'Solteiro(a)' },
        { label: 'União Estável', value: 'União Estável' },
        { label: 'Casado(a)', value: 'Casado(a)' },
        { label: 'Divorciado(a)', value: 'Divorciado(a)' },
        { label: 'Viúvo(a)', value: 'Viúvo(a)' },
    ];





    const phone_format = (phone) => {
        phone = phone?.replace(/\D/g, "")
        phone = phone?.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")

        if (phone.length > 15) {
            phone = phone.substring(0, 14)
        }

        return phone
    }
    const cpf_format = (cpf) => {

        cpf = cpf?.replace(/\D/g, "")
        cpf = cpf?.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf?.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf?.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

        if (cpf.length > 14) {
            cpf = cpf.substring(0, 13)
        }

        return cpf
    }
    const cep_format = (cep) => {
        cep = cep?.replace(/\D/g, "")
        cep = cep?.replace(/(\d{5})(\d{3})/, "$1-$2")

        if (cep.length > 9) {
            cep = cep.substring(0, 8)
        }

        return cep
    }

    const cnpj_format = (cnpj) => {
        cnpj = cnpj?.replace(/\D/g, "")
        cnpj = cnpj?.replace(/(\d{2})(\d)/, "$1.$2")
        cnpj = cnpj?.replace(/(\d{3})(\d)/, "$1.$2")
        cnpj = cnpj?.replace(/(\d{3})(\d)/, "$1/$2")
        cnpj = cnpj?.replace(/(\d{4})(\d)/, "$1-$2")

        if (cnpj.length > 18) {
            cnpj = cnpj.substring(0, 17)
        }

        return cnpj
    }
    const handleCNPJ = async (cnpj) => {
        if (!cnpj) {

            return
        }
        const cnpjCorrect = cnpj?.replace(/[^\d]+/g, '');
        if (cnpjCorrect.length !== 14) {

            return
        }
        await fetch(`https://publica.cnpj.ws/cnpj/${cnpjCorrect}`)
            .then(response => response.json())
            .then(data => {

                if (data.status === 'ERROR' || data.status === 400 || data.detalhes === 'CNPJ inválido') {
                    props.setCnpj(cnpj)
                    props.setCorporate_reason('')
                    props.setFantasy_name('')
                    props.setEmail('')
                    props.setContact('')
                    props.setCep('')
                    props.setStreet('')
                    props.setNumber('')
                    props.setNeighborhood('')
                    props.setCity('')
                    props.setState('')
                    props.setComplement('')
                    props.setName_representative('')
                    props.setIdentify_representative('')
                    props.setTipoDocIdentifyRepresentative('')
                    props.setOutroTipoDocIdentifyRepresentative('')
                    props.setCpf_representative('')
                    props.setNationality_representative('')
                    props.setProfession_representative('')
                    props.setFile_identify('')
                    return
                }

                const cnpjformat = data.estabelecimento.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                const formattedCep = data.estabelecimento.cep?.replace(/(\d{5})(\d{3})/, "$1-$2")



                props.setFantasy_name(data.estabelecimento.nome_fantasia ? data.estabelecimento.nome_fantasia : '---')
                props.setCorporate_reason(data.razao_social)
                props.setCnpj(cnpjformat)
                props.setEmail(data.estabelecimento.email)
                props.setContact(`${data.estabelecimento.ddd1} ${data.estabelecimento.telefone1}`)
                props.setCep(formattedCep)
                props.setStreet(`${data.estabelecimento.tipo_logradouro} ${data.estabelecimento.logradouro}`)
                props.setNumber(data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N')
                props.setNeighborhood(data.estabelecimento.bairro)
                props.setCity(data.estabelecimento.cidade.nome)
                props.setState(data.estabelecimento.estado.sigla)
                props.setComplement(data.estabelecimento.complemento ? data.estabelecimento.complemento : '---')




                const _cnpj = [
                    data.razao_social,
                    cnpjformat,
                    data.estabelecimento.email,
                    data.estabelecimento.nome_fantasia,
                    data.estabelecimento.logradouro,
                    data.estabelecimento.numero ? data.estabelecimento.numero : 'S/N',
                    data.estabelecimento.complemento ? data.estabelecimento.complemento : '---',
                    data.estabelecimento.bairro,
                    data.estabelecimento.cidade.nome,
                    data.estabelecimento.estado.sigla,
                    formattedCep

                ]


            }).catch(err => {
                console.log(err)

            })
    }







    //nome arquivos
    const [name_file_identify, setNameFileIdentify] = useState('')
    const [name_file_social_contract, setNameFileSocialContract] = useState('')


    return (
        <Grid>
            <Alert severity="info">Preencha os campos abaixo corretamente.</Alert>


            <ListItemText sx={{ textAlign: 'center' }} primary="Titular da Conta de Energia" />
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={props.tipoPessoa}
                name="radio-buttons-group"
                onChange={handleChange}
                sx={{ mt: 2, flexDirection: 'row', justifyContent: 'center' }}
            >
                <FormControlLabel value="pf" control={<Radio />} label="Pessoa Física" />
                <FormControlLabel value="pj" control={<Radio />} label="Pessoa Jurídica" />
            </RadioGroup>
            <br />
            {/*props.tipoPessoa === 'pj'  &&       <>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={props.usuario_uc}
                name="radio-buttons-group"
                onChange={handleUcUser}
                sx={{ mt: 2, flexDirection: 'row', justifyContent: 'center' }}
            >
                <FormControlLabel value="cliente_terceiro" control={<Radio />} label="Cliente PJ" />
                <FormControlLabel value="representante_legal_terceiro" control={<Radio />} label="Representante Legal" />
            </RadioGroup>
</>*/}





            {props.tipoPessoa === 'pf' && (
                <Grid sx={{ mt: 1 }} container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='cpf'
                            label="CPF *"
                            variant="outlined"
                            name="cpf"
                            value={props.cpf}
                            onChange={(e) => {
                                document.getElementById('cpf').style.border = 'transparent'
                                e.target.value = e.target.value?.replace(/\D/g, "")
                                e.target.value = cpf_format(e.target.value)
                                props.setCpf(e.target.value)
                                if (e.target.value.length === 14) {
                                    fetch(`https://api.fortesol.com.br/api/customers_of_customers/search-by-cpf-where-integrator/${id_customer_integrator}?cpf=${e.target.value}`, {
                                        method: 'GET',
                                        headers: {
                                            'Content-Type': 'application/json',

                                        }
                                    })
                                        .then(response => response.json())
                                        .then(data => {


                                            if (data.message === 'Server error' || data.message === 'not found') {
                                                props.setName('')
                                                props.setIdentify('')
                                                props.setTipoDocIdentify('')
                                                props.setOutroTipoDocIdentify('')
                                                props.setCivil_state('')
                                                props.setNationality('')
                                                props.setProfession('')
                                                props.setEmail('')
                                                props.setContact('')
                                                props.setCep('')
                                                props.setStreet('')
                                                props.setNumber('')
                                                props.setNeighborhood('')
                                                props.setCity('')
                                                props.setState('')
                                                props.setComplement('')
                                                props.setCustomerCheck(false)
                                                props.setSelectedCustomer('')
                                                props.setOrigemDocIdentify('')
                                            } else {
                                                props.setCustomerCheck(true)
                                                props.searchByCpf(data.cpf_customers_of_customers)
                                                props.setName(data.name_customers_of_customers)
                                                props.setIdentify(data.identify_customers_of_customers)
                                                if (data.type_doc_identify_customers_of_customers !== 'RG' && data.type_doc_identify_customers_of_customers !== 'CNH' && data.type_doc_identify_customers_of_customers !== 'Carteira de Trabalho' && data.type_doc_identify_customers_of_customers !== 'Carteira de Identidade Profissional') {
                                                    props.setTipoDocIdentify('Outro')
                                                    props.setOutroTipoDocIdentify(data.type_doc_identify_customers_of_customers)
                                                } else {
                                                    props.setTipoDocIdentify(data.type_doc_identify_customers_of_customers)
                                                }
                                                props.setOrigemDocIdentify(data.origin_doc_identify_customers_of_customers)
                                                props.setCivil_state(data.civil_state_customers_of_customers)
                                                props.setNationality(data.nationality_customers_of_customers)
                                                props.setProfession(data.profession_customers_of_customers)
                                                props.setEmail(data.email_customers_of_customers)
                                                props.setContact(data.contact_customers_of_customers)
                                                props.setCep(data.cep_customers_of_customers)
                                                props.setStreet(data.street_customers_of_customers)
                                                props.setNumber(data.number_customers_of_customers)
                                                props.setNeighborhood(data.neighborhood_customers_of_customers)
                                                props.setCity(data.city_customers_of_customers)
                                                props.setState(data.state_customers_of_customers)
                                                props.setComplement(data.complement_customers_of_customers)
                                                props.setFile_identify(data.file_doc_identify)
                                            }
                                        })
                                }
                            }}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id='nome'
                            size="small"
                            label="Nome Completo *"
                            variant="outlined"
                            name="nome"
                            value={props.name}
                            onChange={(e) => {
                                document.getElementById('nome').style.border = 'transparent'
                                props.setName(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >

                        <Grid item xs={12} sm={6} >
                            <TextField
                                fullWidth
                                select
                                id='tipo_identidade'
                                size="small"
                                label="Tipo de Documneto de Identidade *"
                                variant="outlined"
                                name="tipo_identidade"
                                value={props.tipoDocIdentify}
                                onChange={(e) => {
                                    document.getElementById('tipo_identidade').style.border = 'transparent'
                                    props.setFile_identify('')

                                    props.setIdentify('')
                                    props.setOrigemDocIdentify('')
                                    props.setCustomerCheck(false)
                                    document.getElementById('identidade').value = ''
                                    if (e.target.value === 'Outro') {
                                        props.setOutroTipoDocIdentify('')
                                    }
                                    if (e.target.value === 'C.I.N.') {
                                        props.setIdentify(props.cpf)
                                        document.getElementById('identidade').value = props.cpf
                                    }
                                    props.setTipoDocIdentify(e.target.value)
                                }}
                            >
                                {
                                    doc_identify.map((option) => (
                                        <MenuItem key={option?.value} value={option?.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        {props.tipoDocIdentify === 'Outro' && <Grid item xs={12} sm={6} >
                            <TextField
                                fullWidth
                                id='desc_identidade'
                                size="small"
                                label="Qual?"
                                variant="outlined"
                                name="desc_identidade"
                                value={props.outroTipoDocIdentify}
                                onChange={(e) => {
                                    document.getElementById('desc_identidade').style.border = 'transparent'
                                    
                                    props.setOutroTipoDocIdentify(e.target.value)
                                }}
                            />
                        </Grid>}
                        <Grid item xs={12} sm={6} >
                            <TextField
                                fullWidth

                                id='identidade'
                                size="small"
                                label="N° Documento *"
                                variant="outlined"
                                name="identidade"
                                value={props.identify}
                                onChange={(e) => {
                                    document.getElementById('identidade').style.border = 'transparent'
                                    e.target.value = e.target.value?.replace(/\D/g, "")
                                    props.setIdentify(e.target.value)
                                }}
                            />
                        </Grid>
                        {props.tipoDocIdentify === 'RG' && <Grid item xs={12} sm={6} >
                            <TextField
                                fullWidth

                                id='origem_doc'
                                size="small"
                                label="Orgão Emissor *: Ex: SSP-CE"
                                variant="outlined"
                                name="origem_doc"
                                value={props.origemDocIdentify}
                                onChange={(e) => {
                                    document.getElementById('origem_doc').style.border = 'transparent'
                                    e.target.value = e.target.value.toUpperCase()
                                    props.setOrigemDocIdentify(e.target.value)
                                }}
                            />
                        </Grid>}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            id='estadoCivil'
                            label="Estado Civil *"
                            variant="outlined"
                            name="estadoCivil"
                            value={props.civil_state}
                            onChange={(e) => {
                                document.getElementById('estadoCivil').style.border = 'transparent'
                                props.setCivil_state(e.target.value)}}
                        >
                            {civil_states.map((option) => (
                                <MenuItem key={option?.value} value={option?.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='nacionalidade'
                            label="Nacionalidade *"
                            variant="outlined"
                            name="nacionalidade"
                            value={props.nationality}
                            onChange={(e) =>{
                                document.getElementById('nacionalidade').style.border = 'transparent'
                                props.setNationality(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='profissao'
                            label="Profissão *"
                            variant="outlined"
                            name="profissao"
                            value={props.profession}
                            onChange={(e) => {
                                document.getElementById('profissao').style.border = 'transparent'
                                props.setProfession(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='email_pf'
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={props.email}
                            onChange={(e) => {
                                
                                e.target.value = e.target.value.toLowerCase()
                                if (e.target.value.includes(' ')) {
                                    e.target.value = e.target.value?.replace(/\s/g, '')
                                }

                                props.setEmail(e.target.value)
                                if (!e.target.value.includes('@')) {
                                    document.getElementById('email_pf').style.border = '2px solid red'
                                } else {
                                    document.getElementById('email_pf').style.border = 'transparent'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='contato_pf'
                            label="Contato"
                            variant="outlined"
                            name="contato"
                            value={props.contact}
                            onChange={(e) => {

                                e.target.value = phone_format(e.target.value)
                                props.setContact(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='cep_pf'
                            label="CEP *"
                            variant="outlined"
                            name="cep"
                            value={props.cep}
                            onChange={(e) => {
                                document.getElementById('cep_pf').style.border = 'transparent'
                                e.target.value = cep_format(e.target.value)
                                props.setCep(e.target.value)
                                if (e.target.value.length === 9) {
                                    fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.erro) {

                                                return
                                            }
                                            props.setStreet(data.logradouro)
                                            props.setNeighborhood(data.bairro)
                                            props.setCity(data.localidade)
                                            props.setState(data.uf)
                                        })
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='logradouro_pf'
                            label="Logradouro *"
                            variant="outlined"
                            name="logradouro"
                            value={props.street}
                            onChange={(e) => {
                                document.getElementById('logradouro_pf').style.border = 'transparent'
                                props.setStreet(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='numero_pf'
                            label="Número"
                            variant="outlined"
                            name="numero"
                            value={props.number}
                            onChange={(e) => {
                                document.getElementById('numero_pf').style.border = 'transparent'
                                e.target.value = e.target.value?.replace(/\D/g, "")
                                props.setNumber(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='complemento_pf'
                            label="Complemento"
                            variant="outlined"
                            name="complemento"
                            value={props.complement}
                            onChange={(e) => props.setComplement(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='bairro_pf'
                            label="Bairro *"
                            variant="outlined"
                            name="bairro"
                            value={props.neighborhood}
                            onChange={(e) => {
                                document.getElementById('bairro_pf').style.border = 'transparent'
                                props.setNeighborhood(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='cidade_pf'
                            label="Cidade *"
                            variant="outlined"
                            name="cidade"
                            value={props.city}
                            onChange={(e) => {
                                document.getElementById('cidade_pf').style.border = 'transparent'
                                props.setCity(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id='estado_pf'
                            label="Estado *"
                            variant="outlined"
                            name="estado"
                            value={props.state}
                            onChange={(e) =>{
                                document.getElementById('estado_pf').style.border = 'transparent'
                                props.setState(e.target.value)}}
                        />
                    </Grid>

                </Grid>
            )}

            {props.tipoPessoa === 'pj' && (
                <>
                    <Grid sx={{ mt: 1 }} container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='cnpj'
                                label="CNPJ *"
                                variant="outlined"
                                name="cnpj"
                                value={props.cnpj}
                                onChange={(e) => {
                                    document.getElementById('cnpj').style.border = 'transparent'
                                    e.target.value = cnpj_format(e.target.value)
                                    props.setCnpj(e.target.value)
                                    if (e.target.value.length === 18) {
                                        fetch(`https://api.fortesol.com.br/api/customers_of_customers/search-by-cnpj-where-integrator/${id_customer_integrator}?cnpj=${e.target.value}`, {
                                            method: 'GET',
                                            headers: {
                                                'Content-Type': 'application/json',

                                            }
                                        })
                                            .then(response => response.json())
                                            .then(data => {

                                                if (data.message === 'Server error' || data.message === 'not found') {
                                                    handleCNPJ(e.target.value)
                                                    props.setCustomerCheck(false)
                                                    props.setSelectedCustomer('')
                                                    props.setName_representative('')
                                                    props.setIdentify_representative('')
                                                    props.setCpf_representative('')
                                                    props.setNationality_representative('')
                                                } else {
                                                    props.setCustomerCheck(true)
                                                    props.searchByCnpj(data.cnpj_customers_of_customers)
                                                    props.setCnpj(data.cnpj_customers_of_customers)
                                                    props.setCorporate_reason(data.corporate_reason_customers_of_customers)
                                                    props.setFantasy_name(data.fantasy_name_customers_of_customers)
                                                    props.setEmail(data.email_customers_of_customers)
                                                    props.setContact(data.contact_customers_of_customers)
                                                    props.setCep(data.cep_customers_of_customers)
                                                    props.setStreet(data.street_customers_of_customers)
                                                    props.setNumber(data.number_customers_of_customers)
                                                    props.setNeighborhood(data.neighborhood_customers_of_customers)
                                                    props.setCity(data.city_customers_of_customers)
                                                    props.setState(data.state_customers_of_customers)
                                                    props.setComplement(data.complement_customers_of_customers)
                                                    //   props.setFile_identify(data.file_rg_cnh_customers_of_customers)
                                                    props.setFile_social_contract(data.file_social_contract_customers_of_customers)
                                                    props.setName_representative(data.name_representative_customers_of_customers)
                                                    props.setIdentify_representative(data.identify_representative_customers_of_customers)
                                                props.setFile_identify(data.file_doc_identify)
                                                props.setFile_social_contract(data.file_contract_social)
                                                    
                                                    if (data.type_doc_identify_representative_customers_of_customers !== 'RG' && data.type_doc_identify_representative_customers_of_customers !== 'CNH' && data.type_doc_identify_representative_customers_of_customers !== 'Carteira de Trabalho' && data.type_doc_identify_representative_customers_of_customers !== 'Carteira de Identidade Profissional') {
                                                        props.setTipoDocIdentifyRepresentative('Outro')
                                                        props.setOutroTipoDocIdentifyRepresentative(data.type_doc_identify_representative_customers_of_customers)
                                                    } else {
                                                        props.setTipoDocIdentifyRepresentative(data.type_doc_identify_representative_customers_of_customers)
                                                    }
                                                    props.setCpf_representative(data.cpf_representative_customers_of_customers)
                                                    props.setNationality_representative(data.nationality_representative_customers_of_customers)
                                                    props.setProfession_representative(data.profession_representative_customers_of_customers)

                                                }

                                            })


                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='razao_social'
                                label="Razão Social *"
                                variant="outlined"
                                name="razaoSocial"
                                value={props.corporate_reason}
                                onChange={(e) => {
                                    document.getElementById('razao_social').style.border = 'transparent'
                                    props.setCorporate_reason(e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='nome_fantasia'
                                label="Nome Fantasia"
                                variant="outlined"
                                name="nomeFantasia"
                                value={props.fantasy_name}
                                onChange={(e) => props.setFantasy_name(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='email_pj'
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={props.email}
                                onChange={(e) => {
                                    e.target.value = e.target.value.toLowerCase()
                                    if (e.target.value.includes(' ')) {
                                        e.target.value = e.target.value?.replace(/\s/g, '')
                                    }
                                    props.setEmail(e.target.value)
                                    if (!e.target.value.includes('@')) {
                                        document.getElementById('email_pj').style.border = '2px solid red'
                                    } else {
                                        document.getElementById('email_pj').style.border = 'transparent'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='contato_pj'
                                label="Contato"
                                variant="outlined"
                                name="contato"
                                value={props.contact}
                                onChange={(e) => {
                                    e.target.value = phone_format(e.target.value)
                                    props.setContact(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='cep_pj'
                                label="CEP *"
                                variant="outlined"
                                name="cep"
                                value={props.cep}
                                onChange={(e) => {
                                    document.getElementById('cep_pj').style.border = 'transparent'
                                    e.target.value = cep_format(e.target.value)
                                    props.setCep(e.target.value)
                                    if (e.target.value.length === 9) {
                                        fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
                                            .then(response => response.json())
                                            .then(data => {
                                                if (data.erro) {

                                                    return
                                                }
                                                props.setStreet(data.logradouro)
                                                props.setNeighborhood(data.bairro)
                                                props.setCity(data.localidade)
                                                props.setState(data.uf)
                                            })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='logradouro_pj'
                                label="Logradouro *"
                                variant="outlined"
                                name="logradouro"
                                value={props.street}
                                onChange={(e) =>{
                                    document.getElementById('logradouro_pj').style.border = 'transparent'
                                    props.setStreet(e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='numero_pj'
                                label="Número *"
                                variant="outlined"
                                name="numero"
                                value={props.number}
                                onChange={(e) => {
                                    document.getElementById('numero_pj').style.border = 'transparent'
                                    e.target.value = e.target.value?.replace(/\D/g, "")
                                    props.setNumber(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='complemento_pj'
                                label="Complemento"
                                variant="outlined"
                                name="complemento"
                                value={props.complement}
                                onChange={(e) => props.setComplement(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='bairro_pj'
                                label="Bairro *"
                                variant="outlined"
                                name="bairro"
                                value={props.neighborhood}
                                onChange={(e) => {
                                    document.getElementById('bairro_pj').style.border = 'transparent'
                                    props.setNeighborhood(e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='cidade_pj'
                                label="Cidade *"
                                variant="outlined"
                                name="cidade"
                                value={props.city}
                                onChange={(e) => {
                                    document.getElementById('cidade_pj').style.border = 'transparent'
                                    props.setCity(e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='estado_pj'
                                label="Estado *"
                                variant="outlined"
                                name="estado"
                                value={props.state}
                                onChange={(e) => {
                                    document.getElementById('estado_pj').style.border = 'transparent'
                                    props.setState(e.target.value)}}
                            />
                        </Grid>

                    </Grid>
                    <br />
                    <Alert severity="info">Representante Legal</Alert>

                    <Grid sx={{ mt: 1 }} container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='cpf_representante'
                                label="CPF *"
                                variant="outlined"
                                name="cpf"
                                value={props.cpf_representative}
                                onChange={(e) => {
                                    document.getElementById('cpf_representante').style.border = 'transparent'
                                    e.target.value = e.target.value?.replace(/\D/g, "")
                                    e.target.value = cpf_format(e.target.value)
                                    props.setCpf_representative(e.target.value)
                                    if (e.target.value.length === 14) {
                                        fetch(`https://api.fortesol.com.br/api/customers_of_customers/search-by-cpf-where-integrator/${id_customer_integrator}?cpf=${e.target.value}`, {
                                            method: 'GET',
                                            headers: {
                                                'Content-Type': 'application/json',

                                            }
                                        })
                                            .then(response => response.json())
                                            .then(data => {
                                                if (data.message === 'Server error' || data.message === 'not found') {
                                                    props.setName_representative('')
                                                    props.setIdentify_representative('')
                                                    props.setNationality_representative('')
                                                    props.setProfession_representative('')
                                                } else {

                                                    props.setName_representative(data.name_customers_of_customers)
                                                    props.setIdentify_representative(data.identify_customers_of_customers)
                                                    props.setNationality_representative(data.nationality_customers_of_customers)
                                                    props.setProfession_representative(data.profession_customers_of_customers)

                                                }
                                            })
                                    }
                                }}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='nome_representante'
                                label="Nome *"
                                variant="outlined"
                                name="nomeRepresentante"
                                value={props.name_representative}
                                onChange={(e) =>{
                                    document.getElementById('nome_representante').style.border = 'transparent'
                                    props.setName_representative(e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >

                            <Grid item xs={12} sm={6} >
                                <TextField
                                    fullWidth
                                    select
                                    id='tipo_identidade'
                                    size="small"
                                    label="Tipo de Documneto de Identidade"
                                    variant="outlined"
                                    name="tipo_identidade"
                                    value={props.tipoDocIdentifyRepresentative}
                                    onChange={(e) => {
                                        props.setFile_identify('')
                                        props.setFile_social_contract('')
                                        props.setIdentify_representative('')
                                        props.setOrigemDocIdentifyRepresentative('')
                                        props.setTipoDocIdentifyRepresentative(e.target.value)
                                    }}
                                >
                                    {
                                        doc_identify.map((option) => (
                                            <MenuItem key={option?.value} value={option?.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            {props.tipoDocIdentifyRepresentative === 'Outro' && <Grid item xs={12} sm={6} >
                                <TextField
                                    fullWidth
                                    id='desc_identidade'
                                    size="small"
                                    label="Qual?"
                                    variant="outlined"
                                    name="desc_identidade"
                                    value={props.outroTipoDocIdentifyRepresentative}
                                    onChange={(e) => {
                                        document.getElementById('desc_identidade').style.border = 'transparent'
                                        props.setOutroTipoDocIdentifyRepresentative(e.target.value)
                                    }}
                                />
                            </Grid>}
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    fullWidth

                                    id='identidade'
                                    size="small"
                                    label="N° Documento"
                                    variant="outlined"
                                    name="identidade"
                                    value={props.identify_representative}
                                    onChange={(e) => {
                                        e.target.value = e.target.value?.replace(/\D/g, "")
                                        props.setIdentify_representative(e.target.value)
                                    }}
                                />
                            </Grid>
                            {props.tipoDocIdentifyRepresentative === 'RG' && <Grid item xs={12} sm={6} >
                            <TextField
                                fullWidth

                                id='origem_doc_representante'
                                size="small"
                                label="Orgão Emissor : Ex: SSP-CE"
                                variant="outlined"
                                name="origem_doc_representante"
                                value={props.origemDocIdentifyRepresentative}
                                onChange={(e) => {
                                    document.getElementById('origem_doc_representante').style.border = 'transparent'
                                    e.target.value = e.target.value.toUpperCase()
                                    props.setOrigemDocIdentifyRepresentative(e.target.value)
                                }}
                            />
                              </Grid>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='nacionalidade_representante'
                                label="Nacionalidade"
                                variant="outlined"
                                name="nacionalidade"
                                value={props.nationality_representative}
                                onChange={(e) => props.setNationality_representative(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                size="small"
                                id='profissao_representante'
                                label="Profissão"
                                variant="outlined"
                                name="profissao"
                                value={props.profession_representative}
                                onChange={(e) => props.setProfession_representative(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            <br />
            <Grid item xs={12} sm={4} md={20}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <Button component="label"
                    variant="contained"
                    disabled={props.identify_representative ? true : false}
                    sx={{ mr: 2 }}
                    startIcon={<CloudUploadIcon />}
                >
                    doc   identidade
                    <input type="file"
                        accept='image/* , application/pdf'
                        hidden onChange={
                            (e) => {
                                const file = e.target.files[0]
                                const formdata = new FormData()
                                formdata.append('identify', file)
                                props.setFile_identify(formdata)
                                toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
                                setNameFileIdentify(file.name)
                            }
                        } />
      
                </Button>
                {props.file_identify && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 1 }}><CheckCircleIcon fontSize="small" color='success' /> &nbsp;<p>{name_file_identify}</p></Box>}

            </Grid>
            <br />
            {props.tipoPessoa === 'pj' && <Grid item xs={12} sm={5} md={20}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <Button component="label"
                    variant="contained"
                    disabled={props.customerCheck}
                    sx={{ mr: 2 }}
                        startIcon={<CloudUploadIcon />}
                >
                    Contrato Social
                    <input type="file"
                        accept='image/* , application/pdf'
                        hidden onChange={
                            (e) => {
                                const file = e.target.files[0]
                                const formdata = new FormData()
                                formdata.append('contract_social', file)
                                props.setFile_social_contract(formdata)
                                toast.success(`Arquivo ${file.name} selecionado com sucesso!`)
                            }
                        } />
                   
                </Button>
                {props.file_social_contract && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 1 }}><CheckCircleIcon fontSize="small" color='success' /> &nbsp;<p>{name_file_social_contract}</p></Box>}
            </Grid>}

        </Grid>
    )
}
