import React from 'react'
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from
  '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, List, ListItem, ListItemText, Box, Icon, IconButton } from '@mui/material';
import { GoogleMap, StreetViewPanorama, useJsApiLoader, useLoadScript, Marker } from '@react-google-maps/api';
import Stepper_Project from '../stepper_project';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';
import DropDialog from '../popup_drop_dialog';
import LayersIcon from '@mui/icons-material/Layers';
import MapView from '../map_view';


function Popup_demand(props) {
  const token = localStorage.getItem('token')
  const tokenParse = JSON.parse(token)
  const typeUser = tokenParse.typeUser

  const [openMap, setOpenMap] = useState(false)

  const handleClickOpenMap = () => {
    setOpenMap(true)
  }
  const handleCloseMap = () => {
    setOpenMap(false)
  }

  const [openDrop, setOpenDrop] = useState(false)
  const [titleDrop, setTitleDrop] = useState('')
  const [msgDrop, setMsgDrop] = useState('')
  const [idDrop, setIdDrop] = useState('')

  const handleClickOpenDrop = () => {
    setTitleDrop('Cancelar Projeto')
    setMsgDrop('Deseja realmente cancelar o projeto?')
    setOpenDrop(true)
  }
  const handleCloseDrop = () => {
    setOpenDrop(false)
  }
  const handle_cancel_demand = () => {
    fetch(`https://api.fortesol.com.br/api/demand_project_solar/update-status-demand/${props.data.id_demand}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({status:'Cancelamento Solicitado'})
    }).then((response) => response.json())
      .then((data) => {
    document.getElementById('bt-all')?.click()
    setOpenDrop(false)
    handleCloseDrop()
    toast.success('Solicitação de cancelamento enviada.')
        fetch(`https://api.fortesol.com.br/api/notifications/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customers_id: parseInt(tokenParse.id),
            title: 'Cancelamento de Projeto',
            message: `Solicitação de cancelamento do projeto cod: ${props.data.id_demand}.`,
            view: false,
            view_date: '',
            //brasilian format
            creation_date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
            send_by: tokenParse.name,
            send_to: 'system',
            id_demand_notifications: props.data.id_demand,
          }),
        }).then((response) => response.json())
          .then((data) => {})
      
    props.handleClose()
  })}



  const [boxPro, setBoxPro] = useState(false)


  const downloadDocProxyDemand = async () => {
    setBoxPro(true)


    const conteudo = document.querySelector('.body-procuracao')
    const pdf = conteudo
    const opt = {
      margin: 0,
      filename: `Procuração Particular - ${props.data.name_customers_of_customers ? props.data.name_customers_of_customers : props.data.corporate_reason_customers_of_customers}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }
    html2pdf().from(pdf).set(opt).save().then(() => {
      toast.success('Procuração Particular gerada com sucesso', { autoClose: 2000 })
      setBoxPro(false)
    }).catch((error) => {
      toast.error('Erro ao gerar Procuração Particular', { autoClose: 2000 })
      setBoxPro(false)
    })

  }

  const handleDateValid = () => {
    var data = new Date()
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (dia < 10) {
      dia = `0${dia}`
    }
    if (mes === 1) {
      mes = 'Janeiro'
    }
    if (mes === 2) {
      mes = 'Fevereiro'
    }
    if (mes === 3) {
      mes = 'Março'
    }
    if (mes === 4) {
      mes = 'Abril'
    }
    if (mes === 5) {
      mes = 'Maio'
    }
    if (mes === 6) {
      mes = 'Junho'
    }
    if (mes === 7) {
      mes = 'Julho'
    }
    if (mes === 8) {
      mes = 'Agosto'
    }
    if (mes === 9) {
      mes = 'Setembro'
    }
    if (mes === 10) {
      mes = 'Outubro'
    }
    if (mes === 11) {
      mes = 'Novembro'
    }
    if (mes === 12) {
      mes = 'Dezembro'
    }
    var data = `${dia} de ${mes} de ${ano + 1}`
    return data
  }
  const handleDate = () => {
    var data = new Date()
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (dia < 10) {
      dia = `0${dia}`
    }
    if (mes === 1) {
      mes = 'Janeiro'
    }
    if (mes === 2) {
      mes = 'Fevereiro'
    }
    if (mes === 3) {
      mes = 'Março'
    }
    if (mes === 4) {
      mes = 'Abril'
    }
    if (mes === 5) {
      mes = 'Maio'
    }
    if (mes === 6) {
      mes = 'Junho'
    }
    if (mes === 7) {
      mes = 'Julho'
    }
    if (mes === 8) {
      mes = 'Agosto'
    }
    if (mes === 9) {
      mes = 'Setembro'
    }
    if (mes === 10) {
      mes = 'Outubro'
    }
    if (mes === 11) {
      mes = 'Novembro'
    }
    if (mes === 12) {
      mes = 'Dezembro'
    }

    var data = `${dia} de ${mes} de ${ano}`
    return data
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAMjFIIlVgRO1yUsl7uLN0RXBMyDHMJUS8",
  })

  const center = {
    lat: parseFloat(props.data.lat_demand),
    lng: parseFloat(props.data.log_demand),
  }
  const mapContainerStyle = {
    width: '250px',
    height:
      '190px',
  };
  const options = {
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: true,
    scaleControl: true,
    rotateControl: false,
    fullscreenControl: true,
    mapTypeId: 'satellite',
    tilt: 0,
    zoom: 18,
  }
  const options2 = {
    disableDefaultUI: false,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    fullscreenControl: true,
    mapTypeId: 'satellite',
    streetViewControl: false,
    draggable: true,
    inclination: 0,
    heading: 0,
    tilt: 0,
    zoom: 19,
  }
  const [open, setOpen] = React.useState(props.open);

  const project_type = (project) => {
    if (project === 'b1_residencial') return 'B1 - Residencial'
    if (project === 'b2_rural') return 'B2 - Rural'
    if (project === 'b2_cooperativa') return 'B2 - Cooperativa'
    if (project === 'b2_servico_publico_de_irrigaca') return 'B2 - Serviço Público de Irrigação'
    if (project === 'b3_demais_classes') return 'B3 - Demais Classes'
    if (project === 'a4_horaria_verde') return 'A4 - Horária Verde'
    if (project === 'a4_horaria_azul') return 'A4 - Horária Azul'
  }
  const service_type = (service) => {
    if (service === 'monofasico') return 'Monofásico'
    if (service === 'bifasico') return 'Bifásico'
    if (service === 'trifasico') return 'Trifásico'
  }
  const branch_type = (branch) => {
    if (branch === 'aereo') return 'Aéreo'
    if (branch === 'subterraneo') return 'Subterrâneo'
  }
  const modality_type = (modality) => {
    if (modality === 'geracao_local') return 'Geração Local'
    else return 'Autoconsumo Remoto'
  }

  if (!props.data) {
    return (<div></div>)
  }

  //Passando de JSON para Array
  let arrayModules = []
  let arrayInverters = []
  let array_qgbt_demand = []
  if (props.data?.info_inverter_demand) {
 
    arrayInverters = JSON.parse(props.data?.info_inverter_demand)
    array_qgbt_demand = JSON.parse(props.data?.array_qgbt_demand)
  }




  //Baixar Procuração


  const informations = {
    cnpj: props.data.cnpj_customers_of_customers,
    name: props.data.cpf_customers_of_customers ? props.data.name_customers_of_customers : props.data.corporate_reason_customers_of_customers,
    cpf: props.data.cpf_customers_of_customers,
    profession: props.data.profession_customers_of_customers,
    identify: props.data.identify_customers_of_customers,
    type_identify: props.data.type_doc_identify_customers_of_customers,
    ssp: props.data.type_doc_identify_customers_of_customers === 'RG' ? 'SSP-CE': '',
    street: props.data.street_customers_of_customers,
    number: props.data.number_customers_of_customers,
    cpmt: props.data.complement_customers_of_customers,
    district: props.data.neighborhood_customers_of_customers,
    city: props.data.city_customers_of_customers,
    state: props.data.state_customers_of_customers,
    cep: props.data.cep_customers_of_customers,
    name_r: props.data.name_representative_customers_of_customers,
    cpf_r: props.data.cpf_representative_customers_of_customers,
    identify_r: props.data.identify_representative_customers_of_customers,
    type_identify_r: props.data.type_doc_identify_representative_customers_of_customers,
    spp_r: props.data.type_doc_identify_representative_customers_of_customers === 'RG' ? 'SSP-CE': '',
    profession_r: props.data.profession_representative_customers_of_customers,
    nationality_r: props.data.nationality_representative_customers_of_customers,
    validity: handleDateValid(),
    date: handleDate()
  };


  const handleDownloadWord = async () => {
    try {
      // Faz a requisição para o backend
      const response = await fetch(`https://api.fortesol.com.br/api/demand_project_solar/generate-procuration_word`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(informations), // Envia os dados para o backend
      });

      // Verifica se a resposta está ok
      if (!response.ok) {
        throw new Error('Erro ao gerar o documento');
      }

      // Converte a resposta para Blob (arquivo binário)
      const blob = await response.blob();

      // Cria um link temporário para download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;

      // Define o nome do arquivo para download
      const contentDisposition = response.headers.get('Content-Disposition');
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
        : `procuracao_${informations.name}.docx`;

      link.setAttribute('download', fileName);

      // Simula o clique no link para iniciar o download
      document.body.appendChild(link);
      link.click();

      // Remove o link após o download
      link.parentNode.removeChild(link);
      props.handleClick()
    } catch (error) {
      console.error('Erro ao baixar o documento:', error);
    }
  };



  const handleDownloadPDF = async () => {
    try {
      const response = await fetch(`https://api.fortesol.com.br/api/demand_project_solar/generate-procuration_pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(informations),
      });

      if (response.ok) {
        // Cria um Blob a partir da resposta
        const blob = await response.blob();

        const url = window.URL.createObjectURL(new Blob([blob]));


        const a = document.createElement('a');
        a.href = url;
        a.download = `procuracao_${informations.name}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url); // Limpa o objeto URL
        props.handleClick()
      } else {
        console.error('Erro ao gerar o PDF:', await response.json());

      }
    } catch (error) {
      console.error('Erro ao enviar a requisição:', error);

    }
  };


const downloads = async (url, name) => {

  const response = fetch(`https://api.fortesol.com.br/api/demand_project_solar/download/${url}/${props.data.id_demand}`, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
}).then((response) => response.blob())
    .then((blob) => {
        const typeFile = (blob.type.split('/')[1])
        if (typeFile === 'json') return
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.${typeFile}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        
    }).catch((error) => {
        console.log(error)
    })
  }



  
  return (
    <div >

      <Dialog
        open={props.open}
        onClose={props.handleClose}

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='1200px'
        sx={{ ml: 5 }}
      >
        <DialogTitle id="alert-dialog-title">{"Detalhes do Projeto"}</DialogTitle>
        <DialogContent>
        <DropDialog openDrop={openDrop} handleCloseDrop={handleCloseDrop} title={titleDrop} msg={msgDrop} delete={handle_cancel_demand} />
        <MapView   open={openMap} close={handleCloseMap} data={props.data} title='Localização do Projeto' />
              {
              //<Stepper_Project />
              }
            <Grid container spacing={2} gap={2} pl={2} pt={2}>

              <Grid item xs={12}>
                <h3 style={{ fontSize: 14, marginBottom: 10, marginTop: 5 }}>Documentos:</h3>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ background: typeUser === 'Cliente' ? '#000' : '#02557e' }}>
                        <TableCell sx={{ p: '5px', color: 'white' }} align="center">Procuração</TableCell>
                        <TableCell sx={{ p: '5px', color: 'white' }} align="center">Art</TableCell>
                        <TableCell sx={{ p: '5px', color: 'white' }} align="center">Memorial Descritivo</TableCell>
                        <TableCell sx={{ p: '5px', color: 'white' }} align="center">Diagrama Unifilar</TableCell>
                        <TableCell sx={{ p: '5px', color: 'white' }} align="center">Parecer de Acesso</TableCell>
                        <TableCell sx={{ p: '5px', color: 'white' }} align="center">Relacionamento Operacional</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center" sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                        { typeUser !== 'Cliente' &&   <IconButton onClick={handleDownloadWord} ><CloudDownloadIcon color='primary' /></IconButton> }
                          <IconButton onClick={handleDownloadPDF} ><CloudDownloadIcon color={typeUser !== 'Cliente' ? 'error': 'primary'} /></IconButton>
                        </TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 13 }} align="center"><IconButton onClick={()=> downloads('data_art','ART')} ><CloudDownloadIcon color={props.data.data_art_demand ? 'primary' : 'inherit'} /></IconButton></TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 13 }} align="center"><IconButton onClick={()=> downloads('signed-descriptive-memorial','Memorial Descritivo Assinado')} ><CloudDownloadIcon color={props.data.signed_descriptive_memorial ? 'primary' : 'inherit'} /></IconButton></TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 13 }} align="center"><IconButton onClick={()=> downloads('single-line-diagram','Diagrama Unifilar')} ><CloudDownloadIcon color={props.data.single_line_diagram ? 'primary' : 'inherit'} /></IconButton></TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 13 }} align="center"><IconButton onClick={()=> downloads('access-opinion','Parecer de Acesso')} ><CloudDownloadIcon color={props.data.access_opinion ? 'primary' : 'inherit'} /></IconButton></TableCell>
                        <TableCell sx={{ p: '5px', fontSize: 13 }} align="center"><IconButton onClick={()=> downloads('operational-relationship','Relacionamento Operacional')} ><CloudDownloadIcon color={props.data.operational_relationship ? 'primary' : 'inherit'} /></IconButton></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

            
              <Grid item xs={12}>
             
                  <h3 style={{ fontSize: 14, marginBottom: 10, marginTop: 0 }}>Cliente:</h3>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table" >
                      <TableHead >
                        <TableRow sx={{ background: typeUser === 'Cliente' ? '#000' : '#02557e' }}>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">{props.data.cpf_customers_of_customers ? 'Nome' : 'Rasão Social'}</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">{props.data.cpf_customers_of_customers ? 'CPF' : 'CNPJ'}</TableCell>
                          {props.data.email_customers_of_customers && <TableCell sx={{ p: '5px', color: 'white' }} align="center">E-mail</TableCell>}
                          {props.data.contact_customers_of_customers && <TableCell sx={{ p: '5px', color: 'white' }} align="center">Contato</TableCell>}
                  
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center" sx={{ p: '5px', fontSize: 12 }} component="th" scope="row">
                            {props.data.name_customers_of_customers ? props.data.name_customers_of_customers : props.data.corporate_reason_customers_of_customers}
                          </TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.cpf_customers_of_customers ? props.data.cpf_customers_of_customers : props.data.cnpj_customers_of_customers}</TableCell>
                          {props.data.email_customers_of_customers && <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.email_customers_of_customers}</TableCell>}
                          {props.data.contact_customers_of_customers && <TableCell sx={{ p: '5px', fontSize: 12 }} align="center">{props.data.contact_customers_of_customers}</TableCell>}
                          
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
   
     
                {props.data.cnpj_customers_of_customers && 
                  <TableContainer component={Paper}>
                    <h3 style={{ fontSize: 14, marginBottom: 10, marginTop: 5 }}>Representante Legal:</h3>
                    <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ background: typeUser === 'Cliente' ? '#000' : '#02557e' }}>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Nome</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">CPF</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Profissão</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Nacionalidade</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={props.data.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center" sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                            {props.data.name_representative_customers_of_customers}
                          </TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.cpf_representative_customers_of_customers}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.profession_representative_customers_of_customers}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.nationality_representative_customers_of_customers}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
                </Grid>
              <Grid item xs={12}>
              
                  <br />
                  <h3 style={{ fontSize: 14, marginBottom: 10, marginTop: 5 }}>Inversores:</h3>

                  <TableContainer component={Paper}>
                    <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ background: typeUser === 'Cliente' ? '#000' : '#02557e' }}>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Inversor</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Fase</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Potência</TableCell>

                        </TableRow>
                      </TableHead>
                      {arrayInverters?.map((item, index) => (
                        <TableBody key={index}>
                          <TableRow align="center"
                            sx={{ '&:last-child td, &:last-child th': { border: 0 , fontSize: 13} }}
                          >
                            <TableCell align="center" sx={{ p: '5px', fontSize: 13 }} component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell align="center" sx={{ p: '5px', fontSize: 13 }}>{service_type(item.fase)}</TableCell>
                            <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{item.ptc}</TableCell>

                          </TableRow>
                        </TableBody>
                      ))}
                    </Table>
                  </TableContainer>
           
                <br />
             
                  {(array_qgbt_demand && array_qgbt_demand[0]?.disjuntor) && <TableContainer component={Paper}>
                    <Table size="small" aria-label="Tabela pequena">
                      <TableHead>
                        <TableRow sx={{ background: typeUser === 'Cliente' ? '#000' : '#02557e' }}>
                          <TableCell align="center" style={{ padding: '4px 8px', color: 'white' }}>Conexão</TableCell>
                          <TableCell align="center" style={{ padding: '4px 8px', color: 'white' }}>Potência (kW)</TableCell>
                          <TableCell align="center" style={{ padding: '4px 8px', color: 'white' }}>Corrente (A)</TableCell>
                          <TableCell align="center" style={{ padding: '4px 8px', color: 'white' }}>Disjuntor Mínimo (A)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {array_qgbt_demand.map((qgbt, index) => (
                          <TableRow key={index}>
                            <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.fase}</TableCell>
                            <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.ptc}</TableCell>
                            <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.corrente}</TableCell>
                            <TableCell align="center" style={{ padding: '4px 8px' }}>{qgbt.disjuntor}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>}
                </Grid>
              <Grid item xs={12}>

                  <h3 style={{ fontSize: 14, marginBottom: 10, marginTop: 5 }}>Informações do Projeto:</h3>

                  <TableContainer component={Paper}>
                    <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ background: typeUser === 'Cliente' ? '#000' : '#02557e' }}>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">N° UC</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">N° Cliente</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Tipo de Projeto</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Tipo de Ramal</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Modalidade</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center" sx={{ p: '5px', fontSize: 11 }} component="th" scope="row">
                            {props.data.number_uc_demand}
                          </TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 11 }} align="center">{props.data.number_customer_uc}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 11 }} align="center">{project_type(props.data.project_type_demand)}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{branch_type(props.data.branch_type_demand)}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{modality_type(props.data.modality_type_demand)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer component={Paper}>
                    <Table x={{ minWidth: 600, height: 50 }} aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ background: typeUser === 'Cliente' ? '#000' : '#02557e' }}>

                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Fornecimento</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Tensão da Rede</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Disjuntor (A)</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Seção Nominal (mm²)</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Tipo de Cabo</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{service_type(props.data.service_type_demand)}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.power_rede_demand}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.disjuntor_demand}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.nominal_section_demand}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.type_cable_demand}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {/*   <Box sx={{ height: 100 }}>
          <br />
        <h3 style={{fontSize:14,marginBottom:10,marginTop:5}}>Modulos:</h3>

          <TableContainer component={Paper}>
            <Table x={{ minWidth: 600, height:50 }} aria-label="simple table">
                <TableHead>
                    <TableRow sx={{background: typeUser === 'Cliente' ? '#000':'#02557e'}}>
                    <TableCell sx={{p:'5px',color:'white'}}align="center">Marca</TableCell>
                    <TableCell sx={{p:'5px',color:'white'}}align="center">Modelo</TableCell>
                    <TableCell sx={{p:'5px',color:'white'}}align="center">Quantidade</TableCell>
                
                    </TableRow>
                </TableHead>
                { arrayModules?.map((item, index) => (
                <TableBody key={index}>
                  
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="center" sx={{p:'5px',fontSize:13}}component="th" scope="row">
                        { item.marca}
                    </TableCell>
                    <TableCell align="center" sx={{p:'5px',fontSize:13}}>{item.modelo}</TableCell>
                    <TableCell sx={{p:'5px',fontSize:13}} align="center">{item.quantidade}</TableCell>
       
                    </TableRow>
                </TableBody>
                    ))}
            </Table>
          </TableContainer>
        </Box>*/}

        
              <Grid item xs={12}>
             
                  <h3 style={{ fontSize: 14, marginBottom: 10, marginTop: 5 }}>Endereço do Projeto:</h3>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500, height: 50 }} aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ background: typeUser === 'Cliente' ? '#000' : '#02557e' }}>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Logradouro</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Numero</TableCell>
                          {props.data?.complement_service_demand ? <TableCell sx={{ p: '5px', color: 'white' }} align="center">Complemento</TableCell> : ''}
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Bairro</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Cidade</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Estado</TableCell>
                          <TableCell sx={{ p: '5px', color: 'white' }} align="center">Mapa</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={props.data.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{ p: '5px', fontSize: 13 }} component="th" scope="row" align="center">
                            {props.data.street_service_demand}
                          </TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.number_service_demand}</TableCell>
                          {props.data?.complement_service_demand ? <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.complement_service_demand}</TableCell> : ''}
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.district_service_demand}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.city_service_demand}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center">{props.data.state_service_demand}</TableCell>
                          <TableCell sx={{ p: '5px', fontSize: 13 }} align="center"><IconButton onClick={handleClickOpenMap} ><LayersIcon color='secondary'/></IconButton></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
      
             
              </Grid>
            </Grid>
  


        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
       {
          (props.data.status === 'Solicitado' && typeUser === 'Cliente')? <Button sx={{mr:2}} onClick={handleClickOpenDrop} variant='contained' autoFocus color='error'>
            solicitar cancelamento
          </Button>
          :
          <p></p>
      }
          <Button onClick={props.handleClose}  autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default Popup_demand
