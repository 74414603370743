import { createContext, useEffect, useState } from 'react';
import {useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from 'react-router-dom'




export const AuthContext = createContext({});
export const AuthProvider = ({ children })=>{

const [auth, setAuth] = useState('')
   


const login = async (email,password)=>{
    <ToastContainer />
    
    try{
      
      // fetch('https://api-aplication-fortesol.vercel.app/api/users/login', {
        fetch('https://api.fortesol.com.br/api/users/login', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        })
        .then((response) => response.json())
        .then((data) => {
        
            if(data.message === 'User logged in successfully'){
                toast.success('Usuário logado com sucesso')
                setAuth(data)
                console.log(data)
                localStorage.setItem('token', JSON.stringify(data))
                setTimeout(() => {
                    window.location.href = '/home'
                }, 3000);
            }
            if(data.message !== 'User logged in successfully'){
                toast.error('Usuário ou senha incorretos')
            }        
        })
        .catch((error) => {
            toast.error('Usuário ou senha incorretos') 
                          

        });
        
        
    }catch(err){
        toast.error('Error')


       
    }
  
}


const sinup = async (name,email,password)=>{
    <ToastContainer />
    try{
       fetch('https://api-aplication-fortesol.vercel.app/api/users/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name,email, password }),
        })
        .then((response) => response.json())
        .then((data) => {

            if(data.message === 'User created successfully'){
                toast.success(data.message)
                localStorage.setItem('token', JSON.stringify(data))
                setTimeout(() => {
                    window.location.href = '/home'
                }, 3000);
            }
            if(data.message !== 'User created successfully'){
                toast.error(data.message) 
            }
            
            
            
        })
        .catch((error) => {
            toast.error('Error')        
        });
        
        
    }catch(err){
        toast.error('Error')
       
    }
  
}


const logout = ()=>{
  
    localStorage.removeItem('token')
    window.location.href = '/'
}







    return(
        <AuthContext.Provider value={{login,logout,sinup,returnAuth:auth}}>
            {children}
        </AuthContext.Provider>
    )




 
}
